import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Web3Provider } from "@ethersproject/providers";
import { createConfig, http } from "wagmi";
import {
  mainnet,
  sepolia,
  polygon,
  gnosis,
  base,
  baseSepolia,
} from "wagmi/chains";
import { metaMask, safe, walletConnect, injected } from "wagmi/connectors";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WagmiProvider } from "wagmi";
import config from "./services/config/homestead.json";
import { createWeb3Modal } from "@web3modal/wagmi/react";
import { GlobalProvider } from "./context/globalContext";

const Wagmiconfig = createConfig({
  chains: [mainnet, sepolia, polygon, gnosis, base, baseSepolia],
  transports: {
    [mainnet.id]: http(),
    [sepolia.id]: http(),
    [polygon.id]: http(),
    [gnosis.id]: http(),
    [base.id]: http(),
    [baseSepolia.id]: http(),
  },
  ssr: true,
  connectors: [
    walletConnect({
      projectId: config.keys.walletConnect,
      showQrModal: false,
      metadata: {
        name: "Verified Network",
        description: "Verified Network",
        url: window.location.href,
      },
    }),
    injected({ shimDisconnect: true }),
    // safe({ shimDisconnect: true, debug: true }),
  ],
});

createWeb3Modal({
  wagmiConfig: Wagmiconfig,
  projectId: config.keys.walletConnect,
  enableAnalytics: true,
  enableOnramp: true,
  // allWallets: "ONLY_MOBILE",
});

function getLibrary(provider) {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
}

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <WagmiProvider config={Wagmiconfig}>
    <QueryClientProvider client={queryClient}>
      <GlobalProvider>
        <App />
      </GlobalProvider>
    </QueryClientProvider>
  </WagmiProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
