import React, { useEffect, useState } from "react";
import Loader from "../../../components/loader";
import { useSecondarySettlements } from "../../../context/contractDataLoaderService";
import UiTable from "../../../components/table/Table";
import {
  SecondaryIssueManager,
  contractAddress,
} from "@verified-network/verified-sdk";
import { useVerifiedSecurity } from "../../../context/verifiedWalletData";
import { useGlobal } from "../../../context/globalContext";

import Web3 from "web3";
import { providers } from "ethers";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../../utils/helpers/networks";
import { useAccount, useClient, useConnectorClient } from "wagmi";

function InvestorInfo(props) {
  const { userRole, isUpdate, setIsUpdate } = useGlobal();
  const [tableData, setTableData] = useState([]);
  const category = props.match.params.category;
  const securityAddress = props.match.params.securityAddress;
  let { data: verifiedSecurity } = useVerifiedSecurity(
    securityAddress,
    isUpdate
  );
  const { data: secondaryInvestors, loading } =
    useSecondarySettlements(verifiedSecurity);

  useEffect(() => {
    setTableData(secondaryInvestors);
  }, [secondaryInvestors, isUpdate]);

  let headers;
  let pageHeading;

  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  let secondaryIssueManagerContract;

  if (provider && provider.getSigner && chainId && address) {
    if (contractAddress[chainId].BalancerSecondaryIssueManager) {
      secondaryIssueManagerContract = new SecondaryIssueManager(
        provider.getSigner(address),
        contractAddress[chainId].BalancerSecondaryIssueManager
      );
    }
  }

  pageHeading = "Secondary Investors";
  if (category == "transactions") {
    headers = [
      { label: "Seller", val: "transferorName" },
      { label: "Price", val: "price" },
      { label: "Qty", val: "unitsToTransfer" },
      { label: "Buyer", val: "transfereeName" },
      { label: "Execution Date", val: "date" },
      userRole == "DP" || userRole == "intermediary"
        ? { label: "Call Action", val: "buttonDetails" }
        : {},
    ];
  } else {
    headers = [
      { label: "Investor name", val: "transferorName" },
      { label: "Investor Address", val: "transferor" },
      { label: "Record date", val: "scheduledRecordDate" },
      { label: "Balance", val: "updateBalance" },
      { label: "Earning", val: "updateEarnings" },
      userRole == "DP" || userRole == "intermediary"
        ? { label: "Call Action", val: "secondaryInvestorCallAction" }
        : {},
    ];
  }

  return (
    <>
      {loading ? <Loader /> : null}
      <section className="d-flex flex-column align-items-start px-0 py-4">
        <div className="mb-3 d-flex justify-content-between w-100 align-items-center">
          <div className="">
            <h3 className="">{pageHeading}</h3>
          </div>
        </div>
        <div className="pools-table width-100">
          <UiTable
            thead={headers}
            tbodyData={tableData || []}
            hover
            bordered={false}
            contract={secondaryIssueManagerContract}
            signer={signer}
            web3={web3}
          />
        </div>
      </section>
    </>
  );
}

export default function SecondaryInvestorDetail(props) {
  return <InvestorInfo {...props} />;
}
