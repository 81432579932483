import React, { useRef, useState } from "react";
import Loader from "../../../components/loader";
import { useBalancerData } from "../../../context/balancerPoolData";
import {
  useGetsubscribers,
  useNetworkOptions,
} from "../../../context/contractDataLoaderService";
import UiTable from "../../../components/table/Table";
import {
  PrimaryIssueManager,
  contractAddress,
} from "@verified-network/verified-sdk";
import { useVerifiedSecurity } from "../../../context/verifiedWalletData";
import { useGlobal } from "../../../context/globalContext";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../../utils/helpers/networks";

import { usePDF } from 'react-to-pdf';
import { useReactToPrint } from 'react-to-print';
import { filterWithDate } from "../../../utils/constants";
import VerticallyModal from "../../../components/modal/VerticallyModal";
import { Col, Row } from "react-bootstrap";
import DateTimePicker from "react-datetime-picker/dist/DateTimePicker";
import UiButton from "../../../components/button";

function InvestorInfo(props) {
  const securityAddress = props.match.params.securityAddress;
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const { userRole } = useGlobal();

  let { data: verifiedSecurity } = useVerifiedSecurity(securityAddress);
  let { data: primarySubscribers, loading } =
    useGetsubscribers(verifiedSecurity);
  const { data: networkOptions } = useNetworkOptions();

  let headers;
  let pageHeading;
  let primaryIssueManagerContract;

  if (provider && provider.getSigner && chainId && address) {
    const BalancerPrimaryIssueManager =
      contractAddress[chainId].BalancerPrimaryIssueManager;
    if (BalancerPrimaryIssueManager) {
      primaryIssueManagerContract = new PrimaryIssueManager(
        provider.getSigner(address),
        BalancerPrimaryIssueManager,
        "balancer"
      );
    }
  }
  const { toPDF, targetRef } = usePDF({filename: `primary investors ${new Date().toLocaleDateString()}-${new Date().toLocaleTimeString()}.pdf`});
  const [startDate, onChangeStartDate] = useState(new Date());
  const [endDate, onChangeEndDate] = useState(new Date());
  const [showModal, setShowModal] = useState(false);
  const [modalView, setModalView] = useState(0);
  const [tableData, setTableData] = useState(null)
  const modalContent = useRef(null);
  const componentToPrintRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentToPrintRef.current,
  });

  pageHeading = "Primary Investors";
  headers = [
    { label: "Date", val: "date" },
    { label: "Investor Name", val: "investorName" },
    { label: "Investor Address", val: "address" },
    { label: "Invested Token", val: "tokenName" },
    { label: "Subscription Qty", val: "qty" },
    { label: "Security Traded", val: "traded" }
  ];

  const handleModalHide = () => {
    setShowModal(false);
    setShowModal(0)
  }

  const commonProps = {
    modalView,
    updateModalView: setModalView,
    showModal,
    updateShowModal: setShowModal,
    tableData,
    updateTableData: setTableData,
    targetRef: targetRef,
    toPDF: toPDF,
    componentToPrintRef: componentToPrintRef,
    handlePrint: handlePrint
  };

  let message, heading;
  if (modalView === 10) {
    heading = "Custom Filter";
    message = (
      <>
        <div className="d-grid gap-2" ref={modalContent}>
          <Row className="ml-1 align-items-center">
            <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
              <Col xs={{ span: 4, offset: 2 }} className="text-left">
                <b>From</b>
              </Col>
              <Col className="pl-0" xs={3.5}>
              <DateTimePicker onChange={onChangeStartDate} value={startDate} />
              </Col>
            </Row>
            <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
              <Col xs={{ span: 4, offset: 2 }} className="text-left">
                <b>To</b>
              </Col>
              <Col className="pl-0" xs={3.5}>
              <DateTimePicker onChange={onChangeEndDate} value={endDate} />
              </Col>
            </Row>
            <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
              <Col className="pl-0">
                <UiButton
                   onClick={() => {
                    if(primarySubscribers.length > 0) {
                       //  console.log("new: ", filterWithDate(list, "date"))
                       setTableData(filterWithDate(primarySubscribers, "date", startDate, endDate));
                    }
                      handleModalHide();
                    }}
                  buttonVariant="primary"
                  buttonClass="SignUpButton flex-1 ml-0"
                  buttonText="Filter"
                  type="submit"
                />
              </Col>
            </Row>
          </Row>
        </div>
      </>
    );
  }



  return (
    <>
      {loading ? <Loader /> : null}
      <section className="d-flex flex-column align-items-start px-0 py-4">
        <div className="mb-3 d-flex justify-content-between w-100 align-items-center">
          <div className="">
            <h3 className="">{pageHeading}</h3>
          </div>
        </div>
        <div className="pools-table width-100">
          <UiTable
            thead={headers}
            tbodyData={tableData? tableData : primarySubscribers ? primarySubscribers : []}
            hover
            bordered={false}
            web3={web3}
            chainId={chainId}
            networkOptions={networkOptions}
            contract={primaryIssueManagerContract}
            signer={signer}
            commonProps={commonProps}
            showFilter={true}
          />
        </div>
      </section>
      <VerticallyModal
        key="connectProvider"
        showModal={showModal}
        modalOnHide={handleModalHide}
        modalSize={"lg"}
        modalHeading={
          <h2>
            <b>{heading}</b>
          </h2>
        }
        withFooter={false}
      >
        {message}
      </VerticallyModal>
    </>
  );
}

export default function PrimaryInvestorDetail(props) {
  return <InvestorInfo {...props} />;
}
