import { useState, useRef, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Loader from "../../components/loader";
import UiButton from "../../components/button";
import TextInput from "../../components/textinput/TextInput";
import {
  useSupportedTokens,
  useNetworkOptions,
  useCustodianData,
} from "../../context/contractDataLoaderService";

import ERC20ABI from "../../utils/abi/ERC20.json";
import PaymentModal from "../../pages/custodian/Payments/PaymentModal";
import paymentGateway from "../../pages/custodian/api/PaymentGateway";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Web3 from "web3";
import {
  PrimaryIssueManager,
  SecuritiesFactory,
  contractAddress,
} from "@verified-network/verified-sdk";
import {
  SuccessToast,
  FailureToast,
  ToastOptions,
  networks,
} from "../../utils/constants";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../utils/helpers/networks";
import { providers } from "ethers";

function NewOffer(props) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: client } = useConnectorClient({ chainId });
  const { chain, transport } = client || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [issueAmount, setIssueAmount] = useState("");
  const [desiredAmount, setDesiredAmount] = useState("");
  const [minInvestment, setMinInvestment] = useState("");
  const [ipfsDoc, setIpfsDoc] = useState("");
  let { data: tokensSupported } = useSupportedTokens();
  const [changeContent, setChangeContent] = useState(false);
  const [paymentModalVisibility, setPaymentModalVisibility] = useState(false);
  const [paymentResponse, setPaymentResponse] = useState(null);
  const { data: networkOptions } = useNetworkOptions();
  const history = useHistory();
  let { data: custodianData } = useCustodianData(
    null,
    props.data.offered,
    props.data.owner
  );

  const tokenSelected = tokensSupported?.find(
    (data) => data.tokenAddress === props.data.currencyAddress
  );

  // console.log("CustodianData", tokenSelected);
  const modalContent = useRef(null);

  let primaryIssueManagerContract, factoryContract;
  if (provider && provider.getSigner && chainId && address) {
    const BalancerPrimaryIssueManager =
      contractAddress[chainId].BalancerPrimaryIssueManager;
    if (BalancerPrimaryIssueManager) {
      primaryIssueManagerContract = new PrimaryIssueManager(
        provider.getSigner(address),
        BalancerPrimaryIssueManager,
        "balancer"
      );
    }
    if (contractAddress[chainId].SecuritiesFactory) {
      factoryContract = new SecuritiesFactory(
        provider.getSigner(address),
        contractAddress[chainId].SecuritiesFactory
      );
    }
  }

  const clearQueryParams = () => {
    history.push({
      search: "",
    });
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const redirectStatus = searchParams.get("redirect_status");

    switch (redirectStatus) {
      case "succeeded":
        toast.success(SuccessToast("", "Payment Successful!"), ToastOptions);
        clearQueryParams();
        break;
      case "failed":
        toast.error(FailureToast("", "Payment Unsuccessful!"), ToastOptions);
        clearQueryParams();
        break;
      default:
        break;
    }
  }, []);

  const stripePayment = async () => {
    const selectedFiatCurrency = custodianData?.find(
      (data) => data.issueCurrencyAddress === props.data.currencyAddress
    );

    // Code for creating cash request for Fiat Payment
    try {
      setLoading(true);
      await paymentGateway
        .createCashIssueRequest(
          selectedFiatCurrency.country,
          account,
          selectedFiatCurrency.accountId,
          issueAmount,
          selectedFiatCurrency.currency,
          selectedFiatCurrency.issueCurrencyAddress,
          chainId
        )
        .then((result) => {
          console.log("Result after API createCashIssueRequest", result);
          setLoading(false);
          setPaymentModalVisibility(true);
          setPaymentResponse(result);
        });
    } catch (error) {
      setLoading(false);
      toast.error(
        FailureToast("", "Amount must be at least CHF 0.50 chf"),
        ToastOptions
      );
      console.log("Error", error);
    }
  };
  // console.log("Make an Offer", props.data);
  const offerCurrency = async () => {
    const rowData = props.data;
    if (issueAmount.length == 0) {
      alert("Enter issue amount");
    } else if (desiredAmount.length == 0) {
      alert("Enter desired amount");
    } else if (minInvestment.length == 0) {
      alert("Enter minimum investment");
    }

    const currencyTokenContract = new web3.eth.Contract(
      ERC20ABI,
      rowData.currencyAddress
    );
    const BalancerPrimaryIssueManager =
      contractAddress[chainId].BalancerPrimaryIssueManager;
    const tokenDecimals = await currencyTokenContract.methods.decimals().call();
    const amountOfferedFixed = String(issueAmount * 10 ** tokenDecimals);
    const allowance = currencyTokenContract.methods
      .allowance(account, BalancerPrimaryIssueManager)
      .call();
    setLoading(true);
    let isApproved = false;
    if (Number(allowance) > Number(amountOfferedFixed)) {
      console.log("it's approved....", allowance, amountOfferedFixed);
      isApproved = true;
    } else {
      console.log("not approved....");
      await currencyTokenContract.methods
        .approve(BalancerPrimaryIssueManager, amountOfferedFixed)
        .send({ from: account, ...(chainId === 137 ? networkOptions : {}) })
        .then(() => {
          isApproved = true;
        })
        .catch((err) => {
          console.error("approval function failed with error: ", err);
          setLoading(false);
        });
    }
    if (isApproved) {
      // console.log("min investment: ", minInvestment);
      try {
        const tx = await primaryIssueManagerContract.offer(
          rowData.currencyAddress,
          rowData.isin,
          amountOfferedFixed,
          rowData.offered,
          desiredAmount,
          minInvestment,
          account,
          rowData.offeringDoc,
          networkOptions
        );
        if (tx.code === "ACTION_REJECTED") {
          toast.error("Transaction rejected by user!", ToastOptions);
        } else {
          const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${tx.response.hash}`;
          toast.success(SuccessToast(transactionLink), ToastOptions);
          setChangeContent(true);
          modalContent.current.innerHTML = "";
        }
        setLoading(false);
      } catch (err) {
        let error = { err };
        console.log(error);
        const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${error.err.transactionHash}`;
        setLoading(false);
        toast.error(FailureToast(transactionLink), ToastOptions);
      }
    }
  };
  return (
    <>
      {loading ? <Loader /> : null}
      {changeContent && (
        <UiButton
          onClick={() => {
            props.onModalHide();
          }}
          buttonVariant="primary"
          buttonClass="SignUpButton flex-1 ml-0"
          buttonText="Click to close this form&nbsp;&rarr;"
          type="submit"
        />
      )}
      <div className="d-grid gap-2" ref={modalContent}>
        <Row className="ml-1 align-items-center">
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Offer amount</b>
              {/*<div>Balance : {tokenSelected?.balance} {tokenSelected?.tokenName}</div>*/}
            </Col>
            <Col className="pl-0" xs={5}>
              <TextInput
                placeholder="Currenncy Amount"
                fieldType="number"
                onChange={(e) => setIssueAmount(e.target.value)}
                required
              />
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Target investment</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <TextInput
                placeholder="Desired Security Amount"
                fieldType="number"
                onChange={(e) =>
                  setDesiredAmount(web3.utils.toWei(e.target.value, "ether"))
                }
                required
              />
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Minimum investment</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <TextInput
                placeholder="Minimum Security Amount"
                fieldType="number"
                onChange={(e) =>
                  setMinInvestment(web3.utils.toWei(e.target.value, "ether"))
                }
                required
              />
            </Col>
          </Row>
          {/* <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col className="pl-0">
              {tokenSelected?.isFiat &&
              tokenSelected?.balance < Number(issueAmount) ? (
                <div className="d-flex justify-content-center">
                  <span onClick={() => stripePayment()} className="stripeLink">
                    Top up using card & online banking
                  </span>
                </div>
              ) : null}
            </Col>
          </Row> */}
        </Row>
        <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
          <Col className="pl-0">
            <UiButton
              onClick={() => {
                offerCurrency();
              }}
              buttonVariant="primary"
              buttonClass="SignUpButton flex-1 ml-0"
              buttonText="Make Offer"
              type="submit"
            />
          </Col>
        </Row>
        <PaymentModal
          show={paymentModalVisibility}
          paymentRequest={paymentResponse}
          onHide={() => {
            setPaymentModalVisibility(!paymentModalVisibility);
          }}
        />
      </div>
    </>
  );
}

export function MakeOffer(props) {
  return <NewOffer {...props} />;
}
