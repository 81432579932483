import { Network } from "../../../utils/helpers";

const DEFAULT_NETWORK_ID = Network.MAINNET;

export const networkId = DEFAULT_NETWORK_ID;

export const isPolygon = networkId === Network.POLYGON;
export const isKovan = networkId === Network.KOVAN;

export default function useNetwork() {
  return {
    // setNetworkId,
    networkId,
  };
}
