import React, { useEffect, useState } from "react";
import Loader from "../../../components/loader";
import UiTable from "../../../components/table/Table";
import { usePlatformDetails } from "../../../context/contractDataLoaderService";

function ServicerPlatforms(props) {

  let { data, loading, reloadPlatformData  } =  usePlatformDetails();
  let headers = [
    { label: "Platform Address", val: "platformAddress" },
    { label: "Platform Name", val: "platformName" },
    { label: "Liquidity Provided (VITTA)", val: "platformLiquidityProvided" },
    { label: "Commissions Earned", val: "platformCommissionsEarned" },
    { label: "Call Action", val: "platformCallAction" },
  ];
  const options = {
    onClick: (event, row) => {
      onInvest(row);
    },
  };

  useEffect(() => {
    reloadPlatformData();
  }, [props.updateTable]);

  const onInvest = (row) => {
    props.history.push(`/platform/${row.platformName}/${row.platformAddress}`);
  };
  return (
    <>
      {loading ? <Loader /> : null}
      <section className="d-flex w-100 flex-column align-items-start px-0 py-4">
        <div className="pools-table w-100">
            <UiTable
            thead={headers}
            tbodyData={data || []}
            hover
            bordered={false}
            rowEvents={options}
            />
        </div>
      </section>
    </>
  );
}

export default function Platforms(props) {
  return <ServicerPlatforms {...props} />;
}
