import React, { useState, useEffect } from "react";
import { Col, Row, Form } from "react-bootstrap";
import UiButton from "../../components/button";
import TextInput from "../../components/textinput/TextInput";
import { runPrimaryBatchSwap } from "./swaps/primarySwap";
import { runSecondaryBatchSwap } from "./swaps/secondarySwap";

import { PoolType } from "./balancer/types";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Loader from "../../components/loader/index";
import { useGlobal, fetchSumsubAccessToken } from "../../context/globalContext";
import {
  SuccessToast,
  FailureToast,
  ToastOptions,
  DefaultTokenPair,
} from "../../utils/constants";
import { toast } from "react-toastify";
import { PrimaryIssuePool, SecondaryIssuePool } from "../../dist/index";
import {
  useTokenList,
  useCustodianData,
  useNetworkOptions,
  useSupportedTokens,
} from "../../context/contractDataLoaderService";
import ERC20ABI from "../../utils/abi/ERC20.json";
import MarginManager from "../../utils/abi/MarginManager.json";
import MarginPool from "../../utils/abi/MarginTradingPool.json";
import PaymentModal from "../custodian/Payments/PaymentModal";
import Web3 from "web3";
import { Pool, contractAddress } from "@verified-network/verified-sdk";
import { useHistory } from "react-router-dom";
import { constructSimpleSDK, SwapSide } from "@paraswap/sdk";
import paymentGateway from "../custodian/api/PaymentGateway";
import KYCModal from "../../components/common/kycModal";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../utils/helpers/networks";
import { getKycStatus } from "../../utils/helpers";

const { ethers, BigNumber, providers } = require("ethers");

const PoolInfo = (props) => {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const { pool, tokenBalance } = props;
  // console.log("pool: ", pool);
  const { kycStatus } = useGlobal();
  const history = useHistory();
  const [showKYCModal, setshowKYCModal] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [slippage, setSlippage] = useState(null);
  const [orderType, setOrderType] = useState("Market");
  const [swapType, setSwapType] = useState("Buy");
  const [qty, setQty] = useState("");
  const [sorValue, setSORValue] = useState("");
  const [price, setPrice] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedToken, setSelectedToken] = useState();
  const [swapAmount, setSwapAmount] = useState("");
  const [priceRoute, setPriceRoute] = useState(false);
  const [priceSwapSDK, setPriceSwapSDK] = useState(null);
  const [paymentModalVisibility, setPaymentModalVisibility] = useState(false);
  const [paymentResponse, setPaymentResponse] = useState(null);
  const [updatedSecurityBalance, setUpdatedSecurityBalance] = useState(null);
  const [averagePrice, setAveragePrice] = useState(0.0);
  const [marginAmount, setMarginAmount] = useState(1);
  const [, setUpdatedCurrencyBalance] = useState(null);
  const { data: networkOptions } = useNetworkOptions();
  const { data: supportedTokensList } = useSupportedTokens();

  const { data: tokenList } = useTokenList();
  const { data: custodianData } = useCustodianData(
    null,
    props.pool.securityToken,
    props.pool.verifiedWalletData?.issuer?.id
  );

  const clearQueryParams = () => {
    history.push({
      search: "",
    });
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const redirectStatus = searchParams.get("redirect_status");

    switch (redirectStatus) {
      case "succeeded":
        toast.success(SuccessToast("", "Payment Successful!"), ToastOptions);
        clearQueryParams();
        break;
      case "failed":
        toast.error(FailureToast("", "Payment Unsuccessful!"), ToastOptions);
        clearQueryParams();
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    const setMargin = async () => {
      if (pool.poolType === PoolType.MarginIssue && web3) {
        const marginPoolContract = new web3.eth.Contract(
          MarginPool.abi,
          pool.address
        );
        const marginAmt = await marginPoolContract.methods.getMargin().call();
        setMarginAmount(web3.utils.fromWei(marginAmt.toString(), "ether"));
      }
    };
    setMargin();
  }, [pool, web3]);

  let paraSwap;
  const tokens = pool.tokens || [];
  const securityToken = tokens.find(
    (token) =>
      token.address.toLowerCase() === props.pool.securityToken.toLowerCase()
  );
  const currencyToken = tokens.find(
    (token) => token.address.toLowerCase() === props.pool.currency.toLowerCase()
  );
  const poolCurrencyList = tokenBalance?.filter(
    (token) => token.symbol === currencyToken.symbol
  );

  // console.log("marginAmount: ", marginAmount);
  const fiatTokenList = supportedTokensList
    ?.filter((token) => token.isFiat)
    .map((token) => token.tokenAddress.toLowerCase());
  const finalTokenList = poolCurrencyList?.map((token) => {
    if (fiatTokenList?.includes(token.address.toLowerCase()))
      return { ...token, isFiat: true };
    else return { ...token, isFiat: false };
  });

  const stripePayment = async () => {
    const selectedFiatCurrency = custodianData?.find(
      (data) =>
        data.issueCurrencyAddress.toLowerCase() ===
        selectedToken.address.toLowerCase()
    );
    // Code for creating cash request for Fiat Payment
    try {
      setLoading(true);
      await paymentGateway
        .createCashIssueRequest(
          selectedFiatCurrency.country,
          account,
          selectedFiatCurrency.accountId,
          qty,
          selectedFiatCurrency.currency,
          selectedFiatCurrency.issueCurrencyAddress,
          chainId
        )
        .then((result) => {
          setLoading(false);
          if (result.error) {
            toast.error(result.error, ToastOptions);
          } else {
            setPaymentModalVisibility(true);
            setPaymentResponse(result);
          }
        });
    } catch (error) {
      setLoading(false);
      // toast.error(
      //   FailureToast("", "Amount must be at least CHF 0.50 chf"),
      //   ToastOptions
      // );
      console.log("Error", error);
    }
  };

  useEffect(() => {
    if (Number(qty) && swapType === "Sell") {
      if (Number(qty) < Number(pool?.minOrderSize)) {
        toast.error(
          FailureToast(
            "",
            "Enter quantity greater than minimum order size (i.e 1)"
          ),
          ToastOptions
        );
      }
    }
  }, [qty, swapType]);

  useEffect(() => {
    if (
      Object.keys(props.pool).length &&
      props.pool.poolType !== PoolType.MarginIssue &&
      qty !== ""
    ) {
      calculateSORValue(qty);
    }
    if (Number(qty) <= 0) setSlippage(null);
  }, [qty, swapType, orderType, selectedToken]);

  const calculateSORValue = async (value) => {
    if (provider && provider.getSigner) {
      const providerOptionsEther = {
        ethersProviderOrSigner: provider, // JsonRpcProvider
        EthersContract: ethers.Contract,
        account: account,
      };
      setQty(value);

      let tokenIn, tokenOut, amount;
      amount = Number(value);
      if (swapType === "Sell") {
        tokenIn = props.pool.security;
        tokenOut = props.pool.currency;
      } else {
        tokenIn = props.pool.currency;
        tokenOut = props.pool.security;
      }
      // console.log("props.pool",props.pool);
      const pool =
        props.pool.poolType === "PrimaryIssue"
          ? PrimaryIssuePool.fromPool(props.pool)
          : SecondaryIssuePool.fromPool(props.pool);
      const poolPairData = pool.parsePoolPairData(tokenIn, tokenOut);
      // console.log("poolPairData: ", poolPairData);
      amount = ethers.utils.parseUnits(
        amount.toString(),
        Number(poolPairData.decimalsIn)
      );
      const balanceArray = pool.tokens.map((token) => {
        return {
          [token.address === securityToken.address ? "security" : "currency"]:
            token.balance,
        };
      });
      let newSecurityBalance, newCurrencyBalance;
      const currencyValue = parseFloat(
        balanceArray?.find((obj) => "currency" in obj).currency
      );
      const securityValue = parseFloat(
        balanceArray?.find((obj) => "security" in obj).security
      );

      const constantPrice = Number(currencyValue * securityValue);
      const beforeSwapPrice = Number(currencyValue / securityValue);
      // console.log("ammt...: ", BigNumber.from(amount.toString()));
      // console.log(
      //   "curVal: ",
      //   currencyValue,
      //   "secVal: ",
      //   securityValue,
      //   "const: ",
      //   constantPrice,
      //   "swpP: ",
      //   beforeSwapPrice
      // );
      let amountOut, slippage;
      if (swapType === "Sell") {
        amountOut = pool._exactTokenInForTokenOut(
          poolPairData,
          BigNumber.from(amount.toString()),
          account
        );
        slippage = pool._spotPriceAfterSwapExactTokenInForTokenOut(
          poolPairData,
          BigNumber.from(amount.toString()),
          account
        );
        // console.log("slippage: ", Number(slippage));
        newSecurityBalance = Number(securityValue) + Number(qty);
        newCurrencyBalance = constantPrice / newSecurityBalance;
      } else if (swapType === "Buy") {
        // console.log("ammt: ", Number(amount), "ammmt: ", Number(value));
        // console.log(
        //   "amt Out 1: ",
        //   Number(
        //     pool._tokenInForExactTokenOut(
        //       poolPairData,
        //       BigNumber.from(amount.toString()),
        //       account
        //     )
        //   )
        // );

        // console.log(
        //   "amt Out 2: ",
        //   Number(
        //     pool._tokenInForExactTokenOut(
        //       poolPairData,
        //       ethers.BigNumber.from(
        //         Web3.utils.toWei(Number(value).toString(), "ether")
        //       ),
        //       account
        //     )
        //   )
        // );
        // console.log(
        //   "amt: ",
        //   amount.toString(),
        //   swapType === "Buy" && props.pool.poolType === "SecondaryIssue"
        //     ? Number(BigNumber.from(amount.toString()))
        //     : ""
        // );
        amountOut = pool._tokenInForExactTokenOut(
          poolPairData,
          swapType === "Buy" && props.pool.poolType === "SecondaryIssue"
            ? BigNumber.from(amount.toString())
            : ethers.BigNumber.from(
                Web3.utils.toWei(Number(value).toString(), "ether")
              ),
          account
        );
        slippage = pool._spotPriceAfterSwapTokenInForExactTokenOut(
          poolPairData,
          BigNumber.from(amount.toString()),
          account
        );
        newSecurityBalance = Number(securityValue) - Number(qty);
        newCurrencyBalance = constantPrice / newSecurityBalance;
      }
      // const slippagePercentage = (
      //   ((Number(slippage) - beforeSwapPrice) / beforeSwapPrice) *
      //   100
      // ).toFixed(2);
      console.log("slippage: ", Number(slippage));
      const slippagePercentage = (Number(slippage) * 100).toFixed(8);
      setSlippage(slippagePercentage);
      setSORValue(amountOut);

      if (
        selectedToken?.address &&
        currencyToken?.address &&
        selectedToken?.address.toLowerCase() !==
          currencyToken?.address.toLowerCase() &&
        Number(amountOut) !== 0
      ) {
        const paraSwap = constructSimpleSDK(
          { chainId: chainId, fetch: window.fetch },
          providerOptionsEther
        );
        setPriceSwapSDK(paraSwap);
        let srcToken, destToken, amountCheck;
        if (swapType === "Sell") {
          srcToken = currencyToken;
          destToken = selectedToken;
          amountCheck = amountOut;
        } else if (swapType === "Buy") {
          srcToken = selectedToken;
          destToken = currencyToken;

          const priceCheckRoute = await paraSwap.swap.getRate({
            srcToken: currencyToken.address,
            destToken: selectedToken.address,
            amount: amountOut * Math.pow(10, currencyToken.decimals),
            userAddress: account,
            side: SwapSide.SELL,
          });
          console.log(
            `==========Price Check Route [USDC ---> ${selectedToken.symbol}]=========`,
            priceCheckRoute
          );
          amountCheck =
            priceCheckRoute.destAmount /
            Math.pow(10, priceCheckRoute.destDecimals);
          setSwapAmount(amountCheck);
        }

        const paraSwapRoute = await paraSwap.swap.getRate({
          srcToken: srcToken.address,
          destToken: destToken.address,
          amount: amountCheck * Math.pow(10, srcToken.decimals),
          userAddress: account,
          side: SwapSide.SELL,
        });
        console.log(
          `=======FINAL priceRoute [${srcToken.symbol} ---> ${destToken.symbol}]====`,
          paraSwapRoute
        );
        const finalCurrencyPriceAfterParaswap = Number(
          paraSwapRoute.destAmount / Math.pow(10, paraSwapRoute.destDecimals)
        );
        const finalPricePercentage =
          ((finalCurrencyPriceAfterParaswap - Number(amountOut)) /
            Number(amountOut)) *
          100;
        // console.log("finalPricePercentage",finalPricePercentage, finalCurrencyPriceAfterParaswap, amountOut, Number(amountOut) - finalCurrencyPriceAfterParaswap);
        setPriceRoute(paraSwapRoute);
        setSlippage(finalPricePercentage.toFixed(2));
        if (swapType === "Sell") setSwapAmount(finalCurrencyPriceAfterParaswap);
        // Code to adjust slippage
        if (finalPricePercentage < 0 && swapType === "Buy") {
          // amountToSend is X --> [X * slippage% of X] = Actual USDC to send
          const amountToSend = (100 * amountOut) / (100 + finalPricePercentage);
          console.log("amountToSend", amountToSend);

          const priceCheckRoute = await paraSwap.swap.getRate({
            srcToken: currencyToken.address,
            destToken: selectedToken.address,
            amount: Math.ceil(
              amountToSend * Math.pow(10, currencyToken.decimals)
            ),
            userAddress: account,
            side: SwapSide.SELL,
          });

          // console.log(`==========2nd Time to cover slippage [USDC ---> ${selectedToken.symbol}]=========`, priceCheckRoute);
          const swapAmountIncreased =
            priceCheckRoute.destAmount /
            Math.pow(10, priceCheckRoute.destDecimals);
          const finalPercentageIncreased =
            ((swapAmountIncreased - Number(amountCheck)) /
              Number(amountCheck)) *
            100;
          setSlippage(finalPercentageIncreased.toFixed(2));
          setSwapAmount(swapAmountIncreased);

          const paraSwapRoute = await paraSwap.swap.getRate({
            srcToken: srcToken.address,
            destToken: destToken.address,
            amount: swapAmountIncreased * Math.pow(10, srcToken.decimals),
            userAddress: account,
            side: SwapSide.SELL,
          });
          console.log(
            `=======2nd Time to cover slippage priceRoute [${srcToken.symbol} ---> ${destToken.symbol}]====`,
            paraSwapRoute
          );
          setPriceRoute(paraSwapRoute);
        }
      } else {
        // console.log("else............");
        setSwapAmount(amountOut);
      }
    } else {
      console.log("no provider......");
    }
  };

  const onPrimaryTrade = async () => {
    const userKycStatus = await getKycStatus(address, chainId, signer);
    if (userKycStatus !== 3) {
      const data = await fetchSumsubAccessToken(account, chainId);
      setshowKYCModal(true);
      setAccessToken(data.accessToken);
      return;
    }
    var poolId = props.pool.id;
    if (qty === "") {
      alert("Enter Quantity");
      return;
    }

    setLoading(true);
    try {
      await runPrimaryBatchSwap(
        poolId,
        props.pool,
        transport,
        provider,
        chainId,
        account,
        swapType,
        securityToken?.symbol,
        qty,
        loading,
        sorValue,
        priceSwapSDK,
        priceRoute,
        networkOptions
      );
    } catch (err) {
      toast.error(FailureToast(), ToastOptions);
    }
    if (swapType === "Sell") {
      setUpdatedSecurityBalance(Number(tokenSelected?.balance) - Number(qty));
      setUpdatedCurrencyBalance(
        Number(selectedToken?.balance) + Number(swapAmount)
      );
    } else if (swapType === "Buy") {
      setUpdatedSecurityBalance(Number(tokenSelected?.balance) + Number(qty));
      setUpdatedCurrencyBalance(
        Number(tokenSelected?.balance) - Number(swapAmount)
      );
    }
    setLoading(false);
  };

  const onSecondaryTrade = async () => {
    const userKycStatus = await getKycStatus(address, chainId, signer);
    if (userKycStatus !== 3) {
      const data = await fetchSumsubAccessToken(account, chainId);
      setshowKYCModal(true);
      setAccessToken(data.accessToken);
      return;
    }

    if (transport && chainId) {
      const web3 = getWeb3(transport);
      let isvalidated = true;
      if (
        props.pool.poolType === PoolType.MarginIssue &&
        swapType.toLowerCase() === "buy"
      ) {
        const marginManagerContract = new web3.eth.Contract(
          MarginManager.abi,
          contractAddress[chainId].BalancerMarginIssueManager
        );
        const poolMargin = Number(props.pool.margin);
        const poolCollateral = Number(props.pool.collateral);
        const cashTraded = Number(props.currentPrice * qty).toFixed(4);
        const marginAmt = cashTraded * poolMargin;
        const collateralAmt = cashTraded * poolCollateral;
        const userCollateral = await marginManagerContract.methods
          .getUserCollateral(account, selectedToken.address)
          .call();
        const userCollateralFmt = Number(
          web3.utils.fromWei(userCollateral.toString(), "ether")
        );
        if (userCollateralFmt >= marginAmt + collateralAmt) {
          isvalidated = true;
        } else {
          isvalidated = false;
          toast.error(
            `Collateral offered lesser than expected collateral. Offer at least: "${(
              marginAmt + collateralAmt
            ).toFixed(2)} ${selectedToken.symbol}" and try again`,
            {
              position: "top-right",
              autoClose: 8000,
              hideProgressBar: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
        }
      }
      if (isvalidated) {
        var poolId = props.pool.id;
        if (qty === "") {
          alert("Enter Quantity");
          return;
        }
        if (orderType !== "Market") {
          if (price === "") {
            alert("Enter Price");
            return;
          }
        }
        setLoading(true);
        try {
          await runSecondaryBatchSwap(
            poolId,
            props.pool,
            transport,
            provider,
            chainId,
            account,
            swapType,
            web3.utils.toAscii(web3.utils.toHex(securityToken?.symbol)),
            orderType,
            qty,
            price,
            loading,
            sorValue,
            priceSwapSDK,
            priceRoute,
            swapType.toLowerCase() === "buy"
              ? (
                  Number(props.currentPrice * qty) * Number(marginAmount)
                ).toFixed(4)
              : Number(props.currentPrice * qty).toFixed(4),
            props.pool.poolType,
            networkOptions
          );
        } catch (err) {
          console.log("error: ", err);
          toast.error(FailureToast(), ToastOptions);
        }

        if (swapType === "Sell") {
          setUpdatedSecurityBalance(
            Number(tokenSelected?.balance) - Number(qty)
          );
          setUpdatedCurrencyBalance(
            Number(selectedToken?.balance) + Number(swapAmount)
          );
        } else if (swapType === "Buy") {
          setUpdatedCurrencyBalance(
            Number(tokenSelected?.balance) - Number(qty)
          );
        }
        setLoading(false);
      }
    }
  };

  const fetchBalance = async (token) => {
    if (transport) {
      const web3 = getWeb3(transport);
      const tokenContract = new web3.eth.Contract(ERC20ABI, token.address);
      let balance = await tokenContract.methods.balanceOf(account).call();
      const divider = Math.pow(10, token.decimals);
      balance = balance / divider;
      token = { ...token, balance };
      setSelectedToken(token);
    }
  };

  let tokenSelected;
  if (tokenBalance) {
    if (swapType === "Buy" && props.pool.poolType !== PoolType.PrimaryIssue) {
      if (selectedToken) {
        tokenSelected = selectedToken;
      } else {
        tokenSelected = tokenBalance.find(
          (token) =>
            token.symbol ===
            (currencyToken ? currencyToken.symbol : { balance: 0 })
        );
      }
    } else {
      if (swapType === "Buy") {
        tokenSelected = tokenBalance.find(
          (token) =>
            token.symbol ===
            (currencyToken ? currencyToken.symbol : { balance: 0 })
        );
      } else {
        tokenSelected = tokenBalance.find(
          (token) =>
            token.symbol ===
            (securityToken ? securityToken.symbol : { balance: 0 })
        );
      }
    }
  }

  let buttonText = "";
  let isDisabled = false;

  const { poolType } = props.pool;
  const balance = tokenSelected?.balance;

  if (poolType === PoolType.SecondaryIssue) {
    if (qty > balance) {
      isDisabled = true;
      buttonText = "Insufficient Balance";
    } else if ((swapAmount === 0 || qty <= 0) && orderType === "Market") {
      isDisabled = true;
      buttonText = "Insufficient Liquidity";
    } else if (qty <= 0) {
      isDisabled = true;
      buttonText = "Enter correct amount value";
    } else if (price <= 0 && orderType === "Limit") {
      isDisabled = true;
      buttonText = "Enter correct price value";
    }
  } else if (poolType === PoolType.PrimaryIssue) {
    if (
      Number(swapAmount) > Number(selectedToken?.balance) &&
      swapType === "Buy"
    ) {
      isDisabled = true;
      buttonText = "Insufficient Balance";
    } else if (Number(swapAmount) > Number(balance) && swapType === "Sell") {
      isDisabled = true;
      buttonText = "Insufficient Balance";
    } else if (swapAmount === 0 || qty <= 0) {
      isDisabled = true;
      buttonText = "Insufficient Liquidity";
    } else if (qty > balance && swapType === "Sell") {
      isDisabled = true;
      buttonText = "Insufficient Balance";
    }
  } else if (poolType === PoolType.MarginIssue) {
    if (
      swapType === "Buy" &&
      orderType !== "Limit" &&
      Number(props.currentPrice * qty) * Number(marginAmount) > balance
    ) {
      isDisabled = true;
      buttonText = "Insufficient Balance";
    } else if (swapType === "Sell" && orderType !== "Limit" && qty > balance) {
      isDisabled = true;
      buttonText = "Insufficient Balance";
    } else if (qty <= 0) {
      isDisabled = true;
      buttonText = "Enter correct amount value";
    } else if (price <= 0 && orderType === "Limit") {
      isDisabled = true;
      buttonText = "Enter correct price value";
    } else if (orderType === "Limit" && qty > balance) {
      isDisabled = true;
      buttonText = "Insufficient Balance";
    }
  }
  return (
    <>
      <div className="border-bottom d-flex justify-content-center width-100 p-3">
        {props.pool.poolType === PoolType.SecondaryIssue && (
          <h5 className="fw-normal">Trade {securityToken?.symbol}</h5>
        )}
        {props.pool.poolType === PoolType.MarginIssue && (
          <h5 className="fw-normal">
            Trade {web3.utils.toAscii(web3.utils.toHex(securityToken?.symbol))}
          </h5>
        )}
        {props.pool.poolType === PoolType.PrimaryIssue && (
          <h5 className="fw-normal">Subcribe to {securityToken?.symbol}</h5>
        )}
      </div>
      <div className="border-bottom pb-3">
        <Form
          className={
            swapType === "Buy" &&
            props.pool.poolType === PoolType.SecondaryIssue &&
            "customForm"
          }
        >
          <Row className="ml-1 align-items-center">
            <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
              <Col xs={4}>
                <b>Order</b>
              </Col>
              <Col className="pl-0" xs={8}>
                <DropdownButton id="dropdown-basic-button" title={swapType}>
                  <Dropdown.Item
                    key="sell"
                    onClick={(e) => {
                      props.setSelectedType("Sell");
                      setSwapType("Sell");
                    }}
                  >
                    Sell
                  </Dropdown.Item>
                  <Dropdown.Item
                    key="buy"
                    onClick={(e) => {
                      props.setSelectedType("Buy");
                      setSwapType("Buy");
                    }}
                  >
                    Buy
                  </Dropdown.Item>
                </DropdownButton>
              </Col>
            </Row>
            <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
              <Col xs={4}>
                <b>Token</b>
              </Col>
              <Col className="" xs={8}>
                <UiButton
                  buttonVariant="primary"
                  buttonClass="flex-1 ml-0 pl-2"
                  buttonText={
                    props.pool.poolType === PoolType.MarginIssue
                      ? securityToken
                        ? web3.utils.toAscii(
                            web3.utils.toHex(securityToken.symbol)
                          )
                        : "Loading..."
                      : securityToken
                      ? securityToken.symbol
                      : "Loading..."
                  }
                />
              </Col>
            </Row>
          </Row>
          {props.pool.poolType !== PoolType.PrimaryIssue && (
            <>
              <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                <Col xs={4}>
                  <b>Type</b>
                </Col>
                <Col className="pl-0" xs={8}>
                  <DropdownButton id="dropdown-basic-button" title={orderType}>
                    <Dropdown.Item onClick={(e) => setOrderType("Market")}>
                      Market
                    </Dropdown.Item>
                    <Dropdown.Item onClick={(e) => setOrderType("Limit")}>
                      Limit
                    </Dropdown.Item>
                  </DropdownButton>
                </Col>
              </Row>

              {orderType !== "Market" && (
                <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                  <Col xs={4}>
                    <b>Price</b>
                  </Col>
                  <Col className="pl-0" xs={8}>
                    <TextInput
                      placeholder="Price"
                      fieldType="number"
                      value={price}
                      name={"price"}
                      onChange={(e) => setPrice(e.target.value)}
                    />
                  </Col>
                </Row>
              )}
            </>
          )}
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={4}>
              {props.pool.poolType === PoolType.MarginIssue && (
                <b>
                  {tokenSelected
                    ? web3.utils.toAscii(web3.utils.toHex(tokenSelected.symbol))
                    : "Loading..."}
                </b>
              )}
              {props.pool.poolType !== PoolType.MarginIssue && (
                <b>{tokenSelected ? tokenSelected.symbol : "Loading..."}</b>
              )}

              <div>
                Balance:{" "}
                {updatedSecurityBalance
                  ? updatedSecurityBalance.toFixed(8)
                  : tokenSelected?.balance.toFixed(8)}
              </div>
            </Col>
            <Col className="pl-0" xs={8}>
              {props.pool.poolType !== PoolType.MarginIssue && (
                <TextInput
                  placeholder={
                    currencyToken && securityToken
                      ? swapType === "Buy"
                        ? `Enter amount to buy with`
                        : `Enter amount to sell`
                      : "Loading..."
                  }
                  fieldType="number"
                  value={qty}
                  onChange={(e) => calculateSORValue(e.target.value)}
                />
              )}
              {props.pool.poolType === PoolType.MarginIssue && (
                <TextInput
                  placeholder={
                    currencyToken && securityToken
                      ? swapType === "Buy"
                        ? `Enter amount to buy with`
                        : `Enter amount to sell`
                      : "Loading..."
                  }
                  fieldType="number"
                  value={qty}
                  onChange={(e) => {
                    setQty(e.target.value.toString());
                  }}
                />
              )}
            </Col>
          </Row>
          {orderType === "Market" && (
            <>
              <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center customTradeWith">
                <Col xs={4}>
                  <b>{swapType === "Buy" ? "Buy with" : "Sell for"}</b>
                  {swapType === "Buy" &&
                  props.pool.poolType === "PrimaryIssue" ? (
                    <div>
                      {" "}
                      {selectedToken
                        ? `Balance: ${selectedToken?.balance.toFixed(2)}`
                        : null}
                    </div>
                  ) : null}
                  {selectedToken && (
                    <div>
                      <span>{selectedToken?.tokenSymbol}</span>
                    </div>
                  )}
                </Col>
                <Col className="pl-0" xs={8}>
                  {tokenList?.tokens ? (
                    // for those network where assests are supported by Paraswap
                    <DropdownButton
                      id="dropdown-basic-button"
                      className="tokenList-dropdown"
                      title={
                        selectedToken ? selectedToken?.symbol : "Select Token"
                      }
                    >
                      {tokenList.tokens.map((token) => {
                        return (
                          <Dropdown.Item
                            key={token.symbol}
                            className={
                              selectedToken?.symbol === token.symbol
                                ? "selectedNetwork"
                                : null
                            }
                            onClick={(e) => fetchBalance(token)}
                          >
                            <img src={token.img} className="token-img" alt="" />
                            <span className="pl-2">{token.symbol}</span>
                          </Dropdown.Item>
                        );
                      })}
                    </DropdownButton>
                  ) : (
                    // for those network where there are no assests supported by paraswap
                    <DropdownButton
                      id="dropdown-basic-button"
                      title={
                        selectedToken ? selectedToken?.symbol : "Select Token"
                      }
                    >
                      {finalTokenList.map((token) => {
                        return (
                          <Dropdown.Item
                            key={token.symbol}
                            className={
                              selectedToken?.symbol === token.symbol
                                ? "selectedNetwork"
                                : null
                            }
                            onClick={(e) => fetchBalance(token)}
                          >
                            <span className="pl-2">{token.symbol}</span>
                          </Dropdown.Item>
                        );
                      })}
                    </DropdownButton>
                  )}
                </Col>
              </Row>
              {
                // !selectedToken?.isFiat &&
                <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                  <Col xs={4}>
                    <b>
                      {swapType === "Buy" ? (
                        props.pool.poolType === PoolType.SecondaryIssue ? (
                          `Estimated ${securityToken?.symbol} credit`
                        ) : props.pool.poolType === PoolType.MarginIssue ? (
                          <></>
                        ) : (
                          `${
                            selectedToken ? selectedToken?.symbol : ""
                          } Required`
                        )
                      ) : props.pool.poolType === PoolType.MarginIssue ? (
                        <></>
                      ) : (
                        `Estimated ${
                          selectedToken ? selectedToken?.symbol : ""
                        } credit`
                      )}
                    </b>
                  </Col>
                  {props.pool.poolType !== PoolType.MarginIssue && (
                    <Col className="pl-0" xs={8}>
                      <TextInput
                        disabled
                        value={
                          swapAmount && selectedToken
                            ? swapAmount === 0
                              ? "Insufficient Liquidity"
                              : Number(swapAmount).toFixed(8)
                            : "Required token balance"
                        }
                      />
                    </Col>
                  )}
                </Row>
              }
              {props.pool.poolType === PoolType.SecondaryIssue &&
                orderType === "Market" &&
                Number(swapAmount) !== 0 && (
                  <>
                    <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                      <Col xs={4}>
                        <b>Average Price</b>
                      </Col>
                      <Col className="pl-0" xs={8}>
                        <TextInput
                          disabled
                          value={
                            swapAmount && selectedToken
                              ? swapAmount === 0
                                ? "Insufficient Liquidity"
                                : swapType === "Buy"
                                ? Number(qty / swapAmount).toFixed(4)
                                : Number(swapAmount / qty)
                              : "Required token balance"
                          }
                        />
                      </Col>
                    </Row>
                  </>
                )}
              {props.pool.poolType === PoolType.SecondaryIssue &&
                orderType === "Market" &&
                Number(swapAmount) === 0 && (
                  <>
                    <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                      <Col xs={4}>
                        <b>Average Price</b>
                      </Col>
                      <Col className="pl-0" xs={8}>
                        <TextInput
                          disabled
                          value={
                            swapAmount && selectedToken
                              ? swapAmount === 0
                                ? "Insufficient Liquidity"
                                : swapType === "Buy"
                                ? 0
                                : 0
                              : "Required token balance"
                          }
                        />
                      </Col>
                    </Row>
                  </>
                )}
              {props.pool.poolType === PoolType.MarginIssue &&
                orderType === "Market" && (
                  <>
                    <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                      {swapType === "Buy" ? (
                        <Col xs={4}>
                          <b>
                            {selectedToken ? selectedToken?.symbol : ""} Amount
                            Required
                          </b>
                        </Col>
                      ) : (
                        <Col xs={4}>
                          <b>
                            Estimated{" "}
                            {selectedToken ? selectedToken?.symbol : ""} Credit
                          </b>
                        </Col>
                      )}
                      {swapType === "Buy" ? (
                        <Col className="pl-0" xs={8}>
                          <TextInput
                            disabled
                            value={
                              qty && selectedToken
                                ? Number(
                                    props.currentPrice * qty * marginAmount
                                  ).toFixed(4)
                                : swapType === "Buy"
                                ? "Required token balance"
                                : ""
                            }
                          />
                        </Col>
                      ) : (
                        <Col className="pl-0" xs={8}>
                          <TextInput
                            disabled
                            value={
                              qty && selectedToken
                                ? Number(props.currentPrice * qty).toFixed(4)
                                : swapType === "Buy"
                                ? "Required token balance"
                                : ""
                            }
                          />
                        </Col>
                      )}
                    </Row>
                  </>
                )}
            </>
          )}
          {slippage ? (
            <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
              <Col xs={4}>
                <b>Expected Slippage</b>
              </Col>
              <Col className="pl-0" xs={8}>
                <TextInput required disabled value={`${Math.abs(slippage)}%`} />
              </Col>
            </Row>
          ) : null}
        </Form>
      </div>
      {selectedToken?.isFiat && selectedToken?.balance < Number(qty) ? (
        <div className="d-flex justify-content-center">
          <span
            onClick={async () => await stripePayment()}
            className="stripeLink"
          >
            Top up using card & online banking
          </span>
        </div>
      ) : null}
      <Col className="p-2 bg-light mx-0 align-items-center">
        <Row className="ml-1 flex-1 mx-0 pl-0 justify-content-between">
          {props.pool.poolType !== PoolType.PrimaryIssue ? (
            <UiButton
              onClick={onSecondaryTrade}
              buttonVariant="primary"
              buttonClass="SignUpButton flex-1 ml-0"
              buttonText={buttonText ? buttonText : "Trade"}
              disabled={isDisabled}
              type="submit"
            />
          ) : (
            <UiButton
              onClick={onPrimaryTrade}
              buttonVariant="primary"
              buttonClass="SignUpButton flex-1 ml-0"
              disabled={isDisabled}
              buttonText={buttonText ? buttonText : "Invest"}
              type="submit"
            />
          )}
        </Row>
      </Col>
      {/*<UiButton
        onClick={checkSDK}
        buttonVariant="primary"
        buttonClass="SignUpButton flex-1 ml-0"
        buttonText={"Test SDK"}
        type="submit"
          />*/}
      {loading ? <Loader /> : null}
      {showKYCModal && (
        <KYCModal
          accessToken={accessToken}
          setshowKYCModal={setshowKYCModal}
          showKYCModal={showKYCModal}
        />
      )}
      <PaymentModal
        show={paymentModalVisibility}
        paymentRequest={paymentResponse}
        onHide={() => {
          setPaymentModalVisibility(!paymentModalVisibility);
        }}
      />
    </>
  );
};

export default PoolInfo;
