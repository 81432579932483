import gql from "graphql-tag";

const SecondaryPoolFields = `
fragment PoolFields on Pool {
  id
  address
  poolType
  swapFee
  tokensList
  totalLiquidity
  totalSwapVolume
  totalSwapFee
  totalShares
  security
  currency
  securityOffered
  balancerManager
  minOrderSize
  owner
  factory
  amp
  createTime
  swapEnabled
  orderBook
  secondaryTrades{
    amount
    price
    executionDate
    orderType
    orderReference
    counterparty {
      id
    }
    party {
      id
    }
  }
  secondaryPreTrades {
    id
    pool {
      id
    }
    party {
      id
    }
    counterparty {
      id
    }
    executionDate
  }
  tokens {
    address
    balance
    weight
    priceRate
    symbol
    decimals
  }
  orders {
    id
    tokenIn {
      address
    }
    tokenOut {
      address
    }
    amountOffered
    priceOffered
    orderReference
    creator
    timestamp
  } 
}
`;

const PrimaryPoolFields = `
fragment PoolFields on Pool {
  id
  address
  poolType
  swapFee
  tokensList
  totalLiquidity
  totalSwapVolume
  totalSwapFee
  totalShares
  owner
  factory
  balancerManager
  security
  currency
  createTime
  minimumOrderSize
  minimumPrice
  securityOffered
  cutoffTime
  offeringDocs
  amp
  createTime
  swapEnabled
  primarySubscriptions{
    subscription
    price
    executionDate
    investor {
      id
    }
  }
  tokens {
    address
    balance
    weight
    priceRate
    symbol
    decimals
  }
}
`;

const MarginPoolFields = `
fragment PoolFields on Pool { 
  id
  address
  poolType
  swapFee
  tokensList
  totalLiquidity
  totalSwapVolume
  totalSwapFee
  totalShares
  security
  securityType
  currency
  collateral
  margin
  securityOffered
  balancerManager
  minOrderSize
  owner
  factory
  amp
  createTime
  swapEnabled
  offeringDocs
  secondaryTrades{
    amount
    price
    executionDate
    orderType
    orderReference
    counterparty {
      id
    }
    party {
      id
    }
  }
  tokens {
    address
    balance
    weight
    priceRate
    symbol
    decimals
  }
  marginOrders {
    id
    pool {
      id
      address
      security
      currency
      tokensList
    }
    creator
    tokenIn {
      id
      symbol
      name
      decimals
      address
    }
    tokenOut {
      id
      symbol
      name
      decimals
      address
    }
    amountOffered
    priceOffered
    stoplossPrice
    timestamp
    orderReference
  } 
}
`;

export const GET_BALANCER_SECONDARY_POOLS = () => {
  const queryString = `
    ${SecondaryPoolFields}
    query pools {
      pools(
        first: 100
      orderBy: "createTime"
      orderDirection: "desc"
      where: {
        poolType: "SecondaryIssue"
      }
      ) {
        ...PoolFields
      }
    }`;
  return gql(queryString);
};

export const GET_BALANCER_MARGIN_ISSUE_POOLS = () => {
  const queryString = `
  ${MarginPoolFields}
  query pools {
    pools(
      first: 100
      where: {
        poolType: "MarginIssue"
      }
    ) {
      ...PoolFields
    }
  }`;
  return gql(queryString);
};

export const GET_ALL_BALANCER_MARGIN_ISSUE_POOLS = () => {
  const queryString = `
  ${MarginPoolFields}
  query pools {
    pools(
      where: {
        poolType: "MarginIssue"
      }
    ) {
      ...PoolFields
    }
  }`;
  return gql(queryString);
};

export const GET_VERIFIED_MARGIN_SECURITIES = (manager) => {
  const queryString = `
  query Securities {
    securities(
      where: {
        productCategory: "0x4346440000000000000000000000000000000000000000000000000000000000"
        issueManager: "${manager.toLowerCase()}"
      }
    ){
      security
      isin
      id
      issuer {
        id
      }
      currency
      country
      restricted
      issueManager
      productCategory
    }
  }`;
  return gql(queryString);
};

export const GET_BALANCER_PRIMARY_POOLS = () => {
  const queryString = `
    ${PrimaryPoolFields}
    query pools {
      pools(
        first: 100
      orderBy: "createTime"
      orderDirection: "desc"
      where: {
        poolType: "PrimaryIssue"
      }
      ) {
        ...PoolFields
      }
    }`;
  return gql(queryString);
};

export const GET_KYBER_POOL_DAYS_DATA = gql`
  query getPoolDayDatas($id: String) {
    poolDayDatas(id: $id, first: 199) {
      date
      id
      totalSupply
    }
  }
`;

export const GET_BALANCER_POOL = gql`
  query getPool($id: String) {
    pool(id: $id) {
      id
      address
      poolType
      swapFee
      tokensList
      totalLiquidity
      totalSwapVolume
      totalSwapFee
      totalShares
      security
      securityType
      currency
      minimumPrice
      minOrderSize
      minimumOrderSize
      collateral
      margin
      securityOffered
      cutoffTime
      orders {
        id
        tokenIn {
          address
        }
        tokenOut {
          address
        }
        amountOffered
        priceOffered
        orderReference
        timestamp
        creator
      }
      owner
      factory
      amp
      cficode
      createTime
      primarySubscriptions {
        subscription
        price
        executionDate
      }
      secondaryTrades {
        amount
        price
        executionDate
        orderReference
      }
      swapEnabled
      tokens {
        address
        balance
        weight
        priceRate
        symbol
        decimals
      }
    }
  }
`;

const SecuritiesField = ` 
  security
  issuer
  isin
  currency
  restricted
  issueManager
  primarySubscribers {
    id
    pool
    currency
    security {
      id
    }
    cashSwapped
    investor
    securitySwapped
    timestamp
  }
  secondaryInvestors {
    id
    currency
    security {
      id
    }
    amount
    investor
    issuer
    price
    timestamp
    tradeRef
    DPID
  }`;

export const GET_VERIFIED_SECURITY = gql`
query Security($id: ID!) {
  security(id: $id){
		${SecuritiesField}
  }
}`;

export const GET_VERIFIED_SECURITIES = gql`
query Securities {
  securities{
		${SecuritiesField}
  }
}`;

export const GET_USER_ORDERS = (address) => {
  const queryString = `
  query users{
    users(where: {id: "${address.toLowerCase()}"}){
    tradeTransferor {
      orderRef
      security{
        id
        security
      }
      securityTraded
      currency
      cashTraded
    }
    tradeTransferee {
      orderRef
      security{
        id
        security
      }
      securityTraded
      currency
      cashTraded
    }
    
    }
  }`;
  return gql(queryString);
};

export const GET_USER_TRADES = (address) => {
  const queryString = `
  query users{
    users(where: {id: "${address.toLowerCase()}"}){
    securitySeller {
      id
      poolid
      unitsToTransfer
      security{
        id
        security
      }
      price
      currency
      settlementStatus
      amountPaid
      tradeRef
      orderRef
      tradingCommission
      timestamp
    }
    securityBuyer {
      id
      poolid
      unitsToTransfer
      security{
        id
        security
      }
      price
      currency
      settlementStatus
      amountPaid
      tradeRef
      orderRef
      tradingCommission
      timestamp
    }
    }
  }`;
  return gql(queryString);
};

export const GET_USER_TRADES_AND_ORDERS = (address) => {
  const queryString = `
  query users{
    users(where: {id: "${address.toLowerCase()}"}){
    securitySeller {
      tradeRef
    }
    securityBuyer {
      tradeRef
    }
    tradeTransferor {
      orderRef
    }
    tradeTransferee {
      orderRef
    }
    }
  }`;
  return gql(queryString);
};
