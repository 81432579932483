import React, {useState, useEffect} from "react";
import VerticallyModal from "../../components/modal/VerticallyModal";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import UiButton from "../button";

function ConnectModal(props) {
  return (
    <> 
        <VerticallyModal
            key="modalAlert"
            showModal={props.alertModal}
            closeButton={false}
            modalSize={"xs"}
            modalHeading={<h2><b>Unsupported Network</b></h2>}
            withFooter={false}
        >
            <div className="centered" >
                <div>The network you're connected to is currently not supported by Verified Network.</div>
                    <div>You're currently connected to network <b>{props.chainName}</b>.</div>
                    <hr/>
                    <div className="supportedNetwork">
                        <span className="noWrap"><b>Supported networks are:</b></span>
                        <div>
                        {Object.values(props.activeNetworkList).map(network => network.chainName).join(', ')}
                        </div>
                    </div>
                    <div>
                        <DropdownButton id="dropdown-basic-button" className="marginOffset" title="Switch Network">
                            {Object.values(props.activeNetworkList).map((network) => (
                              <Dropdown.Item onClick={(e) => props.switchNetwork(parseInt(network.chainId, 16))}>
                                {network.chainName}
                              </Dropdown.Item>
                            ))}
                        </DropdownButton>
                    </div>
            </div>  
        </VerticallyModal>
    </>
  );
}

export default function UnSupportedModal(props) {
  return <ConnectModal {...props} />;
}