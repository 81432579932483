import { ethers } from "ethers";
import Web3 from "web3";

const SUCCESS = 0;

function value(response) {
  if (response.status === SUCCESS) {
    return Promise.resolve(response.response.result[0]);
  } else {
    return Promise.reject(response.reason);
  }
}

function array(response) {
  if (response.status === SUCCESS) {
    return Promise.resolve(response.response.result);
  } else {
    return Promise.reject(response.reason);
  }
}

function empty(response) {
  if (response.status === SUCCESS) {
    return Promise.resolve();
  } else {
    return Promise.reject(response.reason);
  }
}

function emptyPromise(promise) {
  return promise.then((response) => {
    return empty(response);
  });
}

function valuePromise(promise) {
  return promise.then((response) => {
    return value(response);
  });
}

function arrayPromise(promise) {
  return promise.then((response) => {
    return array(response);
  });
}

function parseBytes32Value(text) {
  return ethers.utils.parseBytes32String(text);
}

function parseWeiToEther(text) {
  return Web3.utils.fromWei(text, 'ether');
}

function parseTokenAmount(amount, decimals) {
  return Number(amount / (10**decimals));
}

function tokenAmountConversion(amount, decimals) {
  return Number(amount * (10**decimals));
}

function parseBytes16Value(text) {
  let finalText = text;

  if (text.length === 34) {
    finalText = `${text}00000000000000000000000000000000`;
  }

  return ethers.utils.parseBytes32String(finalText);
}

function parseBytes32Array(array) {
  return array.map((element) => {
    return parseBytes32Value(element);
  });
}

export const signMessage = async (wallet, msg = "L2toL1") => {
  let messageHash =
    "0x0123456789012345678901234567890123456789012345678901234567890123";
  let payload = ethers.utils.defaultAbiCoder.encode(
    ["bytes32", "string"],
    [messageHash, msg]
  );
  let payloadHash = ethers.utils.keccak256(payload);
  let signature = await wallet.signMessage(ethers.utils.arrayify(payloadHash));
  let sig = ethers.utils.splitSignature(signature);
  return { messageHash, v: sig.v, r: sig.r, s: sig.s };
};

export const NULL_ADDRESS = '0x0000000000000000000000000000000000000000';


export default {
  value,
  array,
  empty,
  emptyPromise,
  valuePromise,
  arrayPromise,
  parseBytes32Value,
  parseTokenAmount,
  parseWeiToEther,
  parseBytes16Value,
  parseBytes32Array,
  tokenAmountConversion,
};