import React from "react";
import Loader from "../../../components/loader";
import {
  useManagerDetails,
  useNetworkOptions,
} from "../../../context/contractDataLoaderService";
import UiTable from "../../../components/table/Table";
import Web3 from "web3";
import { Liquidity, contractAddress } from "@verified-network/verified-sdk";

import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../../utils/helpers/networks";
import { providers } from "ethers";

function PlatformInfo(props) {
  let platformId = props.match.params.platform_id;
  let { managerDetail, loading } = useManagerDetails(platformId);
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const { data: networkOptions } = useNetworkOptions();
  let liquidityContract;
  if (provider && provider.getSigner && chainId && address) {
    liquidityContract = new Liquidity(
      provider.getSigner(address),
      contractAddress[chainId].Liquidity
    );
  }

  let headers = [
    { label: "Manager name", val: "managerName" },
    { label: "Manager Country", val: "managerCountry" },
    { label: "Manager Address", val: "managerAddress" },
    { label: "Token name", val: "tokenName" },
    { label: "Liquidity Provided", val: "liquidityProvided" },
    { label: "Commission Earned", val: "commissionEarned" },
    { label: "Call Action", val: "platformDetailCallAction" },
  ];

  const options = {
    onClick: (event, row) => {
      onInvest(row);
    },
  };
  const onInvest = (row) => {};
  return (
    <>
      {loading ? <Loader /> : null}
      <section className="d-flex flex-column align-items-start px-0 py-4">
        <div className="mb-3 d-flex justify-content-between w-100 align-items-center">
          <div className="">
            <h3 className="">Platform Details</h3>
          </div>
        </div>
        <div className="pools-table width-100">
          <UiTable
            thead={headers}
            tbodyData={managerDetail || []}
            hover
            bordered={false}
            rowEvents={options}
            contract={liquidityContract}
            web3={web3}
            account={account}
            chainId={chainId}
            networkOptions={networkOptions}
            platformId={platformId}
          />
        </div>
      </section>
    </>
  );
}

export default function PlatformDetail(props) {
  return <PlatformInfo {...props} />;
}
