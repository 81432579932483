import { Row, Col } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";

function ModalReason(props) {
 console.log("Props", props, props.response.applicantData.review.reviewResult.clientComment);
  return (
    <>
        <div className="d-grid gap-2">
            <Row className="ml-1 align-items-center justify-content-center">
                {props.response ? props.response.applicantData.review.reviewResult.clientComment : "Invalid reason"}
            </Row>
        </div>
    </>
  );
}

export function KYCRejectionCheck(props) {
  return <ModalReason {...props} />;
}