import React, { Component } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { Button } from "react-bootstrap";
import Loader from "../loader";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { format } from "date-fns";
import VerticallyModal from "../../components/modal/VerticallyModal";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter";
import { AddManager } from "../modal/AddManager";
import { AddLiquidity } from "../modal/IssueLiquidity";
import { ProvideLiquidity } from "../modal/ProvideLiquidity";
import { AssignManager } from "../modal/AssignManager";
import { AcceptInvestor } from "../modal/AcceptInvestor";
import { PayOut } from "../modal/PayOut";
import { MakeOffer } from "../modal/MakeOffer";
import { StartIssue } from "../modal/StartIssue";
import { EditOrder } from "../modal/EditOrder";
import { Refunds } from "../modal/ViewRefund";
import { Earnings } from "../modal/Earnings";
import { Allotments } from "../modal/Allotments";
import { LiquidityOffered } from "../modal/LiquidityOffered";
import { OffersList } from "../modal/OffersList";
import { ScheduleSnapshot } from "../modal/ScheduleSnapshot";
import { RescheduleSnapshot } from "../modal/RescheduleSnapshot";
import { UnscheduleSnapshot } from "../modal/UnscheduleSnapshot";
import { ScheduleResolution } from "../modal/ScheduleResolution";
import { SchedulePayout } from "../modal/SchedulePayout";
import { ScheduleInvestorPayout } from "../modal/ScheduleInvestorPayout";
import { SnapshotList } from "../modal/SnapshotList";
import { KYCRejectionCheck } from "../modal/KYCRejectionCheck";
import { FloatingSecurities } from "../modal/FloatingSecurities";
import { InvestorSnapshot } from "../modal/InvestorSnapshot";
import { InvestorVoting } from "../modal/InvestorVoting";
import { BurnInvestorSecurity } from "../modal/BurnInvestorSecurity";
import { FreezeInvestor } from "../modal/FreezeInvestor";
import AddOffer from "../../pages/assetManager/createSecondaryIssue/offer";
import Web3 from "web3";
import "react-toastify/dist/ReactToastify.css";
import { balancerVault } from "../../utils/constants";
import Response, { signMessage } from "../../utils/response";
import { Swaps } from "@balancer-labs/sdk";
import {
  SuccessToast,
  FailureToast,
  ToastOptions,
  productCategory,
  networks,
} from "../../utils/constants";
import { toast } from "react-toastify";
import { contractAddress } from "@verified-network/verified-sdk";
import ERC20ABI from "../../utils/abi/ERC20.json";
import { ethers } from "ethers";
import config from "../../services/config/homestead.json";
const axios = require("axios");

class MarginIssueTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      heading: "",
      modalContent: "",
      loading: false,
      toggle: false,
      recordDate: null,
      balance: null,
      earnings: null,
    };
  }
  noDataIndication = () => {
    return <p>No data</p>;
  };

  render() {
    const {
      tbodyData,
      thead,
      data,
      columns,
      contract,
      signer,
      platformId,
      chainId,
      web3,
      userRole,
      library,
      account,
      updateTableData,
      isUpdate,
      networkOptions,
      history,
    } = this.props;
    const tableColumns = columns ?? thead;
    const tableData = data ?? tbodyData;
    const defaultSortedBy = [
      {
        dataField: "date",
        order: "desc",
      },
    ];

    return (
      <>
        {this.state.loading ? <Loader /> : null}
        {/* <BootstrapTable
          keyField="id"
          data={tableData}
          key={this.state.balance}
          columns={tableColumns.map((element, index) => {
            element.dataField = element.dataField ?? element.val;
            element.text = element.text ?? element.label;
            return element;
          })}
          bootstrap4
          pagination={paginationFactory()}
          noDataIndication={this.noDataIndication}
          wrapperClasses="table-responsive"
          rowClasses="cursor-pointer"
          defaultSorted={defaultSortedBy}
          filter={filterFactory()}
          {...this.props}
        /> */}
      </>
    );
  }
}

export default MarginIssueTable;
