import "dotenv/config";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row, Image, Button } from "react-bootstrap";
import Loader from "../../components/loader";
import PoolInfo from "./poolInfo";
import PoolChart from "./poolChart";
import { useBalancerData } from "../../context/balancerPoolData";
import {
  usePrimarySubscribers,
  useSecurityTokenDetails,
  useGetsubscribers,
  useGetSecurityTrades,
  useGetSecurity,
  getSecurity,
  formatHourlyData,
  useGetPoolChart,
} from "../../context/contractDataLoaderService";
import WalletConnect from "../../components/common/walletConnect/index";
import PoolValues from "./poolValues";
import ERC20ABI from "../../utils/abi/ERC20.json";
import SecurityAbi from "../../utils/abi/Security.json";
import SecondaryManager from "../../utils/abi/SecondaryManager.json";
import MarketDepth from "./viewMarketDepth";

import Web3 from "web3";
import { currenciesToFiat, networks } from "../../utils/constants";
import { useGlobal } from "../../context/globalContext";
import { PoolType } from "./balancer/types";
import { verifiedWalletSubgraphClient } from "../../adapters";
import { format } from "date-fns";
import axios from "axios";
import Response from "../../utils/response";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../utils/helpers/networks";
import { providers } from "ethers";
import config from "../../services/config/homestead.json";
import { contractAddress } from "@verified-network/verified-sdk";

const PoolData = (props) => {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [pool, setPool] = useState({});
  const { userRole } = useGlobal();
  const [tokenBalance, setTokenBalance] = useState([]);
  const [chartData, setChartData] = useState({
    totalVolume: 0.0,
    ohlcData: [],
    price: [],
  });
  const [tokenList, setTokenList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedType, setSelectedType] = useState("Buy");
  const [latestPrice, setLatestPrice] = useState([]);
  const [issuingFee, setIssuingFee] = useState(null);
  const [marginPriceDetails, setMarginPriceDetails] = useState({
    body: { price: 0.0 },
  });
  const { data: poolData, loading } = useBalancerData(
    props.match.params.pool_id
  );

  const { isSecurityIssuer } = useSecurityTokenDetails(pool.security);

  let { chartData: chartDetails, loading: poolChartLoading } =
    useGetPoolChart(poolData);
  // console.log("poolData: ", poolData);
  const axiosGet = async (url) => {
    return await axios({
      method: "GET",
      url: url,
    })
      .then((res) => {
        const response = res.data;
        const date = new Date() * 1000;
        const formattedDate = format(date, "yyyy-MM-dd hh:mm a");
        // console.log("price: ", response.body.price);
        setLatestPrice(formatHourlyData([formattedDate, response.body.price]));
        return response;
      })
      .catch((err) => {
        console.log(
          `Request to get response from url: ${url} failed with error: ${err}`
        );
        return { body: { price: 0.0 } };
      });
  };

  useEffect(() => {
    if (poolData) {
      setPool(poolData);
    }
  }, [poolData]);

  useEffect(() => {
    if (chartDetails) {
      setChartData(chartDetails);
    }
  }, [chartDetails]);

  useEffect(() => {
    if (pool.tokens && provider) {
      fetchBalance();
    }
  }, [pool]);

  useEffect(() => {
    (async () => {
      if (pool && pool.poolType === PoolType.MarginIssue) {
        let currencySymbol =
          currenciesToFiat[pool.marginCurrencySymbol.toUpperCase()] || "USD";
        const securitySymbol = pool.marginSecuritySymbol;
        const cfiCodeFmt = web3.utils.hexToAscii(
          Response.parseBytes32Value(pool.cfiCode)
        );
        const brokerageHost = config.brokerageEndpoint;
        const url = `${brokerageHost}/${cfiCodeFmt.toLowerCase()}?security=${securitySymbol}&cash=${currencySymbol}&type=${selectedType}`;
        const marketData = await axiosGet(url);
        if (marketData) {
          // console.log("market data: ", marketData);
          setMarginPriceDetails(marketData);
        }
      } else if (
        pool?.poolType === PoolType.SecondaryIssue &&
        chainId &&
        web3
      ) {
        const secondaryManager = new web3.eth.Contract(
          SecondaryManager.abi,
          contractAddress[chainId].BalancerSecondaryIssueManager
        );
        const issueingFee = await secondaryManager.methods
          .getIssuingFee(pool.security, pool.currency)
          .call();
        const issuingFeeFmt = web3.utils.fromWei(
          issueingFee.toString(),
          "ether"
        );
        setIssuingFee(issuingFeeFmt);
      }
    })();
  }, [pool, selectedType]);

  let headers = [
    { label: "Investor Wallet", val: "address" },
    { label: "Name", val: "name" },
    { label: "Token Invested", val: "tokenName" },
    { label: "Amount Invested", val: "amount" },
  ];

  const getPoolTypeLabel = () => {
    const poolType =
      pool.poolType === PoolType.MarginIssue
        ? "Margin Issue"
        : pool.poolType === PoolType.PrimaryIssue
        ? "Primary Issue"
        : pool.poolType === PoolType.SecondaryIssue
        ? "Secondary Issue"
        : "";
    return poolType;
  };

  const fetchBalance = async () => {
    if (transport) {
      const web3 = getWeb3(transport);
      let value = [];
      const details = await Promise.all(
        pool.tokens.map(async (token) => {
          let contract = new web3.eth.Contract(ERC20ABI, token.address);
          let decimals = await contract.methods.decimals().call();
          let walletTokenBalance = await contract.methods
            .balanceOf(account)
            .call();
          let divider = Math.pow(10, decimals);
          value.push({ [token.symbol]: walletTokenBalance / divider });
          return {
            symbol: token.symbol,
            decimals: decimals,
            balance: walletTokenBalance / divider,
            address: token.address,
          };
        })
      );
      setTokenBalance(details);
    }
  };

  const poolTypeLabel = getPoolTypeLabel();
  const tokens = pool.tokens || [];
  return (
    <>
      {loading ? <Loader /> : null}
      {pool.poolType === PoolType.MarginIssue && poolChartLoading ? (
        <Loader />
      ) : null}
      <Container className="d-flex flex-column align-items-start px-0 py-4">
        <Container className="mb-4 px-0">
          <Row className="d-flex align-items-center p-0">
            <div className="mb-3 d-flex w-100 align-items-center">
              <h4
                className="mr-3"
                style={isSecurityIssuer ? { width: "30%" } : null}
              >
                {poolTypeLabel}
              </h4>
              {tokens.length > 0 ? (
                tokens.map((token, index) => (
                  <div className="bg-light py-1 px-2 rounded mr-1" key={index}>
                    <Image
                      src={token.logoURI}
                      id={index}
                      style={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "20px",
                      }}
                    />
                    <span id={index} className="ml-1">
                      <a
                        href={`${networks[chainId].blockExplorerUrls[0]}/address/${token.address}`}
                        target="_blank"
                      >
                        {/* {token.symbol} */}
                        {web3.utils.toAscii(web3.utils.toHex(token.symbol))}
                      </a>
                    </span>
                  </div>
                ))
              ) : (
                <div className="bg-light py-1 px-2 rounded mr-1"></div>
              )}
            </div>
          </Row>
          <Row>
            <span className="text-black-50">Fixed swap fees: </span>
            {issuingFee ? (
              <b>&nbsp; {issuingFee} %</b>
            ) : pool.swapFee ? (
              <b>&nbsp; {pool.swapFee} %</b>
            ) : null}
          </Row>
        </Container>
        <Row className="d-flex md-flex width-100 mb-4">
          {pool.poolType === PoolType.MarginIssue && (
            <Col style={{ flex: "6" }} className="px-0">
              {chartData.totalVolume > 0.0 ||
              chartData?.ohlcData?.length > 0 ? (
                <>
                  <Card
                    style={{ height: "auto" }}
                    className="mr-3 shadow d-flex justify-content-stretch align-items-center border-sm mb-3"
                  >
                    <PoolChart
                      pool={pool}
                      volume={chartData.volumeData}
                      prices={chartData.priceData}
                      ohlc={chartData.ohlcData}
                      latestPrice={latestPrice}
                      {...props}
                    />
                  </Card>
                  <PoolValues
                    pool={pool}
                    totalVolume={chartData.totalVolume}
                    currentPrice={
                      marginPriceDetails?.body?.price > 0
                        ? marginPriceDetails.body.price
                        : chartData.currentPrice
                    }
                  />
                </>
              ) : (
                <>
                  <Card
                    style={{ height: "auto" }}
                    className="mr-3 shadow d-flex justify-content-stretch align-items-center border-sm mb-3"
                  >
                    <PoolChart
                      pool={pool}
                      volume={[]}
                      prices={[]}
                      ohlc={[]}
                      latestPrice={latestPrice}
                      {...props}
                    />
                  </Card>
                  <PoolValues
                    pool={pool}
                    totalVolume={0.0}
                    currentPrice={marginPriceDetails?.body?.price || 0.0}
                  />
                </>
              )}
              {userRole === "AM" && (
                <Row className="d-flex md-flex justify-content-stretch width-100 mb-4">
                  <Card className="p-3 shadow mr-3 border-sm flex-1">
                    <h2 className="text-left mb-4">Market Depth</h2>
                    <MarketDepth pool={pool} />
                  </Card>
                </Row>
              )}
            </Col>
          )}
          {pool.poolType !== PoolType.MarginIssue && (
            <Col style={{ flex: "6" }} className="px-0">
              <Card
                style={{ height: "auto" }}
                className="mr-3 shadow d-flex justify-content-stretch align-items-center border-sm mb-3"
              >
                <PoolChart
                  pool={pool}
                  currentPrice={
                    marginPriceDetails.body.price || chartData.currentPrice
                  }
                  latestPrice={latestPrice}
                  {...props}
                />
              </Card>
              <PoolValues pool={pool} />
              {userRole === "AM" && (
                <Row className="d-flex md-flex justify-content-stretch width-100 mb-4">
                  <Card className="p-3 shadow mr-3 border-sm flex-1">
                    <h2 className="text-left mb-4">Market Depth</h2>
                    <MarketDepth pool={pool} />
                  </Card>
                </Row>
              )}
            </Col>
          )}
          <Col style={{ flex: "3" }} className="px-0">
            <div className="ml-1 shadow border-sm rounded">
              <PoolInfo
                pool={pool}
                setSelectedType={setSelectedType}
                currentPrice={
                  marginPriceDetails?.body?.price > 0
                    ? marginPriceDetails.body.price
                    : chartData.currentPrice
                }
                tokenBalance={tokenBalance}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <WalletConnect loggedIn={props.loggedIn} />
    </>
  );
};

export default PoolData;
