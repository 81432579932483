import gql from "graphql-tag";

const SecuritiesField = ` 
  security
  isin
  id
  issuer {
    id
    name
  }
  currency
  country
  restricted
  restrictions
  issueManager
  productCategory
  liquidityOffered {
    offered
    offeredBy {
      id
    }
    tomatch {
      id
    }
    isin
    amount
    desired
    minimum
    orderSize
    offeringDocs
  }
  liquidityProviders {
    owner {
      id
    }
    tokenOffered
    security {
      id
    }
    currency
    underwritten
    earned
  }
  primarySubscribers {
    id
    pool
    currency
    security {
      id
    }
    cashSwapped
    investor {
      id
    }
    securitySwapped
    timestamp
    bought
  }
  secondaryInvestors {
    id
    currency
    security {
      id
    }
    amount
    investor {
      id
    }
    issuer {
      id
    }
    price
    timestamp
    tradeRef
    DPID
  }
  trades {
    poolid
    transferor {
      id
    }
    transferee {
      id
    }
    timestamp
    unitsToTransfer
    amountPaid
    price
    settlementStatus
    tradingCommission
    tradeRef
  }
  subscriptionsClosed {
    timestamp
  }
  primarySettlements {
    liquidityProvider {
      id
    }
    underwritingFee
    issuer {
      id
    }
    subscription
    currency
  }
  marginTraders{
    id
    security {
      id
      security
    }
    securityTraded
    currency
    cashTraded
    orderRef
    timestamp
  }
  primaryAllotments{
    investor {
      id
    }
    currency
    security {
      id
    }
    allotedAmount
    securitySubscribed
    timestamp
  }
  subscriptionsClosed {
    timestamp
  }
  primaryRefunds {
    investor {
      id
    }
    securitySubscribed
    refundAmount
    currency
    timestamp
  }
  resolutions{
    security{
      id
    }
    recordDate
    resolution
    voting
  }
  snapshots{
    security {
      id
    }
    oldTime
    newTime
  }
  `;
const UserField = `
  client
  name
  accountid
  country
  status
  shareholder{
    shareholder {
      id
    }
    shareholderType
    token
    amount
    timestamp
  }
  earnedByInvestor {
    vitta
    platform {
      id
      name
    }
    prorataStake
  }
`;
export const GET_VERIFIED_SECURITY = gql`
query Security($id: ID!) {
  security(id: $id){
	${SecuritiesField}
  }
}`;

export const GET_MARGIN_COLLATERALS = gql`
  query marginCollaterals {
    marginCollaterals {
      id
      action
      user {
        id
        name
        accountid
        country
        status
      }
      currency
      balance
      time
    }
  }
`;

export const GET_MARGIN_PNLS = gql`
  query marginTradePnLs {
    marginTradePnLs {
      id
      user {
        id
        name
        accountid
        country
        status
      }
      security {
        id
      }
      currency
      settlementTime
      commission
      financing
      dividend
    }
  }
`;

export const GET_VERIFIED_SECURITIES = gql`
query Securities {
  securities{
	${SecuritiesField}
  }
}`;

export const GET_VERIFIED_USERS = gql`
query Users {
  users{
    ${UserField}
  }
}`;

export const GET_VERIFIED_USER = gql`
query User($id: ID!) {
  user(id: $id){
    ${UserField}
  }
}`;

export const GET_VERIFIED_PLATFORMS = gql`
  query Platforms {
    platforms {
      platform
      name
      feeCollections {
        feeCollected
      }
      platformReturns {
        vitta
        distribution
      }
      platformLiquidity {
        tokenAmount
        LPToken
        manager {
          id
        }
        token {
          id
        }
        platform {
          id
        }
      }
    }
  }
`;

export const GET_VERIFIED_CURRENCIES = gql`
  query currencies {
    currencies {
      token
      name
      liquidityTokenRequests {
        investor {
          id
          name
          country
        }
        token {
          id
          name
        }
        tokenAmount
      }
      liquidityTokenIssues {
        investor {
          id
          name
          country
        }
        token {
          id
          name
        }
        tokenAmount
        LPToIssue
      }
      liquidityOnPlatform {
        platform {
          id
          name
        }
        manager {
          id
          name
          country
        }
        LPToken
        token {
          id
          name
        }
        tokenAmount
      }
      liquidityEarningsByManager {
        platform {
          id
        }
        manager {
          id
        }
        token {
          id
        }
        distribution
      }
    }
  }
`;

export const GET_VERIFIED_RWAS = gql`
  query {
    rwas {
      id
      issuer {
        id
        name
        accountid
        country
        bondIssues {
          id
        }
      }
      bond {
        id
        token
        tokenName
        tokenType
        bondIssues {
          id
          collateralAmount
          collateralCurrency
          issuedAmount
          bondName
        }
        bondPurchases {
          id
          token {
            id
            token
            tokenName
            tokenType
          }
          bondName
          purchaseValue
          paidInCurrency
          purchasedAmount
          purchaseTime
        }
        bondRedemptions {
          id
          token {
            id
            token
            tokenName
            tokenType
          }
          bondName
          redeemedValue
          redemptionCurrency
          redemptionAmount
        }
        bondLiquidations {
          id
          token {
            id
            token
            tokenName
            tokenType
          }
          liquidatedValue
          bondName
          liquidationCurrency
          liquidatedAmount
        }
      }
      apy
      issuingDocs
      faceValue
    }
  }
`;
