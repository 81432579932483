import React, { useState, useRef, useEffect } from "react";
import Loader from "../../components/loader";
import { useBalancerPoolsData } from "../../context/balancerPoolData";
import PoolsTable from "../poolDetail/PoolsTable";
import WalletConnect from "../../components/common/walletConnect/index";
import { PoolType } from "../poolDetail/balancer/types";
import { Button, Col, Dropdown, DropdownButton, Row } from "react-bootstrap";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../utils/helpers/networks";
import KYCModal from "../../components/common/kycModal";
import { useGlobal } from "../../context/globalContext";

function MarginProductsPage(props) {
  let { data: balancerPools, loading } = useBalancerPoolsData(
    PoolType.MarginIssue
  );
  const { userType, userCountry, userCountryCode } = useGlobal();
  balancerPools = balancerPools.filter(
    (pool) => !pool.restrictedCountries?.includes(userCountryCode)
  );
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [accessToken, setAccessToken] = useState(null);
  const [showKycModal, setShowKycModal] = useState(false);

  return (
    <>
      {loading ? <Loader /> : null}
      <section className="d-flex flex-column align-items-start px-0 py-4">
        <div className="mb-3 d-flex justify-content-between w-100 align-items-center">
          <div className="w-100">
            <h3 className="text-left">Margin Traded Products</h3>
          </div>
        </div>
        <PoolsTable {...props} data={balancerPools || []} isMargin={true} />
      </section>
      <WalletConnect loggedIn={props.loggedIn} />
      {showKycModal && (
        <KYCModal
          accessToken={accessToken}
          setshowKYCModal={setShowKycModal}
          showKYCModal={showKycModal}
        />
      )}
    </>
  );
}

export default function MarginProducts(props) {
  return <MarginProductsPage {...props} />;
}
