import Loader from "../../../components/loader";
import { useBalancerPoolsData } from "../../../context/balancerPoolData";
import {
  useFetchMarginTrades,
  useMatchedTrades,
  useSettledTrades,
} from "../../../context/contractDataLoaderService";
import TradesTable from "./trades_table";
import { PoolType } from "../../poolDetail/balancer/types";
import WalletConnect from "../../../components/common/walletConnect/index";

import { useEffect, useState } from "react";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../../utils/helpers/networks";
import { providers } from "ethers";

function TradesData(props) {
  const [allPoolTrades, setAllPoolTrades] = useState([]);
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }

  let { data: margionPoolsData, loading: marginLoading } = useBalancerPoolsData(
    PoolType.MarginIssue
  );
  let tradeData = useFetchMarginTrades(margionPoolsData);


  let { data: secondaryPoolsData, loading: secondaryLoading } =
    useBalancerPoolsData(PoolType.SecondaryIssue);
  let { data: tradeDetails } = useSettledTrades();
  let { matchedTradesList, secondaryTradesLoading } =
    useMatchedTrades(secondaryPoolsData);

  let formattedData =
    tradeData && tradeDetails
      ? [...tradeData.allTradesData, ...tradeDetails, ...matchedTradesList]
      : tradeData
      ? [...tradeData.allTradesData, ...matchedTradesList]
      : tradeDetails
      ? [...tradeDetails, ...matchedTradesList]
      : [...matchedTradesList];

  return (
    <>
      {marginLoading ||
      tradeData.loading ||
      secondaryTradesLoading ||
      secondaryLoading ? (
        <Loader />
      ) : null}
      <section className="d-flex flex-column align-items-start px-0 py-4">
        <div className="w-100">
          <h3 className="mb-3 d-flex">Trades</h3>
          <TradesTable
            {...props}
            data={formattedData}
            account={account}
            provider={provider}
            transport={transport}
            chainId={chainId}
          />
        </div>
      </section>
      <WalletConnect loggedIn={props.loggedIn} />
    </>
  );
}

export default function Trades(props) {
  return <TradesData {...props} />;
}
