import { SwapType, Swaps } from "@balancer-labs/sdk";
import { ethers } from "ethers";
import Web3 from "web3";
import ERC20ABI from "../../../utils/abi/ERC20.json";
import secondaryPoolArtifact from "../../../utils/abi/SecondaryIssuePool.json";
import { balancerVault, networks } from "../../../utils/constants";
import { contractAddress } from "@verified-network/verified-sdk";
import { paraSwapTransaction } from "./primarySwap";
import { PoolType } from "../balancer/types";
import { useNetworkOptions } from "../../../context/contractDataLoaderService";
import { getWeb3 } from "../../../utils/helpers/networks";
import {
  SuccessToast,
  FailureToast,
  ToastOptions,
  DefaultTokenPair,
} from "../../../utils/constants";
import { toast } from "react-toastify";

const vaultAddress = "0xBA12222222228d8Ba445958a75a0704d566BF2C8";

export const runSecondaryBatchSwap = async (
  pool_id,
  pool,
  transport,
  provider,
  chainId,
  account,
  swapType,
  tokenSymbol,
  orderType,
  qty,
  price,
  loading,
  swapValue,
  paraSwap,
  priceRoute,
  marginAmount,
  poolType,
  networkOptions
) => {
  // console.log(
  //   "type: ",
  //   swapType,
  //   "orderType: ",
  //   orderType,
  //   "qty: ",
  //   qty,
  //   "price: ",
  //   price,
  //   "swapValue: ",
  //   Number(swapValue),
  //   "priceRoute: ",
  //   priceRoute
  // );
  if (transport) {
    const web3 = getWeb3(transport);
    const secondaryIssueManager =
      contractAddress[chainId].BalancerSecondaryIssueManager;
    const securityToken = pool.securityToken;

    const abiCoder = new ethers.utils.AbiCoder();

    let selectedToken,
      otherToken,
      cashTokenAddress,
      securityTokenAddress,
      bptTokenAddress,
      assetIn,
      assetOut,
      limitArr,
      userData,
      amount,
      securityContract,
      swapAmountCalculated,
      cashContract;

    // amount = qty ? web3.utils.toWei(qty, "ether") : "0";
    // const highLimit = web3.utils.toWei("1000", "ether");

    const priceWei = price ? web3.utils.toWei(price, "ether") : "0";
    // console.log(priceWei);
    if (orderType === "Market") {
      userData = abiCoder.encode([], []);
    } else if (orderType === "Limit") {
      userData = abiCoder.encode(
        ["bytes32", "uint"],
        [ethers.utils.formatBytes32String("Limit"), priceWei]
      );
    } else if (orderType === "Stop Loss") {
      userData = abiCoder.encode(
        ["bytes32", "uint"],
        [ethers.utils.formatBytes32String("Stop"), priceWei]
      );
    }

    const tokensList = pool.tokensList;

    const getSelectedToken = async () => {
      pool.tokens.map((token) => {
        if (token.symbol === tokenSymbol) {
          selectedToken = token;
        }
      });

      const data = pool.tokens.filter((token) => {
        return token.address !== selectedToken.address;
      });
      otherToken = data[0];
      limitArr = new Array(tokensList.length).fill(0);

      if (securityToken === selectedToken.address) {
        cashTokenAddress = otherToken.address;
        securityTokenAddress = selectedToken.address;
        bptTokenAddress = pool.address;

        securityContract = new web3.eth.Contract(
          ERC20ABI,
          securityTokenAddress
        );
        cashContract = new web3.eth.Contract(ERC20ABI, cashTokenAddress);

        const currencyDecimals = await cashContract.methods.decimals().call();
        console.log("decimals: ", currencyDecimals, "qty: ", qty);
        swapAmountCalculated = Math.ceil(swapValue) * 10 ** currencyDecimals;

        if (swapType === "Sell") {
          assetIn = tokensList.indexOf(securityTokenAddress);
          assetOut = tokensList.indexOf(bptTokenAddress);
          amount = web3.utils.toWei(qty, "ether");
        } else if (swapType === "Buy") {
          assetIn = tokensList.indexOf(cashTokenAddress);
          assetOut = tokensList.indexOf(bptTokenAddress);
          if (poolType === PoolType.MarginIssue) {
            amount = ethers.utils.parseUnits(
              marginAmount.toString(),
              Number(currencyDecimals)
            );
          } else {
            amount = ethers.utils.parseUnits(
              qty.toString(),
              Number(currencyDecimals)
            );
          }
        }
        limitArr[assetIn] = amount.toString();
      }
    };

    if (pool.tokens) {
      await getSelectedToken();
    }

    const vptContract = new web3.eth.Contract(ERC20ABI, pool.address);
    const balanceReceivingToken = await vptContract.methods
      .balanceOf(account)
      .call();
    const swapKind = SwapType.SwapExactIn;
    // console.log("test: ", {
    //   poolId: pool_id,
    //   assetInIndex: assetIn,
    //   assetOutIndex: assetOut,
    //   amount: Number(amount),
    //   userData: userData,
    // });
    // console.log("kind: ", swapKind, "list: ", tokensList, "limit: ", limitArr);
    const encodedBatchSwapData = Swaps.encodeBatchSwap({
      kind: swapKind,
      swaps: [
        {
          poolId: pool_id,
          assetInIndex: assetIn,
          assetOutIndex: assetOut,
          amount: amount,
          userData: userData,
        },
      ],
      assets: tokensList,
      funds: {
        fromInternalBalance: false,
        recipient: account,
        sender: account,
        toInternalBalance: false,
      },
      limits: limitArr, // +ve for max to send, -ve for min to receive
      deadline: "999999999999999999", // Infinity
    });
    const tx = {
      from: account,
      to: balancerVault,
      data: encodedBatchSwapData,
      //gasLimit: "2000000"
    };

    if (swapType === "Buy") {
      await cashContract.methods
        .approve(balancerVault, amount)
        .send({
          from: account,
        })
        .then((res) => {
          loading = true;
        })
        .catch((e) => {
          if (e.code === 4001) {
            loading = false;
            return;
          }
          toast.error("Transaction Failed", ToastOptions);
        });
    } else if (swapType === "Sell") {
      await securityContract.methods
        .approve(balancerVault, amount)
        .send({
          from: account,
        })
        .then((res) => {
          loading = true;
        })
        .catch((e) => {
          if (e.code === 4001) {
            loading = false;
            alert("Transaction Rejected");
            return;
          }
          toast.error("Transaction Failed", ToastOptions);
        });
    }

    if (swapType === "Buy" && priceRoute) {
      await paraSwapTransaction(
        priceRoute,
        account,
        provider,
        paraSwap,
        web3,
        chainId,
        networkOptions
      );
    }

    if (loading) {
      const transactionData = await provider
        .getSigner(account)
        .sendTransaction(tx);
      try {
        const pendingTransaction = await transactionData.wait();
        if (pendingTransaction?.transactionHash) {
          console.log(
            "Transaction Mined!!! Join Pool Vault.",
            pendingTransaction
          );
          const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${pendingTransaction.transactionHash}`;
          toast.success(SuccessToast(transactionLink), ToastOptions);
        } else {
          toast.success("Transaction Successful", ToastOptions);
        }
        if (swapType === "Sell" && priceRoute) {
          await paraSwapTransaction(
            priceRoute,
            account,
            provider,
            paraSwap,
            web3,
            chainId,
            networkOptions
          );
        }
        // Add VPT token code
        if (
          Number(balanceReceivingToken) <= 0 &&
          provider &&
          provider?.request
        ) {
          await provider.request({
            method: "wallet_watchAsset",
            params: {
              type: "ERC20",
              options: {
                address: pool.address,
                symbol: "VPT",
                decimals: 18,
              },
            },
          });
        }
      } catch (err) {
        console.log("error: ", err);
        if (err && err.transactionHash) {
          toast.error("Transaction Failed", ToastOptions);
        }
        return;
      }
    }
  }
};
