import { useState, useRef, useEffect } from "react";
import { Row, Col, FormControl } from "react-bootstrap";
import Loader from "../../components/loader";
import UiButton from "../../components/button";
import { contractAddress, Client } from "@verified-network/verified-sdk";
import Web3 from "web3";
import ERC20ABI from "../../utils/abi/ERC20.json";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import paymentGateway from "./api/PaymentGateway";
import Select from "react-select";

import {
  countryDetails,
  getCountryName,
  getCountryCode,
} from "../../utils/constants";
import { toast } from "react-toastify";
import { ethers, providers } from "ethers";
import { useNetworkOptions } from "../../context/contractDataLoaderService";
import "react-toastify/dist/ReactToastify.css";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../utils/helpers/networks";

function NewCustodianAccount(props) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [changeContent, setChangeContent] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const { data: networkOptions } = useNetworkOptions();

  const modalContent = useRef(null);

  let clientContract;
  if (provider && provider.getSigner && chainId && address) {
    clientContract = new Client(
      provider.getSigner(address),
      contractAddress[chainId].Client
    );
  }

  useEffect(() => {
    if (country) {
      const countryCurrencies = countryDetails.find(
        (data) => data.code == country
      )?.currency;
      if (Array.isArray(countryCurrencies)) {
        const mappedOptions = countryCurrencies.map((option) => ({
          value: option,
          label: option,
        }));
        setCurrencyList(mappedOptions);
      } else {
        setCurrencyList([
          { value: countryCurrencies, label: countryCurrencies },
        ]);
      }
    }
  }, [country]);
  const filteredCountries = countryDetails.filter((country) =>
    country.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const setCustodyAccount = async () => {
    setLoading(true);
    const userData = {
      beneficiaryName: "ABC",
      businessName: "ABC",
      businessType: "public_limited",
      accountNumber: "12345678",
      ifscCode: "HDFC0000542",
    };
    const accountId = await paymentGateway
      .createAccount(account, userData, country, chainId)
      .then((result) => {
        return result.accountId;
      })
      .catch((err) => {
        setLoading(false);
      });
    if (props.isCustodian && accountId) {
      await Promise.all(
        selectedCurrency.map(async (currency) => {
          const countryCurrencyPair = country + currency.value;
          const countryCurrencyPairBytes =
            ethers.utils.formatBytes32String(countryCurrencyPair);
          const accountIdBytes = ethers.utils.formatBytes32String(accountId);
          try {
            const tx = await clientContract.setCustodyAccount(
              account,
              countryCurrencyPairBytes,
              accountIdBytes,
              networkOptions
            );
            setLoading(false);
            await paymentGateway
              .createAccountLink(accountId, country, window.location.href)
              .then((link) => {
                if (link) {
                  window.location.replace(link);
                }
              });
          } catch (err) {
            let error = { err };
            console.log(error);
          }
        })
      );
    }
  };
  return (
    <>
      {loading ? <Loader /> : null}
      {changeContent && (
        <UiButton
          onClick={() => {
            props.onModalHide();
          }}
          buttonVariant="primary"
          buttonClass="SignUpButton flex-1 ml-0"
          buttonText="Click to close this form&nbsp;&rarr;"
          type="submit"
        />
      )}
      <div className="d-grid gap-2" ref={modalContent}>
        <Row className="ml-1 align-items-center">
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Country</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <DropdownButton
                id="dropdown-basic-button"
                title={
                  country
                    ? getCountryName(country, "fullList")
                    : "Select country"
                }
              >
                <FormControl
                  type="text"
                  placeholder="Search country"
                  className="mx-3 my-2 w-auto"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                {filteredCountries.map((country) => (
                  <Dropdown.Item
                    key={country.code}
                    onClick={() => setCountry(country.code)}
                  >
                    {country.name}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Currency</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <Select
                options={currencyList ?? []}
                isMulti
                value={selectedCurrency}
                onChange={(selected) => setSelectedCurrency(selected)}
              />
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col className="pl-0">
              <UiButton
                onClick={() => {
                  setCustodyAccount();
                }}
                buttonVariant="primary"
                buttonClass="SignUpButton flex-1 ml-0"
                buttonText="Create"
                type="submit"
              />
            </Col>
          </Row>
        </Row>
      </div>
    </>
  );
}

export default function AddDetails(props) {
  return <NewCustodianAccount {...props} />;
}
