import { Row, Col } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";

function EarningsModal(props) {
  const settlements = props.data;
  
  return (
    <>
        <div className="d-grid gap-2">
          {settlements.length ? 
            <Row className="ml-1 align-items-center">
              <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                  <Col xs={{span:4, offset:2}} className="text-left">
                  <b>Underwriting Fee:</b>
                  </Col>
                  <Col className="pl-0" xs={5}>
                  {settlements?.at(-1)?.underwritingFee.toFixed(2) + " " + settlements?.at(-1)?.currencyName}
                  </Col>
              </Row>
              <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                  <Col xs={{span:4, offset:2}} className="text-left">
                  <b>Subscription Amount:</b>
                  </Col>
                  <Col className="pl-0" xs={5}>
                  {settlements?.at(-1)?.subscription.toFixed(2) + " " + settlements?.at(-1)?.currencyName}
                  </Col>
              </Row>
            </Row>
            :
            <Row className="ml-1 align-items-center">
              <b>Issue not settled</b>
            </Row>
          }
            
        </div>
    </>
  );
}

export function Earnings(props) {
  return <EarningsModal {...props} />;
}