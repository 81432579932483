import { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import Web3 from "web3";
import UiButton from "../../components/button";
import Loader from "../loader";

import {
  SuccessToast,
  FailureToast,
  ToastOptions,
} from "../../utils/constants";
import { toast } from "react-toastify";
import ERC20ABI from "../../utils/abi/ERC20.json";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useNetworkOptions } from "../../context/contractDataLoaderService";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import { providers } from "ethers";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../utils/helpers/networks";

function Resolution(props) {
  const { address, chainId } = useAccount();
  const { data: client } = useConnectorClient({ chainId });
  const { chain, transport } = client || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [ballot, setBallot] = useState(true);
  const [loading, setLoading] = useState(false);
  const [changeContent, setChangeContent] = useState(false);
  const modalContent = useRef(null);
  const { data: networkOptions } = useNetworkOptions();

  const castVote = async () => {
    const securityTokenContract = new web3.eth.Contract(
      ERC20ABI,
      props.data.securityAddress
    );
    setLoading(true);
    try {
      await securityTokenContract.methods
        .vote(props.data.date, ballot)
        .send({ ...(chainId === 137 ? networkOptions : {}), from: address });
      setLoading(false);
      toast.success(SuccessToast(), ToastOptions);
      setChangeContent(true);
      modalContent.current.innerHTML = "";
    } catch (err) {
      let error = { err };
      setLoading(false);
      toast.error(FailureToast(), ToastOptions);
    }
  };
  return (
    <>
      {loading ? <Loader /> : null}
      {changeContent && (
        <UiButton
          onClick={() => {
            props.onModalHide();
          }}
          buttonVariant="primary"
          buttonClass="SignUpButton flex-1 ml-0"
          buttonText="Click to close this form&nbsp;&rarr;"
          type="submit"
        />
      )}
      <div className="d-grid gap-2" ref={modalContent}>
        <Row className="ml-1 align-items-center">
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Cast your vote</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <DropdownButton
                id="dropdown-basic-button"
                title={ballot ? "Yes" : "No"}
              >
                <Dropdown.Item onClick={(e) => setBallot(true)}>
                  Yes
                </Dropdown.Item>
                <Dropdown.Item onClick={(e) => setBallot(false)}>
                  No
                </Dropdown.Item>
              </DropdownButton>
            </Col>
          </Row>
          <Row className="mx-0 my-2 pl-0 width-100 align-items-center">
            <Col className="pl-0">
              <UiButton
                onClick={() => {
                  castVote();
                }}
                buttonVariant="primary"
                buttonClass="SignUpButton flex-1 ml-0"
                buttonText="Vote"
                type="submit"
              />
            </Col>
          </Row>
        </Row>
      </div>
    </>
  );
}

export function InvestorVoting(props) {
  return <Resolution {...props} />;
}
