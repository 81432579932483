import { Client, contractAddress } from "@verified-network/verified-sdk";

export const BUNDLE_ID = "1";

export const timeframeOptions = {
  FOUR_HOURS: "4 hours",
  ONE_DAY: "1 day",
  THERE_DAYS: "3 days",
  WEEK: "1 week",
  MONTH: "1 month",
  // THREE_MONTHS: '3 months',
  // YEAR: '1 year',
  ALL_TIME: "All time",
};

export const Network = {
  MAINNET: 1,
  ROPSTEN: 3,
  RINKEBY: 4,
  GÖRLI: 5,
  KOVAN: 42,
  POLYGON: 137,
  ARBITRUM: 42161,
};

export const getKycStatus = async (address, chainId, signer) => {
  const clientAddress = contractAddress[chainId].Client;
  const clientContract = new Client(signer, clientAddress);
  const kycDataResponse = await clientContract.getClientKYC(address);
  const result = kycDataResponse.response.result;
  return Number(result[3]);
};

//Todo: add more login to handle token name to accepted token ticker on coinbase
export const getTokenTicker = (tokenName) => {
  if (tokenName.toLowerCase().startsWith("w")) {
    return tokenName.slice(1, tokenName.length);
  } else {
    return tokenName;
  }
};
