import { useEffect, useState } from "react";
import Loader from "../../components/loader";
import { useOfferedSecurities } from "../../context/contractDataLoaderService";

import {
  PrimaryIssueManager,
  contractAddress,
} from "@verified-network/verified-sdk";
import UiTable from "../../components/table/Table";
import Web3 from "web3";
import { useGlobal } from "../../context/globalContext";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import { providers } from "ethers";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../utils/helpers/networks";

function ManagerFacing(props) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const { userRole } = useGlobal();
  let { data: secondaryData, loading: secondaryLoading } =
    useOfferedSecurities();
  let primaryIssueManagerContract;
  if (provider && provider.getSigner && chainId && address) {
    const BalancerPrimaryIssueManager =
      contractAddress[chainId].BalancerPrimaryIssueManager;
    if (BalancerPrimaryIssueManager) {
      primaryIssueManagerContract = new PrimaryIssueManager(
        provider.getSigner(address),
        BalancerPrimaryIssueManager,
        "balancer"
      );
    }
  }

  let headers = [
    { label: "Owner Name", val: "ownerName" },
    { label: "Owner Country", val: "ownerCountry" },
    { label: "Offered", val: "securityName" },
    { label: "Amount offered", val: "amountOffered" },
    { label: "Currency", val: "currencyName" },
    { label: "Amount desired", val: "amountDesired" },
    { label: "Minimum amount asked", val: "min" },
    { label: "Call Action", val: "offerCallAction" },
  ];

  return (
    <>
      {secondaryLoading ? <Loader /> : null}
      <section className="d-flex flex-column align-items-start px-0 py-4">
        <div className="w-100">
          <h3 className="mb-3 d-flex">Offers</h3>
          <section className="d-flex flex-column align-items-start px-0 py-4">
            <div className="pools-table width-100">
              <UiTable
                thead={headers}
                tbodyData={secondaryData ?? []}
                hover
                bordered={false}
                owner={account}
                web3={web3}
                userRole={userRole}
                contract={primaryIssueManagerContract}
              />
            </div>
          </section>
        </div>
      </section>
    </>
  );
}

export default function ManagerOffers(props) {
  return <ManagerFacing {...props} />;
}
