import { useState, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import Web3 from "web3";
import UiButton from "../../components/button";
import Loader from "../loader";

import { format } from "date-fns";
import TextInput from "../../components/textinput/TextInput";
import {
  SuccessToast,
  FailureToast,
  ToastOptions,
  auth,
} from "../../utils/constants";
import "react-toastify/dist/ReactToastify.css";
import { contractAddress } from "@verified-network/verified-sdk";
import { toast } from "react-toastify";
import Response from "../../utils/response";
import ERC20ABI from "../../utils/abi/ERC20.json";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useNetworkOptions } from "../../context/contractDataLoaderService";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../utils/helpers/networks";
import { ethers, providers } from "ethers";

function Payout(props) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: client } = useConnectorClient({ chainId });
  const { chain, transport } = client || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [value, onChange] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(null);
  const [isVotable, setIsVotable] = useState(null);
  const [amount, setAmount] = useState(null);
  const [tokenAddress, setTokenAddress] = useState(null);
  const [loading, setLoading] = useState(false);
  const [changeContent, setChangeContent] = useState(false);
  const modalContent = useRef(null);
  const { data: networkOptions } = useNetworkOptions();
  const secondaryIssueManager =
    contractAddress[chainId].BalancerSecondaryIssueManager;
  const primaryIssueManager =
    contractAddress[chainId].BalancerPrimaryIssueManager;

  let { snapShots } = props;

  // Fetching dates in future
  const currentDate = Math.floor(new Date().getTime() / 1000).toString();
  snapShots = snapShots.filter((date) => date > currentDate);
  // console.log("props",props);

  const scheduleSnapshot = async () => {
    let dateTime = Math.floor(value.getTime() / 1000).toString();
    const securityTokenContract = new web3.eth.Contract(
      ERC20ABI,
      props.data.security
    );
    const currencyTokenContract = new web3.eth.Contract(
      ERC20ABI,
      props.data.currency
    );
    const currencyTokenDecimals = await currencyTokenContract.methods
      .decimals()
      .call();
    const currencyTokenBalance = await currencyTokenContract.methods
      .balanceOf(account)
      .call();
    const fixedCurencyBalance = ethers.utils.parseUnits(
      amount.toString(),
      Number(currencyTokenDecimals)
    );
    // console.log("fixedCurencyBalance",fixedCurencyBalance, currencyTokenBalance);
    if (!selectedTime) {
      toast.error(FailureToast("", "No time selected"), ToastOptions);
      return;
    }
    if (!amount) {
      toast.error(FailureToast("", "Enter proper amount"), ToastOptions);
      return;
    }
    if (Number(fixedCurencyBalance) > currencyTokenBalance) {
      toast.error(
        FailureToast("", "Enter currency amount less than balance"),
        ToastOptions
      );
      return;
    }
    setLoading(true);
    try {
      await currencyTokenContract.methods
        .approve(props.data.security, fixedCurencyBalance.toString())
        .send({ ...(chainId === 137 ? networkOptions : {}), from: account });

      await securityTokenContract.methods
        .payoutProrata(
          selectedTime,
          account,
          props.data.currency,
          fixedCurencyBalance.toString()
        )
        .send({ ...(chainId === 137 ? networkOptions : {}), from: account });

      setLoading(false);
      toast.success(SuccessToast(), ToastOptions);
      setChangeContent(true);
      modalContent.current.innerHTML = "";
    } catch (err) {
      console.log("erorr: ", err);
      let error = { err };
      setLoading(false);
      toast.error(FailureToast(), ToastOptions);
    }
  };
  return (
    <>
      {loading ? <Loader /> : null}
      {changeContent && (
        <UiButton
          onClick={() => {
            props.onModalHide();
          }}
          buttonVariant="primary"
          buttonClass="SignUpButton flex-1 ml-0"
          buttonText="Click to close this form&nbsp;&rarr;"
          type="submit"
        />
      )}
      <div className="d-grid gap-2" ref={modalContent}>
        <Row className="ml-1 align-items-center">
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Time</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <DropdownButton
                id="dropdown-basic-button"
                title={
                  selectedTime
                    ? format(
                        new Date(parseInt(selectedTime) * 1000),
                        "yyyy-MM-dd hh:mm a"
                      )
                    : "Select Time"
                }
              >
                {snapShots.length ? (
                  snapShots.map((snapshot) => (
                    <Dropdown.Item onClick={(e) => setSelectedTime(snapshot)}>
                      {format(
                        new Date(parseInt(snapshot) * 1000),
                        "yyyy-MM-dd hh:mm a"
                      )}
                    </Dropdown.Item>
                  ))
                ) : (
                  <div className="pl-2 pr-2">No snapshot scheduled</div>
                )}
              </DropdownButton>
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Amount</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <TextInput
                placeholder="Currency amount"
                value={amount}
                required
                onChange={(e) => setAmount(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mx-0 my-2 pl-0 width-100 align-items-center">
            <Col className="pl-0">
              <UiButton
                onClick={() => {
                  scheduleSnapshot();
                }}
                buttonVariant="primary"
                buttonClass="SignUpButton flex-1 ml-0"
                disabled={snapShots.length ? false : true}
                buttonText="Payout"
                type="submit"
              />
            </Col>
          </Row>
        </Row>
      </div>
    </>
  );
}

export function SchedulePayout(props) {
  return <Payout {...props} />;
}
