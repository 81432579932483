import React, { useState, useEffect } from "react";

import VerticallyModal from "../../../components/modal/VerticallyModal";
import { Row, Button } from "react-bootstrap";
import metamask from "../../../assets/images/metamask.png";
import walletconnectImg from "../../../assets/images/walletconnect.svg";
import { toast } from "react-toastify";
import { ToastOptions, FailureToast } from "../../../utils/constants";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount } from "wagmi";

function ConnectModal(props) {
  const { open } = useWeb3Modal();
  const { address, isConnected } = useAccount();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!address) open();
    }, 3000);

    return () => clearTimeout(timeoutId);
  });

  return <></>;
}

export default function WalletConnect(props) {
  return <ConnectModal {...props} />;
}
