import React, { useRef, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import LineChart from "../../components/chart/LineChart";
import CandlestickChart from "../../components/chart/CandlestickChart";
import { useChartData } from "../../context/contractDataLoaderService";
import { useVerifiedSecurity } from "../../context/verifiedWalletData";
import { PoolType } from "./balancer/types";
import Loader from "../../components/loader";

const PoolChart = (props) => {
  const chartColors = ["red", "green"];
  const chartLabel = ["Price", "Volume"];
  const [interval, setInterval] = useState("1D");
  const [dataset, setDataset] = useState(null);
  const [priceClicked, setPriceClicked] = useState(true);
  const [volumeClicked, setVolumeClicked] = useState(false);
  const [label, setLabel] = useState("Price");
  const [chartLoading, setChartLoading] = useState(true);
  const { data: verifiedSecurity } = useVerifiedSecurity(props.pool.security);
  const {
    priceData: priceData,
    volumeData: volumeData,
    loading: loading,
  } = useChartData(verifiedSecurity, props.pool);

  useEffect(() => {
    if (props.pool.poolType === PoolType.MarginIssue) {
      if (props.latestPrice) {
        // console.log("will set: .....");
        setDataset(props.latestPrice[props.latestPrice.length - 1]);
      }
      if (props.ohlc) {
        setDataset(props.ohlc);
      }
    }
  }, [props.ohlc, props.latestPrice]);

  useEffect(() => {
    if (props.pool.poolType !== PoolType.MarginIssue && priceData) {
      setDataset(priceData);
    }
  }, [verifiedSecurity, priceData]);

  const handleClick = (labelName) => {
    if (props.ohlc && props.volume) {
      if (labelName === "Price") {
        setLabel("Price");
        setDataset(props.ohlc);
        setPriceClicked(!priceClicked);
        setVolumeClicked(!volumeClicked);
      } else {
        setDataset(props.volume);
        setLabel("Volume");
        setVolumeClicked(!volumeClicked);
        setPriceClicked(!priceClicked);
      }
    } else {
      if (labelName === "Price") {
        setLabel("Price");
        setDataset(priceData);
        setPriceClicked(!priceClicked);
        setVolumeClicked(!volumeClicked);
      } else {
        setDataset(volumeData);
        setLabel("Volume");
        setVolumeClicked(!volumeClicked);
        setPriceClicked(!priceClicked);
      }
    }
  };

  const priceButtonStyle = {
    backgroundColor: "#e84d1a",
    color: "white",
    fontWeight: "bold",
    borderColor: "#d75e21",
  };

  const volumeButtonStyle = {
    backgroundColor: "#3ac23f",
    color: "white",
    fontWeight: "bold",
    borderColor: "#43c46a",
  };
  const normal = {
    backgroundColor: "#979ea1",
    color: "white",
    fontWeight: "bold",
    borderColor: "#9aa4a6",
  };

  return (
    <>
      {props.pool.poolType !== PoolType.MarginIssue && loading ? (
        <Loader />
      ) : null}
      <div className="w-100 d-flex p-2 justify-content-between dynamic-chart">
        <div className="text-align-center">
          <Button
            className={priceClicked ? "priceButtonStyle" : "normal"}
            onClick={() => handleClick("Price")}
          >
            Price
          </Button>
          <Button
            className={volumeClicked ? "volumeButtonStyle" : "normal"}
            onClick={() => handleClick("Volume")}
          >
            Volume
          </Button>
        </div>
      </div>
      {dataset ? (
        priceClicked ? (
          <CandlestickChart
            data={dataset}
            yAxisTitle={label}
            firstLabel={label}
            lineColor={priceClicked ? "#e84d1a" : "#3ac23f"}
            chartHeight={400}
          />
        ) : (
          <LineChart
            data={dataset}
            yAxisTitle={label}
            firstLabel={label}
            lineColor={priceClicked ? "#e84d1a" : "#3ac23f"}
            chartHeight={400}
          />
        )
      ) : (
        "No data available"
      )}
    </>
  );
};

export default PoolChart;
