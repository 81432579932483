import React, { useRef, useEffect } from "react";
import anychart from "anychart";

const CandlestickChart = (props) => {
  const chartContainer = useRef(null);
  useEffect(() => {
    const element = chartContainer.current;
    if(element.lastElementChild !== null)
      element.removeChild(element.lastElementChild);
      
    const chart = anychart.stock();
    const dataTable = anychart.data.table();
    dataTable.addData(props.data);

    // create a mapping for the data table
    const mapping = dataTable.mapAs({
      'open': 1,
      'high': 2,
      'low': 3,
      'close': 4
    });
    // create first plot on the chart
    var plot = chart.plot(0);
    // plot.title('Hourly Chart')
    // set grid settings
    plot.yGrid(true).xGrid(true);
    var series = plot.candlestick(mapping);
    series.name('Price');
    series.legendItem().iconType('rising-falling');
    
    series.fallingFill("#ED3944"); // set the fill color for falling candles
    series.fallingStroke("#FF0000"); // set the stroke color for falling candles
    series.risingFill("#178a6a"); // set the fill color for rising candles
    series.risingStroke("#108564"); // set the stroke color for rising candles
    
    // set title formatter
    var tooltip = chart.tooltip();
    tooltip.titleFormat(function () {
        return anychart.format.dateTime(this.x, 'dd MMMM yyyy hh:mm a');
    });
    // render the chart
    chart.container(chartContainer.current);
    chart.draw();

  }, [props.data,props.data2, props.chartTitle, props.yAxisTitle]);

  return <div ref={chartContainer} style={{ height: props.chartHeight, width: "100%" }} />;
};

export default CandlestickChart;
