import config from "../Config";
import Axios from "./Axios";

const SUCCESS_PAYMENT = "confirmed";

/**
 * Get the gateway name for a user
 *
 * @param {String} userAddress User for check payment gateway (logged user)
 * @return {Promise<null>}
 */
/*function getGatewayName(userAddress, chainId) {
  const axios = Axios.getInstance();

  return axios
    .get(config.paymentGatewayUrl, {
      params: {
        'action': 'get_gateway_name',
        'user_address': userAddress,
        'chainId': chainId,
      },
    })
    .then((response) => {
      return response.data.gateway_name;
    })
    .catch((e) => handleError(e));
}*/

/**
 * Create the account for the user in the payment gateway
 *
 * @param {String} userAddress User owner of account (logged user)
 * @param {{beneficiaryName: *, businessName: *, businessType: *, accountNumber: *, ifscCode: *}} userData
 * @return {Promise<null>}
 */
async function createAccount(userAddress, userData, country, chainId) {
  const axios = Axios.getInstance();

  return await axios
    .post(config.paymentGatewayUrl, {
      action: "create_account",
      user_address: userAddress,
      beneficiary_name: userData.beneficiaryName,
      business_name: userData.businessName,
      business_type: userData.businessType,
      account_number: userData.accountNumber,
      ifsc_code: userData.ifscCode,
      country: country,
      chain_id: chainId,
    })
    .then((response) => {
      // console.log("res: ", response);
      return response.data;
    })
    .catch((e) => {
      console.log("error: ", e);
      handleError(e);
    });
}

/*function getAccount(userAddress, chainId) {
  const axios = Axios.getInstance();

  return axios
    .get(config.paymentGatewayUrl, {
      params: {
        'action': 'get_account',
        'user_address': userAddress,
        'chainId': chainId,
      },
    })
    .then(() => {
      return null;
    })
    .catch((e) => handleError(e));
}*/

/**
 * Create a link for kyc in payment gateway
 * @param {String} userAddress User owner of account (logged user)
 * @return {Promise<String>} Url to follow for kyc fulfill
 */
function createAccountLink(accountid, country, link) {
  const axios = Axios.getInstance();

  return axios
    .get(config.paymentGatewayUrl, {
      params: {
        action: "create_link",
        //user_address: userAddress,
        accountid: accountid,
        return_url: link,
        refresh_url: link,
        //chainId: chainId,
        country: country,
      },
    })
    .then((response) => response.data.url)
    .catch((e) => handleError(e));
}

/**
 * Returns if a user is kyc accepted in the payment gateway
 *
 * @param {String} userAddress userAddress User owner of account (logged user)
 * @return {Promise<boolean>} True if the user is kyc accepted, false otherwise
 */
/*function isKycAccepted(userAddress) {
  const axios = Axios.getInstance();

  return axios
    .get(config.paymentGatewayUrl, {
      params: {
        action: 'get_account',
        user_address: userAddress,
      },
    })
    .then((response) => response.data.kyc_accepted)
    .catch((ignored) => {
      return false;
    });
}*/

/**
 * Create a payment for request verified cash paying with fiat money using the payment gateway
 *
 * @param {String} investorAddress User requester of the cash (logged user)
 * @param {Number} amount Amount to pay (cents in the payed fiat currency)
 * @param {String} payCurrency Fiat currency to pay with e.g.(usd, eur, inr)
 * @param {String } issueCurrency Address of the verified cash token to issue
 *
 * @return {Promise<Object>} Returns a object with necessary data for process the payment in frontend (This object will
 * be different depending on the payment gateway to use) for stripe it looks like this:
 * {client_secret: "pi_3K....", gateway_name: "Stripe"}
 */
async function createCashIssueRequest(
  country,
  investorAddress,
  custodianAccountId,
  amount,
  payCurrency,
  issueCurrency,
  chainId
) {
  const axios = Axios.getInstance();
  return await axios
    .post(config.paymentGatewayUrl, {
      action: "create_cash_issue_request",
      amount: amount,
      investor_address: investorAddress,
      country: country,
      custodianAccountId: custodianAccountId,
      //custodian_address : custodianAddress,
      pay_currency: payCurrency,
      issue_currency: issueCurrency,
      chainId: chainId,
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => handleError(e));
}

/**
 * List the cash issues requested to a custodian
 *
 * @param {String} custodianAddress Custodian responsible for accept the request (logged user)
 *
 * @return {Promise<[Object]>} An array of cash issue requests, each object will look like this:
 * {"userAddress": "0x7079...", "amount": 505, "paymentRef": "pi_3KCW...", "date": 1640900778000, "currency": "eur",
 * "requestProduct": "0x5684...", "status": "requested", "requestType": "Cash"
 * }
 */
function listCashIssueRequests(custodianAccountId, country, chainId) {
  const axios = Axios.getInstance();

  return axios
    .get(config.paymentGatewayUrl, {
      params: {
        action: "list_cash_issue_requests",
        //custodian_address: custodianAddress,
        custodianAccountId: custodianAccountId,
        country: country,
        chainId: chainId,
      },
    })
    .then((response) => response.data)
    .catch((e) => handleError(e));
}

function getCashIssueRequest(custodianAddress, paymentRef) {
  const axios = Axios.getInstance();

  return axios
    .get(config.paymentGatewayUrl, {
      params: {
        action: "get_withdraw_request",
        payment_id: paymentRef,
        custodian_address: custodianAddress,
      },
    })
    .then((response) => response.data)
    .catch((e) => handleError(e));
}

/**
 * Confirm a cash issue request in the payment gateway
 *
 * @param {String} custodianAddress Address of custodian responsible for accept the request (logged user)
 * @param {String} paymentRef Payment reference of the request, come from a item in listCashIssueRequests function
 * @return {Promise<void>}
 */
function confirmCashIssueRequest(country, paymentRef) {
  const axios = Axios.getInstance();

  return axios
    .get(config.paymentGatewayUrl, {
      params: {
        action: "confirm_cash_issue_request",
        payment_id: paymentRef,
        country: country,
        // custodian_address: custodianAddress,
        // chainId: chainId,
      },
    })
    .then(() => null)
    .catch((e) => handleError(e));
}

/**
 * Reject a cash issue request in the payment gateway, refunding the money to investor
 *
 * @param {String} custodianAddress Address of custodian responsible for the request (logged user)
 * @param {String} paymentRef Payment reference of the request, come from a item in listCashIssueRequests function
 * @return {Promise<void>}
 */
function rejectCashIssueRequest(country, paymentRef) {
  const axios = Axios.getInstance();

  return axios
    .get(config.paymentGatewayUrl, {
      params: {
        action: "reject_cash_issue_request",
        payment_id: paymentRef,
        //custodian_address: custodianAddress,
        //chainId: chainId
        country: country,
      },
    })
    .then((response) => response.data)
    .catch((e) => handleError(e));
}

function createWithdrawRequest(
  country,
  investorAddress,
  investorAccountId,
  custodianAddress,
  custodianAccountId,
  amount,
  withdraw_currency,
  chainId
) {
  const axios = Axios.getInstance();

  return axios
    .get(config.paymentGatewayUrl, {
      params: {
        action: "create_withdraw_request",
        country: country,
        investor_address: investorAddress,
        investorAccountId: investorAccountId,
        amount: amount,
        pay_currency: withdraw_currency,
        custodian_address: custodianAddress,
        custodianAccountId: custodianAccountId,
        chainId: chainId,
      },
    })
    .then((response) => response.data)
    .catch((e) => handleError(e));
}

/**
 * List fiat withdrawals for payments from custodian to issuers (to pay subscription capital) and investors (for distributions)
 *
 * @param {String} custodianAddress Custodian responsible for accept the request (logged user)
 *
 * @return {Promise<[Object]>} An array of withdrawal requests, each object will look like this:
 * {"userAddress": "0x7079...", "amount": 505, "paymentRef":"pi_3KC...", "date":1640884411000, "currency":"usd",
 * "status": "confirmed", "payment_status": "succeeded", "client_secret": "pi_3KCSB...", "gateway_name": "Stripe"}
 */
function listWithdrawRequests(accountId, country, chainId) {
  const axios = Axios.getInstance();

  return axios
    .get(config.paymentGatewayUrl, {
      params: {
        action: "list_withdraw_requests",
        //custodian_address: custodianAddress,
        accountId: accountId,
        country: country,
        chainId: chainId,
      },
    })
    .then((response) => response.data)
    .catch((e) => handleError(e));
}

/**
 * Returns a withdrawal request by its payment ref
 *
 * @param {String} custodianAddress Custodian responsible for transferring subscriptions or distributions
 * @param {String} paymentRef Payment reference of the request, come from a item in listCashIssueRequests function
 *
 * @return {Promise<Object>} A withdrawal request object,it look like this:
 * {"userAddress": "0x7079...", "amount": 505, "paymentRef":"pi_3KC...", "date":1640884411000, "currency":"usd",
 * "status": "confirmed", "payment_status": "succeeded", "client_secret": "pi_3KCSB...", "gateway_name": "Stripe"}
 */
function getWithdrawRequest(country, paymentRef) {
  const axios = Axios.getInstance();

  return axios
    .get(config.paymentGatewayUrl, {
      params: {
        action: "get_withdraw_request",
        country: country,
        payment_id: paymentRef,
        //custodian_address: custodianAddress,
        //chainId: chainId,
      },
    })
    .then((response) => response.data)
    .catch((e) => handleError(e));
}

/**
 * Create a payment for kyc in the payment gateway
 *
 * @param {String} userAddress Payer user (logged user)
 * @param {String } userCountry Country of payer user (we need to ask this in UI, because here our user still dont have
 * a submitted kyc, so we dont know country)
 *
 * @return {Promise<Object>} Returns a object with necessary data for process the payment in frontend
 * for Stripe: {payment_ref, client_secret, gateway_name}
 */
/*function createKycPayment(userAddress, userCountry) {
  const axios = Axios.getInstance();

  return axios
    .get(config.paymentGatewayUrl, {
      params: {
        action: 'create_kyc_payment',
        user_address: userAddress,
        user_country: userCountry,
      },
    })
    .then((response) => response.data)
    .catch((e) => handleError(e));
}*/

/**
 * Returns if the user still dont pay for kyc
 *
 * @param {String} userAddress User address to check for (logged user)
 * @return {Promise<Boolean>}
 */
/*function getKycPaymentStatus(userAddress) {
  const axios = Axios.getInstance();

  return axios
    .get(config.paymentGatewayUrl, {
      params: {
        action: 'get_kyc_payment',
        user_address: userAddress,
      },
    })
    .then((response) => response.data.status === SUCCESS_PAYMENT)
    .catch((e) => handleError(e));
}*/

/**
 * Success the kyc payment when the user invite 10 friends, so future requests for getKycPaymentStatus will return true
 *
 * @param {String} userAddress User address for success payment
 * @return {Promise<null>}
 */
/*function successKycPaymentWithInviteFriends(userAddress) {
  const axios = Axios.getInstance();

  return axios
    .get(config.paymentGatewayUrl, {
      params: {
        action: 'success_kyc_payment',
        user_address: userAddress,
      },
    })
    .then(() => null)
    .catch((e) => handleError(e));
}*/

function handleError(e) {
  if (e.response && e.response.data && e.response.data.error) {
    return Promise.reject(new Error(e.response.data.error));
  } else {
    return Promise.reject(
      new Error("There was an error serving your request.")
    );
  }
}

export default {
  createAccount,
  createAccountLink,
  createCashIssueRequest,
  listCashIssueRequests,
  confirmCashIssueRequest,
  rejectCashIssueRequest,
  createWithdrawRequest,
  listWithdrawRequests,
  getWithdrawRequest,
  //isKycAccepted,
  //createKycPayment,
  //getKycPaymentStatus,
  getCashIssueRequest,
  //successKycPaymentWithInviteFriends,
  //getGatewayName,
  //getAccount,
  Gateways: {
    STRIPE: "Stripe",
    RAZOR: "Razor",
  },
  SUCCESS_PAYMENT: SUCCESS_PAYMENT,
};
