import { Row, Col } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";

function AllotmentModal(props) {
  const { allotments } = props.data;
  return (
    <>
        <div className="d-grid gap-2">
            <Row className="ml-1 align-items-center">
            <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                <Col xs={{span:4, offset:2}} className="text-left">
                <b>Allotment amount:</b>
                </Col>
                <Col className="pl-0" xs={5}>
                {Number(allotments?.at(-1).allotedAmount).toFixed(2) + " " + allotments?.at(-1).currencyTokenName}
                </Col>
            </Row>
            <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                <Col xs={{span:4, offset:2}} className="text-left">
                <b>Securities Subscribed:</b>
                </Col>
                <Col className="pl-0" xs={5}>
                {allotments?.at(-1).securitySubscribed + " " + allotments?.at(-1).securityTokenName}
                </Col>
            </Row>
            </Row>
        </div>
    </>
  );
}

export function Allotments(props) {
  return <AllotmentModal {...props} />;
}