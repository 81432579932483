import React, { useEffect, useState } from "react";
import Loader from "../../components/loader";

import { Button } from "react-bootstrap";
import UiTable from "../../components/table/Table";
import VerticallyModal from "../../components/modal/VerticallyModal";
import { useCashIssueRequestList } from "../../context/contractDataLoaderService";
import AddDetails from "./details";

function CustodianData(props) {
  const [showModal, setShowModal] = useState(false);
  let { data: tableData, loading } = useCashIssueRequestList();

  const issueRequestHeader = [
    // {label: 'User address', val: 'userID'},
    { label: "User name", val: "userName" },
    { label: "Payment ref", val: "requestID" },
    { label: "Date", val: "formattedDate" },
    { label: "Currency", val: "currencyName" },
    { label: "Quantity", val: "quantity" },
    { label: "Request token", val: "requestTokenName" },
    // {label: 'Request Type', val: 'requestType'},
    { label: "Status", val: "status" },
    { label: "Action", val: "action" },
  ];

  return (
    <>
      {loading ? <Loader /> : null}
      <div>
        <section className="d-flex flex-column align-items-start px-0 py-4">
          <div className="mb-3 d-flex justify-content-between w-100 align-items-center">
            <div className="w-100">
              <h3 className="mb-3 d-flex">Issue Requests</h3>
            </div>
            <div className="w-100 text-right">
              <Button
                onClick={() => {
                  setShowModal(true);
                }}
              >
                Link Bank Account
              </Button>
            </div>
          </div>
        </section>
        <section id="requestsTable">
          <UiTable thead={issueRequestHeader} tbodyData={tableData ?? []} />
        </section>
      </div>
      <VerticallyModal
        key="showDetails"
        showModal={showModal}
        modalOnHide={() => {
          setShowModal(false);
        }}
        modalSize={"lg"}
        modalHeading={
          <h2>
            <b>Create Custody Account</b>
          </h2>
        }
        withFooter={false}
      >
        <AddDetails isCustodian={true} />
      </VerticallyModal>
    </>
  );
}

export default function IssueRequests(props) {
  return <CustodianData {...props} />;
}
