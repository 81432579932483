import axios from "axios";
import config from "../../services/config/homestead.json";
const sgMail = require("@sendgrid/mail");

export const getImagesInBase64 = async (url) => {
  return await axios({
    method: "GET",
    url: url,
  })
    .then((res) => {
      if (res.data.errors) {
        console.error("Unexpected error occured");
        return;
      } else {
        // console.log("pool res: ", res.data);
        return res.data;
      }
    })
    .catch((err) => {
      console.error("Error while images: ", err);
      return;
    });
};

export const changeApllicantName = async (
  applicantId,
  firstName,
  lastName,
  middleName
) => {
  return await axios({
    method: "POST",
    url: config.approvalEndpoint,
    headers: {
      Accept: "application/json",
    },
    data:
      middleName !== ""
        ? {
            applicantId: applicantId,
            firstName: firstName,
            middleName: middleName,
            lastName: lastName,
          }
        : {
            applicantId: applicantId,
            firstName: firstName,
            lastName: lastName,
          },
  })
    .then((res) => {
      const response = res?.data;
      if (response) {
        console.log("return from name change request: ", response);
        return true;
      }
    })
    .catch((err) => {
      //handle request error
      console.log(`post request to update name failed with error: ${err}`);
      return false;
    });
};

export const approveOrRejectApplicant = async (
  applicantId,
  inspectionId,
  externalUserData,
  reviewResult
) => {
  // console.log("det: ", {
  //   applicantId: applicantId,
  //   inspectionId: inspectionId,
  //   externalUserId: externalUserData,
  //   reviewResult: reviewResult,
  // });
  return await axios({
    method: "POST",
    url: config.approvalEndpoint,
    headers: {
      Accept: "application/json",
    },
    data: reviewResult
      ? {
          applicantId: applicantId,
          inspectionId: inspectionId,
          externalUserId: externalUserData,
          reviewResult: reviewResult,
        }
      : {
          applicantId: applicantId,
          inspectionId: inspectionId,
          externalUserId: externalUserData,
        },
  })
    .then((res) => {
      const response = res?.data;
      if (response) {
        console.log("return from req: ", response);
        return true;
      }
    })
    .catch((err) => {
      //handle request error
      console.log(`post request failed with error: ${err}`);
      return false;
    });
};

export const sendMessageToApplicant = async (
  applicantEmail,
  applicantName,
  messageSubject,
  message
) => {
  // sgMail.setApiKey(config.keys.sendGridApiKey);
  // const msg = {
  //   to: applicantEmail,
  //   from: config.sendGridSenderEmail,
  //   subject: messageSubject,
  //   text: message,
  //   html: `<p>${message}</p>`,
  // };
  // console.log("msg: ", msg, config.keys.sendGridApiKey);
  // try {
  //   await sgMail.send(msg);
  //   return true;
  // } catch (err) {
  //   console.log("Send message failed with error: ", err);
  //   if (err.response) {
  //     console.log("Send message failed with error: ", err.response.body);
  //   }
  //   return false;
  // }
  // console.log(
  //   "data: ",
  //   JSON.stringify({
  //     personalizations: [
  //       {
  //         to: applicantName
  //           ? [{ email: applicantEmail, name: applicantName }]
  //           : [{ email: applicantEmail }],
  //         subject: messageSubject,
  //         content: [{ type: "text/plain", value: message }],
  //         from: { email: config.sendGridSenderEmail, name: "Verified Network" },
  //         reply_to: {
  //           email: config.sendGridSenderEmail,
  //           name: "Verified Network",
  //         },
  //       },
  //     ],
  //   })
  // );
  // return;
  return await axios({
    method: "POST",
    url: config.sendGridEndpoint,
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${config.keys.sendGridApiKey}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      personalizations: [
        {
          to: applicantName
            ? [
                {
                  email: applicantEmail,
                  name: applicantName,
                },
              ]
            : [
                {
                  email: applicantEmail,
                },
              ],
          subject: messageSubject,
        },
      ],
      content: [
        {
          type: "text/plain",
          value: message,
        },
      ],
      from: {
        email: config.sendGridSenderEmail,
        name: "Verified Network",
      },
      reply_to: {
        email: config.sendGridSenderEmail,
        name: "Verified Network",
      },
    }),
  })
    .then((res) => {
      const response = res?.data;
      if (response) {
        console.log("return from req: ", response);
      }
      return true;
    })
    .catch((err) => {
      //handle request error
      console.log(`post request failed with error: ${err}`);
      return false;
    });
};

export const blockApplicant = async (applicantId) => {
  console.log("will block user: ", applicantId);
  return;
};
