import React, { useState } from "react";
import Loader from "../../components/loader";
import UiTable from "../../components/table/Table";

import Web3 from "web3";
import {
  useClientKYCData,
  useNetworkOptions,
} from "../../context/contractDataLoaderService";
import { Client, contractAddress } from "@verified-network/verified-sdk";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../utils/helpers/networks";
import { providers } from "ethers";
import { useGlobal } from "../../context/globalContext";
import VerticallyModal from "../../components/modal/VerticallyModal";
import TextInput from "../../components/textinput/TextInput";
import UiButton from "../../components/button";
import { Col, Row } from "react-bootstrap";
import { changeApllicantName, sendMessageToApplicant } from "./kycUtils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  SuccessToast,
  FailureToast,
  ToastOptions,
} from "../../utils/constants";

function KYCList(props) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const { userCountry } = useGlobal();
  let { data: kycList, loading: kycLoading } = useClientKYCData(
    false,
    true,
    userCountry
  );
  const [showModal, setShowModal] = useState(false);
  const [modalView, setModalView] = useState(0);
  const [userIndex, setUserIndex] = useState(0);
  const [kycMessage, setKycMessage] = useState("");
  const [kycMessageSubject, setKycMessageSubject] = useState("");
  const [newApplicantName, setNewApplicantName] = useState(null);
  const [messageApplicantName, setMessageApplicantName] = useState(null);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [applicantDoc, setApplicantDoc] = useState();
  const [applicantSelfie, setApplicantSelfie] = useState();
  const [generalLoading, setGeneralLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  let message, heading;

  const handleModalHide = () => {
    setModalView(0);
    setShowModal(false);
    setNewApplicantName(null);
    setMessageApplicantName(null);
    setShowSaveButton(false);
  };

  const commonProps = {
    modalView,
    updateModalView: setModalView,
    showModal,
    updateShowModal: setShowModal,
    userIndex,
    updateUserIndex: setUserIndex,
    applicantDoc,
    updateApplicantDoc: setApplicantDoc,
    newApplicantName,
    applicantSelfie,
    updateApplicantSelfie: setApplicantSelfie,
    generalLoading,
    updateGeneralLoading: setGeneralLoading,
  };
  const { data: networkOptions } = useNetworkOptions();
  const headers = [
    { label: "Wallet address", val: "accountAddress" },
    { label: "Name", val: "fullName" },
    { label: "Applicant ID", val: "applicantId" },
    { label: "Country", val: "countryName" },
    { label: "Staus", val: "applicantStatus" },
    // { label: "Photo/Video KYC", val: "selfieURL" },
    // { label: "Indentity KYC", val: "identityURL" },
    { label: "Actions", val: "kycAction" },
  ];

  // console.log("loading: ", kycList);

  const processedData = kycList?.filter((kyc) => {
    if (
      kyc &&
      kyc.countryName.toLowerCase() === userCountry.toLowerCase() &&
      kyc.status !== 3
    ) {
      return kyc;
    }
  });

  if (modalView == 1) {
    heading = "Send Kyc Message";
    message = (
      <div className="d-grid gap-2">
        <Row className="ml-1 align-items-center">
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Applicant Email</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <TextInput
                placeholder="Applicant email"
                disabled={true}
                value={processedData ? processedData[userIndex]["email"] : ""}
              />
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Applicant Name</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <TextInput
                placeholder="Applicant name"
                onChange={(e) => setMessageApplicantName(e.target.value)}
                value={
                  processedData && messageApplicantName === null
                    ? processedData[userIndex]["fullName"]
                    : messageApplicantName
                }
              />
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Subject</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <textarea
                onChange={(e) => setKycMessageSubject(e.target.value)}
                name="KycMessageSubject"
                style={{
                  minHeight: "50px",
                  width: "300px",
                }}
              >
                {kycMessageSubject}
              </textarea>
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Message</b>
            </Col>
            <Col className="pl-0" xs={5}>
              {/* <MessageInput
                style={{
                  height: "100px",
                }}
                placeholder="Message"
                onChange={(e) => setKycMessage(e.target.value)}
                value={kycMessage}
              /> */}
              <textarea
                onChange={(e) => setKycMessage(e.target.value)}
                name="kycMessage"
                style={{
                  minHeight: "190px",
                  width: "400px",
                }}
              >
                {kycMessage}
              </textarea>
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col className="pl-0">
              <UiButton
                onClick={async () => {
                  if (kycMessage === "") {
                    toast.error("Message can not be empty", ToastOptions);
                    return;
                  }
                  if (kycMessageSubject === "") {
                    toast.error(
                      "Message subject can not be empty",
                      ToastOptions
                    );
                    return;
                  }
                  if (
                    !processedData ||
                    processedData[userIndex]["email"].length < 1
                  ) {
                    toast.error(
                      "Error while getting applicant's email try again later",
                      ToastOptions
                    );
                    return;
                  }
                  setGeneralLoading(true);
                  await sendMessageToApplicant(
                    processedData[userIndex]["email"],
                    null,
                    kycMessageSubject,
                    kycMessage
                  ).then((res) => {
                    setGeneralLoading(false);
                    res
                      ? toast.success("Message sent succesfully", ToastOptions)
                      : toast.error(
                          "Error while sending message",
                          ToastOptions
                        );
                  });
                }}
                buttonVariant="primary"
                buttonClass="SignUpButton flex-1 ml-0"
                buttonText="Send Message"
                type="submit"
              />
            </Col>
          </Row>
        </Row>
      </div>
    );
  } else if (modalView == 2) {
    heading = "Apllicant Portal";
    message = (
      <div className="d-grid gap-2">
        {loader ? <Loader /> : null}
        <Row className="ml-1 align-items-center">
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Applicant Id</b>
            </Col>
            <Col className="pl-0" xs={5}>
              {processedData ? processedData[userIndex]["applicantId"] : ""}
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Applicant Address</b>
            </Col>
            <Col className="pl-0" xs={5}>
              {processedData ? processedData[userIndex]["accountAddress"] : ""}
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Applicant Name</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <TextInput
                placeholder="Applicant's name(firstname lastname)"
                value={
                  processedData && !showSaveButton && newApplicantName === null
                    ? processedData[userIndex]["fullName"]
                    : showSaveButton
                    ? newApplicantName
                    : ""
                }
                onChange={(e) => {
                  setNewApplicantName(e.target.value);
                  e.target.value !== ""
                    ? setShowSaveButton(true)
                    : setShowSaveButton(false);
                }}
              />
              {showSaveButton && (
                <UiButton
                  onClick={async () => {
                    const nameSplit = newApplicantName.split(" ");
                    if (nameSplit.length < 2) {
                      toast.error(
                        "Appllicant's name must include first and last name",
                        ToastOptions
                      );
                      return;
                    }
                    setLoader(true);
                    const firstName =
                      nameSplit.length > 0 ? nameSplit[0] : newApplicantName;
                    const middleName =
                      nameSplit.length > 2
                        ? nameSplit[nameSplit.length - 2]
                        : "";
                    const lastName =
                      nameSplit.length > 1
                        ? nameSplit[nameSplit.length - 1]
                        : "";
                    await changeApllicantName(
                      processedData[userIndex]["applicantId"],
                      firstName,
                      lastName,
                      middleName
                    ).then((res) => {
                      setLoader(false);
                      res
                        ? toast.success(
                            "Appllicant's name changed succesfully",
                            ToastOptions
                          )
                        : toast.error(
                            "Appllicant's name change request failed",
                            ToastOptions
                          );
                      if (res) {
                        setShowSaveButton(false);
                      }
                    });
                  }}
                  buttonVariant="primary"
                  buttonClass="SignUpButton flex-1 ml-0"
                  buttonText="Save Changes"
                  type="submit"
                />
              )}
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Applicant Email</b>
            </Col>
            <Col className="pl-0" xs={5}>
              {processedData ? processedData[userIndex]["email"] : ""}
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Applicant Country</b>
            </Col>
            <Col className="pl-0" xs={5}>
              {processedData ? processedData[userIndex]["countryName"] : ""}
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Status</b>
            </Col>
            <Col className="pl-0" xs={5}>
              {processedData ? processedData[userIndex]["applicantStatus"] : ""}
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Applicant ID</b>
            </Col>
            <Col className="pl-0" xs={5}>
              {applicantDoc && (
                <img
                  src={`data:image/jfif;base64,${applicantDoc}`}
                  width="200px"
                  height="180px"
                  alt="Applicant's document"
                />
              )}
              {!applicantDoc && (
                <img
                  src=""
                  width="200px"
                  height="180px"
                  alt="Applicant's Document Loading..."
                />
              )}
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Applicant Selfie</b>
            </Col>
            <Col className="pl-0" xs={5}>
              {applicantSelfie && (
                <img
                  src={`data:image/jfif;base64,${applicantSelfie}`}
                  width="150px"
                  height="100px"
                  alt="Applicant's document"
                />
              )}
              {!applicantSelfie && (
                <img
                  src=""
                  width="150px"
                  height="100px"
                  alt="Applicant's Selfie Loading..."
                />
              )}
            </Col>
          </Row>
          {/* <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col className="pl-0">
              <UiButton
                onClick={() => {}}
                buttonVariant="primary"
                buttonClass="SignUpButton flex-1 ml-0"
                buttonText="Approve"
                type="submit"
              />
            </Col>
          </Row> */}
        </Row>
      </div>
    );
  }

  let clientContract;
  if (provider && provider.getSigner && chainId && address) {
    clientContract = new Client(
      provider.getSigner(address),
      contractAddress[chainId].Client
    );
  }
  return (
    <>
      {kycLoading || generalLoading ? <Loader /> : null}
      <section className="d-flex flex-column align-items-start px-0 py-4">
        <div className="w-100">
          <div className="mb-3 d-flex justify-content-between w-100 align-items-center">
            <h3 className="w-50 text-left">Pending KYC</h3>
          </div>
          <div className="pools-table width-100">
            <UiTable
              thead={headers}
              tbodyData={processedData ?? []}
              hover
              provider={provider}
              transport={transport}
              account={account}
              chainId={chainId}
              commonProps={commonProps}
              networkOptions={networkOptions}
              contract={clientContract}
              bordered={false}
            />
          </div>
        </div>
      </section>
      <VerticallyModal
        key="connectProvider"
        showModal={showModal}
        modalOnHide={handleModalHide}
        modalSize={"lg"}
        modalHeading={
          <h2>
            <b>{heading}</b>
          </h2>
        }
        withFooter={false}
      >
        {message}
      </VerticallyModal>
    </>
  );
}

export default function ApprovedKYC(props) {
  return <KYCList {...props} />;
}
