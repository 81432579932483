import { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Web3 from "web3";
import UiButton from "../../components/button";

import DateTimePicker from "react-datetime-picker";
import "react-toastify/dist/ReactToastify.css";
import ERC20ABI from "../../utils/abi/ERC20.json";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import { providers } from "ethers";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../utils/helpers/networks";

function RefundsModal(props) {
  const { address, chainId } = useAccount();
  const { data: client } = useConnectorClient({ chainId });
  const { chain, transport } = client || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [value, onChange] = useState(new Date());
  const [snapShots, setSnapShots] = useState(null);

  const scheduleSnapshot = async () => {
    let dateTime = Math.floor(value.getTime() / 1000).toString();
    const securityTokenContract = new web3.eth.Contract(
      ERC20ABI,
      props.data.security
    );
    let snapshotTotalSupply;
    try {
      snapshotTotalSupply = await securityTokenContract.methods
        .snapshotTotalSupply(dateTime)
        .call();
      setSnapShots(web3.utils.fromWei(snapshotTotalSupply, "ether"));
    } catch (err) {
      let error = { err };
    }
  };
  return (
    <>
      <div className="d-grid gap-2">
        <Row className="ml-1 align-items-center">
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Time</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <DateTimePicker onChange={onChange} value={value} />
            </Col>
          </Row>
          {snapShots ? (
            <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
              <Col xs={{ span: 3, offset: 2 }} className="text-left">
                <b>Total Supply</b>
              </Col>
              <Col className="pl-0" xs={5}>
                {snapShots}
              </Col>
            </Row>
          ) : null}
          <Row className="mx-0 my-2 pl-0 width-100 align-items-center">
            <Col className="pl-0">
              <UiButton
                onClick={() => {
                  scheduleSnapshot();
                }}
                buttonVariant="primary"
                buttonClass="SignUpButton flex-1 ml-0"
                buttonText="Get Float"
                type="submit"
              />
            </Col>
          </Row>
        </Row>
      </div>
    </>
  );
}

export function FloatingSecurities(props) {
  return <RefundsModal {...props} />;
}
