import { useEffect, useState } from "react";
import { Button, Row, Col } from "react-bootstrap";
import Loader from "../../../components/loader";
import UiButton from "../../../components/button";
import TextInput from "../../../components/textinput/TextInput";
import Web3 from "web3";

import {
  useSupportedTokens,
  useSecurityTokenDetails,
  useNetworkOptions,
  useIntermediaryList,
  useCustodianData,
} from "../../../context/contractDataLoaderService";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { shortAddress } from "../../../components/common/CommonHeader";
import {
  SecuritiesFactory,
  contractAddress,
  Security,
} from "@verified-network/verified-sdk";
import { signMessage, NULL_ADDRESS } from "../../../utils/response";
import {
  SuccessToast,
  FailureToast,
  ToastOptions,
  countryList,
  getCountryName,
  getCountryCode,
  productCategory,
  networks,
  MarginIssueCategoryMarkers,
  countryDetails,
  supportedCountries,
} from "../../../utils/constants";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import { ethers, providers } from "ethers";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../../utils/helpers/networks";

function NewIssue(props) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [showModal, setShowModal] = useState(false);
  let { data: securityTokens } = useSecurityTokenDetails();
  const productType = ["Qualified", "Retail"];
  const { data: networkOptions } = useNetworkOptions();

  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(null);
  const [issuer, setIssuer] = useState("");
  let { data } = useSupportedTokens();
  const [country, setCountry] = useState("");
  const [countryName, setCountryName] = useState("");
  let { data: intermediaryList } = useIntermediaryList(country, "DP");
  const [selectedCurrencyToken, setSelectedCurrencyToken] = useState(null);
  const [restrictedCountries, setRestrictedCountries] = useState([]);
  const [showCustodian, setShowCustodian] = useState(false);
  const [custodian, setCustodian] = useState(null);
  const { data: custodianList } = useIntermediaryList(country, "Custodian");
  const abiCoder = new ethers.utils.AbiCoder();
  const { data: custodianData } = useCustodianData(custodian);
  const currencyList = custodianData?.map((data) => {
    return {
      tokenAddress: data.issueCurrencyAddress,
      tokenName: data.issueCurrency,
    };
  });
  let currencySupportedList;
  if (data) {
    currencySupportedList = [...data?.filter((token) => !token.isFiat)];
    if (currencyList) {
      currencySupportedList.push(...currencyList);
    }
  }

  // if (
  //   intermediaryList &&
  //   !intermediaryList.find((int) => int.address === NULL_ADDRESS)
  // ) {
  //   intermediaryList.push({ name: "NO Manager", address: NULL_ADDRESS });
  // }

  // console.log("list: ", intermediaryList);

  useEffect(() => {
    if (props.intermediary) {
      if (props.intermediary.address.toLowerCase() === account.toLowerCase())
        setShowCustodian(true);
      else setShowCustodian(false);
    }
  }, [props.intermediary]);
  let factoryContract, securityContract;
  if (provider && provider.getSigner && chainId && address) {
    factoryContract = new SecuritiesFactory(
      provider.getSigner(address),
      contractAddress[chainId].SecuritiesFactory
    );
  }

  const addIssue = async () => {
    console.log("mag: ", contractAddress[chainId].BalancerSecondaryIssueManager)
    if (props.companyName.length == 0) {
      toast.error(FailureToast("", "Enter Company name"), ToastOptions);
      return;
    } else if (props.companyName.length > 32) {
      toast.error(
        FailureToast("", "Keep company name length < 32"),
        ToastOptions
      );
      return;
    } else if (props.isinValue.length == 0) {
      toast.error(FailureToast("", "Enter company symbol"), ToastOptions);
      return;
    } else if (props.currency == "Select Currency") {
      toast.error(FailureToast("", "Select currency to pair"), ToastOptions);
      return;
    } else if (props.productType == "Select type") {
      toast.error(FailureToast("", "Select product type"), ToastOptions);
      return;
    } else if (props.productCategory == "Select category") {
      toast.error(FailureToast("", "Select product category"), ToastOptions);
      return;
    } else if (country.length == 0) {
      toast.error(FailureToast("", "Select issuer country"), ToastOptions);
      return;
    } else if (amount <= 0) {
      toast.error(FailureToast("", "Select proper amount"), ToastOptions);
      return;
    }

    const productCategory = ethers.utils.formatBytes32String(
      props.productCategory
    );
    const issuingCountry = ethers.utils.formatBytes32String(country);
    const restrictedCountryList = restrictedCountries.map((countryName) => {
      if (countryName.code !== "") {
        return ethers.utils.formatBytes32String(countryName.code);
      }
    });

    const restrictedCountryListEncoded = abiCoder.encode(
      ["bytes32[]"],
      [restrictedCountryList]
    );
    setLoading(true);
    try {
      const tx = await factoryContract.issueSecurity(
        NULL_ADDRESS,
        productCategory,
        props.companyName,
        props.isinValue,
        selectedCurrencyToken.tokenAddress,
        account,
        props.intermediary.address,
        restrictedCountryListEncoded,
        issuingCountry,
        props.productType == "Qualified" ? 1 : 0,
        networkOptions
      );
      const response = await factoryContract.getIssues();
      const securityAddress = response.response.result.at(-1);
      // console.log("securityAddress 1", securityAddress);
      console.log("securityAddress", securityAddress);
      if (showCustodian && custodian) {
        const custodianTX = await factoryContract.setCustodian(
          securityAddress,
          account,
          custodian,
          networkOptions
        );
        if (custodianTX.code === "ACTION_REJECTED") {
          toast.error("Transaction rejected by user!", ToastOptions);
        } else {
          const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${tx.response.hash}`;
          toast.success(SuccessToast(transactionLink), ToastOptions);
        }
      }
      if (tx.code === "ACTION_REJECTED") {
        toast.error("Transaction rejected by user!", ToastOptions);
      } else {
        const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${tx.response.hash}`;
        toast.success(SuccessToast(transactionLink), ToastOptions);
      }
      setLoading(false);
    } catch (err) {
      let error = { err };
      console.log(error);
      const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${error.err.transactionHash}`;
      setLoading(false);
      toast.error(FailureToast(transactionLink), ToastOptions);
    }
    setLoading(true);
    // const securityToken = securityTokens.find((element) => element.companyName === props.companyName);
    const response = await factoryContract.getIssues();
    const securityAddress = response.response.result.at(-1);
    // console.log("securityAddress 2", securityAddress);
    props.updateSecurity(securityAddress);
    try {
      const tx = await factoryContract.addBalance(
        securityAddress,
        NULL_ADDRESS, //transferor
        account, //transferee
        web3.utils.toWei(amount, "ether"), //amount
        networkOptions
      );
      if (tx.code === "ACTION_REJECTED") {
        toast.error("Transaction rejected by user!", ToastOptions);
      } else {
        const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${tx.response.hash}`;
        toast.success(SuccessToast(transactionLink), ToastOptions);
        props.updateModalView(2);
      }
      setLoading(false);
    } catch (err) {
      let error = { err };
      console.log(error);
      const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${error.err.transactionHash}`;
      setLoading(false);
      toast.error(FailureToast(transactionLink), ToastOptions);
    }
  };

  return (
    <>
      <ToastContainer />
      {loading ? <Loader /> : null}
      <div className="d-grid gap-2">
        <Row className="ml-1 align-items-center">
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Security Name</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <TextInput
                placeholder="Name"
                required
                value={props.companyName}
                onChange={(e) => props.updateCompanyName(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Security Symbol</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <TextInput
                placeholder="Security token symbol"
                value={props.isinValue}
                required
                onChange={(e) => props.updateIsinValue(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Target client</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <DropdownButton
                id="dropdown-basic-button"
                title={props.productType}
              >
                {productType?.map((type, id) => (
                  <Dropdown.Item
                    key={id}
                    onClick={(e) => props.updateProductType(type)}
                  >
                    {type}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Product Category</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <DropdownButton
                id="dropdown-basic-button"
                title={props.productCategory}
              >
                {productCategory?.map(
                  (type, id) =>
                    !MarginIssueCategoryMarkers.includes(type) && (
                      <Dropdown.Item
                        key={id}
                        onClick={(e) => props.updateProductCategory(type)}
                      >
                        {type}
                      </Dropdown.Item>
                    )
                )}
              </DropdownButton>
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Country</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <Select
                options={supportedCountries.map((country, id) => {
                  return {
                    value: country.name,
                    label: country.name,
                    code: country.code,
                  };
                })}
                isMulti={false}
                value={countryName}
                onChange={(selected) => {
                  setCountry(selected.code);
                  setCountryName(selected.name);
                }}
              />
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Issue manager</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <DropdownButton
                id="dropdown-basic-button"
                title={
                  props.intermediary
                    ? shortAddress(props.intermediary.address)
                    : "Select issue manager"
                }
              >
                {intermediaryList?.map((manager, id) => (
                  <Dropdown.Item
                    key={manager.address}
                    onClick={(e) => props.updateIntermediary(manager)}
                  >
                    <b>{manager.name}</b>&nbsp;({shortAddress(manager.address)})
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </Col>
          </Row>
          {showCustodian && (
            <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
              <Col xs={{ span: 3, offset: 2 }} className="text-left">
                <b>Custodian</b>
              </Col>
              <Col className="pl-0" xs={5}>
                <DropdownButton
                  id="dropdown-basic-button"
                  title={
                    custodianList
                      ? custodianList.length
                        ? custodian
                          ? shortAddress(custodian)
                          : "Select custodian"
                        : "Select custodian"
                      : null
                  }
                >
                  {custodianList ? (
                    custodianList.length ? (
                      custodianList?.map((manager, id) => (
                        <Dropdown.Item
                          key={manager.address}
                          onClick={(e) => setCustodian(manager.address)}
                        >
                          <b>{manager.name}</b>&nbsp;(
                          {shortAddress(manager.address)})
                        </Dropdown.Item>
                      ))
                    ) : (
                      <span className="p-2">
                        {country ? "No custodian present" : "Select a country"}
                      </span>
                    )
                  ) : null}
                </DropdownButton>
              </Col>
            </Row>
          )}
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Currency denomination</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <DropdownButton
                id="dropdown-basic-button"
                title={
                  currencySupportedList?.find(
                    (data) => data.tokenAddress == props.currency
                  )
                    ? currencySupportedList?.find(
                        (data) => data.tokenAddress == props.currency
                      )?.tokenName
                    : "Select Currency"
                }
              >
                {currencySupportedList?.map((token, id) => (
                  <Dropdown.Item
                    key={id}
                    onClick={(e) => {
                      props.updateCurrency(token?.tokenAddress);
                      setSelectedCurrencyToken(token);
                    }}
                  >
                    {token?.tokenName}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Restricted Countries</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <Select
                options={countryDetails.map((cont) => {
                  if (cont.code.toLowerCase() !== country.toLowerCase()) {
                    return {
                      value: cont.name,
                      label: cont.name,
                      code: cont.code,
                    };
                  } else {
                    return {
                      value: "",
                      label: "",
                      code: "",
                    };
                  }
                })}
                isMulti
                value={restrictedCountries}
                onChange={(selected) => setRestrictedCountries(selected)}
              />
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Amount</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <TextInput
                placeholder="Decimals supported"
                fieldType="number"
                required
                onChange={(e) => setAmount(e.target.value)}
              />
            </Col>
          </Row>
          {/*<Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                <Col xs={{span:3, offset:2}} className="text-left">
                <b>Intermediary</b>
                </Col>
                <Col className="pl-0" xs={5}>
                        <TextInput
                        placeholder="Intermediary address"
                        required
                        onChange={(e) => setIssuer(e.target.value)}
                        />
                </Col>
              </Row>*/}
          <Row className="mx-0 my-2 pl-0 width-100 align-items-center">
            <Col className="pl-0">
              <UiButton
                onClick={() => {
                  addIssue();
                }}
                buttonVariant="primary"
                buttonClass="SignUpButton flex-1 ml-0"
                buttonText="Create Issue"
                type="submit"
              />
            </Col>
          </Row>
        </Row>
      </div>
    </>
  );
}

export default function AddIssue(props) {
  return <NewIssue {...props} />;
}
