import React, { useState } from "react";
import Loader from "../../components/loader";
import UiTable from "../../components/table/Table";

import { useDistributionsData } from "../../context/contractDataLoaderService";
import WalletConnect from "../../components/common/walletConnect/index";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../utils/helpers/networks";
import { providers } from "ethers";

function PendingOrders(props) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const { data, loading } = useDistributionsData();
  const headers = [
    // { label: "Security", val: "security" },
    // { label: "Record date", val: "recordDate" },
    // { label: "Balance", val: "balance" },
    // { label: "Earnings", val: "earnings" },
    // { label: "Next record date", val: "nextRecordDate" },
    { label: "Platform Name", val: "platformName" },
    { label: "Earnings", val: "earnings" },
  ];

  // console.log("data: ", data);
  return (
    <>
      {loading ? <Loader /> : null}
      <section className="d-flex flex-column align-items-start px-0 py-4">
        <div className="w-100">
          <h3 className="mb-3 d-flex">Distributions</h3>
          <div className="pools-table width-100">
            <UiTable
              thead={headers}
              tbodyData={data ?? []}
              hover
              provider={provider}
              transport={transport}
              account={account}
              chainId={chainId}
              bordered={false}
            />
          </div>
        </div>
      </section>
      <WalletConnect loggedIn={props.loggedIn} />
    </>
  );
}

export default function Distributions(props) {
  return <PendingOrders {...props} />;
}
