import React, { useState, useEffect, useRef } from "react";
import Loader from "../../components/loader";

import {
  useGetAllCurrencies,
  useGetAllSecurities,
  useSecuritiesDetails,
} from "../../context/contractDataLoaderService";
import UiTable from "../../components/table/Table";
import PortfolioChart from "./chart";
import WalletConnect from "../../components/common/walletConnect/index";
import { Card, Col, Container, Row, Button, Dropdown, DropdownButton } from "react-bootstrap";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../utils/helpers/networks";
import { usePDF } from 'react-to-pdf';
import { useReactToPrint } from 'react-to-print';

function PortfolioPage(props) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const currencyData = useGetAllCurrencies();
  const securityData = useGetAllSecurities();



  // let {
  //   data: securitiesDetails,
  //   loading,
  //   currencyList,
  //   acquisitionData,
  //   portfolioData,
  // } = useSecuritiesDetails();
  const { toPDF, targetRef } = usePDF({filename: `verified account statement ${new Date().toLocaleDateString()}-${new Date().toLocaleTimeString()}.pdf`});
  const [cashBalance, setCashBalance] = useState(null);
  const [portfolioDataList, setPortfolioDataList] = useState(null);
  const [acquisitionDataList, setAcquisitionDataList] = useState(null);
  const [securities, setSecurities] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [currencyTokenList, setCurrencyTokenList] = useState(0);
  const componentToPrintRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentToPrintRef.current,
  });

  let formattedData =
    securityData.allSecurities.length > 0
      ? securityData.allSecurities.filter(
          (sec) => Number(sec.securitySwapped) > 0.0 && Number(sec.date) > 0
        )
      : [];



  useEffect(() => {
    if (securityData.portfolioData && !clicked) {
      setPortfolioDataList(
        securityData.portfolioData[securityData.portfolioData.length - 1]
      );
      setAcquisitionDataList(
        securityData.acquisitionData[securityData.acquisitionData.length - 1]
      );
    }
  }, [securityData, chainId, transport]);

  const headers = [
    { label: "Security ID", val: "securityId" },
    { label: "Issuer Name", val: "issuerName" },
    { label: "Security Balance", val: "securitySwapped" },
    { label: "Holding since", val: "date" },
    { label: "Current Price", val: "cashSwapped" },
  ];


  const onSecuritySelect = (row) => {
    setCashBalance(row.securitySwapped);
    setPortfolioDataList(row.portfolioData);
    setAcquisitionDataList(row.acquisitionData);
  };

  const options = {
    onClick: (event, row) => {
      setClicked(true);
      onSecuritySelect(row);
    },
  };

  return (
    <>
      {currencyData.loading || securityData.loading ? <Loader /> : null}
      <section className="d-flex flex-column align-items-start px-0 py-4">
        <div className="mb-3 d-flex justify-content-between w-100 align-items-center">
          <div className="" style={{ width: "20%" }}>
            <h3 className="">My Portfolio</h3>
          </div>
        </div>
        <div className="mb-3 d-flex justify-content-between w-100 align-items-center">
          <div className="mr-3 w-100 text-right">
              <DropdownButton id="dropdown-basic-button" title="Actions">
                  <Dropdown.Item
                      onClick={() => {
                        toPDF()
                      }}
                  >
                      Download
                  </Dropdown.Item>
                  <Dropdown.Item
                      onClick={() => {
                        handlePrint()
                      }}
                  >
                      Print
                  </Dropdown.Item>
              </DropdownButton>
          </div>
          </div>
        <div className="w-100" ref={componentToPrintRef}>
          <div className="w-100" ref={targetRef}>
          <div className="mb-3 d-flex justify-content-between w-100 align-items-center">
          <Container className="d-flex flex-column align-items-start px-0 py-4">
            <Row className="d-flex md-flex width-100 mb-4">
              <Col style={{ flex: "3" }} className="pl-2">
                <Card
                  style={{ height: "auto" }}
                  className="mr-3 shadow d-flex x border-sm mb-3"
                >
                  <div className="chartHeading text-center">Asset Balances</div>
                  {currencyData.allCurrencies.length > 0 && (
                    <>
                      {currencyData.allCurrencies?.map((token, index) => {
                        if (token?.balance > 0.0) {
                          return (
                            <Card
                              style={{ height: "auto" }}
                              className="p-2 m-3 shadow d-flex justify-content-stretch border-sm"
                              key={index}
                            >
                              <div
                                className="d-flex justify-content-between align-items-center"
                                key={index}
                              >
                                <div className="w-50">{token?.name}:</div>
                                <div className="w-50">{token?.balance}</div>
                              </div>
                            </Card>
                          );
                        }
                      })}
                    </>
                  )}
                </Card>
              </Col>
              <Col style={{ flex: "6" }} className="px-0">
                <Card
                  style={{ height: "auto" }}
                  className="mr-3 shadow d-flex justify-content-stretch align-items-center border-sm mb-3"
                >
                  <div className="chartHeading">Portfolio Growth</div>
                  <PortfolioChart
                    portfolioData={portfolioDataList ?? []}
                    acquisitionData={acquisitionDataList ?? []}
                    clicked={clicked}
                    {...props}
                  />
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="pools-table width-100">
          <UiTable
            thead={headers}
            tbodyData={formattedData}
            hover
            rowEvents={options}
            provider={provider}
            transport={transport}
            account={account}
            chainId={chainId}
            bordered={false}
          />
        </div>
          </div>
        </div>
      </section>
      <WalletConnect loggedIn={props.loggedIn} />
    </>
  );
}

export default function Portfolio(props) {
  return <PortfolioPage {...props} />;
}
