import Loader from "../../components/loader";
import { useState, useEffect } from "react";
import UiTable from "../../components/table/Table";

import paymentGateway from "./api/PaymentGateway";
import { ToastOptions } from "../../utils/constants";
import Tabledropdown from "../../components/tableDropdown/TableDropdown";
import { useCashIssueWithdrawList } from "../../context/contractDataLoaderService";
import { toast } from "react-toastify";
import { useAccount, useClient, useConnectorClient } from "wagmi";

function TradesData(props) {
  const { address, chainId } = useAccount();
  const account = address;
  const [issueRequest, setIssueRequest] = useState([]);
  const [loading, setLoading] = useState(false);
  const data = useCashIssueWithdrawList();

  const approvalRequestHeader = [
    { label: "User ID", val: "userID" },
    { label: "User name", val: "userName" },
    { label: "Payment ref", val: "requestID" },
    { label: "Date", val: "date" },
    { label: "Currency", val: "currency" },
    { label: "Quantity", val: "quantity" },
    { label: "Status", val: "status" },
    { label: "Action", val: "action" },
  ];
  const handlePaymentApprovalOption = (selected) => {
    const CONFIRM_OPTION = "0";
    const { index } = selected;
    if (index === CONFIRM_OPTION) {
      // this.setState({
      //   paymentRequest: selected,
      //   paymentModalVisibility: true,
      // });
    }
  };
  console.log("issueRequest", issueRequest);
  // useEffect(() => {
  //   paymentGateway.listWithdrawRequests(account, 'CH', chainId)
  //       .then((payments) => {
  //         console.log("payments",payments);
  //         const paymentRows = payments.map((element, id) => ({
  //           id,
  //           userID: element.userAddress,
  //           requestID: element.paymentRef,
  //           date: (new Date(element.date)).toLocaleDateString(),
  //           currency: element.currency,
  //           quantity: element.amount,
  //           requestType: 'Fiat',
  //           status: element.status,
  //           requestToken: element.requestProduct,
  //           action: element.status === 'requested' ? (
  //             <Tabledropdown dataObject={element}
  //               tableDropdownClass="tableDropDodownStyle"
  //               tableDropdownList={['Confirm']}
  //               onSelect={handlePaymentApprovalOption}
  //             />) : '',
  //         }));
  //         console.log("paymentRows",paymentRows);
  //         setIssueRequest(paymentRows);
  //       })
  //       .catch((error) => {
  //         toast.error('Error loading approval requests ' ,ToastOptions);
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  // }, [account]);

  return (
    <>
      {loading ? <Loader /> : null}
      <div>
        <section className="d-flex flex-column align-items-start px-0 py-4">
          <div className="w-100">
            <h3 className="mb-3 d-flex">Withdrawal Requests</h3>
          </div>
        </section>
        <section id="requestsTable">
          <UiTable thead={approvalRequestHeader} tbodyData={[]} />
        </section>
      </div>
    </>
  );
}

export default function ApprovalRequests(props) {
  return <TradesData {...props} />;
}
