import { useState, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import Web3 from "web3";
import Loader from "../loader";
import UiButton from "../../components/button";

import DateTimePicker from "react-datetime-picker";
import "react-toastify/dist/ReactToastify.css";
import ERC20ABI from "../../utils/abi/ERC20.json";
import { toast } from "react-toastify";
import { useGlobal } from "../../context/globalContext";
import {
  FailureToast,
  SuccessToast,
  ToastOptions,
} from "../../utils/constants";
import { useNetworkOptions } from "../../context/contractDataLoaderService";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../utils/helpers/networks";
import { providers } from "ethers";

function RefundsModal(props) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const { isUpdate, setIsUpdate } = useGlobal();
  const [value, onChange] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [changeContent, setChangeContent] = useState(false);
  const modalContent = useRef(null);
  const { data: networkOptions } = useNetworkOptions();

  const scheduleSnapshot = async () => {
    const currentDate = Math.floor(new Date().getTime() / 1000).toString();
    value.setSeconds(0);
    let dateTime = Math.floor(value.getTime() / 1000).toString();
    if (dateTime < currentDate) {
      toast.error(
        FailureToast("", "Select correct time(future date)"),
        ToastOptions
      );
      return;
    }
    const securityTokenContract = new web3.eth.Contract(
      ERC20ABI,
      props.data.security
    );

    setLoading(true);
    try {
      await securityTokenContract.methods
        .scheduleSnapshot(dateTime)
        // .send({from: account});
        .send({ ...(chainId === 137 ? networkOptions : {}), from: account });

      setLoading(false);
      toast.success(SuccessToast(), ToastOptions);
      setChangeContent(true);
      modalContent.current.innerHTML = "";
      setIsUpdate(!isUpdate);
    } catch (err) {
      let error = { err };
      setLoading(false);
      toast.error(FailureToast(), ToastOptions);
    }
  };
  return (
    <>
      {loading ? <Loader /> : null}
      {changeContent && (
        <UiButton
          onClick={() => {
            props.onModalHide();
          }}
          buttonVariant="primary"
          buttonClass="SignUpButton flex-1 ml-0"
          buttonText="Click to close this form&nbsp;&rarr;"
          type="submit"
        />
      )}
      <div className="d-grid gap-2" ref={modalContent}>
        <Row className="ml-1 align-items-center">
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Time</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <DateTimePicker onChange={onChange} value={value} />
            </Col>
          </Row>
          <Row className="mx-0 my-2 pl-0 width-100 align-items-center">
            <Col className="pl-0">
              <UiButton
                onClick={() => {
                  scheduleSnapshot();
                }}
                buttonVariant="primary"
                buttonClass="SignUpButton flex-1 ml-0"
                buttonText="Schedule"
                type="submit"
              />
            </Col>
          </Row>
        </Row>
      </div>
    </>
  );
}

export function ScheduleSnapshot(props) {
  return <RefundsModal {...props} />;
}
