import { useEffect, useState } from "react";
import {
  KYBER_POOL_CHART,
  KYBER_POOL_DATA,
  KYBER_POOLS_DATA,
} from "../utils/queries/kyberQueries";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { kyberSubgraphClient } from "../adapters";
import { formattedNum } from "../utils";

dayjs.extend(utc);

const getPoolChartData = async (client, poolAddress) => {
  let data = [];
  const utcEndTime = dayjs.utc();
  let utcStartTime = utcEndTime.subtract(1, "year").startOf("minute");
  let startTime = utcStartTime.unix() - 1;

  try {
    let allFound = false;
    let skip = 0;
    while (!allFound) {
      let result = await client.query({
        query: KYBER_POOL_CHART,
        variables: {
          poolAddress: poolAddress?.toLowerCase() || "",
          skip,
        },
        fetchPolicy: "cache-first",
      });
      skip += 1000;
      data = data.concat(result.data.poolDayDatas);
      if (result.data.poolDayDatas.length < 1000) {
        allFound = true;
      }
    }

    let dayIndexSet = new Set();
    let dayIndexArray = [];
    const oneDay = 24 * 60 * 60;
    data.forEach((dayData, i) => {
      // add the day index to the set of days
      dayIndexSet.add((data[i].date / oneDay).toFixed(0));
      dayIndexArray.push(data[i]);
      dayData.dailyVolumeUSD = parseFloat(dayData.dailyVolumeUSD);
      dayData.reserveUSD = parseFloat(dayData.reserveUSD);
    });

    if (data[0]) {
      // fill in empty days
      let timestamp = data[0].date ? data[0].date : startTime;
      let latestLiquidityUSD = data[0].reserveUSD;
      let index = 1;
      while (timestamp < utcEndTime.unix() - oneDay) {
        const nextDay = timestamp + oneDay;
        let currentDayIndex = (nextDay / oneDay).toFixed(0);
        if (!dayIndexSet.has(currentDayIndex)) {
          data.push({
            date: nextDay,
            dayString: nextDay,
            dailyVolumeUSD: 0,
            reserveUSD: latestLiquidityUSD,
          });
        } else {
          latestLiquidityUSD = dayIndexArray[index].reserveUSD;
          index = index + 1;
        }
        timestamp = nextDay;
      }
    }

    data = data.sort((a, b) => (parseInt(a.date) > parseInt(b.date) ? 1 : -1));
  } catch (e) {
    console.log(e);
  }

  return data;
};

export function usePoolChartData(poolAddress) {
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function checkForChartData() {
      let data = await getPoolChartData(kyberSubgraphClient, poolAddress);

      setState(data);
      setLoading(false);
    }
    checkForChartData();
  }, [poolAddress]);

  return { data: state, loading };
}

const getPoolData = async (client, poolAddress) => {
  let data = [];

  try {
    let result = await client.query({
      query: KYBER_POOL_DATA(poolAddress),
      fetchPolicy: "cache-first",
    });
    data = result;
    console.log("result", result);
  } catch (e) {
    console.log("PoolData getPoolData catch", e);
    console.log(e);
  }

  return data;
};

const getPoolsData = async (client) => {
  let data = [];

  try {
    let result = await client.query({
      query: KYBER_POOLS_DATA,
      fetchPolicy: "cache-first",
    });
    data = result;
    console.log("result", result);
  } catch (e) {
    console.log("PoolData getPoolData catch", e);
    console.log(e);
  }

  return data;
};

export function usePoolData(poolAddress) {
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchPoolData() {
      let result = await getPoolData(kyberSubgraphClient, poolAddress);
      const data = result.data || {};
      const pools = data.pools || [];
      const pool = { ...pools[0] } || {};
      const token0 = { ...pool.token0 } || {};
      const token1 = { ...pool.token1 } || {};
      token0.value = pool.reserve0;
      token1.value = pool.reserve1;
      pool.tokens = [token0, token1];

      const liquidity = pool.reserveUSD
        ? formattedNum(pool.reserveUSD, true)
        : "-";
      pool.poolValue = liquidity;
      pool.volume = "-";

      setState(pool);
      setLoading(false);
    }
    fetchPoolData();
  }, [poolAddress]);

  return { data: state, loading };
}

export function usePoolsData() {
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchPoolsData() {
      let result = await getPoolsData(kyberSubgraphClient);
      const data = result.data || {};
      const pools = data.pools || [];

      const newData = [];

      pools.map((item) => {
        const newItem = { ...item };
        let composition = `${item.token0.symbol}, ${item.token1.symbol}`;
        newItem.composition = composition;
        newItem.isKyber = true;
        const token0 = { ...item.token0 } || {};
        const token1 = { ...item.token1 } || {};
        token0.value = item.reserve0;
        token1.value = item.reserve1;
        newItem.tokens = [token0, token1];
        const liquidity = item.reserveUSD
          ? formattedNum(item.reserveUSD, true)
          : "-";
        newItem.poolValue = liquidity;
        newItem.volume = "-";
        newData.push(newItem);
      });

      setState(newData);
      setLoading(false);
    }
    fetchPoolsData();
  }, []);

  return { data: state, loading };
}
