import React, { useState, useEffect } from "react";
import Loader from "../../../components/loader";
import { useBalancerPoolsData } from "../../../context/balancerPoolData";
import { PoolType } from "../../poolDetail/balancer/types";
import Web3 from "web3";
import { useHistory } from "react-router-dom";

import { useGlobal } from "../../../context/globalContext";
import UiTable from "../../../components/table/Table";
import { useNetworkOptions } from "../../../context/contractDataLoaderService";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../../utils/helpers/networks";
import { providers } from "ethers";

function ServicerHome(props) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const { userRole, isUpdate, setIsUpdate } = useGlobal();
  const [tableData, setTableData] = useState();
  const { data, loading } = useBalancerPoolsData(
    PoolType.SecondaryIssue,
    isUpdate
  );
  const { data: networkOptions } = useNetworkOptions();
  const history = useHistory();

  const fetchData = () => {
    let dataFetch;
    if (userRole == "DP" || userRole == "intermediary")
      dataFetch = data.filter(
        (pool) =>
          pool.verifiedWalletData?.issuer?.id.toLowerCase() ===
            account.toLowerCase() &&
          !pool.verifiedWalletData?.subscriptionsClosed.length
      );
    else if (userRole == "AM")
      dataFetch = data.filter(
        (pool) => !pool.verifiedWalletData?.subscriptionsClosed.length
      );
    setTableData(dataFetch);
  };

  useEffect(() => {
    fetchData();
  }, [data]);

  let headers = [
    { label: "Security", val: "security" },
    { label: "Currency", val: "currency" },
    { label: "Start Date", val: "createTime" },
    { label: "Call Action", val: "secondaryCallAction" },
  ];

  const options = {
    onClick: (event, row) => {
      onInvest(row);
    },
  };

  const onInvest = (row) => {
    if (userRole !== "AM")
      props.history.push(
        `/investors/secondary_investors/${row.poolType}/${row.security}`
      );
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <section className="d-flex flex-column align-items-start px-0 py-4">
        <div className="pools-table width-100">
          <UiTable
            thead={headers}
            tbodyData={tableData ?? []}
            hover
            bordered={false}
            userRole={userRole}
            rowEvents={options}
            web3={web3}
            isUpdate
            history={history}
            networkOptions={networkOptions}
            updateTableData={setIsUpdate}
            account={account}
          />
        </div>
      </section>
    </>
  );
}

export default function Secondary(props) {
  return <ServicerHome {...props} />;
}
