import React, { useRef, useState } from "react";
import Loader from "../../components/loader";
import { useBalancerPoolsData } from "../../context/balancerPoolData";
import WalletConnect from "../../components/common/walletConnect/index";
import { PoolType } from "../poolDetail/balancer/types";
import { Button, Col, Dropdown, DropdownButton, Row } from "react-bootstrap";
import MarginIssueTable from "../../components/table/MarginIssueTable";
import UiTable from "../../components/table/Table";
import VerticallyModal from "../../components/modal/VerticallyModal";
import UiButton from "../../components/button";
import {
  MarginIssueManager,
  contractAddress,
} from "@verified-network/verified-sdk";


import {
  filterWithDate,
} from "../../utils/constants";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../utils/helpers/networks";
import { useFetchMarginPoolsOrders } from "../../context/contractDataLoaderService";
import DateTimePicker from "react-datetime-picker";
import { usePDF } from 'react-to-pdf';
import { useReactToPrint } from 'react-to-print';

function Transaction(props) {
    let {marginOrders: list, loading, PoolLoading} = useFetchMarginPoolsOrders();
   list = list.sort((a, b) => Number(a.date) - Number(b.date))
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, marginManagerContract, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    const marginManagerAddress =
      contractAddress[chainId].BalancerMarginIssueManager;
    if (marginManagerAddress) {
      marginManagerContract = new MarginIssueManager(
        provider.getSigner(address),
        marginManagerAddress
      );
    }
    web3 = getWeb3(transport);
  }


  const { toPDF, targetRef } = usePDF({filename: `margin transactions ${new Date().toLocaleDateString()}-${new Date().toLocaleTimeString()}.pdf`});
  const [startDate, onChangeStartDate] = useState(new Date());
  const [endDate, onChangeEndDate] = useState(new Date());
  const [showModal, setShowModal] = useState(false);
  const [modalView, setModalView] = useState(0);
  const [tableData, setTableData] = useState(null)
  const modalContent = useRef(null);
  const componentToPrintRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentToPrintRef.current,
  });

  const headers = [
    { label: "Date", val: "date" },
    { label: "Investor", val: "investorName" },
    { label: "Currency", val: "currencySymbol" },
    { label: "Type", val: "transactionType" },
    { label: "Security", val: "securitySymbol" },
    { label: "Amount", val: "transactionAmount" },
  ];

  const handleModalHide = () => {
    setShowModal(false);
    setShowModal(0)
  }

 



  const commonProps = {
    modalView,
    updateModalView: setModalView,
    showModal,
    updateShowModal: setShowModal,
    tableData,
    updateTableData: setTableData,
    targetRef: targetRef,
    toPDF: toPDF,
    componentToPrintRef: componentToPrintRef,
    handlePrint: handlePrint
  };




  let message, heading;
  if (modalView === 10) {
    heading = "Custom Filter";
    message = (
      <>
        <div className="d-grid gap-2" ref={modalContent}>
          <Row className="ml-1 align-items-center">
            <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
              <Col xs={{ span: 4, offset: 2 }} className="text-left">
                <b>From</b>
              </Col>
              <Col className="pl-0" xs={3.5}>
              <DateTimePicker onChange={onChangeStartDate} value={startDate} />
              </Col>
            </Row>
            <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
              <Col xs={{ span: 4, offset: 2 }} className="text-left">
                <b>To</b>
              </Col>
              <Col className="pl-0" xs={3.5}>
              <DateTimePicker onChange={onChangeEndDate} value={endDate} />
              </Col>
            </Row>
            <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
              <Col className="pl-0">
                <UiButton
                   onClick={() => {
                    if(list.length > 0) {
                       //  console.log("new: ", filterWithDate(list, "date"))
                       setTableData(filterWithDate(list, "date", startDate, endDate));
                    }
                      handleModalHide();
                    }}
                  buttonVariant="primary"
                  buttonClass="SignUpButton flex-1 ml-0"
                  buttonText="Filter"
                  type="submit"
                />
              </Col>
            </Row>
          </Row>
        </div>
      </>
    );
  }

  
  return (
    <>
      {loading || PoolLoading ? <Loader /> : null}
      <section className="d-flex flex-column align-items-start px-0 py-4">
        <div className="mb-3 d-flex justify-content-between w-100 align-items-center">
          <div className="w-100">
            <h3 className="text-left">Transactions</h3>
          </div>
        </div>
        <div className="pools-table width-100">
        <UiTable
            thead={headers}
            tbodyData={tableData? tableData : list ? list : []}
            hover
            bordered={false}
            chainId={chainId}
            contract={marginManagerContract}
            commonProps={commonProps}
            isUpdate
            showFilter={true}
            />
        </div>
      </section>
      <VerticallyModal
        key="connectProvider"
        showModal={showModal}
        modalOnHide={handleModalHide}
        modalSize={"lg"}
        modalHeading={
          <h2>
            <b>{heading}</b>
          </h2>
        }
        withFooter={false}
      >
        {message}
      </VerticallyModal>
      <WalletConnect loggedIn={props.loggedIn} />
    </>
  );
}

export default function MarginTransaction(props) {
  return <Transaction {...props} />;
}
