import { useEffect, useState } from "react";
import Loader from "../../../components/loader";
import { useBalancerPoolsData } from "../../../context/balancerPoolData";

import Primary from "./primary";
import { useAccount, useClient, useConnectorClient } from "wagmi";

function ServicerFacing(props) {
  const { address, chainId } = useAccount();
  const account = address;
  const [key, setKey] = useState("home");
  return (
    <>
      <section className="d-flex flex-column align-items-start px-0 py-4">
        <div className="w-100">
          <h3 className="mb-3 d-flex">Primary Issues</h3>
          <Primary {...props} />
        </div>
      </section>
    </>
  );
}

export default function ManagerPrimary(props) {
  return <ServicerFacing {...props} />;
}
