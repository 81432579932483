import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Loader from "../../../components/loader";
import UiButton from "../../../components/button";
import TextInput from "../../../components/textinput/TextInput";
import Web3 from "web3";
import {
  useSecurityTokenDetails,
  useNetworkOptions,
} from "../../../context/contractDataLoaderService";

import {
  SecuritiesFactory,
  contractAddress,
} from "@verified-network/verified-sdk";
import { signMessage } from "../../../utils/response";
import {
  SuccessToast,
  FailureToast,
  ToastOptions,
  networks,
  NULL_ADDRESS,
  MarginIssueCategoryMarkers,
} from "../../../utils/constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Route, useHistory } from "react-router-dom";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../../utils/helpers/networks";
import { providers } from "ethers";

function Balance(props) {
  const history = useHistory();
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonText, setButtonText] = useState("Add Balance");
  const [changeContent, setChangeContent] = useState(false);
  const { data: networkOptions } = useNetworkOptions();
  let factoryContract;
  if (provider && provider.getSigner && chainId && address) {
    factoryContract = new SecuritiesFactory(
      provider.getSigner(address),
      contractAddress[chainId].SecuritiesFactory
    );
  }
  const addBalance = async () => {
    if (!web3.utils.isAddress(props.investorAddress)) {
      alert("Enter investor address");
    } else if (props.amount.length == 0) {
      alert("Enter amount");
    }

    const securityTokenAddress = props.security;
    setLoading(true);
    // console.log("amt: ", web3.utils.toWei(props.amount, "ether"));
    // console.log("acct: ", account, "sec:  ", securityTokenAddress);
    try {
      const tx = await factoryContract.addBalance(
        securityTokenAddress,
        MarginIssueCategoryMarkers.includes(props.productCategory)
          ? NULL_ADDRESS
          : account, //transferror
        props.investorAddress, //transferee
        web3.utils.toWei(props.amount, "ether"),
        networkOptions
        // {...networkOptions, gasLimit: '200000'}
      );
      if (tx.code === "ACTION_REJECTED") {
        toast.error("Transaction rejected by user!", ToastOptions);
      } else {
        const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${tx.response.hash}`;
        toast.success(SuccessToast(transactionLink), ToastOptions);
        props.updateInvestorAddress("");
        props.updateAmount("");
        setButtonText("Add Balance for another investor");
        setChangeContent(true);
      }
      setLoading(false);
    } catch (err) {
      let error = { err };
      console.log(error);
      const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${error.err.transactionHash}`;
      setLoading(false);
      toast.error(FailureToast(transactionLink), ToastOptions);
    }
  };
  return (
    <>
      <ToastContainer />
      {loading ? <Loader /> : null}
      <div className="d-grid gap-2">
        <Row className="ml-1 align-items-center">
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Investor</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <TextInput
                placeholder="Investor wallet address"
                required
                value={props.investorAddress}
                onChange={(e) => props.updateInvestorAddress(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Amount</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <TextInput
                placeholder="Amount to credit"
                fieldType="number"
                required
                value={props.amount}
                onChange={(e) => props.updateAmount(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mx-0 my-2 pl-0 width-100 align-items-center">
            <Col className="pl-0">
              <UiButton
                onClick={() => {
                  addBalance();
                }}
                buttonVariant="primary"
                buttonClass="SignUpButton flex-1 ml-0"
                buttonText={buttonText}
                type="submit"
              />
            </Col>
          </Row>
        </Row>
      </div>
      {changeContent &&
        !MarginIssueCategoryMarkers.includes(props.productCategory) && (
          <UiButton
            onClick={() => {
              props.updateModalView(3);
            }}
            buttonVariant="primary"
            buttonClass="SignUpButton flex-1 ml-0"
            buttonText="Next&nbsp;&rarr;"
            type="submit"
          />
        )}
      {changeContent &&
        MarginIssueCategoryMarkers.includes(props.productCategory) && (
          <UiButton
            onClick={() => {
              history.push("/marginproducts");
            }}
            buttonVariant="primary"
            buttonClass="SignUpButton flex-1 ml-0"
            buttonText="View Margin Products"
            type="submit"
          />
        )}
    </>
  );
}

export default function AddSecondaryBalance(props) {
  return <Balance {...props} />;
}
