import { WebSocketProvider } from "@ethersproject/providers";
import template from "../../utils/template";
import { configService } from "../config/config.service";
import { StaticJsonRpcBatchProvider } from "./static-json-rpc-batch-provider";

export default class RpcProviderService {
  constructor(config = configService, network, jsonProvider, loggingProvider) {
    this.config = config;
    this.network = network || this.config.network.shortName;
    this.jsonProvider =
      jsonProvider || new StaticJsonRpcBatchProvider(this.config.rpc);
    this.loggingProvider =
      loggingProvider || new StaticJsonRpcBatchProvider(this.config.loggingRpc);
  }

  initBlockListener(newBlockHandler) {
    const wsProvider = new WebSocketProvider(this.config.ws);
    wsProvider.on("block", (newBlockNumber) => newBlockHandler(newBlockNumber));
  }

  async getBlockNumber() {
    return await this.jsonProvider.getBlockNumber();
  }

  getJsonProvider(networkKey) {
    const rpcUrl = template(this.config.getNetworkConfig(networkKey).rpc, {
      INFURA_KEY: this.config.env.INFURA_PROJECT_ID,
      ALCHEMY_KEY: this.config.env.ALCHEMY_KEY,
    });
    return new StaticJsonRpcBatchProvider(rpcUrl);
  }
}

export const rpcProviderService = new RpcProviderService();
