import React, { Component } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Loader from "../loader";
import VerticallyModal from "../../components/modal/VerticallyModal";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import "react-toastify/dist/ReactToastify.css";

class MarginProductTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      heading: "",
      modalContent: "",
      loading: false,
      toggle: false,
      recordDate: null,
      balance: null,
      earnings: null,
    };
    this.handleRecordDateChange = this.handleRecordDateChange.bind(this);
  }
  noDataIndication = () => {
    return <p>No data</p>;
  };

  handleRecordDateChange = async (row, snapshot) => {
    const balance = await row.securityTokenContract.methods
      .snapshotBalanceOf(snapshot, row.transferor)
      .call();
    const earnings = await row.securityTokenContract.methods
      .getDistribution(snapshot)
      .call();
    this.setState({
      recordDate: snapshot,
      balance: this.props.web3.utils.fromWei(balance, "ether"),
      earnings: earnings[1],
    });
  };

  render() {
    const { tbodyData, thead, data, columns, chainId } = this.props;
    const tableColumns = columns ?? thead;
    const tableData = data ?? tbodyData;

    const handleModalHide = () => {
      this.setState({ showModal: !this.state.showModal });
    };

    const defaultSortedBy = [
      {
        dataField: "date",
        order: "desc",
      },
    ];

    return (
      <>
        {this.state.loading ? <Loader /> : null}
        <BootstrapTable
          keyField="id"
          data={tableData}
          key={this.state.balance}
          columns={tableColumns.map((element, index) => {
            element.dataField = element.dataField ?? element.val;
            element.text = element.text ?? element.label;
            return element;
          })}
          bootstrap4
          pagination={paginationFactory()}
          noDataIndication={this.noDataIndication}
          wrapperClasses="table-responsive"
          rowClasses="cursor-pointer"
          defaultSorted={defaultSortedBy}
          filter={filterFactory()}
          {...this.props}
        />
        <VerticallyModal
          showModal={this.state.showModal}
          modalOnHide={handleModalHide}
          modalSize={"lg"}
          modalHeading={
            <h2>
              <b>{this.state.heading}</b>
            </h2>
          }
          withFooter={false}
        >
          {this.state.modalContent}
        </VerticallyModal>
      </>
    );
  }
}

export default MarginProductTable;
