import { Row, Col } from "react-bootstrap";
import { format } from "date-fns";
import "react-toastify/dist/ReactToastify.css";

function RefundsModal(props) {
  const {snapShots} = props;

  return (
    <>
        <div className="d-grid gap-2">
            <Row className="ml-1 align-items-center">
            {snapShots.length ? (
              snapShots.map(dateTime => (
                  <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                      <Col xs={{span:3, offset:2}} className="text-left">
                          <b>SnapShot Time:</b>
                      </Col>
                      <Col className="pl-0" xs={5}>
                          {format(new Date(parseInt(dateTime)*1000), "yyyy-MM-dd hh:mm a")}           
                      </Col>
                  </Row>
              ))
              ) 
              : 
              <p>No Data</p>}
          
            </Row>
        </div>
    </>
  );
}

export function SnapshotList(props) {
  return <RefundsModal {...props} />;
}