import { useEffect, useState } from "react";
import { Button, Row, Col } from "react-bootstrap";
import Loader from "../../components/loader";
import UiButton from "../../components/button";
import TextInput from "../../components/textinput/TextInput";
import { ethers, BigNumber, providers } from "ethers";
import Web3 from "web3";
import {
  SecuritiesFactory,
  contractAddress,
} from "@verified-network/verified-sdk";

import { useNetworkOptions } from "../../context/contractDataLoaderService";
import VerticallyModal from "../../components/modal/VerticallyModal";
import { signMessage } from "../../utils/response";
import {
  SuccessToast,
  FailureToast,
  ToastOptions,
  networks,
  MarginIssueCategoryMarkers,
} from "../../utils/constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Route, useHistory } from "react-router-dom";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../utils/helpers/networks";

const NULL_ADDRESS = "0x0000000000000000000000000000000000000000";

function Balance(props) {
  const history = useHistory();
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const { data: networkOptions } = useNetworkOptions();

  const handleModalHide = () => {
    setShowModal(false);
  };

  let factoryContract;
  if (provider && provider.getSigner && chainId && address) {
    factoryContract = new SecuritiesFactory(
      provider.getSigner(address),
      contractAddress[chainId].SecuritiesFactory
    );
  }
  const addBalance = async () => {
    if (props.balance.length == 0) {
      alert("Enter amount");
    }
    // console.log("props", props);
    const securityAddress = props.security;
    setLoading(true);
    try {
      const tx = await factoryContract.addBalance(
        securityAddress,
        NULL_ADDRESS, //transferor
        account, //transferee
        web3.utils.toWei(props.balance, "ether"), //amount,
        networkOptions
      );
      if (tx.code === "ACTION_REJECTED") {
        toast.error("Transaction rejected by user!", ToastOptions);
      } else {
        const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${tx.response.hash}`;
        toast.success(SuccessToast(transactionLink), ToastOptions);
        if (MarginIssueCategoryMarkers.includes(props.productCategory)) {
          if (props.existingIssue) {
            props.setIssuedModalView(4);
          } else {
            props.updateModalView(4);
          }
        } else {
          if (props.existingIssue) {
            props.setIssuedModalView(3);
          } else {
            props.updateModalView(3);
          }
        }
      }
      setLoading(false);
    } catch (err) {
      let error = { err };
      console.log(error);
      const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${error.err.transactionHash}`;
      setLoading(false);
      toast.error(FailureToast(transactionLink), ToastOptions);
    }
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <div className="d-grid gap-2">
        <Row className="ml-1 align-items-center">
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Amount to issue</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <TextInput
                placeholder="Decimals supported"
                fieldType="number"
                required
                value={props.balance}
                onChange={(e) => props.updateBalance(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col className="pl-0">
              <UiButton
                onClick={() => {
                  addBalance();
                }}
                buttonVariant="primary"
                buttonClass="SignUpButton flex-1 ml-0"
                buttonText="Add Balance"
                type="submit"
              />
            </Col>
          </Row>
        </Row>
      </div>
    </>
  );
}

export default function AddBalance(props) {
  return (
    <Route>
      <Balance {...props} />
    </Route>
  );
}
