import React from "react";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";

class TextInput extends React.Component {
  render() {
    const {
      fieldType,
      placeholder,
      value,
      onChange,
      name,
      readOnly,
      onKeyPress,
      disabled,
      style,
    } = this.props;

    return (
      <Form.Control
        type={fieldType}
        placeholder={placeholder}
        className="textForm"
        value={value}
        name={name}
        onChange={onChange}
        readOnly={readOnly}
        onKeyPress={onKeyPress}
        disabled={disabled}
        style={style}
      />
    );
  }
}

TextInput.propTypes = {
  fieldType: PropTypes.string,
};

export default TextInput;
