import { differenceInWeeks } from "date-fns";
import { getAddress } from "ethers/lib/utils";

// import { AprConcern } from './concerns/apr/apr.concern';
// import LiquidityConcern from './concerns/liquidity.concern';
import { isStable } from "../../pages/poolDetail/balancer/usePool";
import { bnum } from "../../utils/balancer";
import { oneSecondInMs } from "../../pages/poolDetail/balancer/useTime";
import { balancerSubgraphService } from "../subgraph/balancer-subgraph.service";

export default class PoolService {
  constructor(
    pool
    // liquidity = LiquidityConcern,
    // apr = AprConcern
  ) {
    this.pool = { ...pool };
    // this.liquidity = liquidity;
    // this.apr = apr;
    this.format();
  }

  /**
   * @summary Statically format various pool attributes.
   */
  format() {
    this.pool.address = this.address;
    this.pool.isNew = this.isNew;
    this.pool.tokenAddresses = this.pool.tokensList.map((t) => getAddress(t));
    this.formatPoolTokens();
    return this.pool;
  }

  get address() {
    return getAddress(this.pool.id.slice(0, 42));
  }

  get bptPrice() {
    return bnum(this.pool.totalLiquidity).div(this.pool.totalShares).toString();
  }

  /**
   * @summary Calculates and sets total liquidity of pool.
   */
  setTotalLiquidity(prices, currency) {
    const liquidityConcern = new this.liquidity(this.pool);
    const totalLiquidity = liquidityConcern.calcTotal(prices, currency);
    return (this.pool.totalLiquidity = totalLiquidity);
  }

  /**
   * @summary Calculates APRs for pool.
   */
  async setAPR(
    poolSnapshot,
    prices,
    currency,
    protocolFeePercentage,
    stakingBalApr,
    stakingRewardApr = "0"
  ) {
    const aprConcern = new this.apr(this.pool);
    const apr = await aprConcern.calc(
      poolSnapshot,
      prices,
      currency,
      protocolFeePercentage,
      stakingBalApr,
      stakingRewardApr
    );

    return (this.pool.apr = apr);
  }

  /**
   * fetches StablePhantom linear pools and extracts
   * required attributes.
   */
  async getLinearPoolAttrs() {
    // Fetch linear pools from subgraph
    const linearPools = await balancerSubgraphService.pools.get(
      {
        where: {
          address_in: this.pool.tokensList,
          totalShares_gt: -1, // Avoid the filtering for low liquidity pools
        },
      },
      { mainIndex: true, wrappedIndex: true }
    );

    const linearPoolTokensMap = {};

    // Inject main/wrapped tokens into pool schema
    linearPools.forEach((linearPool) => {
      if (!this.pool.mainTokens) this.pool.mainTokens = [];
      if (!this.pool.wrappedTokens) this.pool.wrappedTokens = [];

      const index = this.pool.tokensList.indexOf(
        linearPool.address.toLowerCase()
      );

      this.pool.mainTokens[index] = getAddress(
        linearPool.tokensList[linearPool.mainIndex]
      );
      this.pool.wrappedTokens[index] = getAddress(
        linearPool.tokensList[linearPool.wrappedIndex]
      );

      linearPool.tokens
        .filter((token) => token.address !== linearPool.address)
        .forEach((token) => {
          const address = getAddress(token.address);

          linearPoolTokensMap[address] = {
            ...token,
            address,
          };
        });
    });

    return (this.pool.linearPoolTokensMap = linearPoolTokensMap);
  }

  removePreMintedBPT() {
    const poolAddress = balancerSubgraphService.pools.addressFor(this.pool.id);
    // Remove pre-minted BPT token if exits
    return (this.pool.tokensList = this.pool.tokensList.filter(
      (address) => address !== poolAddress.toLowerCase()
    ));
  }

  formatPoolTokens() {
    const tokens = this.pool.tokens.map((token) => ({
      ...token,
      address: getAddress(token.address),
    }));

    if (isStable(this.pool.poolType)) return (this.pool.tokens = tokens);

    return (this.pool.tokens = tokens.sort(
      (a, b) => parseFloat(b.weight) - parseFloat(a.weight)
    ));
  }

  setFeesSnapshot(poolSnapshot) {
    if (!poolSnapshot) return "0";

    const feesSnapshot = bnum(this.pool.totalSwapFee)
      .minus(poolSnapshot.totalSwapFee)
      .toString();

    return (this.pool.feesSnapshot = feesSnapshot);
  }

  setVolumeSnapshot(poolSnapshot) {
    if (!poolSnapshot) return "0";

    const volumeSnapshot = bnum(this.pool.totalSwapVolume)
      .minus(poolSnapshot.totalSwapVolume)
      .toString();

    return (this.pool.volumeSnapshot = volumeSnapshot);
  }

  get isNew() {
    return (
      differenceInWeeks(Date.now(), this.pool.createTime * oneSecondInMs) < 1
    );
  }
}
