import React, { useState } from "react";
import Loader from "../../../components/loader";
import { useBalancerPoolsData } from "../../../context/balancerPoolData";
import { PoolType } from "../../poolDetail/balancer/types";
import VerticallyModal from "../../../components/modal/VerticallyModal";
import UiTable from "../../../components/table/Table";

import {
  PrimaryIssueManager,
  contractAddress,
} from "@verified-network/verified-sdk";
import Web3 from "web3";
import { useNetworkOptions } from "../../../context/contractDataLoaderService";
import { useGlobal } from "../../../context/globalContext";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../../utils/helpers/networks";
import { providers } from "ethers";

function ServicerHome(props) {
  let { data, loading } = useBalancerPoolsData(PoolType.PrimaryIssue);
  data = data.filter(
    (pool) => !pool.verifiedWalletData?.subscriptionsClosed.length
  );

  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const { userRole } = useGlobal();
  const { data: networkOptions } = useNetworkOptions();
  // Snippet to filter pools issued by issuer
  // data = data.filter((pool) => pool.issuer.toLowerCase() === account.toLowerCase());

  let primaryIssueManagerContract;
  if (provider && provider.getSigner && chainId && address) {
    const BalancerPrimaryIssueManager =
      contractAddress[chainId].BalancerPrimaryIssueManager;
    if (BalancerPrimaryIssueManager) {
      primaryIssueManagerContract = new PrimaryIssueManager(
        provider.getSigner(address),
        BalancerPrimaryIssueManager,
        "balancer"
      );
    }
  }
  let headers = [
    { label: "Security", val: "security" },
    { label: "Currency", val: "currency" },
    { label: "Close Date", val: "cutoffTime" },
    userRole == "DP" || userRole == "intermediary"
      ? { label: "Call Action", val: "homeCallAction" }
      : { label: "Call Action", val: "managerEarnings" },
  ];
  const options = {
    onClick: (event, row) => {
      onInvest(row);
    },
  };
  const onInvest = (row) => {
    props.history.push(`/primary_investors/${row.poolType}/${row.security}`);
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <section className="d-flex flex-column align-items-start px-0 py-4">
        <div className="pools-table width-100">
          <UiTable
            thead={headers}
            tbodyData={data || []}
            hover
            bordered={false}
            rowEvents={options}
            signer={signer}
            useGlobal={useGlobal}
            networkOptions={networkOptions}
            contract={primaryIssueManagerContract}
          />
        </div>
      </section>
    </>
  );
}

export default function Primary(props) {
  return <ServicerHome {...props} />;
}
