import { useState, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import Loader from "../../components/loader";
import UiButton from "../../components/button";
import TextInput from "../../components/textinput/TextInput";
import Web3 from "web3";
import { ethers, providers } from "ethers";
import { Swaps } from "@balancer-labs/sdk";
import { MaxUint256, balancerVault } from "../../utils/constants";

import ERC20ABI from "../../utils/abi/ERC20.json";
import Response from "../../utils/response";
import { useNetworkOptions } from "../../context/contractDataLoaderService";
import {
  SuccessToast,
  FailureToast,
  ToastOptions,
  networks,
} from "../../utils/constants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Vault from "../../utils/abi/Vault.json";
import MarginPool from "../../utils/abi/MarginTradingPool.json";
import { CurrencyAmount } from "dmm-sdk";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../utils/helpers/networks";

function EditOderModal(props) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: client } = useConnectorClient({ chainId });
  const { chain, transport } = client || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState(null);
  const [amount, setAmount] = useState(null);
  const [changeContent, setChangeContent] = useState(false);
  const modalContent = useRef(null);
  const { data: networkOptions } = useNetworkOptions("issue");

  // console.log("props", props.data);
  const editOrder = async () => {
    console.log("price", price);
    const newPrice = props.data.marketOrder ? "0" : price;

    const rowData = props.data;
    if (newPrice.length == 0) {
      alert("Enter price");
      return;
    } else if (amount.length == 0) {
      alert("Enter quantity");
      return;
    }

    const abiCoder = new ethers.utils.AbiCoder();
    const {
      orderType,
      security,
      currency,
      poolAddress,
      poolId,
      tokensList,
      orderRef,
      amount: previouQty,
    } = rowData;
    const currencyTokenContract = new web3.eth.Contract(ERC20ABI, currency);
    const currencyTokenDecimals = await currencyTokenContract.methods
      .decimals()
      .call();

    const limitArr = new Array(tokensList.length).fill(0);
    const editedAmount = Number(amount);
    const originalAmount = Number(previouQty);
    console.log("editedAmount", editedAmount, originalAmount);
    const differenceAmount =
      editedAmount > originalAmount
        ? editedAmount - originalAmount
        : originalAmount - editedAmount;
    const amountToSend =
      orderType === "Sell"
        ? ethers.utils.parseEther(differenceAmount.toString())
        : Response.tokenAmountConversion(
            differenceAmount,
            currencyTokenDecimals
          ).toString();
    const assetOutAddress =
      editedAmount > originalAmount
        ? poolAddress
        : orderType === "Sell"
        ? security
        : currency;
    const assetInAddress =
      editedAmount > originalAmount
        ? orderType === "Sell"
          ? security
          : currency
        : poolAddress;
    const approvalAmount =
      assetInAddress == poolAddress
        ? ethers.utils.parseEther(differenceAmount.toString())
        : amountToSend;
    limitArr[tokensList.indexOf(assetInAddress)] = approvalAmount.toString();
    console.log(
      "AssetIn",
      assetInAddress,
      "AssetOut",
      assetOutAddress,
      approvalAmount.toString(),
      limitArr,
      newPrice,
      differenceAmount
    );
    const encodedBatchSwapData = Swaps.encodeBatchSwap({
      kind: 0,
      swaps: [
        {
          poolId: poolId,
          assetInIndex: tokensList.indexOf(assetInAddress),
          assetOutIndex: tokensList.indexOf(assetOutAddress),
          amount: approvalAmount,
          userData: abiCoder.encode(
            ["bytes32", "uint"],
            [orderRef, ethers.utils.parseEther(newPrice)]
          ),
        },
      ],
      assets: tokensList,
      funds: {
        fromInternalBalance: false,
        recipient: account,
        sender: account,
        toInternalBalance: false,
      },
      limits: limitArr,
      deadline: "999999999999999999", // Infinity
    });

    const tx = {
      from: account,
      to: balancerVault,
      data: encodedBatchSwapData,
      gasLimit: "8721975",
    };

    const sendingTokenContract = new web3.eth.Contract(
      ERC20ABI,
      assetInAddress
    );
    const sendingTokenContractBalance = await sendingTokenContract.methods
      .balanceOf(account)
      .call();
    const isSufficientBalance =
      Number(sendingTokenContractBalance) > Number(approvalAmount)
        ? true
        : false;

    if (isSufficientBalance) {
      setLoading(true);
      try {
        await sendingTokenContract.methods
          .approve(balancerVault, approvalAmount.toString())
          .send({ from: account, ...(chainId === 137 ? networkOptions : {}) })
          .then(async (res) => {});
        let transactionData = await signer.sendTransaction(tx);
        console.log(transactionData);
        const pendingTransaction = await transactionData.wait();
        console.log(
          "Transaction Mined!!! Join Pool Vault.",
          pendingTransaction
        );
        let transactionLink = "";
        if (pendingTransaction.transactionHash) {
          transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${pendingTransaction.transactionHash}`;
        }
        toast.success(SuccessToast(transactionLink), ToastOptions);
        setChangeContent(true);
        modalContent.current.innerHTML = "";
        setLoading(false);
      } catch (err) {
        let error = { err };
        console.log(error);
        setLoading(false);
        toast.error(FailureToast(), ToastOptions);
      }
    } else {
      toast.error(FailureToast("", "Insufficient balance"), ToastOptions);
      setLoading(false);
    }
  };

  const handleEditOrder = async () => {
    const newPrice = props.data.marketOrder ? "0" : price;
    const rowData = props.data;
    if (newPrice.length == 0) {
      alert("Enter price");
      return;
    } else if (amount.length == 0) {
      alert("Enter quantity");
      return;
    }
    const vaultContract = new web3.eth.Contract(Vault.abi, balancerVault);
    const poolContract = new web3.eth.Contract(
      MarginPool.abi,
      rowData.poolAddress
    );
    const originalAmount = Number(rowData.amount);
    const editedAmount = Number(amount);
    let amountToSend;
    const tokenOut =
      editedAmount > originalAmount
        ? rowData.poolAddress
        : rowData.orderType === "Sell"
        ? rowData.security
        : rowData.currency;
    const tokenIn =
      editedAmount > originalAmount
        ? rowData.orderType === "Sell"
          ? rowData.security
          : rowData.currency
        : rowData.poolAddress;
    const differenceAmount =
      editedAmount > originalAmount
        ? editedAmount - originalAmount
        : originalAmount - editedAmount;
    if (
      tokenIn.toLowerCase() === rowData.security.toLowerCase() ||
      tokenIn.toLowerCase() === rowData.poolAddress.toLowerCase()
    ) {
      amountToSend = web3.utils.toWei(differenceAmount.toString(), "ether");
    } else if (tokenIn.toLowerCase() === rowData.currency.toLowerCase()) {
      const tokenInDecimals = await new web3.eth.Contract(
        ERC20ABI,
        tokenIn
      ).methods
        .decimals()
        .call();
      amountToSend = BigInt(differenceAmount * 10 ** tokenInDecimals);
    }
    console.log("amountToSend: ", amountToSend);
    if (Number(amountToSend) > 0) {
      const poolId = await poolContract.methods.getPoolId().call();
      let isApproved;
      setLoading(true);
      await new web3.eth.Contract(ERC20ABI, rowData.poolAddress).methods
        .approve(balancerVault, amountToSend)
        .send({ from: account, ...(chainId === 137 ? networkOptions : {}) })
        .then((res) => {
          isApproved = true;
          const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${res.transactionHash}`;
          toast.success(
            SuccessToast(transactionLink, "Token approved succesfully"),
            ToastOptions
          );
        })
        .catch((err) => {
          setLoading(false);
          err.message.includes("User denied transaction")
            ? toast.error("User Rejected Transaction", ToastOptions)
            : toast.error(`Transactin to approve token failed`, ToastOptions);
        });
      if (isApproved) {
        await vaultContract.methods
          .swap(
            {
              poolId: poolId,
              kind: 0,
              assetIn: tokenIn,
              assetOut: tokenOut,
              amount: amountToSend,
              userData: web3.eth.abi.encodeParameters(
                ["bytes32", "uint256"],
                [rowData.orderRef, web3.utils.toWei(newPrice, "ether")]
              ),
            },
            {
              sender: account,
              fromInternalBalance: false,
              recipient: account,
              toInternalBalance: false,
            },
            0n,
            MaxUint256
          )
          .send({
            from: account,
            ...(chainId === 137 ? networkOptions : {}),
            gasLimit: "8721975",
          })
          .then((res) => {
            setLoading(false);
            const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${res.transactionHash}`;
            toast.success(
              SuccessToast(transactionLink, "Order Editted succesfully"),
              ToastOptions
            );
            modalContent.current.innerHTML = "";
          })
          .catch((err) => {
            setLoading(false);
            err.message.includes("User denied transaction")
              ? toast.error("User Rejected Transaction", ToastOptions)
              : toast.error(`Transactin to cancel order failed`, ToastOptions);
          });
      }
    }
  };

  return (
    <>
      {loading ? <Loader /> : null}
      {changeContent && (
        <UiButton
          onClick={() => {
            props.onModalHide();
          }}
          buttonVariant="primary"
          buttonClass="SignUpButton flex-1 ml-0"
          buttonText="Click to close this form&nbsp;&rarr;"
          type="submit"
        />
      )}
      <div className="d-grid gap-2" ref={modalContent}>
        <Row className="ml-1 align-items-center">
          {props.data.marketOrder ? null : (
            <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
              <Col xs={{ span: 3, offset: 2 }} className="text-left">
                <b>New price</b>
              </Col>
              <Col className="pl-0" xs={5}>
                <TextInput
                  fieldType="number"
                  placeholder={`Previous price: ${props.data.price}`}
                  onChange={(e) => setPrice(e.target.value)}
                  required
                />
              </Col>
            </Row>
          )}

          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>New quantity</b>
              <div>
                Balance:{" "}
                {props.data.orderType === "Sell"
                  ? props.data.securityBalance +
                      " " +
                      props.data.securityTokenSymbol ??
                    props.data.securitySymbol
                  : props.data.currencyBalance +
                      " " +
                      props.data.currencyTokenSymbol ??
                    props.data.currencySymbol}
              </div>
            </Col>
            <Col className="pl-0" xs={5}>
              <TextInput
                fieldType="number"
                placeholder={`Previous quantity: ${Number(
                  props.data.amount
                ).toFixed(2)}`}
                onChange={(e) => setAmount(e.target.value)}
                required
              />
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col className="pl-0">
              <UiButton
                onClick={() => {
                  editOrder();
                }}
                buttonVariant="primary"
                buttonClass="SignUpButton flex-1 ml-0"
                buttonText="Edit Order"
                type="submit"
              />
            </Col>
          </Row>
        </Row>
      </div>
    </>
  );
}

export function EditOrder(props) {
  return <EditOderModal {...props} />;
}
