import React, { useRef, useEffect, useState } from "react";
import LineChart from "../../components/chart/LineChart";
const intervalList = ["30min", "1hr", "1D", "1W", "1M", "1Y"];

const PortfolioChart = (props) => {
  const [portfolioDataset, setPortfolioDataset] = useState(null);
  const [acquisitionData, setAcquisitionData] = useState(null);
  const [label, setLabel] = useState("Current Price");

  useEffect(() => {
    setPortfolioDataset(props.portfolioData);
    setAcquisitionData(props.acquisitionData);
  }, [props.chartData, props.acquisitionData]);

  return (
    <>
      {portfolioDataset ? (
        <LineChart
          data={portfolioDataset ?? []}
          yAxisTitle={"Price"}
          firstLabel={label}
          chartHeight={350}
          lineColor={"#FF0000"}
          acquisitionData={acquisitionData ?? []}
          secondLabel={"Acquisition Price"}
        />
      ) : (
        "No data avaialable"
      )}
    </>
  );
};

export default PortfolioChart;
