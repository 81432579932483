import { useEffect, useState } from "react";
import Loader from "../../../components/loader";
import { Button, Row, Col, Card } from "react-bootstrap";

import {
  Client,
  Distribution,
  Factory,
  Liquidity,
  MarginIssueManager,
  contractAddress,
} from "@verified-network/verified-sdk";
import UiButton from "../../../components/button";
import TextInput from "../../../components/textinput/TextInput";
import DateTimePicker from "react-datetime-picker";
import {
  SuccessToast,
  FailureToast,
  ToastOptions,
} from "../../../utils/constants";
import ERC20ABI from "../../../utils/abi/ERC20.json";
import MarginManager from "../../../utils/abi/MarginManager.json";
import { toast } from "react-toastify";
import { useNetworkOptions } from "../../../context/contractDataLoaderService";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../../utils/helpers/networks";
import { providers } from "ethers";
import config from "../../../services/config/homestead.json";
import { getTokenTicker } from "../../../utils/helpers";
import Select from "react-select";

function ConfigureApp(props) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [value, onChange] = useState(new Date());
  const [capLimit, setCapLimit] = useState(null);
  const [tokenName, setTokenName] = useState(null);
  const [tokenAddress, setTokenAddress] = useState(null);
  const [oracleAddress, setOracleAddress] = useState(null);
  const [bridgeAddress, setBridgeAddress] = useState(null);
  const [signerAddress, setSignerAddress] = useState(null);
  const [approvalAmount, setApprovalAmount] = useState(null);
  const [desiredCurrency, setDesiredCurrency] = useState(null);
  const [loading, setLoading] = useState(false);
  const { data: networkOptions } = useNetworkOptions();

  let liquidityContract, marginManagerContract, clientContract;
  if (provider && provider.getSigner && chainId && address && web3) {
    liquidityContract = new Liquidity(
      provider.getSigner(address),
      contractAddress[chainId].Liquidity
    );
    marginManagerContract = new web3.eth.Contract(
      MarginManager.abi,
      contractAddress[chainId].BalancerMarginIssueManager
    );
    clientContract = new Client(
      provider.getSigner(address),
      contractAddress[chainId].Client
    );
  }
  const supportToken = async () => {
    if (!tokenName) {
      toast.error("Input Token Name. Token name cannot be empty", ToastOptions);
      return;
    }
    if (!tokenAddress) {
      toast.error(
        "Input Token Address. Token address cannot be empty",
        ToastOptions
      );
      return;
    }
    if (!desiredCurrency) {
      toast.error(
        "Select Desired Currency. Desired Currency cannot be empty",
        ToastOptions
      );
      return;
    }
    setLoading(true);
    const factoryContract = new Factory(
      signer,
      contractAddress[chainId].Factory
    );
    await factoryContract
      .supportTokens(tokenName, tokenAddress)
      .then(async (res) => {
        if (res && res.status === 0) {
          toast.success(
            SuccessToast(res.response.hash, "Transaction Succesful"),
            ToastOptions
          );
          const tokenTickerUrl = `${
            config.coinbaseApiEndpoint
          }/${getTokenTicker(tokenName)}-${desiredCurrency.value}/ticker`;
          await factoryContract
            .setCryptoDataURL(tokenTickerUrl, tokenName, desiredCurrency.value)
            .then((_res) => {
              setLoading(false);
              if (_res && _res.status === 0) {
                toast.success(
                  SuccessToast(_res.response.hash, "Transaction Succesful"),
                  ToastOptions
                );
              } else {
                if (res && res.message) {
                  console.error("unexpected error: ", res.message);
                }
                toast.error(
                  "Transaction failed with unexpected error",
                  ToastOptions
                );
              }
            })
            .catch((err) => {
              setLoading(false);
              console.error("setCryptoDataURL failed with error: ", err);
              toast.error("Transaction failed", ToastOptions);
            });
        } else {
          setLoading(false);
          if (res && res.message) {
            console.error("unexpected error: ", res.message);
          }
          toast.error("Transaction failed with unexpected error", ToastOptions);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error("supportTokens failed with error: ", err);
        toast.error("Support Tokens transaction failed", ToastOptions);
      });
  };
  const addSupply = async () => {
    let dateTime = Math.floor(value.getTime() / 1000).toString();
    setLoading(true);
    try {
      await liquidityContract.createSupply(capLimit, dateTime);
      setLoading(false);
    } catch (e) {
      toast.error("Transaction rejected by user!", ToastOptions);
      setLoading(false);
      return;
    }
  };
  const setOracle = async () => {
    setLoading(true);
    try {
      await liquidityContract.contract.setOracle(oracleAddress);
      setLoading(false);
    } catch (e) {
      toast.error("Transaction rejected by user!", ToastOptions);
      setLoading(false);
      return;
    }
  };
  const approveAmount = async () => {
    const vittaContractAddress = contractAddress[chainId].Vitta;
    const vittaContract = new web3.eth.Contract(ERC20ABI, vittaContractAddress);
    setLoading(true);
    try {
      await vittaContract.methods
        .approve(liquidityContract.contractAddress, approvalAmount)
        .send({ ...(chainId === 137 ? networkOptions : {}), from: account });
      setLoading(false);
    } catch (e) {
      toast.error("Transaction rejected by user!", ToastOptions);
      setLoading(false);
      return;
    }
  };

  const setBridge = async () => {
    if (!bridgeAddress) {
      alert("Input Bridge Address");
      return;
    }
    if (marginManagerContract) {
      setLoading(true);
      try {
        await marginManagerContract.methods
          .setBridge(bridgeAddress)
          .send({ from: account })
          .then((res) => {
            setLoading(false);
            toast.success("Bridge Set Successfully", ToastOptions);
          });
      } catch (e) {
        setLoading(false);
        toast.error("Transaction Failed", ToastOptions);
      }
    }
    return;
  };

  const setSigner = async () => {
    if (!signerAddress) {
      alert("Input Signer Address");
      return;
    }
    if (clientContract) {
      setLoading(true);
      try {
        await clientContract.setSigner(signerAddress).then((res) => {
          if (res?.status === 0) {
            setLoading(false);
            toast.success("Signer Set Successfully", ToastOptions);
          }
          if (res?.status === 1) {
            setLoading(false);
            console.log("failure: ", res);
            toast.error("Transaction Failed", ToastOptions);
          }
        });
      } catch (e) {
        setLoading(false);
        console.log("error: ", e);
        toast.error("Transaction Failed", ToastOptions);
      }
    }
    return;
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <section className="d-flex flex-column align-items-start px-0 py-4">
        <div className="mb-3 d-flex justify-content-between w-100 align-items-center">
          <div className="w-50">
            <h3 className="text-left">Configure App</h3>
          </div>
        </div>
        <div className="mb-3 d-flex justify-content-between w-100 align-items-center config-wrapper">
          <Row className="d-flex md-flex justify-content-stretch mb-4 config-row">
            <Card className="p-3 shadow mr-3 border-sm flex-1">
              <h4 className="text-center mb-4">Support Tokens</h4>
              <Row className="ml-1 align-items-center">
                <Row className="mx-0 mb-3 pl-0 width-100 align-items-center">
                  <Col xs={4}>
                    <b>Token Name</b>
                  </Col>
                  <Col className="pl-0" xs={6}>
                    <TextInput
                      placeholder="Token Name e.g WETH"
                      onChange={(e) => setTokenName(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mx-0 mb-3 pl-0 width-100 align-items-center">
                  <Col xs={4}>
                    <b>Token Address</b>
                  </Col>
                  <Col className="pl-0" xs={6}>
                    <TextInput
                      placeholder="Token Address"
                      onChange={(e) => setTokenAddress(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mx-0 mb-3 pl-0 width-100 align-items-center">
                  <Col xs={4}>
                    <b>Desired Currency</b>
                  </Col>
                  <Col className="pl-0" xs={6}>
                    <Select
                      options={config.supportedCurrencies.map((currency) => {
                        return { value: currency, label: currency };
                      })}
                      isMulti={false}
                      value={desiredCurrency}
                      onChange={(selected) => setDesiredCurrency(selected)}
                    />
                  </Col>
                </Row>
                <Row className="mx-0 pl-0 width-100 align-items-center">
                  <Col className="pl-0" xs={4} />
                  <Col className="pl-0" xs={4}>
                    <UiButton
                      buttonVariant="primary"
                      buttonClass="SignUpButton flex-1 ml-0"
                      onClick={supportToken}
                      buttonText="Support Tokens"
                      type="submit"
                    />
                    <Col className="pl-0" xs={4} />
                  </Col>
                </Row>
              </Row>
            </Card>
          </Row>
          <Row className="d-flex md-flex justify-content-stretch mb-4 config-row">
            <Card className="p-3 shadow mr-3 border-sm flex-1">
              <h4 className="text-center mb-4">Create Supply</h4>
              <Row className="ml-1 align-items-center">
                <Row className="mx-0 mb-3 pl-0 width-100 align-items-center">
                  <Col xs={4}>
                    <b>Cap Limit</b>
                  </Col>
                  <Col className="pl-0" xs={6}>
                    <TextInput
                      placeholder="Amount"
                      onChange={(e) =>
                        setCapLimit(web3.utils.toWei(e.target.value, "ether"))
                      }
                    />
                  </Col>
                </Row>
                <Row className="mx-0 mb-3 pl-0 width-100 align-items-center">
                  <Col xs={4}>
                    <b>Time Limit</b>
                  </Col>
                  <Col className="pl-0" xs={6}>
                    <DateTimePicker onChange={onChange} value={value} />
                  </Col>
                </Row>
                <Row className="mx-0 pl-0 width-100 align-items-center">
                  <Col className="pl-0" xs={4} />
                  <Col className="pl-0" xs={4}>
                    <UiButton
                      buttonVariant="primary"
                      buttonClass="SignUpButton flex-1 ml-0"
                      onClick={addSupply}
                      buttonText="Add Supply"
                      type="submit"
                    />
                    <Col className="pl-0" xs={4} />
                  </Col>
                </Row>
              </Row>
            </Card>
          </Row>
          <Row className="d-flex md-flex justify-content-stretch mb-4 config-row">
            <Card className="p-3 shadow mr-3 border-sm flex-1">
              <h4 className="text-center mb-4">Set Oracle</h4>
              <Row className="ml-1 align-items-center">
                <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                  <Col xs={4}>
                    <b>Oracle</b>
                  </Col>
                  <Col className="pl-0" xs={6}>
                    <TextInput
                      placeholder="Address"
                      onChange={(e) => setOracleAddress(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mx-0 pl-0 width-100 align-items-center">
                  <Col className="pl-0" xs={4} />
                  <Col className="pl-0" xs={4}>
                    <UiButton
                      buttonVariant="primary"
                      buttonClass="SignUpButton flex-1 ml-0"
                      onClick={setOracle}
                      buttonText="Set Address"
                      type="submit"
                    />
                    <Col className="pl-0" xs={4} />
                  </Col>
                </Row>
              </Row>
            </Card>
          </Row>
          <Row className="d-flex md-flex justify-content-stretch mb-4 config-row">
            <Card className="p-3 shadow mr-3 border-sm flex-1">
              <h4 className="text-center mb-4">
                Approve Amount of Vitta to Issue
              </h4>
              <Row className="ml-1 align-items-center">
                <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                  <Col xs={4}>
                    <b>Approval Amount</b>
                  </Col>
                  <Col className="pl-0" xs={6}>
                    <TextInput
                      placeholder="Amount"
                      onChange={(e) =>
                        setApprovalAmount(
                          web3.utils.toWei(e.target.value, "ether")
                        )
                      }
                    />
                  </Col>
                </Row>
                <Row className="mx-0 pl-0 width-100 align-items-center">
                  <Col className="pl-0" xs={4} />
                  <Col className="pl-0" xs={4}>
                    <UiButton
                      buttonVariant="primary"
                      buttonClass="SignUpButton flex-1 ml-0"
                      onClick={approveAmount}
                      buttonText="Set Approval"
                      type="submit"
                    />
                    <Col className="pl-0" xs={4} />
                  </Col>
                </Row>
              </Row>
            </Card>
          </Row>
          <Row className="d-flex md-flex justify-content-stretch mb-4 config-row">
            <Card className="p-3 shadow mr-3 border-sm flex-1">
              <h4 className="text-center mb-4">Set Bridge</h4>
              <Row className="ml-1 align-items-center">
                <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                  <Col xs={4}>
                    <b>Bridge</b>
                  </Col>
                  <Col className="pl-0" xs={6}>
                    <TextInput
                      placeholder="Bridge Address"
                      onChange={(e) => setBridgeAddress(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mx-0 pl-0 width-100 align-items-center">
                  <Col className="pl-0" xs={4} />
                  <Col className="pl-0" xs={4}>
                    <UiButton
                      buttonVariant="primary"
                      buttonClass="SignUpButton flex-1 ml-0"
                      onClick={async () => {
                        await setBridge();
                      }}
                      buttonText="Set Bridge"
                      type="submit"
                    />
                    <Col className="pl-0" xs={4} />
                  </Col>
                </Row>
              </Row>
            </Card>
          </Row>
          <Row className="d-flex md-flex justify-content-stretch mb-4 config-row">
            <Card className="p-3 shadow mr-3 border-sm flex-1">
              <h4 className="text-center mb-4">Set Signer</h4>
              <Row className="ml-1 align-items-center">
                <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                  <Col xs={4}>
                    <b>Signer</b>
                  </Col>
                  <Col className="pl-0" xs={6}>
                    <TextInput
                      placeholder="Signer's Address"
                      onChange={(e) => setSignerAddress(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mx-0 pl-0 width-100 align-items-center">
                  <Col className="pl-0" xs={4} />
                  <Col className="pl-0" xs={4}>
                    <UiButton
                      buttonVariant="primary"
                      buttonClass="SignUpButton flex-1 ml-0"
                      onClick={async () => {
                        await setSigner();
                      }}
                      buttonText="Set Signer"
                      type="submit"
                    />
                    <Col className="pl-0" xs={4} />
                  </Col>
                </Row>
              </Row>
            </Card>
          </Row>
        </div>
      </section>
    </>
  );
}

export default function AdminConfiguration(props) {
  return <ConfigureApp {...props} />;
}
