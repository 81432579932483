import React, { Component } from "react";
import Button from "react-bootstrap/Button";

class UiButton extends Component {
  render() {
    const {
      buttonClass,
      buttonLink,
      buttonText,
      buttonVariant,
      onClick,
      ...prop
    } = this.props;

    return (
      <Button
        variant={buttonVariant}
        className={buttonClass}
        href={buttonLink}
        {...prop}
        onClick={onClick}
      >
        {buttonText}
      </Button>
    );
  }
}

export default UiButton;
