import { Row, Col } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";

function LiquidityModal(props) {
  const { liquidityProviders } = props.data;

  return (
    <>
        <div className="d-grid gap-2">
            <Row className="ml-1 align-items-center">
              <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                  <Col xs={{span:4, offset:2}} className="text-left">
                  <b>Offer amount:</b>
                  </Col>
                  <Col className="pl-0" xs={5}>
                  {liquidityProviders?.at(-1)?.tokenOffered+ " " + liquidityProviders?.at(-1)?.currencyName}
                  </Col>
              </Row>
            </Row>
        </div>
    </>
  );
}

export function LiquidityOffered(props) {
  return <LiquidityModal {...props} />;
}