import React from 'react';
import StripePaymentModal from './StripePaymentModal';
import PaymentGateway from '../api/PaymentGateway';

class PaymentModal extends React.Component {

  handlePaymentStatusChange = (status) => {
    if (typeof this.props.onPaymentStatusChange === 'function') {
      this.props.onPaymentStatusChange(status);
    }
  }

  render() {
    const {paymentRequest, show, onHide} = this.props;
    if (paymentRequest) {
      const paymentGateway = paymentRequest.gateway_name;

      switch (paymentGateway) {
        case PaymentGateway.Gateways.STRIPE: {
          return <StripePaymentModal show={show} paymentRequest={paymentRequest} onHide={onHide}/>;
        }
      }
    }

    return null;
  }
}

export default PaymentModal;