import React, { useState } from "react";
import Loader from "../../../components/loader";
import { useBalancerData } from "../../../context/balancerPoolData";
import {
  useGetsubscribers,
  useNetworkOptions,
} from "../../../context/contractDataLoaderService";
import UiTable from "../../../components/table/Table";
import {
  PrimaryIssueManager,
  contractAddress,
} from "@verified-network/verified-sdk";
import { useVerifiedSecurity } from "../../../context/verifiedWalletData";
import { useGlobal } from "../../../context/globalContext";

import Web3 from "web3";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../../utils/helpers/networks";
import { providers } from "ethers";

function InvestorInfo(props) {
  const securityAddress = props.match.params.securityAddress;
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const { userRole } = useGlobal();
  let { data: verifiedSecurity, loading: securityLoading } = useVerifiedSecurity(securityAddress);
  let { data: primarySubscribers, loading } =
    useGetsubscribers(verifiedSecurity);
  const { data: networkOptions } = useNetworkOptions();
  let headers;
  let pageHeading;
  let primaryIssueManagerContract;

  if (provider && provider.getSigner && chainId && address) {
    const BalancerPrimaryIssueManager =
      contractAddress[chainId].BalancerPrimaryIssueManager;
    if (BalancerPrimaryIssueManager) {
      primaryIssueManagerContract = new PrimaryIssueManager(
        provider.getSigner(address),
        BalancerPrimaryIssueManager,
        "balancer"
      );
    }
  }

  pageHeading = "Primary Investors";
  headers = [
    { label: "Date", val: "date" },
    { label: "Investor Name", val: "investorName" },
    { label: "Investor Address", val: "address" },
    { label: "Invested Token", val: "tokenName" },
    { label: "Subscription Qty", val: "qty" },
    { label: "Security Traded", val: "traded" },
    userRole == "DP" || userRole == "intermediary"
      ? { label: "Call Action", val: "primaryInvestorCallAction" }
      : {},
  ];

  return (
    <>
      {loading || securityLoading ? <Loader /> : null}
      <section className="d-flex flex-column align-items-start px-0 py-4">
        <div className="mb-3 d-flex justify-content-between w-100 align-items-center">
          <div className="">
            <h3 className="">{pageHeading}</h3>
          </div>
        </div>
        <div className="pools-table width-100">
          <UiTable
            thead={headers}
            tbodyData={primarySubscribers || []}
            hover
            bordered={false}
            web3={web3}
            chainId={chainId}
            networkOptions={networkOptions}
            contract={primaryIssueManagerContract}
            signer={signer}
          />
        </div>
      </section>
    </>
  );
}

export default function ClosedInvestorDetail(props) {
  return <InvestorInfo {...props} />;
}
