import React, { useState } from "react";
import UiTable from "../../../components/table/Table";
import { useNetworkOptions } from "../../../context/contractDataLoaderService";
import Web3 from "web3";
import { getWeb3 } from "../../../utils/helpers/networks";

const TradesTable = (props) => {
  const { data: networkOptions } = useNetworkOptions();
  let web3;
  if (props.transport) {
    web3 = getWeb3(props.transport);
  }
  let headers;
  headers = [
    { label: "Date", val: "date" },
    { label: "Security", val: "securityName" },
    { label: "Order Type", val: "orderType" },
    { label: "Quantity", val: "quantity" },
    { label: "Price", val: "price" },
    { label: "Amount", val: "currencyAmount" },
    { label: "Action", val: "claimTrade" },
  ];

  const data = [...props.data];

  return (
    <>
      <div className="pools-table width-100">
        <UiTable
          thead={headers}
          tbodyData={data}
          hover
          provider={props.provider}
          transport={props.transport}
          account={props.account}
          chainId={props.chainId}
          bordered={false}
          networkOptions={networkOptions}
          web3={web3}
        />
      </div>
    </>
  );
};

export default TradesTable;
