import React, { useState, createContext, useContext, useEffect } from "react";
import Response from "../utils/response";
import { Client, contractAddress } from "@verified-network/verified-sdk";
import { useVerifiedSecuritiesList } from "../context/verifiedWalletData";
import config from "../services/config/homestead.json";
import axios from "axios";
import { Config, useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../utils/helpers/networks";
import { countryDetails } from "../utils/constants";

const { ethers, providers } = require("ethers");

const GlobalContext = createContext(null);

export const GlobalProvider = ({ children }) => {
  const { address, chainId } = useAccount();
  const { data: client } = useConnectorClient({ chainId });
  const { chain, transport } = client || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  let securitiesLength = 0;
  let { data: verifiedSecurities } = useVerifiedSecuritiesList();

  if (Object.keys(verifiedSecurities).length !== 0) {
    verifiedSecurities = verifiedSecurities?.filter(
      (security) =>
        security.issueManager?.toLowerCase() === address?.toLowerCase()
    );
    securitiesLength = verifiedSecurities.length;
  }

  // Using the useState hook to keep track of the value authed (if a user is logged in)
  const [userRole, setUserRole] = useState("investor");
  const [isSecondaryManager, setSecondaryManager] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [globalLoading, setGlobalLoading] = useState(false);
  const [issuedSecondary, setIssuedSecondary] = useState();
  const [kycStatus, setKYCstatus] = useState(0);
  const [userType, setUserType] = useState("");
  const [userCountry, setUserCountry] = useState("");
  const [userCountryCode, setUserCountryCode] = useState("");

  useEffect(() => {
    if (userRole === "investor" && securitiesLength)
      setUserRole("intermediary");
  }, [userRole, securitiesLength]);

  const login = async () => {
    const result = await fetchUserRole();
    if (result) {
      setUserRole(result);
    }
  };

  const fetchUserRole = async () => {
    if (address && provider && provider.getSigner && chainId) {
      const clientAddress = contractAddress[chainId].Client;
      const clientContract = new Client(
        provider.getSigner(address),
        clientAddress
      );
      if (clientContract) {
        const data = await clientContract
          .getRole(address)
          .then((response) => Response.array(response))
          .then((response) => Response.parseBytes32Value(response[0]))
          .then((role) => {
            // console.log("role ret: ", role);
            return role;
          });
        const kycDataResponse = await clientContract.getClientKYC(address);
        const fullKYCResponse = await clientContract.getFullClientKYC(address);
        const result = kycDataResponse.response.result;
        const kycStatus = Number(result[3]);
        const fullKYCResult = fullKYCResponse.response.result;
        const userCountry = Response.parseBytes32Value(result[2]);
        const userCountryDetails = countryDetails.find(
          (cont) => cont.name.toLowerCase() === userCountry.toLowerCase()
        );
        setUserCountry(userCountry);
        if (userCountryDetails) {
          setUserCountryCode(userCountryDetails.code);
        }
        setKYCstatus(kycStatus);
        setUserType(fullKYCResult[1]);
        setLoggedIn(true);
        return data;
      }
    }
  };

  return (
    // Using the provider so that ANY component in our application can use the values that we are sending.
    <GlobalContext.Provider
      value={{
        userRole,
        setUserRole,
        login,
        isUpdate,
        setIsUpdate,
        globalLoading,
        setGlobalLoading,
        loggedIn,
        kycStatus,
        userType,
        isSecondaryManager,
        setSecondaryManager,
        issuedSecondary,
        setIssuedSecondary,
        userCountry,
        userCountryCode,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

// Finally creating the custom hook
export const useGlobal = () => useContext(GlobalContext);

export const fetchSumsubAccessToken = async (account, networkId) => {
  return axios({
    method: "GET",
    url: `${config.sumsubEndpoint}?account=${account}&networkId=${networkId}`,
  })
    .then((res) => {
      return {
        accessToken: res.data.token,
      };
    })
    .catch((err) => {
      console.log("err: ", err);
    });
};
