import React, { useState, useRef, useEffect } from "react";

import { getTimeframe, toNiceDateTime } from "../../../utils";
import { timeframeOptions } from "../../../utils/helpers";
import { usePoolChartData } from "../../../context/kyberPoolData";
import LineChart from "../../../components/chart/LineChart";
import Loader from "../../../components/loader";

const PoolChart = ({ address }) => {
  const [timeWindow] = useState(timeframeOptions.MONTH);

  const ref = useRef();
  const isClient = typeof window === "object";
  const [width, setWidth] = useState(ref?.current?.container?.clientWidth);
  const [height, setHeight] = useState(ref?.current?.container?.clientHeight);

  useEffect(() => {
    if (!isClient) {
      return false;
    }
    function handleResize() {
      setWidth(ref?.current?.container?.clientWidth ?? width);
      setHeight(ref?.current?.container?.clientHeight ?? height);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [height, isClient, width]); // Empty array ensures that effect is only run on mount and unmount

  let { data: chartData, loading } = usePoolChartData(address);

  let utcStartTime = getTimeframe(timeWindow);
  chartData = chartData?.filter((entry) => entry.date >= utcStartTime);

  const getChartData = () => {
    let values = [];
    chartData.map((item) => {
      const date = toNiceDateTime(item.date);
      values.push([date, Number(item.reserveUSD)]);
    });
    const chartSeries = [
      {
        name: "Pool Liquidity",
        values,
      },
    ];
    return chartSeries;
  };

  console.log("kyberPoolChart Render", chartData, getChartData());

  return (
    <>
      <LineChart
        data={getChartData()}
        isPeriodSelectionEnabled={false}
        axisLabelFormatter={{
          yAxis: {
            style: "currency",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            fixedFormat: true,
          },
        }}
        color={["green"]}
        height="96"
        showLegend={true}
        legendState={{ HODL: false }}
        showTooltip={true}
      />{" "}
      {loading ? <Loader /> : null}
    </>
  );
};

export default PoolChart;
