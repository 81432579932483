import UiTable from "../../components/table/Table";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../components/loader";
import VerticallyModal from "../../components/modal/VerticallyModal";
import { useMarketDepth } from "../../context/contractDataLoaderService";

function ViewDepth(props) {
  const {pool} = props;
  const {buyOrderBook, sellOrderBook, loading} = useMarketDepth(pool);

  const buyOrders = [
    { label: "BID PRICE", val: "bid" },
    { label: "QTY", val: "amountOffered" },
  ];
  const sellOrders = [
    { label: "OFFER PRICE", val: "offer" },
    { label: "QTY", val: "amountOffered" },
  ];
  return (
    <>
    {
        <div className="d-flex w-100">
            <div className="w-50 pr-2">
                <UiTable thead={buyOrders} tbodyData={ buyOrderBook ?? []} hover bordered={false} />
            </div>
            <div className="w-50 pl-2">
                <UiTable thead={sellOrders} tbodyData={ sellOrderBook ?? []} hover bordered={false} />
            </div>
        </div>
    }
    </>
  );
}

export default function MarketDepth(props) {
  return <ViewDepth {...props} />;
}