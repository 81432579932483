import React, { useState, useEffect, useRef } from "react";
import Loader from "../../components/loader";

import UiTable from "../../components/table/Table";
import WalletConnect from "../../components/common/walletConnect/index";
import { Button, Col, Dropdown, DropdownButton, Row } from "react-bootstrap";
import TextInput from "../../components/textinput/TextInput";
import UiButton from "../../components/button";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import VerticallyModal from "../../components/modal/VerticallyModal";
import { SuccessToast, ToastOptions, networks } from "../../utils/constants";
import ERC20ABI from "../../utils/abi/ERC20.json";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../utils/helpers/networks";
import { useGetUserMarginCollateral } from "../../context/contractDataLoaderService";
import {
  contractAddress,
  MarginIssueManager,
} from "@verified-network/verified-sdk";
import { useBalancerPoolsData } from "../../context/balancerPoolData";
import { PoolType } from "../poolDetail/balancer/types";
import { fetchSumsubAccessToken, useGlobal } from "../../context/globalContext";
import { getKycStatus } from "../../utils/helpers";
import { toast } from "react-toastify";
import { useNetworkOptions } from "../../context/contractDataLoaderService";
import KYCModal from "../../components/common/kycModal";

function CollateralsPage(props) {
  let { allCollaterals: data, loading: loading } = useGetUserMarginCollateral();
  data = data.sort((a, b) => Number(a.time) - Number(b.time));
  let { data: balancerPools, loading: poolLoading } = useBalancerPoolsData(
    PoolType.MarginIssue
  );
  // console.log("all: ", data);
  const { userType, userCountry, userCountryCode } = useGlobal();
  balancerPools = balancerPools.filter(
    (pool) => !pool.restrictedCountries?.includes(userCountryCode)
  );
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3, marginManagerContract, marginManagerAddress;
  if (provider && provider.getSigner && chainId && address) {
    marginManagerAddress = contractAddress[chainId].BalancerMarginIssueManager;
    signer = provider.getSigner(address);
    if (contractAddress[chainId].BalancerMarginIssueManager) {
      marginManagerContract = new MarginIssueManager(
        provider.getSigner(address),
        contractAddress[chainId].BalancerMarginIssueManager
      );
    }
    web3 = getWeb3(transport);
  }
  const { data: networkOptions } = useNetworkOptions();
  const [modalView, setModalView] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  const [showKycModal, setShowKycModal] = useState(false);
  const [contractCallLoading, setContractCallLoading] = useState(false);
  const [securities, setSecurities] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [currencySelected, setCurrencySelected] = useState({
    name: "Select Currency",
    address: "",
    decimals: 0,
  });
  const [securitySelected, setSecuritySelected] = useState({
    name: "Select Security",
    address: "",
  });
  const [collateralAmount, setCollateralAmount] = useState(0);
  const modalContent = useRef(null);

  if (balancerPools) {
    balancerPools.map((pool) => {
      if (securities.length == 0) {
        securities.push({
          name: pool.marginSecurityName,
          address: pool.securityToken,
        });
      } else {
        let isIn = false;
        securities.map((sec) => {
          if (sec.address.toLowerCase() === pool.securityToken.toLowerCase()) {
            isIn = true;
          }
        });
        if (!isIn) {
          securities.push({
            name: pool.marginSecurityName,
            address: pool.securityToken,
          });
        }
      }

      if (currencies.length == 0) {
        currencies.push({
          name: pool.marginCurrencyName,
          address: pool.currencyToken,
          decimals: pool.marginCurrencyDecimals,
        });
      } else {
        let isIn = false;
        currencies.map((cur) => {
          if (cur.address.toLowerCase() === pool.currencyToken.toLowerCase()) {
            isIn = true;
          }
        });
        if (!isIn) {
          currencies.push({
            name: pool.marginCurrencyName,
            address: pool.currencyToken,
            decimals: pool.marginCurrencyDecimals,
          });
        }
      }
    });
  }

  const headers = [
    { label: "Date", val: "date" },
    { label: "Currency", val: "currencySymbol" },
    { label: "Type", val: "collateralType" },
    { label: "Balance", val: "balance" },
    { label: "action", val: "collateralAction" },
  ];

  const handleModalHide = () => {
    setModalView(1);
    setShowModal(false);
    setCurrencySelected({
      name: "Select Currency",
      address: "",
      decimals: 0,
    }); //reset currency
    setSecuritySelected({
      name: "Select Security",
      address: "",
    }); //reset security
  };

  const handleOfferCollateral = async () => {
    let isValidated = true;
    if (collateralAmount === 0) {
      alert("Collateral amount cannot be zero(0)");
      isValidated = false;
    }
    if (currencySelected.name === "Select Currency") {
      alert("Select Currency to proceed");
      isValidated = false;
    }
    if (securitySelected.name === "Select Security") {
      alert("Select Security to proceed");
      isValidated = false;
    }
    if (isValidated) {
      const userKycStatus = await getKycStatus(address, chainId, signer);
      if (userKycStatus !== 3) {
        const data = await fetchSumsubAccessToken(account, chainId);
        setShowModal(false);
        setShowKycModal(true);
        setAccessToken(data.accessToken);
        return;
      }
      if (web3 && marginManagerAddress && marginManagerContract) {
        const currencyContract = new web3.eth.Contract(
          ERC20ABI,
          currencySelected.address
        );
        setContractCallLoading(true);
        const currencyAllowance = await currencyContract.methods
          .allowance(account, marginManagerAddress)
          .call();
        if (Number(currencyAllowance) < collateralAmount) {
          await currencyContract.methods
            .approve(marginManagerAddress, collateralAmount)
            .send({ from: account, ...(chainId === 137 ? networkOptions : {}) })
            .then(async (res) => {
              await marginManagerContract
                .offerCollateral(
                  currencySelected.address,
                  collateralAmount.toString(),
                  securitySelected.address
                )
                .then((res) => {
                  setContractCallLoading(false);
                  if (res.code === "ACTION_REJECTED") {
                    toast.error("User Rejected Transaction", ToastOptions);
                  } else if (res.code === "UNPREDICTABLE_GAS_LIMIT") {
                    toast.error(
                      "Offer collateral transaction failed",
                      ToastOptions
                    );
                  } else {
                    setContractCallLoading(false);
                    setShowModal(false);
                    const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${res.response.hash}`;
                    toast.success(
                      SuccessToast(
                        transactionLink,
                        "Offered collateral succesfully"
                      ),
                      ToastOptions
                    );
                  }
                })
                .catch((err) => {
                  console.log("err: ", err);
                  setContractCallLoading(false);
                  toast.error(
                    "Offer collateral transaction failed",
                    ToastOptions
                  );
                });
            })
            .catch((err) => {
              setContractCallLoading(false);
              err.message.includes("User denied transaction")
                ? toast.error("User Rejected Transaction", ToastOptions)
                : toast.error(
                    "Transactin to approve security token failed",
                    ToastOptions
                  );
            });
        } else {
          await marginManagerContract
            .offerCollateral(
              currencySelected.address,
              collateralAmount.toString(),
              securitySelected.address
            )
            .then((res) => {
              setContractCallLoading(false);
              if (res.code === "ACTION_REJECTED") {
                toast.error("User Rejected Transaction", ToastOptions);
              } else if (res.code === "UNPREDICTABLE_GAS_LIMIT") {
                toast.error(
                  "Offer collateral transaction failed",
                  ToastOptions
                );
              } else {
                setContractCallLoading(false);
                setShowModal(false);
                const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${res.response.hash}`;
                toast.success(
                  SuccessToast(
                    transactionLink,
                    "Offered collateral succesfully"
                  ),
                  ToastOptions
                );
              }
            })
            .catch((err) => {
              setContractCallLoading(false);
              toast.error("Offer collateral transaction failed", ToastOptions);
            });
        }
      }
    }
  };

  let message, heading;
  if (modalView === 1) {
    heading = "Offer Collateral";
    message = (
      <>
        {contractCallLoading ? <Loader /> : null}
        <div className="d-grid gap-2" ref={modalContent}>
          <Row className="ml-1 align-items-center">
            <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
              <Col xs={{ span: 4, offset: 2 }} className="text-left">
                <b>Securities</b>
              </Col>
              <Col className="pl-0" xs={3.5}>
                <DropdownButton title={securitySelected.name}>
                  {securities.map((sec) => (
                    <DropdownItem
                      onClick={() => {
                        setSecuritySelected(sec);
                      }}
                    >
                      {sec.name}
                    </DropdownItem>
                  ))}
                </DropdownButton>
              </Col>
            </Row>
            <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
              <Col xs={{ span: 4, offset: 2 }} className="text-left">
                <b>Currencies</b>
              </Col>
              <Col className="pl-0" xs={3.5}>
                <DropdownButton title={currencySelected.name}>
                  {currencies.map((cur) => (
                    <DropdownItem
                      onClick={() => {
                        setCurrencySelected(cur);
                      }}
                    >
                      {cur.name}
                    </DropdownItem>
                  ))}
                </DropdownButton>
              </Col>
            </Row>
            <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
              <Col xs={{ span: 4, offset: 2 }} className="text-left">
                <b> Amount</b>
              </Col>
              <Col className="pl-0" xs={3.5}>
                <TextInput
                  placeholder="Value of collateral"
                  fieldType="number"
                  onChange={(e) =>
                    setCollateralAmount(
                      Number(e.target.value) *
                        10 ** Number(currencySelected.decimals)
                    )
                  }
                  required
                />
              </Col>
            </Row>
            <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
              <Col className="pl-0">
                <UiButton
                  onClick={async () => {
                    await handleOfferCollateral();
                  }}
                  buttonVariant="primary"
                  buttonClass="SignUpButton flex-1 ml-0"
                  buttonText="Offer Collateral"
                  type="submit"
                />
              </Col>
            </Row>
          </Row>
        </div>
      </>
    );
  }

  return (
    <>
      {loading || poolLoading ? <Loader /> : null}
      <section className="d-flex flex-column align-items-start px-0 py-4">
        <div className="mb-3 d-flex justify-content-between w-100 align-items-center">
          <div className="w-100">
            <h3 className="text-left">Margin Collaterals</h3>
          </div>
          <div className="w-100 text-right">
            <Button
              id="offerCollateral Button"
              onClick={() => {
                setShowModal(true);
                setModalView(1);
              }}
            >
              Offer Collateral
            </Button>
          </div>
        </div>
        <div className="pools-table width-100">
          <UiTable
            thead={headers}
            tbodyData={data ?? []}
            hover
            provider={provider}
            transport={transport}
            account={account}
            chainId={chainId}
            contract={marginManagerContract}
            bordered={false}
          />
        </div>
      </section>
      <VerticallyModal
        key="connectProvider"
        showModal={showModal}
        modalOnHide={handleModalHide}
        modalSize={"lg"}
        modalHeading={
          <h2>
            <b>{heading}</b>
          </h2>
        }
        withFooter={false}
      >
        {message}
      </VerticallyModal>
      <WalletConnect loggedIn={props.loggedIn} />
      {showKycModal && (
        <KYCModal
          accessToken={accessToken}
          setshowKYCModal={setShowKycModal}
          showKYCModal={showKycModal}
        />
      )}
    </>
  );
}

export default function Collaterals(props) {
  return <CollateralsPage {...props} />;
}
