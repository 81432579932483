import { useState, useRef } from "react";
import { Row, Col, DropdownButton, Dropdown } from "react-bootstrap";
import Loader from "../../components/loader";
import UiButton from "../../components/button";
import TextInput from "../../components/textinput/TextInput";
import {
  useSupportedTokens,
  useSecurityTokenDetails,
  useNetworkOptions,
} from "../../context/contractDataLoaderService";
import {
  PrimaryIssueManager,
  SecuritiesFactory,
  contractAddress,
  Security,
  MarginIssueManager,
} from "@verified-network/verified-sdk";
import Web3 from "web3";
import { useGlobal, fetchSumsubAccessToken } from "../../context/globalContext";
import ERC20ABI from "../../utils/abi/ERC20.json";

import { create } from "ipfs-http-client";
import {
  SuccessToast,
  FailureToast,
  ToastOptions,
  auth,
  networks,
  BrokeragesList,
} from "../../utils/constants";
import { toast } from "react-toastify";
import ERC20 from "../../utils/abi/ERC20.json";
import { Route, useHistory } from "react-router-dom";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../utils/helpers/networks";
import { providers } from "ethers";
import { getKycStatus } from "../../utils/helpers";
const BigNumber = require("bignumber.js");

function NewProduct(props) {
  const history = useHistory();
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, securityContract, web3, marginManagerAddress;
  if (provider && provider.getSigner && chainId && address) {
    marginManagerAddress = contractAddress[chainId].BalancerMarginIssueManager;
    signer = provider.getSigner(address);
    securityContract = new Security(
      provider.getSigner(address),
      props.security
    );
    web3 = getWeb3(transport);
  }
  const { data: networkOptions } = useNetworkOptions();
  const [loading, setLoading] = useState(false);
  const { kycStatus } = useGlobal();
  const [securityAmount, setSecurityAmount] = useState(0);
  const [currencyAmount, setCurrencyAmount] = useState(0);
  const [minimumAmount, setMinimumAmount] = useState(0);
  const [marginAmount, setMarginAmount] = useState(0);
  const [collateralAmount, setCollateralAmount] = useState(0);
  const [tradeFee, setTradeFee] = useState(0);
  const [brokerage, setBrokerage] = useState("Select Brokerage");
  const modalContent = useRef(null);

  const web3CurrencyContract = new web3.eth.Contract(ERC20, props.currency);
  const web3SecurityContract = new web3.eth.Contract(ERC20, props.security);

  const validateParams = () => {
    let validated = true;
    if (Number(securityAmount) === 0) {
      alert(`${props.securitySymbol} amount cannot be zero(0)`);
      validated = false;
    }
    if (Number(currencyAmount) === 0) {
      alert(`${props.currencySymbol} amount cannot be zero(0)`);
      validated = false;
    }
    if (Number(minimumAmount) === 0) {
      alert(`Minimum order size amount cannot be zero(0)`);
      validated = false;
    }
    if (Number(marginAmount) === 0) {
      alert(`Margin amount cannot be zero(0)`);
      validated = false;
    }
    if (Number(collateralAmount) === 0) {
      alert(`Collateral amount cannot be zero(0)`);
      validated = false;
    }
    if (Number(tradeFee) === 0) {
      alert(`Trade fee amount cannot be zero(0)`);
      validated = false;
    }
    if (brokerage === "Select Brokerage") {
      alert(`Select Brokerage`);
      validated = false;
    }
    return validated;
  };

  const handleCurrencyApprove = async (currencyAllowance) => {
    if (
      Number(currencyAllowance) < Number(currencyAmount) &&
      marginManagerAddress
    ) {
      await web3CurrencyContract.methods
        .approve(marginManagerAddress, currencyAmount)
        .send({ from: account, ...(chainId === 137 ? networkOptions : {}) })
        .then(async () => {})
        .catch((err) => {
          setLoading(false);
          err.message.includes("User denied transaction")
            ? toast.error("User Rejected Transaction", ToastOptions)
            : toast.error(
                "Transactin to approve currency token failed",
                ToastOptions
              );
        });
    }
  };

  const handleSecurityApprove = async (
    securityAllowance,
    currencyAllowance
  ) => {
    if (
      Number(securityAllowance) < Number(securityAmount) &&
      marginManagerAddress
    ) {
      await web3SecurityContract.methods
        .approve(marginManagerAddress, securityAmount)
        .send({ from: account, ...(chainId === 137 ? networkOptions : {}) })
        .then(async () => {
          await handleCurrencyApprove(currencyAllowance);
        })
        .catch((err) => {
          setLoading(false);
          err.message.includes("User denied transaction")
            ? toast.error("User Rejected Transaction", ToastOptions)
            : toast.error(
                "Transactin to approve security token failed",
                ToastOptions
              );
        });
    } else {
      await handleCurrencyApprove(currencyAllowance);
    }
  };

  const handleWhiteListAndApprove = async (
    securityAllowance,
    currencyAllowance
  ) => {
    setLoading(true);
    //TODO: confirm this
    //since whitelist has to be called before you can approve security
    // if allowance amount is higher should whitelist be ignored???
    if (marginManagerAddress) {
      await securityContract
        .whiteList(marginManagerAddress, securityAmount)
        .then(async (e) => {
          if (e.code === "ACTION_REJECTED") {
            setLoading(false);
            toast.error("User Rejected Transaction", ToastOptions);
          } else if (e.code === "UNPREDICTABLE_GAS_LIMIT") {
            setLoading(false);
            toast.error("WhiteList transaction failed", ToastOptions);
          } else {
            await handleSecurityApprove(securityAllowance, currencyAllowance);
          }
        })
        .catch((err) => {
          setLoading(false);
          toast.error("WhiteList transaction failed", ToastOptions);
        });
    }
  };

  const handleIssueProduct = async () => {
    const isValidated = validateParams();
    if (isValidated && marginManagerAddress) {
      const userKycStatus = await getKycStatus(address, chainId, signer);
      if (userKycStatus !== 3) {
        const data = await fetchSumsubAccessToken(account, chainId);
        props.setIssuedSecurityModal(false);
        props.setshowKYCModal(true);
        props.setAccessToken(data.accessToken);
        return;
      }
      const securityAllowance = await web3SecurityContract.methods
        .allowance(account, marginManagerAddress)
        .call();
      const currencyAllowance = await web3CurrencyContract.methods
        .allowance(account, marginManagerAddress)
        .call();
      const marginManagerContract = new MarginIssueManager(
        signer,
        marginManagerAddress
      );
      await handleWhiteListAndApprove(
        securityAllowance,
        currencyAllowance
      ).then(async () => {
        await marginManagerContract
          .issueProduct(
            props.security,
            web3.utils.asciiToHex(props.productCategory),
            props.currency,
            web3.utils.asciiToHex(brokerage),
            securityAmount,
            minimumAmount,
            currencyAmount,
            marginAmount,
            collateralAmount,
            tradeFee
          )
          .then((res) => {
            setLoading(false);
            if (res.code === "ACTION_REJECTED") {
              toast.error("User Rejected Transaction", ToastOptions);
            } else if (res.code === "UNPREDICTABLE_GAS_LIMIT") {
              toast.error("Issue Product transaction failed", ToastOptions);
            } else {
              const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${res.response.hash}`;
              toast.success(
                SuccessToast(
                  transactionLink,
                  "Margin Product Issued Succesfully"
                ),
                ToastOptions
              );
              history.push("/");
            }
          })
          .catch((err) => {
            setLoading(false);
            toast.error("Issue Product transaction failed", ToastOptions);
          });
      });
    }
  };
  return (
    <>
      {loading ? <Loader /> : null}
      <div className="d-grid gap-2" ref={modalContent}>
        <Row className="ml-1 align-items-center">
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 3 }} className="text-left">
              <b>{props.securitySymbol} Amount</b>
            </Col>
            <Col className="pl-0" xs={3.5}>
              <TextInput
                placeholder="Value of security tokens"
                fieldType="number"
                onChange={(e) =>
                  setSecurityAmount(web3.utils.toWei(e.target.value, "ether"))
                }
                required
              />
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 3 }} className="text-left">
              <b>{props.currencySymbol} Amount</b>
            </Col>
            <Col className="pl-0" xs={3.5}>
              <TextInput
                placeholder="Value of currency tokens"
                fieldType="number"
                onChange={async (e) => {
                  const amount = (
                    Number(e.target.value) *
                    10 **
                      Number(
                        await new web3.eth.Contract(
                          ERC20,
                          props.currency
                        ).methods
                          .decimals()
                          .call()
                      )
                  ).toString();
                  setCurrencyAmount(amount);
                }}
                required
              />
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 3 }} className="text-left">
              <b>Minimum Order Size</b>
            </Col>
            <Col className="pl-0" xs={3.5}>
              <TextInput
                placeholder="Value of Minimum Order"
                fieldType="number"
                onChange={(e) =>
                  setMinimumAmount(web3.utils.toWei(e.target.value, "ether"))
                }
                required
              />
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 3 }} className="text-left">
              <b>Margin Amount</b>
            </Col>
            <Col className="pl-0" xs={3.5}>
              <TextInput
                placeholder="Value of Margin in %"
                fieldType="number"
                onChange={(e) =>
                  setMarginAmount(web3.utils.toWei(e.target.value, "ether"))
                }
                required
              />
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 3 }} className="text-left">
              <b>Collateral Amount</b>
            </Col>
            <Col className="pl-0" xs={3.5}>
              <TextInput
                placeholder="Value of collateral in %"
                fieldType="number"
                onChange={(e) =>
                  setCollateralAmount(web3.utils.toWei(e.target.value, "ether"))
                }
                required
              />
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 3 }} className="text-left">
              <b>Trade Fee</b>
            </Col>
            <Col className="pl-0" xs={3.5}>
              <TextInput
                placeholder="Value of trade fee in %"
                fieldType="number"
                onChange={(e) =>
                  setTradeFee(web3.utils.toWei(e.target.value, "ether"))
                }
                required
              />
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 3 }} className="text-left">
              <b>Brokerage</b>
            </Col>
            <Col className="pl-0" xs={3.5}>
              <DropdownButton id="dropdown-basic-button" title={brokerage}>
                {BrokeragesList.map((Brk, idx) => (
                  <>
                    <Dropdown.Item
                      key={idx}
                      onClick={(e) => {
                        setBrokerage(e.target.textContent);
                      }}
                    >
                      {Brk}
                    </Dropdown.Item>
                  </>
                ))}
              </DropdownButton>
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col className="pl-0">
              <UiButton
                onClick={async () => {
                  handleIssueProduct();
                }}
                buttonVariant="primary"
                buttonClass="SignUpButton flex-1 ml-0"
                buttonText="Issue Product"
                type="submit"
              />
            </Col>
          </Row>
        </Row>
      </div>
    </>
  );
}

export default function CreateProduct(props) {
  return (
    <Route>
      <NewProduct {...props} />
    </Route>
  );
}
