import { Contract } from "@ethersproject/contracts";

import {
  rpcProviderService as _rpcProviderService,
  rpcProviderService,
} from "../rpc-provider/rpc-provider.service";
import { networkId } from "../../pages/poolDetail/balancer/useNetwork";
import { twentyFourHoursInSecs } from "../../pages/poolDetail/balancer/useTime";
import { configService } from "../config/config.service";
import { Network } from "../../utils/helpers";

export default class Web3Service {
  constructor(
    rpcProviderService = _rpcProviderService,
    config = configService
  ) {
    this.rpcProviderService = rpcProviderService;
    this.config = config;
    this.appProvider = this.rpcProviderService.jsonProvider;
    this.ensProvider = this.rpcProviderService.getJsonProvider(Network.MAINNET);
  }

  setUserProvider(provider) {
    this.userProvider = provider;
  }

  async getEnsName(address) {
    try {
      return await this.ensProvider.lookupAddress(address);
    } catch (error) {
      return null;
    }
  }

  async getProfile(address) {
    return {
      ens: await this.getEnsName(address),
      // avatar: await this.getEnsAvatar(address)
    };
  }

  async getUserAddress() {
    const signer = this.userProvider.getSigner();
    const userAddress = await signer.getAddress();
    return userAddress;
  }

  get blockTime() {
    switch (networkId) {
      case Network.MAINNET:
        return 13;
      case Network.POLYGON:
        return 2;
      case Network.ARBITRUM:
        return 3;
      case Network.KOVAN:
        // Should be ~4s but this causes subgraph to return with unindexed block error.
        return 1;
      default:
        return 13;
    }
  }

  async getTimeTravelBlock(period) {
    const currentBlock = await rpcProviderService.getBlockNumber();
    const blocksInDay = Math.round(twentyFourHoursInSecs / this.blockTime);

    switch (period) {
      case "24h":
        return currentBlock - blocksInDay;
      default:
        return currentBlock - blocksInDay;
    }
  }

  async callStatic(contractAddress, abi, action, params = [], options = {}) {
    console.log("Sending transaction");
    console.log("Contract", contractAddress);
    console.log("Action", `"${action}"`);
    console.log("Params", params);
    const signer = this.userProvider.getSigner();
    const contract = new Contract(contractAddress, abi, signer);
    const contractWithSigner = contract.connect(signer);
    return await contractWithSigner.callStatic[action](...params, options);
  }
}

export const web3Service = new Web3Service();
