import { useState, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import UiButton from "../../components/button";
import TextInput from "../../components/textinput/TextInput";
import Web3 from "web3";
import Loader from "../../components/loader";
import { useNetworkOptions } from "../../context/contractDataLoaderService";
import ERC20ABI from "../../utils/abi/ERC20.json";
import { Liquidity, contractAddress } from "@verified-network/verified-sdk";

import {
  SuccessToast,
  FailureToast,
  ToastOptions,
  networks,
} from "../../utils/constants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../utils/helpers/networks";
import { providers } from "ethers";

function IssueLiquidity(props) {
  const { address, chainId } = useAccount();
  const { data: client } = useConnectorClient({ chainId });
  const { chain, transport } = client || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3, liquidityContract;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    liquidityContract = new Liquidity(
      provider.getSigner(address),
      contractAddress[chainId].Liquidity
    );
    web3 = getWeb3(transport);
  }
  const [showModal, setShowModal] = useState(false);
  const [lpToIssue, setLpToIssue] = useState("");
  const [loading, setLoading] = useState(false);
  const [changeContent, setChangeContent] = useState(false);
  const modalContent = useRef(null);
  const { data: networkOptions } = useNetworkOptions();

  const vittaContractAddress = contractAddress[chainId].Vitta;

  const issueLiquidity = async () => {
    let tokenAddress, investorAddress, tokenAmount;
    tokenAddress = props.data.tokenAddress;
    investorAddress = props.data.address;
    tokenAmount = props.data.amountInvestedCurrencyFixed;

    if (lpToIssue.length == 0) {
      alert("Enter lp to issue amount");
    }
    console.log("tokenAmount", tokenAmount, lpToIssue);

    const vittaContract = new web3.eth.Contract(ERC20ABI, vittaContractAddress);
    setLoading(true);
    try {
      await vittaContract.methods
        .approve(liquidityContract.contractAddress, lpToIssue)
        .send({ ...(chainId === 137 ? networkOptions : {}), from: address });
    } catch (e) {
      toast.error("Transaction rejected by user!", ToastOptions);
      setLoading(false);
      return;
    }

    try {
      const tx = await liquidityContract.issue(
        investorAddress,
        tokenAddress,
        tokenAmount,
        lpToIssue,
        networkOptions
      );
      if (tx.code === "ACTION_REJECTED") {
        toast.error("Transaction rejected by user!", ToastOptions);
      } else {
        const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${tx.response.hash}`;
        toast.success(SuccessToast(transactionLink), ToastOptions);
        setChangeContent(true);
        modalContent.current.innerHTML = "";
      }
      setLoading(false);
    } catch (err) {
      let error = { err };
      console.log(error);
      const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${error.err.transactionHash}`;
      setLoading(false);
      toast.error(FailureToast(transactionLink), ToastOptions);
    }
  };
  return (
    <>
      {loading ? <Loader /> : null}
      {changeContent && (
        <UiButton
          onClick={() => {
            props.onModalHide();
          }}
          buttonVariant="primary"
          buttonClass="SignUpButton flex-1 ml-0"
          buttonText="Click to close this form&nbsp;&rarr;"
          type="submit"
        />
      )}
      <div className="d-grid gap-2" ref={modalContent}>
        <Row className="ml-1 align-items-center">
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Lp to issue</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <TextInput
                placeholder="Amount"
                fieldType="number"
                onChange={(e) =>
                  setLpToIssue(web3.utils.toWei(e.target.value, "ether"))
                }
              />
            </Col>
          </Row>
          <Row className="mx-0 my-2 pl-0 width-100 align-items-center">
            <Col className="pl-0">
              <UiButton
                onClick={() => {
                  issueLiquidity();
                }}
                buttonVariant="primary"
                buttonClass="SignUpButton flex-1 ml-0"
                buttonText="Issue Liquidity"
                type="submit"
              />
            </Col>
          </Row>
        </Row>
      </div>
    </>
  );
}

export function AddLiquidity(props) {
  return <IssueLiquidity {...props} />;
}
