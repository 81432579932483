import React from "react";
import KyberPoolDetail from "./kyber";
import PoolData from "./poolData";

const PoolDetail = (props) => {
  return props.match.params.type === "kyber" ? (
    <KyberPoolDetail {...props} />
  ) : (
    <PoolData {...props} />
  );
};

export default PoolDetail;
