import { useState, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import UiButton from "../../components/button";
import Loader from "../../components/loader";
import TextInput from "../../components/textinput/TextInput";
import Web3 from "web3";
import { Client, contractAddress } from "@verified-network/verified-sdk";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { signMessage } from "../../utils/response";
import { countryList, getCountryName } from "../../utils/constants";
import { useNetworkOptions } from "../../context/contractDataLoaderService";
import {
  SuccessToast,
  FailureToast,
  ToastOptions,
  networks,
} from "../../utils/constants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../utils/helpers/networks";
import { providers } from "ethers";

const ISSUER_ROLES = ["DP", "AM", "KYCAML", "Custodian", "Admin", "Issuer"];
const NULL_ADDRESS = "0x0000000000000000000000000000000000000000";

function AddManager(props) {
  const { address, chainId } = useAccount();
  const { data: client } = useConnectorClient({ chainId });
  const { chain, transport } = client || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3, clientContract;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    clientContract = new Client(
      provider.getSigner(address),
      contractAddress[chainId].Client
    );
    web3 = getWeb3(transport);
  }
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [managerAddress, setManagerAddress] = useState("");
  const [country, setCountry] = useState("Select a country");
  const [role, setRole] = useState("Select a role");
  const [id, setId] = useState("");
  const [changeContent, setChangeContent] = useState(false);
  const modalContent = useRef(null);
  const { data: networkOptions } = useNetworkOptions();

  const assignManager = async () => {
    let subManagerAddress;
    subManagerAddress = props.data.address;

    if (country === "Select a country") {
      alert("Enter Country name");
    } else if (role === "Select a role") {
      alert("Enter proper Role");
    } else if (id.length == 0) {
      alert("Enter Id");
    }
    setLoading(true);
    let hashData;
    try {
      if (provider && provider.getSigner) {
        hashData = await signMessage(provider.getSigner(address));
      }
    } catch (e) {
      toast.error("Transaction rejected by user!", ToastOptions);
      setLoading(false);
      return;
    }
    try {
      const tx = await clientContract.addRole(
        NULL_ADDRESS,
        subManagerAddress,
        country,
        role,
        id,
        hashData.messageHash,
        hashData.v,
        hashData.r,
        hashData.s,
        networkOptions
      );
      if (tx.code === "ACTION_REJECTED") {
        toast.error("Transaction rejected by user!", ToastOptions);
      } else {
        const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${tx.response.hash}`;
        toast.success(SuccessToast(transactionLink), ToastOptions);
        setChangeContent(true);
        modalContent.current.innerHTML = "";
      }
      setLoading(false);
    } catch (err) {
      let error = { err };
      console.log(error);
      const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${error.err.transactionHash}`;
      setLoading(false);
      toast.error(FailureToast(transactionLink), ToastOptions);
    }
  };
  return (
    <>
      {loading ? <Loader /> : null}
      {changeContent && (
        <UiButton
          onClick={() => {
            props.onModalHide();
          }}
          buttonVariant="primary"
          buttonClass="SignUpButton flex-1 ml-0"
          buttonText="Click to close this form&nbsp;&rarr;"
          type="submit"
        />
      )}
      <div className="d-grid gap-2" ref={modalContent}>
        <Row className="ml-1 align-items-center">
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Country</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <DropdownButton
                id="dropdown-basic-button"
                title={country ? getCountryName(country) : "Select country"}
              >
                {countryList?.map((country) => (
                  <Dropdown.Item onClick={(e) => setCountry(country.code)}>
                    {country.name}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Role</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <DropdownButton id="dropdown-basic-button" title={role}>
                {ISSUER_ROLES?.map((role, index) => (
                  <Dropdown.Item key={index} onClick={(e) => setRole(role)}>
                    {role}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Id</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <TextInput
                placeholder="Id"
                required
                onChange={(e) => setId(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mx-0 my-2 pl-0 width-100 align-items-center">
            <Col className="pl-0">
              <UiButton
                onClick={() => {
                  assignManager();
                }}
                buttonVariant="primary"
                buttonClass="SignUpButton flex-1 ml-0"
                buttonText="Assign Manager"
                type="submit"
              />
            </Col>
          </Row>
        </Row>
      </div>
    </>
  );
}

export function AssignManager(props) {
  return <AddManager {...props} />;
}
