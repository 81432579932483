import React, { useState } from "react";
import Loader from "../../../components/loader";
import { useBalancerPoolsData } from "../../../context/balancerPoolData";
import { PoolType } from "../../poolDetail/balancer/types";
import Web3 from "web3";

import {
  SecondaryIssueManager,
  contractAddress,
} from "@verified-network/verified-sdk";
import { useGlobal } from "../../../context/globalContext";
import UiTable from "../../../components/table/Table";
import { useNetworkOptions } from "../../../context/contractDataLoaderService";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../../utils/helpers/networks";
import { providers } from "ethers";

function ServicerHome(props) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const { data, loading } = useBalancerPoolsData(PoolType.SecondaryIssue);
  const filteredData = data.filter(
    (pool) =>
      pool.verifiedWalletData?.issuer?.id.toLowerCase() ===
        account.toLowerCase() &&
      !pool.verifiedWalletData?.subscriptionsClosed.length
  );
  const { data: networkOptions } = useNetworkOptions();
  const { userRole } = useGlobal();
  let secondaryIssueManagerContract;

  if (provider && provider.getSigner && chainId && address) {
    if (contractAddress[chainId].BalancerSecondaryIssueManager) {
      secondaryIssueManagerContract = new SecondaryIssueManager(
        provider.getSigner(address),
        contractAddress[chainId].BalancerSecondaryIssueManager
      );
    }
  }

  let headers = [
    { label: "Security", val: "security" },
    { label: "Currency", val: "currency" },
    { label: "Start Date", val: "createTime" },
    { label: "Call Action", val: "transactionCallAction" },
  ];

  const options = {
    onClick: (event, row) => {
      onInvest(row);
    },
  };

  const onInvest = (row) => {
    props.history.push(
      `/transactions/secondary_investors/${row.poolType}/${row.security}`
    );
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <section className="d-flex flex-column align-items-start px-0 py-4">
        <div className="mb-3 d-flex justify-content-between w-100 align-items-center">
          <div className="w-100">
            <h3 className="text-left">Transactions</h3>
          </div>
        </div>
        <div className="pools-table width-100">
          <UiTable
            thead={headers}
            tbodyData={filteredData || []}
            hover
            bordered={false}
            userRole={userRole}
            rowEvents={options}
            web3={web3}
            account={account}
            chainId={chainId}
            networkOptions={networkOptions}
            contract={secondaryIssueManagerContract}
          />
        </div>
      </section>
    </>
  );
}

export default function SecondaryTransactions(props) {
  return <ServicerHome {...props} />;
}
