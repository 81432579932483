import { rpcProviderService as _rpcProviderService } from "../rpc-provider/rpc-provider.service";
import { Network } from "../../utils/helpers";
import { networkId } from "../../pages/poolDetail/balancer/useNetwork";

import { balancerSubgraphClient } from "./balancer-subgraph.client";
import Pools from "./entities/pools";

export default class BalancerSubgraphService {
  constructor(
    client = balancerSubgraphClient,
    rpcProviderService = _rpcProviderService
  ) {
    // Init entities
    this.client = client;
    this.rpcProviderService = rpcProviderService;
    this.pools = new Pools(this);
  }

  get blockTime() {
    switch (networkId) {
      case Network.MAINNET:
        return 13;
      case Network.POLYGON:
        return 2;
      case Network.ARBITRUM:
        return 3;
      case Network.KOVAN:
        // Should be ~4s but this causes subgraph to return with unindexed block error.
        return 1;
      default:
        return 13;
    }
  }
}

export const balancerSubgraphService = new BalancerSubgraphService();
