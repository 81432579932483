import React, { useState, useEffect } from "react";
import VerticallyModal from "../../components/modal/VerticallyModal";
import { Row, Button } from "react-bootstrap";

function ConnectModal(props) {
  const [showModal, setShowModal] = useState(true);

  return (
    <>
      <VerticallyModal
        key="connectProvider"
        showModal={showModal}
        modalSize={"xs"}
        modalOnHide={() => {
          props.setshowKYCModal(false);
        }}
        // closeButton={false}
        modalHeading="Verify Your Identity"
        withFooter={false}
      >
        <div className="d-grid gap-2">
          Thanks for completing your KYC. You will receive an email after your
          KYC submission is approved.
        </div>
      </VerticallyModal>
    </>
  );
}

export default function HelperModal(props) {
  return <ConnectModal {...props} />;
}
