import { getAddress } from "@ethersproject/address";

// import { balancerContractsService } from '@/services/balancer/contracts/balancer-contracts.service';
import { configService as _configService } from "../../../config/config.service";
// import { PoolDecorator } from '@/services/pool/decorators/pool.decorator';
// import PoolService from '@/services/pool/pool.service';

import queryBuilder from "./query";
import { twentyFourHoursInSecs } from "../../../../pages/poolDetail/balancer/useTime";

export default class Pools {
  constructor(
    service,
    query = queryBuilder,
    configService = _configService
    // poolServiceClass = PoolService,
    // balancerContracts = balancerContractsService,
    // poolDecoratorClass = PoolDecorator
  ) {
    this.configService = configService;
    // this.poolServiceClass = poolServiceClass;
    // this.balancerContracts = balancerContracts;
    // this.poolDecoratorClass = poolDecoratorClass;
    this.service = service;
    this.query = query;
    this.networkId = configService.env.NETWORK;
  }

  async get(args = {}, attrs = {}) {
    const query = this.query(args, attrs);
    const data = await this.service.client.get(query);
    return data.pools;
  }

  async decorate(pools, period, prices, currency, gauges, tokens) {
    // Get past state of pools
    const poolDecorator = new this.poolDecoratorClass(pools);

    return await poolDecorator.decorate(
      gauges,
      prices,
      currency,
      tokens
    );
  }

  async timeTravelBlock(period) {
    const currentBlock = await this.service.rpcProviderService.getBlockNumber();
    const blocksInDay = Math.round(
      twentyFourHoursInSecs / this.service.blockTime
    );

    switch (period) {
      case "24h":
        return currentBlock - blocksInDay;
      default:
        return currentBlock - blocksInDay;
    }
  }

  addressFor(poolId) {
    return getAddress(poolId.slice(0, 42));
  }
}
