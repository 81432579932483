import React from "react";
import { Card, Row } from "react-bootstrap";
import { useChartData } from "../../context/contractDataLoaderService";
import { useVerifiedSecurity } from "../../context/verifiedWalletData";

const PoolValues = (props) => {
  let { data: verifiedSecurity } = useVerifiedSecurity(props.pool.security);
  const {
    totalVolume: totalVolume,
    ltp: ltp,
    loading: loading,
  } = useChartData(verifiedSecurity, props.pool);

  const symbol = props.pool.tokens?.filter(
    (data) => data.address === props.pool.currency
  )[0].symbol;
  return (
    <Row className="mx-0 justify-content-between mb-4">
      <Card className="p-3 shadow mr-3 border-sm flex-1">
        <div className="text-black-50 mb-1 fs-7">Total Volume</div>
        <div className="fw-bold fs-6">
          {props.totalVolume
            ? Number(props.totalVolume).toFixed(6)
            : totalVolume?.toFixed(4)}
        </div>
      </Card>
      <Card className="p-3 shadow mr-3 border-sm flex-1">
        <div className="text-black-50 mb-1 fs-7">Current Price</div>
        <div className="fw-bold fs-6">
          {props.currentPrice
            ? Number(props.currentPrice).toFixed(2)
            : ltp.at(-1)
            ? Number(ltp.at(-1).price).toFixed(8)
            : 0.0}{" "}
          {symbol}
        </div>
      </Card>
    </Row>
  );
};

export default PoolValues;
