import { useState, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import Loader from "../../components/loader";
import UiButton from "../../components/button";
import TextInput from "../../components/textinput/TextInput";
import Web3 from "web3";
import { Liquidity, contractAddress } from "@verified-network/verified-sdk";

import ERC20ABI from "../../utils/abi/ERC20.json";
import {
  useSupportedTokens,
  useNetworkOptions,
} from "../../context/contractDataLoaderService";
import {
  SuccessToast,
  FailureToast,
  ToastOptions,
  networks,
} from "../../utils/constants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../utils/helpers/networks";
import { providers } from "ethers";

function ProvidesLiquidity(props) {
  const { address, chainId } = useAccount();
  const { data: client } = useConnectorClient({ chainId });
  const { chain, transport } = client || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3, liquidityContract;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    liquidityContract = new Liquidity(
      provider.getSigner(address),
      contractAddress[chainId].Liquidity
    );
    web3 = getWeb3(transport);
  }
  const [showModal, setShowModal] = useState(false);
  let { data } = useSupportedTokens();
  const { data: networkOptions } = useNetworkOptions();
  const [liquidityAmount, setLiquidityAmount] = useState("");
  const [tokenAmount, setTokenAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [changeContent, setChangeContent] = useState(false);
  const modalContent = useRef(null);

  const provideLiquidity = async () => {
    if (liquidityAmount.length == 0) {
      alert("Enter liquidity amount");
    } else if (tokenAmount.length == 0) {
      alert("Enter token amount");
    }
    const selectedToken = data.find(
      (element) => element.tokenAddress === props.data.tokenAddress
    );
    console.log("selectedToken", selectedToken);
    const tokenContract = new web3.eth.Contract(
      ERC20ABI,
      props.data.tokenAddress
    );
    const tokenDecimals = await tokenContract.methods.decimals().call();
    const amountFixed = String(tokenAmount * 10 ** tokenDecimals);

    setLoading(true);
    try {
      const tx = await liquidityContract.provideLiquidity(
        props.platformId,
        props.data.managerAddress,
        liquidityAmount,
        props.data.tokenAddress,
        amountFixed,
        networkOptions
      );
      if (tx.code === "ACTION_REJECTED") {
        toast.error("Transaction rejected by user!", ToastOptions);
      } else {
        const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${tx.response.hash}`;
        toast.success(SuccessToast(transactionLink), ToastOptions);
        setChangeContent(true);
        modalContent.current.innerHTML = "";
      }
      setLoading(false);
    } catch (err) {
      let error = { err };
      console.log(error);
      const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${error.err.transactionHash}`;
      setLoading(false);
      toast.error(FailureToast(transactionLink), ToastOptions);
    }
  };
  return (
    <>
      {loading ? <Loader /> : null}
      {changeContent && (
        <UiButton
          onClick={() => {
            props.onModalHide();
          }}
          buttonVariant="primary"
          buttonClass="SignUpButton flex-1 ml-0"
          buttonText="Click to close this form&nbsp;&rarr;"
          type="submit"
        />
      )}
      <div className="d-grid gap-2" ref={modalContent}>
        <Row className="ml-1 align-items-center">
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Token amount</b>
              <div>
                Balance:{" "}
                {Number(props.data.liquidityContractBalance).toFixed(2) +
                  " " +
                  props.data.tokenName}
              </div>
            </Col>
            <Col className="pl-0" xs={5}>
              <TextInput
                placeholder={props.data.tokenName + " amount"}
                fieldType="number"
                required
                onChange={(e) => setTokenAmount(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Liquidity amount</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <TextInput
                placeholder="Amount"
                fieldType="number"
                required
                onChange={(e) =>
                  setLiquidityAmount(web3.utils.toWei(e.target.value, "ether"))
                }
              />
            </Col>
          </Row>
          <Row className="mx-0 my-2 pl-0 width-100 align-items-center">
            <Col className="pl-0">
              <UiButton
                onClick={() => {
                  provideLiquidity();
                }}
                buttonVariant="primary"
                buttonClass="SignUpButton flex-1 ml-0"
                buttonText="Provide Liquidity"
                type="submit"
              />
            </Col>
          </Row>
        </Row>
      </div>
    </>
  );
}

export function ProvideLiquidity(props) {
  return <ProvidesLiquidity {...props} />;
}
