import { SwapType, Swaps } from "@balancer-labs/sdk";
import ERC20ABI from "../../../utils/abi/ERC20.json";
import { balancerVault, networks } from "../../../utils/constants";
import { contractAddress } from "@verified-network/verified-sdk";
import Web3 from "web3";
import BigNumber from "bignumber.js";
import { getWeb3 } from "../../../utils/helpers/networks";
import {
  SuccessToast,
  FailureToast,
  ToastOptions,
  DefaultTokenPair,
} from "../../../utils/constants";
import { toast } from "react-toastify";
import { ethers } from "ethers";

export const paraSwapTransaction = async (
  priceRoute,
  account,
  provider,
  paraSwap,
  web3,
  chainId,
  networkOptions
) => {
  console.log("Inside paraSwapTransaction func");
  const tokenTransfered = new web3.eth.Contract(ERC20ABI, priceRoute.srcToken);
  await tokenTransfered.methods
    .approve(priceRoute.tokenTransferProxy, priceRoute.srcAmount)
    .send({ ...(chainId === 137 ? networkOptions : {}), from: account });

  const txParams = await paraSwap.swap.buildTx({
    srcToken: priceRoute.srcToken,
    destToken: priceRoute.destToken,
    srcAmount: priceRoute.srcAmount,
    destAmount: priceRoute.destAmount,
    priceRoute,
    userAddress: account,
    partner: account,
  });
  console.log("txParams", txParams);
  const transaction = {
    ...txParams,
    gasPrice: "0x" + new BigNumber(txParams.gasPrice).toString(16),
    gasLimit: "0x" + new BigNumber(1000000).toString(16),
    value: "0x" + new BigNumber(txParams.value).toString(16),
  };

  console.log(
    "transaction paraSwapTransaction func",
    transaction,
    priceRoute,
    web3
  );
  const tx = {
    from: account,
    to: transaction.to,
    data: transaction.data,
    gasLimit: transaction.gasLimit,
    value: transaction.value,
    chainId: transaction.chainId,
    gasPrice: transaction.gasPrice,
  };

  try {
    const transactionData = await provider
      .getSigner(account)
      .sendTransaction(tx);
    const pendingTransaction = await transactionData.wait();
    if (pendingTransaction?.transactionHash) {
      return true;
    }
  } catch (err) {
    let error = { err };
    return false;
  }
};

export const runPrimaryBatchSwap = async (
  pool_id,
  pool,
  transport,
  provider,
  chainId,
  account,
  swapType,
  tokenSymbol,
  qty,
  loading,
  swapValue,
  paraSwap,
  priceRoute,
  networkOptions
) => {
  if (transport) {
    const web3 = getWeb3(transport);
    const securityToken = pool.securityToken;
    let selectedToken,
      otherToken,
      cashTokenAddress,
      securityTokenAddress,
      assetIn,
      assetOut,
      limitArr,
      amount,
      swapAmountCalculated,
      securityContract,
      cashContract;

    const tokensList = pool.tokensList;

    limitArr = new Array(tokensList.length).fill(0);

    const getSelectedToken = async () => {
      pool.tokens.map((token) => {
        if (token.symbol === tokenSymbol) {
          selectedToken = token;
        }
      });

      const data = pool.tokens.filter((token) => {
        return token.address !== selectedToken.address;
      });
      otherToken = data[0];

      if (securityToken === selectedToken.address) {
        cashTokenAddress = otherToken.address;
        securityTokenAddress = selectedToken.address;

        securityContract = new web3.eth.Contract(
          ERC20ABI,
          securityTokenAddress
        );
        cashContract = new web3.eth.Contract(ERC20ABI, cashTokenAddress);
        let paraSwapValue;
        const currencyDecimals = await cashContract.methods.decimals().call();
        swapAmountCalculated = ethers.utils.parseUnits(
          swapValue.toString(),
          Number(currencyDecimals)
        );
        if (priceRoute) {
          paraSwapValue = Number(priceRoute.destAmount);
        }

        if (
          swapType === "Buy" &&
          priceRoute &&
          swapAmountCalculated <= paraSwapValue
        ) {
          swapAmountCalculated = paraSwapValue;
        }
        amount = web3.utils.toWei(qty, "ether");
        if (swapType === "Sell") {
          assetIn = tokensList.indexOf(securityTokenAddress);
          assetOut = tokensList.indexOf(cashTokenAddress);
          limitArr[assetIn] = amount;
        } else if (swapType === "Buy") {
          assetIn = tokensList.indexOf(cashTokenAddress);
          assetOut = tokensList.indexOf(securityTokenAddress);
          limitArr[assetIn] = swapAmountCalculated;
        }
      }
    };

    // console.log("will continue.....");

    if (pool.tokens) {
      await getSelectedToken();
    }

    // console.log("priceRoute", priceRoute);
    if (swapType === "Buy" && priceRoute) {
      const txDetail = await paraSwapTransaction(
        priceRoute,
        account,
        provider,
        paraSwap,
        web3,
        chainId,
        networkOptions
      );
      if (!txDetail) return 0;
    }

    // console.log(
    //   "In",
    //   assetIn,
    //   "Out",
    //   assetOut,
    //   "Limit",
    //   limitArr,
    //   tokensList,
    //   amount
    // );

    const receivingContract =
      swapType === "Buy" ? securityContract : cashContract;
    const receivingTokenSymbol =
      swapType === "Buy" ? selectedToken.symbol : otherToken.symbol;
    const receivingTokenDecimals = await receivingContract.methods
      .decimals()
      .call();
    const balanceReceivingToken = await receivingContract.methods
      .balanceOf(account)
      .call();

    const encodedBatchSwapData = Swaps.encodeBatchSwap({
      kind: swapType === "Sell" ? SwapType.SwapExactIn : SwapType.SwapExactOut,
      swaps: [
        {
          poolId: pool_id,
          assetInIndex: assetIn,
          assetOutIndex: assetOut,
          amount: amount,
          userData: "0x",
        },
      ],
      assets: tokensList,
      funds: {
        fromInternalBalance: false,
        recipient: account,
        sender: account,
        toInternalBalance: false,
      },
      limits: limitArr,
      deadline: "999999999999999999", // Infinity
    });

    const tx = {
      from: account,
      to: balancerVault,
      data: encodedBatchSwapData,
      ...networkOptions,
    };
    const primaryIssueManager =
      contractAddress[chainId].BalancerPrimaryIssueManager;
    if (swapType === "Buy") {
      await cashContract.methods
        .approve(balancerVault, swapAmountCalculated)
        .send({ ...(chainId === 137 ? networkOptions : {}), from: account })
        .then(async (res) => {
          loading = true;
          //Code for Approval to primaryIssueManager to reject back tokens
          const allowance = await securityContract.methods
            .allowance(account, primaryIssueManager)
            .call();
          const amountApprove = new BigNumber(allowance).plus(amount);

          await securityContract.methods
            .approve(primaryIssueManager, amountApprove.toString())
            .send({ ...(chainId === 137 ? networkOptions : {}), from: account })
            .then((res) => {
              loading = true;
            })
            .catch((e) => {
              if (e.code === 4001) {
                loading = false;
                return 0;
              }
            });
        })
        .catch((e) => {
          loading = false;
          toast.error("Transaction Failed", ToastOptions);
        });
    } else if (swapType === "Sell") {
      // loading = true;
      await securityContract.methods
        .approve(balancerVault, amount)
        .send({ ...(chainId === 137 ? networkOptions : {}), from: account })
        .then((res) => {
          loading = true;
        })
        .catch((e) => {
          loading = false;
          toast.error("Transaction Failed", ToastOptions);
        });
    }

    if (loading) {
      try {
        let transactionData = await provider
          .getSigner(account)
          .sendTransaction(tx);
        const pendingTransaction = await transactionData.wait();
        if (pendingTransaction?.transactionHash) {
          // console.log("Transaction Mined!!! Join Pool Vault.", pendingTransaction);
          const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${pendingTransaction.transactionHash}`;
          toast.success(SuccessToast(transactionLink), ToastOptions);
        } else {
          toast.success("Transaction Successful", ToastOptions);
        }
        if (swapType === "Sell" && priceRoute) {
          // await paraSwapTransaction(priceRoute, account, provider, paraSwap, web3, chainId, networkOptions);
          const txDetail = await paraSwapTransaction(
            priceRoute,
            account,
            provider,
            paraSwap,
            web3,
            chainId,
            networkOptions
          );
          if (!txDetail) return 0;
        }
        // Code to add receiving token
        if (
          Number(balanceReceivingToken) <= 0 &&
          provider &&
          provider?.request
        ) {
          await provider.request({
            method: "wallet_watchAsset",
            params: {
              type: "ERC20",
              options: {
                address: receivingContract._address,
                symbol: receivingTokenSymbol.substring(0, 10),
                decimals: receivingTokenDecimals,
              },
            },
          });
        }
      } catch (err) {
        console.log("error", err);
        if (err && err.transactionHash) {
          toast.error("Transaction Failed", ToastOptions);
        }
        return;
      }
    }
  }
};
