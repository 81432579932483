import React, { useState, useRef, useEffect } from "react";
import Loader from "../../components/loader";
import UiTable from "../../components/table/Table";

import { Row, Col } from "react-bootstrap";
import UiButton from "../../components/button";
import { useGlobal, fetchSumsubAccessToken } from "../../context/globalContext";
import { toast } from "react-toastify";
import VerticallyModal from "../../components/modal/VerticallyModal";
import Web3 from "web3";
import {
  Liquidity,
  contractAddress,
  Client,
} from "@verified-network/verified-sdk";
import {
  useLiquidityIssuesData,
  useNetworkOptions,
  useSupportedTokens,
  getOwnerDetails,
  useIntermediaryList,
  useCustodianData,
} from "../../context/contractDataLoaderService";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import {
  SuccessToast,
  FailureToast,
  ToastOptions,
  networks,
  getCountryCode,
  NULL_ADDRESS,
} from "../../utils/constants";
import ERC20ABI from "../../utils/abi/ERC20.json";
import KYCModal from "../../components/common/kycModal";
import TextInput from "../../components/textinput/TextInput";
import WalletConnect from "../../components/common/walletConnect/index";
import PaymentModal from "../../pages/custodian/Payments/PaymentModal";
import paymentGateway from "../../pages/custodian/api/PaymentGateway";
import { useHistory } from "react-router-dom";
import { ethers, providers } from "ethers";
import config from "../../services/config/homestead.json";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../utils/helpers/networks";
import { getKycStatus } from "../../utils/helpers";

function PurchaseLiquidity(props) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [showModal, setShowModal] = useState(false);
  const { kycStatus, userRole } = useGlobal();
  const { data } = useLiquidityIssuesData(userRole);
  const [loading, setLoading] = useState(false);
  const [tokenName, setTokenName] = useState("Select Token");
  const [accountCountry, setAccountCountry] = useState(null);
  const [tokenSelected, setTokenSelected] = useState(null);
  const [custodian, setCustodian] = useState(null);
  const [tokenAmount, setTokenAmount] = useState("");
  const [changeContent, setChangeContent] = useState(false);
  const [showKYCModal, setshowKYCModal] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [paymentModalVisibility, setPaymentModalVisibility] = useState(false);
  const [paymentResponse, setPaymentResponse] = useState(null);
  const { data: networkOptions } = useNetworkOptions();
  let { data: currencySupported } = useSupportedTokens();
  let { data: custodianDetail } = useIntermediaryList(
    accountCountry,
    "Custodian"
  );
  const { data: custodianData } = useCustodianData(custodian);
  const history = useHistory();
  // console.log("cust..: ", custodianDetail, accountCountry);
  let liquidityContract, clientContract;
  if (provider && provider.getSigner && chainId && address && transport) {
    liquidityContract = new Liquidity(
      provider.getSigner(address),
      contractAddress[chainId].Liquidity
    );
    clientContract = new Client(
      provider.getSigner(address),
      contractAddress[chainId].Client
    );
  }

  useEffect(() => {
    const fetchOwnerDetails = async () => {
      try {
        if (clientContract) {
          const details = await getOwnerDetails(account, clientContract);
          const country = getCountryCode(details.ownerCountry, "ALL");
          setAccountCountry(country);
        }
      } catch (error) {
        console.error("Error fetching owner details:", error);
      }
    };
    fetchOwnerDetails();
  }, [account, currencySupported, clientContract]);

  useEffect(() => {
    if (custodianDetail?.length) {
      setCustodian(custodianDetail[0].address);
    }
  }, [custodianDetail, accountCountry]);

  const currencyList = custodianData?.map((data) => {
    return {
      isFiat: data.fiat,
      balance: data.cashBalance,
      tokenAddress: data.issueCurrencyAddress,
      tokenName: data.issueCurrency,
    };
  });

  let currencySupportedList;
  if (currencySupported) {
    currencySupportedList = [
      ...currencySupported?.filter((token) => !token.isFiat),
    ];
    if (currencyList) {
      currencySupportedList.push(...currencyList);
    }
  }

  let headers = [
    { label: "Token", val: "tokenName" },
    { label: "Amount invested", val: "amountInvested" },
    { label: "Liquidity tokens", val: "liquidityTokens" },
  ];
  if (userRole === "AM") {
    headers.splice(3, 0, { label: "Platform funded", val: "platformName" });
    headers.push({ label: "Returns", val: "returns" });
  }
  const modalContent = useRef(null);

  const handleModalHide = () => {
    setShowModal(false);
    setChangeContent(false);
  };

  const clearQueryParams = () => {
    history.push({
      search: "",
    });
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const redirectStatus = searchParams.get("redirect_status");

    switch (redirectStatus) {
      case "succeeded":
        toast.success(SuccessToast("", "Payment Successful!"), ToastOptions);
        clearQueryParams();
        break;
      case "failed":
        toast.error(FailureToast("", "Payment Unsuccessful!"), ToastOptions);
        clearQueryParams();
        break;
      default:
        break;
    }
  });

  const onlineTopUp = async () => {
    const selectedFiatCurrency = custodianData?.find(
      (data) => data.issueCurrencyAddress === tokenSelected.tokenAddress
    );

    // Code for creating cash request for Fiat Payment
    try {
      setLoading(true);
      await paymentGateway
        .createCashIssueRequest(
          selectedFiatCurrency.country,
          account,
          selectedFiatCurrency.accountId,
          tokenAmount,
          selectedFiatCurrency.currency,
          selectedFiatCurrency.issueCurrencyAddress,
          chainId
        )
        .then((result) => {
          setLoading(false);
          if (result.error) {
            toast.error(result.error, ToastOptions);
            console.log("error: ", result.error);
          } else {
            setShowModal(false);
            setPaymentModalVisibility(true);
            setPaymentResponse(result);
          }
        });
    } catch (error) {
      setLoading(false);
      toast.error("Bank Top up Failed", ToastOptions);
      console.log("Error", error);
    }
  };

  const buyLiquidity = async () => {
    const userKycStatus = await getKycStatus(address, chainId, signer);
    // console.log("kyc: ", userKycStatus);
    if (userKycStatus !== 3) {
      const data = await fetchSumsubAccessToken(account, chainId);
      // console.log("data: ", data)
      setShowModal(false);
      setshowKYCModal(true);
      setAccessToken(data.accessToken);
      return;
    }

    if (!tokenSelected) {
      toast.error(`Select a token!`, ToastOptions);
      return;
    }
    if (tokenAmount.length === 0) {
      toast.error(`Amount can't be empty!`, ToastOptions);
      return;
    }
    let tokenContract, currencyDecimals, currencyAmountFixed;

    if (tokenSelected.tokenAddress !== ethers.constants.AddressZero) {
      tokenContract = new web3.eth.Contract(
        ERC20ABI,
        tokenSelected.tokenAddress
      );
      currencyDecimals = await tokenContract.methods.decimals().call();
      currencyAmountFixed = String(tokenAmount * 10 ** currencyDecimals);
    } else {
      currencyAmountFixed = String(
        tokenAmount * 10 ** networks[chainId].nativeCurrency.decimals
      );
    }
    const currencyBalance = tokenSelected.balance;

    if (Number(currencyBalance) < Number(tokenAmount)) {
      toast.error("Insufficient currency balance!", ToastOptions);
      return;
    }

    setLoading(true);
    if (tokenSelected.tokenAddress === ethers.constants.AddressZero) {
      const amountToSend = ethers.utils.parseEther(tokenAmount);
      const transactionObject = {
        from: account,
        to: contractAddress[chainId].Liquidity,
        value: amountToSend,
        ...networkOptions,
      };
      try {
        const transaction = await signer.sendTransaction(transactionObject);
        let pendingTransaction = await transaction.wait();
        let transactionLink;
        if (pendingTransaction?.transactionHash) {
          transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${pendingTransaction.transactionHash}`;
          toast.success(SuccessToast(transactionLink), ToastOptions);
          setChangeContent(true);
          modalContent.current.innerHTML = "";
        }
      } catch (e) {
        console.log("Error", e);
        toast.error("Transaction Failed");
      }
      setLoading(false);
      return;
    }
    if (tokenSelected.tokenAddress !== ethers.constants.AddressZero) {
      try {
        await tokenContract.methods
          .approve(liquidityContract.contractAddress, currencyAmountFixed)
          .send({ ...(chainId === 137 ? networkOptions : {}), from: account });
      } catch (e) {
        toast.error("Transaction rejected by user!", ToastOptions);
        setLoading(false);
        return;
      }
    }
    try {
      const tx = await liquidityContract.buy(
        tokenSelected.tokenAddress,
        currencyAmountFixed,
        networkOptions
      );
      if (tx.code === "ACTION_REJECTED") {
        toast.error("Transaction rejected by user!", ToastOptions);
      } else {
        const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${tx.response.hash}`;
        toast.success(SuccessToast(transactionLink), ToastOptions);
        setChangeContent(true);
        modalContent.current.innerHTML = "";
      }
      setLoading(false);
    } catch (err) {
      let error = { err };
      console.log(error);
      const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${error.err.transactionHash}`;
      setLoading(false);
      toast.error(FailureToast(transactionLink), ToastOptions);
    }
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <section className="d-flex flex-column align-items-start px-0 py-4">
        <div className="w-100">
          <div className="mb-3 d-flex justify-content-between w-100 align-items-center">
            <h3 className="w-50 text-left">Underwriting liquidity</h3>
            <div className="w-50 text-right">
              <UiButton
                onClick={() => {
                  setShowModal(true);
                }}
                buttonVariant="primary"
                buttonClass="flex-1 ml-0"
                buttonText="Provide Liquidity"
                type="submit"
              />
            </div>
          </div>
          <div className="pools-table width-100">
            <UiTable
              thead={headers}
              tbodyData={data ?? []}
              hover
              provider={provider}
              transport={transport}
              account={account}
              chainId={chainId}
              bordered={false}
            />
          </div>
          <div className="mb-3 d-flex justify-content-end w-100 align-items-center">
            <a
              href={config.whitePaperLink}
              className="stripeLink"
              target="_blank"
            >
              Whitepaper on Verified Liquidity
            </a>
          </div>
          <div className="mb-3 d-flex justify-content-end w-100 align-items-center">
            <a
              href={config.legalOpinionLink}
              target="_blank"
              className="stripeLink"
            >
              Legal opinion on the Verified Liquidity token
            </a>
          </div>
        </div>
      </section>
      <VerticallyModal
        key="connectProvider"
        showModal={showModal}
        modalOnHide={handleModalHide}
        modalSize={"lg"}
        modalHeading={
          <h2>
            <b>Buy Liquidity</b>
          </h2>
        }
        withFooter={false}
      >
        {changeContent && (
          <UiButton
            onClick={handleModalHide}
            buttonVariant="primary"
            buttonClass="SignUpButton flex-1 ml-0"
            buttonText="Click to close this form&nbsp;&rarr;"
            type="submit"
          />
        )}
        <div className="d-grid gap-2" ref={modalContent}>
          <Row className="ml-1 align-items-center">
            <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
              <Col xs={{ span: 3, offset: 2 }} className="text-left">
                <b>Token address</b>
              </Col>
              <Col className="pl-0" xs={5}>
                <DropdownButton id="dropdown-basic-button" title={tokenName}>
                  {currencySupportedList?.map((token, index) => (
                    <Dropdown.Item
                      key={index}
                      onClick={(e) => {
                        setTokenName(token?.tokenName);
                        setTokenSelected(token);
                      }}
                    >
                      {token?.tokenName}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Col>
            </Row>
            <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
              <Col xs={{ span: 3, offset: 2 }} className="text-left">
                <b>Amount</b>
                <div>
                  Balance : {Number(tokenSelected?.balance).toFixed(4)}{" "}
                  {tokenSelected?.tokenName}
                </div>
              </Col>
              <Col className="pl-0" xs={5}>
                <TextInput
                  placeholder="Amount"
                  fieldType="number"
                  onChange={(e) => setTokenAmount(e.target.value)}
                />
              </Col>
            </Row>
            {tokenSelected?.isFiat &&
              tokenSelected?.balance < Number(tokenAmount) && (
                <Row className="mx-0 my-2 pl-0 width-100 align-items-center">
                  <Col className="pl-0" xs={4} />
                  <Col className="pl-0" xs={4}>
                    <UiButton
                      onClick={() => onlineTopUp()}
                      buttonVariant="primary"
                      buttonClass="SignUpButton flex-1 ml-0"
                      buttonText="Top Up With Bank"
                      type="submit"
                    />
                    <Col className="pl-0" xs={4} />
                  </Col>
                </Row>
              )}
            {tokenSelected?.isFiat &&
              tokenSelected?.balance >= Number(tokenAmount) && (
                <Row className="mx-0 my-2 pl-0 width-100 align-items-center">
                  <Col className="pl-0" xs={4} />
                  <Col className="pl-0" xs={4}>
                    <UiButton
                      onClick={() => buyLiquidity()}
                      buttonVariant="primary"
                      buttonClass="SignUpButton flex-1 ml-0"
                      buttonText="Buy Liquidity"
                      type="submit"
                    />
                    <Col className="pl-0" xs={4} />
                  </Col>
                </Row>
              )}
            {!tokenSelected?.isFiat && (
              <Row className="mx-0 my-2 pl-0 width-100 align-items-center">
                <Col className="pl-0" xs={4} />
                <Col className="pl-0" xs={4}>
                  <UiButton
                    onClick={() => buyLiquidity()}
                    buttonVariant="primary"
                    buttonClass="SignUpButton flex-1 ml-0"
                    buttonText="Buy Liquidity"
                    type="submit"
                  />
                  <Col className="pl-0" xs={4} />
                </Col>
              </Row>
            )}
          </Row>
        </div>
      </VerticallyModal>
      <WalletConnect loggedIn={props.loggedIn} />
      {showKYCModal && (
        <KYCModal
          accessToken={accessToken}
          setshowKYCModal={setshowKYCModal}
          showKYCModal={showKYCModal}
        />
      )}
      <PaymentModal
        show={paymentModalVisibility}
        paymentRequest={paymentResponse}
        onHide={() => {
          setPaymentModalVisibility(false);
          setShowModal(true);
        }}
      />
    </>
  );
}

export default function BuyLiquidity(props) {
  return <PurchaseLiquidity {...props} />;
}
