import { Row, Col } from "react-bootstrap";
import {shortAddressCheck} from '../table/Table';
import "react-toastify/dist/ReactToastify.css";

function RefundsModal(props) {
  const { refunds } = props.data;
  return (
    <>
        <div className="d-grid gap-2">
            <Row className="ml-1 align-items-center">
            <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                <Col xs={{span:3, offset:2}} className="text-left">
                <b>Refund amount:</b>
                </Col>
                <Col className="pl-0" xs={5}>
                {Number(refunds?.at(-1).amount).toFixed(2) + " "+ refunds?.at(-1).tokenName}
                </Col>
            </Row>
            <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                <Col xs={{span:3, offset:2}} className="text-left">
                <b>Refunded to:</b>
                </Col>
                <Col className="pl-0" xs={5}>
                {refunds.length ? shortAddressCheck(refunds?.at(-1).investor.id) : null}
                </Col>
            </Row>
            </Row>
        </div>
    </>
  );
}

export function Refunds(props) {
  return <RefundsModal {...props} />;
}