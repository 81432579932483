import { useState, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import Loader from "../../components/loader";
import UiButton from "../../components/button";
import TextInput from "../../components/textinput/TextInput";
import {
  useSupportedTokens,
  useSecurityTokenDetails,
  useNetworkOptions,
} from "../../context/contractDataLoaderService";
import {
  PrimaryIssueManager,
  SecuritiesFactory,
  contractAddress,
  Security,
} from "@verified-network/verified-sdk";
import Web3 from "web3";
import { useGlobal, fetchSumsubAccessToken } from "../../context/globalContext";
import ERC20ABI from "../../utils/abi/ERC20.json";

import { create } from "ipfs-http-client";
import {
  SuccessToast,
  FailureToast,
  ToastOptions,
  auth,
  networks,
} from "../../utils/constants";
import { toast } from "react-toastify";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../utils/helpers/networks";
import { ethers, providers } from "ethers";
import { getKycStatus } from "../../utils/helpers";
const BigNumber = require("bignumber.js");

const client = create({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
  headers: {
    authorization: auth,
  },
});

function NewOffer(props) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [loading, setLoading] = useState(false);
  const { kycStatus } = useGlobal();
  const [issueAmount, setIssueAmount] = useState("");
  const [desiredAmount, setDesiredAmount] = useState("");
  const [minInvestment, setMinInvestment] = useState("");
  const [ipfsDoc, setIpfsDoc] = useState("");
  const [changeContent, setChangeContent] = useState(false);
  const modalContent = useRef(null);
  const { data: networkOptions } = useNetworkOptions();

  let { data } = useSupportedTokens();
  let { data: securityTokens } = useSecurityTokenDetails();

  let primaryIssueManagerContract,
    factoryContract,
    securityContract,
    BalancerPrimaryIssueManager;
  if (provider && provider.getSigner && chainId && address) {
    if (contractAddress[chainId].BalancerPrimaryIssueManager) {
      BalancerPrimaryIssueManager =
        contractAddress[chainId].BalancerPrimaryIssueManager;
      primaryIssueManagerContract = new PrimaryIssueManager(
        provider.getSigner(address),
        contractAddress[chainId].BalancerPrimaryIssueManager,
        "balancer"
      );
    }
    if (contractAddress[chainId].SecuritiesFactory) {
      factoryContract = new SecuritiesFactory(
        provider.getSigner(address),
        contractAddress[chainId].SecuritiesFactory
      );
    }
  }

  const retrieveFile = (e) => {
    const fileData = e.target.files[0];
    const reader = new window.FileReader();
    reader.readAsArrayBuffer(fileData);
    reader.onloadend = () => {
      setIpfsDoc(reader.result);
    };
    e.preventDefault();
  };

  const offerSecurity = async () => {
    const userKycStatus = await getKycStatus(address, chainId, signer);
    if (userKycStatus !== 3) {
      const data = await fetchSumsubAccessToken(account, chainId);
      props.setIssuedSecurityModal(false);
      props.setshowKYCModal(true);
      props.setAccessToken(data.accessToken);
      return;
    }
    if (issueAmount.length === 0) {
      alert("Enter issue amount");
    } else if (desiredAmount.length === 0) {
      alert("Enter desired amount");
    } else if (minInvestment.length === 0) {
      alert("Enter minimum investment");
    }

    let currencyTokenAddress;
    console.log(props);
    const securityTokenAddress = props.security;
    if (props.existingIssue) {
      const currencyToken = data.find(
        (element) => element.tokenAddress === props.currency
      );
      currencyTokenAddress = currencyToken.tokenAddress;
    } else {
      console.log("data", data);
      const currencyToken = data.find(
        (element) => element.tokenAddress === props.currency
      );
      currencyTokenAddress = currencyToken.tokenAddress;
    }

    console.log("securityTokenAddress primary", securityTokenAddress);
    console.log("currencyTokenAddress", currencyTokenAddress);
    const securityTokenContract = new web3.eth.Contract(
      ERC20ABI,
      securityTokenAddress
    );
    const cashContract = new web3.eth.Contract(ERC20ABI, currencyTokenAddress);
    const securityBalance = await securityTokenContract.methods
      .balanceOf(account)
      .call();

    const currencyDecimals = await cashContract.methods.decimals().call();
    const desiredAmountFixed = ethers.utils.parseUnits(
      desiredAmount.toString(),
      Number(currencyDecimals)
    );
    const minInvestmentFixed = ethers.utils.parseUnits(
      minInvestment.toString(),
      Number(currencyDecimals)
    );
    // console.log("issueAmount",issueAmount, Number(securityBalance));
    if (Number(securityBalance) < Number(issueAmount)) {
      toast.error("Insufficient Security Balance!", ToastOptions);
      return;
    }

    setLoading(true);
    const ipfsCID = (await client.add(ipfsDoc)).path;
    if (ipfsCID.length === 0) {
      alert("File not uploaded, try again");
      setLoading(false);
      return;
    }
    securityContract = new Security(
      provider.getSigner(address),
      securityTokenAddress
    );
    if (BalancerPrimaryIssueManager) {
      try {
        const tx = await securityContract.whiteList(
          BalancerPrimaryIssueManager,
          issueAmount,
          { ...networkOptions, from: account }
        );
        if (tx.code === "ACTION_REJECTED") {
          toast.error("Transaction rejected by user!", ToastOptions);
        } else {
          const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${tx.response.hash}`;
          toast.success(SuccessToast(transactionLink), ToastOptions);
        }
        setLoading(false);
      } catch (err) {
        let error = { err };
        console.log(error);
        const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${error.err.transactionHash}`;
        setLoading(false);
        toast.error(FailureToast(transactionLink), ToastOptions);
      }
    }

    setLoading(true);
    try {
      await securityTokenContract.methods
        .approve(primaryIssueManagerContract.contractAddress, issueAmount)
        // .send({ from: account, gasLimit: '2000000'});
        .send({ ...(chainId === 137 ? networkOptions : {}), from: account });
    } catch (e) {
      toast.error("Transaction rejected by user!", ToastOptions);
      setLoading(false);
      return;
    }

    try {
      await primaryIssueManagerContract
        .offer(
          securityTokenAddress,
          props.isinValue,
          issueAmount,
          currencyTokenAddress,
          desiredAmountFixed.toString(),
          minInvestmentFixed.toString(),
          account,
          ipfsCID,
          networkOptions
        )
        .then((res) => {
          if (res?.status === 0) {
            const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${res.response.hash}`;
            toast.success(SuccessToast(transactionLink), ToastOptions);
            setChangeContent(true);
            modalContent.current.innerHTML = "";
          }
        });
      setLoading(false);
    } catch (err) {
      let error = { err };
      console.log(err);
      const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${error.err.transactionHash}`;
      setLoading(false);
      toast.error(FailureToast(transactionLink), ToastOptions);
    }
  };
  return (
    <>
      {loading ? <Loader /> : null}
      {changeContent && (
        <UiButton
          onClick={() => {
            props.existingIssue
              ? props.onIssuedModalHide()
              : props.onModalHide();
          }}
          buttonVariant="primary"
          buttonClass="SignUpButton flex-1 ml-0"
          buttonText="Click to close this form&nbsp;&rarr;"
          type="submit"
        />
      )}
      <div className="d-grid gap-2" ref={modalContent}>
        <Row className="ml-1 align-items-center">
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Issue amount</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <TextInput
                placeholder="Value of security tokens to issue"
                fieldType="number"
                onChange={(e) =>
                  setIssueAmount(web3.utils.toWei(e.target.value, "ether"))
                }
                required
              />
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Target investment</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <TextInput
                placeholder="Amount of capital to raise"
                fieldType="number"
                onChange={(e) => setDesiredAmount(e.target.value)}
                required
              />
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Minimum investment</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <TextInput
                placeholder="Minimum amount of capital acceptable"
                fieldType="number"
                onChange={(e) => setMinInvestment(e.target.value)}
                required
              />
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Offer document</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <TextInput
                placeholder="ipfs address for offering documents"
                fieldType="file"
                onChange={(e) => retrieveFile(e)}
                required
              />
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col className="pl-0">
              <UiButton
                onClick={() => {
                  offerSecurity();
                }}
                buttonVariant="primary"
                buttonClass="SignUpButton flex-1 ml-0"
                buttonText="Make Offer"
                type="submit"
              />
            </Col>
          </Row>
        </Row>
      </div>
    </>
  );
}

export default function AddOffer(props) {
  return <NewOffer {...props} />;
}
