import React, { useState } from "react";
import Loader from "../../../components/loader";
import { useBalancerPoolsData } from "../../../context/balancerPoolData";
import { PoolType } from "../../poolDetail/balancer/types";
import Web3 from "web3";

import { useGlobal } from "../../../context/globalContext";
import UiTable from "../../../components/table/Table";
import {
  PrimaryIssueManager,
  contractAddress,
} from "@verified-network/verified-sdk";
import { useNetworkOptions } from "../../../context/contractDataLoaderService";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../../utils/helpers/networks";
import { providers } from "ethers";

function ServicerHome(props) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }

  const { userRole } = useGlobal();
  let { data, loading } = useBalancerPoolsData(PoolType.PrimaryIssue);
  const { data: networkOptions } = useNetworkOptions();

  if (userRole == "DP" || userRole == "intermediary")
    data = data.filter(
      (pool) =>
        pool.verifiedWalletData?.issueManager.toLowerCase() ===
          account.toLowerCase() &&
        !pool.verifiedWalletData?.subscriptionsClosed.length
    );
  else if (userRole == "AM")
    data = data.filter(
      (pool) =>
        pool.verifiedWalletData?.liquidityProviders.some((obj) =>
          Object.values(obj).includes(account.toLowerCase())
        ) && !pool.verifiedWalletData?.subscriptionsClosed.length
    );

  let primaryIssueManagerContract;
  if (provider && provider.getSigner && chainId && address) {
    const BalancerPrimaryIssueManager =
      contractAddress[chainId].BalancerPrimaryIssueManager;
    if (BalancerPrimaryIssueManager) {
      primaryIssueManagerContract = new PrimaryIssueManager(
        provider.getSigner(address),
        BalancerPrimaryIssueManager,
        "balancer"
      );
    }
  }

  let headers = [
    { label: "Security", val: "security" },
    { label: "Company", val: "companyName" },
    { label: "Currency", val: "currency" },
    { label: "Close Date", val: "cutoffTime" },
    userRole === "DP" || userRole === "intermediary"
      ? { label: "Call Action", val: "homeCallAction" }
      : { label: "Call Action", val: "managerEarnings" },
  ];

  const options = {
    onClick: (event, row) => {
      onInvest(row);
    },
  };
  const onInvest = (row) => {
    console.log(props, row);
    props.history.push(
      `/active_issues/primary_investors/${row.poolType}/${row.security}`
    );
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <section className="d-flex flex-column align-items-start px-0 py-4">
        <div className="mb-3 d-flex justify-content-between w-100 align-items-center">
          <div className="w-100">
            <h3 className="text-left">Active Issues</h3>
          </div>
        </div>
        <div className="w-100">
          <div className="pools-table width-100">
            <UiTable
              thead={headers}
              tbodyData={data || []}
              hover
              bordered={false}
              userRole={userRole}
              rowEvents={options}
              web3={web3}
              account={account}
              signer={signer}
              useGlobal={useGlobal}
              chainId={chainId}
              networkOptions={networkOptions}
              contract={primaryIssueManagerContract}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default function ActiveIssues(props) {
  return <ServicerHome {...props} />;
}
