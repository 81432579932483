import { useState, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import Loader from "../../../components/loader";
import UiButton from "../../../components/button";
import TextInput from "../../../components/textinput/TextInput";

import {
  useSupportedTokens,
  useSecurityTokenDetails,
  useNetworkOptions,
} from "../../../context/contractDataLoaderService";
import Web3 from "web3";
import ERC20ABI from "../../../utils/abi/ERC20.json";
import {
  SecondaryIssueManager,
  SecuritiesFactory,
  contractAddress,
  Security,
} from "@verified-network/verified-sdk";
import {
  SuccessToast,
  FailureToast,
  ToastOptions,
  networks,
} from "../../../utils/constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../../utils/helpers/networks";
import { ethers, providers } from "ethers";

function NewOffer(props) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  let { data: securityTokens } = useSecurityTokenDetails();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [securityAmount, setSecurityAmount] = useState("");
  const [minOrderSize, setMinOrderSize] = useState("");
  const [currencyAmount, setCurrencyAmount] = useState("");
  const [issuingFee, setIssuingFee] = useState("");
  const [securityOutstanding, setSecurityOutstanding] = useState();
  const [changeContent, setChangeContent] = useState(false);
  const { data: networkOptions } = useNetworkOptions("issue");
  const modalContent = useRef(null);
  const [id, setId] = useState("");

  let secondaryIssueContract, factoryContract, securityContract;
  let { data } = useSupportedTokens();
  if (provider && provider.getSigner && chainId && address) {
    if (contractAddress[chainId].BalancerSecondaryIssueManager) {
      secondaryIssueContract = new SecondaryIssueManager(
        provider.getSigner(address),
        contractAddress[chainId].BalancerSecondaryIssueManager
      );
    }
    if (contractAddress[chainId].SecuritiesFactory) {
      factoryContract = new SecuritiesFactory(
        provider.getSigner(address),
        contractAddress[chainId].SecuritiesFactory
      );
    }
  }

  const addIssue = async () => {
    if (minOrderSize.length == 0) {
      alert("Enter minimum order size");
    } else if (securityOutstanding.length == 0) {
      alert("Enter security outstanding");
    } else if (securityAmount.length == 0) {
      alert("Enter security amount");
    } else if (currencyAmount.length == 0) {
      alert("Enter currency amount");
    }
    let securityTokenAddress, currencyTokenAddress;
    if (props.existingIssue) {
      console.log("Here");
      securityTokenAddress = props.data.security;
      currencyTokenAddress = props.data.currency;
    } else {
      const currencyToken = data.find(
        (element) => element.tokenAddress === props.currency
      );
      currencyTokenAddress = currencyToken.tokenAddress;
      securityTokenAddress = props.security;
    }
    console.log(
      securityTokenAddress,
      currencyTokenAddress,
      props.existingIssue
    );
    const cashContract = new web3.eth.Contract(ERC20ABI, currencyTokenAddress);
    const currencyDecimals = await cashContract.methods.decimals().call();
    const cashBalance = await cashContract.methods.balanceOf(account).call();
    const securityTokenContract = new web3.eth.Contract(
      ERC20ABI,
      securityTokenAddress
    );
    const securityDecimals = await securityTokenContract.methods
      .decimals()
      .call();
    const securityBalance = await securityTokenContract.methods
      .balanceOf(account)
      .call();
    const currencyAmountFixed = String(currencyAmount * 10 ** currencyDecimals);
    let securityContract;
    if (provider && provider.getSigner) {
      securityContract = new Security(
        provider.getSigner(address),
        securityTokenAddress
      );
    }

    if (Number(securityBalance) < securityAmount) {
      toast.error("Insufficient Security Balance!", ToastOptions);
      return;
    } else if (Number(cashBalance) < currencyAmountFixed) {
      toast.error("Insufficient Currency Balance!", ToastOptions);
      return;
    }
    // const nonce = await web3.eth.getTransactionCount(account);
    // console.log("nonce", nonce);
    setLoading(true);
    try {
      console.log("is updated");
      await securityContract
        .whiteList(
          contractAddress[chainId].BalancerSecondaryIssueManager,
          securityOutstanding
          // { from: account}
        )
        .then((res) => {
          if (res?.status === 0) {
            toast.success("Transaction Successful", ToastOptions);
          }
          if (res?.status === 1) {
            setLoading(false);
            toast.error(FailureToast(), ToastOptions);
          }
          if (res?.code === "ACTION_REJECTED") {
            toast.error("Transaction rejected by user!", ToastOptions);
          }
        });
    } catch (err) {
      setLoading(false);
      let error = { err };
      console.log(error);
      const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${error.err.transactionHash}`;
      toast.error(FailureToast(transactionLink), ToastOptions);
      return;
    }
    const currencyAllowance = Number(
      await cashContract.methods
        .allowance(account, secondaryIssueContract.contractAddress)
        .call()
    );
    let isCurrencyApproved = false;
    await cashContract.methods
      .approve(secondaryIssueContract.contractAddress, currencyAmountFixed)
      // .send({ from: account });
      .send({ from: account, ...(chainId === 137 ? networkOptions : {}) })
      .then((res) => {
        isCurrencyApproved = true;
      })
      .catch((err) => {
        err.message.includes("was not mined within 50 blocks")
          ? toast.warn(
              "Approval transaction taking longer time please wait...",
              ToastOptions
            )
          : console.error("approve failed with error: ", err);
        toast.error("Transaction failed", ToastOptions);
        setLoading(false);
      });
    // if (currencyAllowance < Number(currencyAmountFixed)) {

    // } else {
    //   isCurrencyApproved = true;
    //   toast.success("Currency Approved Succesfully", ToastOptions);
    // }
    const securityAllowance = Number(
      await securityTokenContract.methods
        .allowance(account, secondaryIssueContract.contractAddress)
        .call()
    );

    if (isCurrencyApproved && securityAllowance < Number(securityAmount)) {
      await securityTokenContract.methods
        .approve(
          contractAddress[chainId].BalancerSecondaryIssueManager,
          BigInt(securityAmount)
        )
        .send({
          from: account,
          ...(chainId === 137 ? networkOptions : {}),
        })
        .then(async (res) => {
          console.log("approve succesful");
          toast.success("Security Approved Succesfully", ToastOptions);
          try {
            await secondaryIssueContract
              .setIssuingFee(
                securityTokenAddress,
                currencyTokenAddress,
                ethers.utils.parseUnits(issuingFee.toString(), 18).toString()
              )
              .then(async (res) => {
                if (res?.status === 0) {
                  toast.success("Transaction Successful", ToastOptions);
                  const tx = await secondaryIssueContract.issueSecondary(
                    securityTokenAddress,
                    currencyTokenAddress,
                    securityOutstanding,
                    securityAmount,
                    minOrderSize,
                    currencyAmountFixed
                    // networkOptions
                    // { ...networkOptions, gasLimit: "8721975" }
                  );
                  if (tx.code === "ACTION_REJECTED") {
                    toast.error("Transaction rejected by user!", ToastOptions);
                  } else {
                    const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${tx.response.hash}`;
                    toast.success(SuccessToast(transactionLink), ToastOptions);
                    setChangeContent(true);
                    modalContent.current.innerHTML = "";
                  }
                  setLoading(false);
                }
                if (res?.status === 1) {
                  toast.error(FailureToast(), ToastOptions);
                  setLoading(false);
                }
              });
          } catch (err) {
            let error = { err };
            console.log(error);
            const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${error.err.transactionHash}`;
            setLoading(false);
            toast.error(FailureToast(transactionLink), ToastOptions);
          }
        })
        .catch((err) => {
          err.message.includes("was not mined within 50 blocks")
            ? toast.warn(
                "Approval transaction taking longer time please wait...",
                ToastOptions
              )
            : console.error("approve failed with error: ", err);
          toast.error("Transaction failed", ToastOptions);
          setLoading(false);
        });
    } else if (isCurrencyApproved) {
      try {
        const tx = await secondaryIssueContract.issueSecondary(
          securityTokenAddress,
          currencyTokenAddress,
          securityOutstanding,
          securityAmount,
          minOrderSize,
          currencyAmountFixed,
          // networkOptions
          { ...networkOptions, gasLimit: "8721975" }
        );
        if (tx.code === "ACTION_REJECTED") {
          toast.error("Transaction rejected by user!", ToastOptions);
        } else {
          const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${tx.response.hash}`;
          toast.success(SuccessToast(transactionLink), ToastOptions);
          setChangeContent(true);
          modalContent.current.innerHTML = "";
        }
        setLoading(false);
      } catch (err) {
        let error = { err };
        console.log(error);
        const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${error.err.transactionHash}`;
        setLoading(false);
        toast.error(FailureToast(transactionLink), ToastOptions);
      }
    }
  };
  return (
    <>
      <ToastContainer />
      {loading ? <Loader /> : null}
      {changeContent && (
        <UiButton
          onClick={() => {
            props.existingIssue
              ? props.onIssuedModalHide()
              : props.onModalHide();
          }}
          buttonVariant="primary"
          buttonClass="SignUpButton flex-1 ml-0"
          buttonText="Click to close this form&nbsp;&rarr;"
          type="submit"
        />
      )}
      <div className="d-grid gap-2" ref={modalContent}>
        <Row className="ml-1 align-items-center">
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Minimum Security Order</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <TextInput
                placeholder="Minimum order size"
                fieldType="number"
                required
                onChange={(e) =>
                  setMinOrderSize(web3.utils.toWei(e.target.value, "ether"))
                }
              />
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Security Outstanding</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <TextInput
                placeholder="Number of total securities outstanding"
                fieldType="number"
                required
                onChange={(e) =>
                  setSecurityOutstanding(
                    web3.utils.toWei(e.target.value, "ether")
                  )
                }
              />
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Security Amount</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <TextInput
                placeholder="Number of securities to issue"
                fieldType="number"
                required
                onChange={(e) =>
                  setSecurityAmount(web3.utils.toWei(e.target.value, "ether"))
                }
              />
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Currency Amount</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <TextInput
                placeholder="Equivalent cash value"
                fieldType="number"
                required
                onChange={(e) => setCurrencyAmount(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Issuing Fee</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <TextInput
                placeholder="Equivalent cash value"
                fieldType="number"
                required
                onChange={(e) => setIssuingFee(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mx-0 my-2 pl-0 width-100 align-items-center">
            <Col className="pl-0">
              <UiButton
                onClick={() => {
                  addIssue();
                }}
                buttonVariant="primary"
                buttonClass="SignUpButton flex-1 ml-0"
                buttonText="Create Issue"
                type="submit"
              />
            </Col>
          </Row>
        </Row>
      </div>
    </>
  );
}

export default function AddOffer(props) {
  return <NewOffer {...props} />;
}
