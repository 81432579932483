import React, { useState } from "react";
import Loader from "../../components/loader";
import UiTable from "../../components/table/Table";

import Web3 from "web3";
import { useClientKYCData } from "../../context/contractDataLoaderService";
import {
  Liquidity,
  TokenContract,
  contractAddress,
} from "@verified-network/verified-sdk";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../utils/helpers/networks";
import { ethers, providers } from "ethers";
import { useGlobal } from "../../context/globalContext";
import { Col, Dropdown, DropdownButton, Row } from "react-bootstrap";
import VerticallyModal from "../../components/modal/VerticallyModal";
import TextInput from "../../components/textinput/TextInput";
import UiButton from "../../components/button";
import { toast } from "react-toastify";
import { ToastOptions } from "../../utils/constants";
import axios from "axios";
import config from "../../services/config/homestead.json";
import { approveOrRejectApplicant } from "./kycUtils";

function KYCList(props) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const { userCountry } = useGlobal();
  let { data: kycList, loading: kycLoading } = useClientKYCData(
    true,
    false,
    userCountry
  );
  const [generalLoading, setGeneralLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalView, setModalView] = useState(0);
  const [applicantId, setApplicantId] = useState("");
  const [applicantAddress, setApplicantAddress] = useState("");
  const [formLoader, setFormLoader] = useState(false);
  const [changeContent, setChangeContent] = useState(false);

  const processedData = kycList?.filter((kyc) => {
    if (
      kyc &&
      kyc.countryName.toLowerCase() === userCountry.toLowerCase() &&
      kyc.status === 3
    ) {
      return kyc;
    }
  });
  const headers = [
    { label: "Wallet address", val: "accountAddress" },
    { label: "Name", val: "fullName" },
    { label: "Applicant ID", val: "applicantId" },
    { label: "Country", val: "countryName" },
    { label: "Staus", val: "applicantStatus" },
    { label: "Email", val: "emailFmt" },
    // { label: "Photo/Video KYC", val: "selfieURL" },
    // { label: "Indentity KYC", val: "identityURL" },
    { label: "Actions", val: "kycAction" },
    // { label: "Documents", val: "documentKYC" },
    // { label: "Approval Date", val: "approvalDate" },
  ];
  // console.log("proceesed list: ", processedData);
  // let web3, liquidityContract;
  // if (provider && provider.getSigner && chainId && address) {
  //   web3 = getWeb3(transport);
  //   liquidityContract = new Liquidity(
  //     provider.getSigner(address),
  //     contractAddress[chainId].Liquidity
  //   );
  // }

  const commonProps = {
    generalLoading,
    updateGeneralLoading: setGeneralLoading,
  };

  const handleModalHide = () => {
    setShowModal(false);
    setModalView(0);
    setApplicantAddress("");
    setApplicantId("");
    setChangeContent(false);
  };

  const handleManualVerification = async () => {
    if (applicantId === "") {
      toast.error("Input Applicant Id", ToastOptions);
      return;
    }
    if (applicantAddress === "") {
      toast.error("Input Applicant Address", ToastOptions);
      return;
    }

    if (chainId) {
      const applicantDetails = await axios
        .get(`${config.sumsubEndpoint}?applicantId=${applicantId}`)
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          setFormLoader(false);
          return null;
        });
      if (applicantDetails) {
        const abiCoder = new ethers.utils.AbiCoder();
        if (
          abiCoder
            .decode(
              ["string", "string"],
              applicantDetails.applicantData.externalUserId
            )[0]
            .toString()
            .toLowerCase() === applicantAddress.toLowerCase()
        ) {
          await approveOrRejectApplicant(
            applicantId,
            applicantDetails.applicantData.inspectionId,
            abiCoder.encode(
              ["string", "string"],
              [applicantAddress, String(chainId)]
            ),
            {
              reviewAnswer: "GREEN",
            }
          ).then((res) => {
            setFormLoader(false);
            if (res) {
              toast.success("Applicant Verified Succesfully", ToastOptions);
              setChangeContent(true);
            } else {
              toast.error("Applicant Verification Failed", ToastOptions);
            }
          });
        } else {
          setFormLoader(false);
          toast.error(
            "Applicant address does not match existing address",
            ToastOptions
          );
          return;
        }
      } else {
        toast.error("Invalid ApplicantId", ToastOptions);
        return;
      }
    }
  };

  let message, heading;

  if (modalView == 1) {
    heading = "Manual Verification";
    message = (
      <>
        {formLoader ? <Loader /> : null}
        <div className="d-grid gap-2">
          {changeContent && (
            <UiButton
              onClick={() => {
                handleModalHide();
              }}
              buttonVariant="primary"
              buttonClass="SignUpButton flex-1 ml-0"
              buttonText="Click to close this form&nbsp;&rarr;"
              type="submit"
            />
          )}
          {!changeContent && (
            <Row className="ml-1 align-items-center">
              <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                <Col xs={{ span: 3, offset: 2 }} className="text-left">
                  <b>Applicant Id</b>
                </Col>
                <Col className="pl-0" xs={5}>
                  <TextInput
                    placeholder="Applicant id"
                    onChange={(e) => setApplicantId(e.target.value)}
                    value={applicantId}
                  />
                </Col>
              </Row>
              <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                <Col xs={{ span: 3, offset: 2 }} className="text-left">
                  <b>Applicant Address</b>
                </Col>
                <Col className="pl-0" xs={5}>
                  <TextInput
                    placeholder="Applicant address"
                    onChange={(e) => setApplicantAddress(e.target.value)}
                    value={applicantAddress}
                  />
                </Col>
              </Row>
              <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                <Col className="pl-0">
                  <UiButton
                    onClick={async () => {
                      setFormLoader(true);
                      await handleManualVerification();
                      setFormLoader(false);
                    }}
                    buttonVariant="primary"
                    buttonClass="SignUpButton flex-1 ml-0"
                    buttonText="Verify"
                    type="submit"
                  />
                </Col>
              </Row>
            </Row>
          )}
        </div>
      </>
    );
  }

  return (
    <>
      {kycLoading || generalLoading ? <Loader /> : null}
      <section className="d-flex flex-column align-items-start px-0 py-4">
        <div className="w-100">
          <div className="mb-3 d-flex justify-content-between w-100 align-items-center">
            <h3 className="w-50 text-left">Processed KYC</h3>
            <div className="w-100 text-right">
              <DropdownButton id="dropdown-basic-button" title="New">
                <Dropdown.Item
                  onClick={() => {
                    setModalView(1);
                    setShowModal(true);
                  }}
                >
                  Manual Verification
                </Dropdown.Item>
              </DropdownButton>
            </div>
          </div>

          <div className="pools-table width-100">
            <UiTable
              thead={headers}
              tbodyData={processedData ?? []}
              hover
              provider={provider}
              transport={transport}
              account={account}
              chainId={chainId}
              bordered={false}
              commonProps={commonProps}
            />
          </div>
        </div>
      </section>
      <VerticallyModal
        key="connectProvider"
        showModal={showModal}
        modalOnHide={handleModalHide}
        modalSize={"lg"}
        modalHeading={
          <h2>
            <b>{heading}</b>
          </h2>
        }
        withFooter={false}
      >
        {message}
      </VerticallyModal>
    </>
  );
}

export default function ProcessedKYC(props) {
  return <KYCList {...props} />;
}
