import { useEffect, useState } from "react";
import Loader from "../../components/loader";
import { useBalancerPoolsData } from "../../context/balancerPoolData";
import PoolsTable from "../poolDetail/PoolsTable";
import { PoolType } from "../poolDetail/balancer/types";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import VerticallyModal from "../../components/modal/VerticallyModal";
import WalletConnect from "../../components/common/walletConnect/index";
import AddIssue from "./issue";
import ExistingIssue from "./existingIssue";
import KYCModal from "../../components/common/kycModal";
import AddBalance from "./balance";
import AddOffer from "./offer";
import CreateProduct from "../marginProducts/marginProduct";
import { useKYCData } from "../../context/contractDataLoaderService";
import AddSecondaryBalance from "../assetManager/createSecondaryIssue/balance";
import { useBlockNumber } from "wagmi";
import { useGlobal } from "../../context/globalContext";
import { contractAddress } from "@verified-network/verified-sdk";

function HomePage(props) {
  const [showModal, setShowModal] = useState(false);
  const [issuedSecurityModal, setIssuedSecurityModal] = useState(false);
  let { data: balancerPools, loading } = useBalancerPoolsData();
  const { userType, userCountry, userCountryCode, kycStatus } = useGlobal();
  const currentDate = Math.floor(new Date().getTime() / 1000);
  const balancerPoolsFmt = balancerPools.filter(
    (pool) =>
      !pool.restrictedCountries?.includes(userCountryCode) &&
      !pool.verifiedWalletData?.subscriptionsClosed.length &&
      currentDate < Number(pool.cutoffTime) &&
      pool.totalShares !== "0.000000000001"
  );

  
  const [modalView, setModalView] = useState(1);
  const [issuedModalView, setIssuedModalView] = useState(1);
  let { data: kycData } = useKYCData();

  const [companyName, setCompanyName] = useState("");
  const [isinValue, setIsinValue] = useState("");
  const [currency, setCurrency] = useState("Select Currency");
  const [security, setSecurity] = useState("");
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [securitySymbol, setSecuritySymbol] = useState("");
  const [balance, setBalance] = useState("");
  const [amount, setAmount] = useState("");
  const [investorAddress, setInvestorAddress] = useState("");
  const [productType, setProductType] = useState("Select type");
  const [productCategory, setProductCategory] = useState("Select category");
  const [showKYCModal, setshowKYCModal] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [intermediary, setIntermediary] = useState();

  const handleModalHide = () => {
    setModalView(1);
    setShowModal(false);
  };

  const handleModalHideIssued = () => {
    setCompanyName("");
    setIsinValue("");
    setCurrency("Select Currency");
    setIssuedModalView(1);
    setIssuedSecurityModal(false);
  };

  const commonProps = {
    companyName,
    updateCompanyName: setCompanyName,
    isinValue,
    updateIsinValue: setIsinValue,
    currency,
    updateCurrency: setCurrency,
    security,
    updateSecurity: setSecurity,
    currencySymbol,
    updateCurrencySymbol: setCurrencySymbol,
    securitySymbol,
    updateSecuritySymbol: setSecuritySymbol,
    productType,
    updateProductType: setProductType,
    intermediary,
    updateIntermediary: setIntermediary,
    balance,
    updateBalance: setBalance,
    amount,
    updateAmount: setAmount,
    investorAddress,
    updateInvestorAddress: setInvestorAddress,
    productCategory,
    updateProductCategory: setProductCategory,
    modalView,
    updateModalView: setModalView,
    onModalHide: handleModalHide,
    onIssuedModalHide: handleModalHideIssued,
    setShowModal,
    setIssuedSecurityModal,
    setshowKYCModal,
    setAccessToken,
    setIssuedModalView,
    issuedModalView,
  };

  let message, heading;
  let alreadyIssuedHeading, alreadyIssuedData;
  if (issuedModalView == 1) {
    alreadyIssuedHeading = "Existing Primary Issue";
    alreadyIssuedData = (
      <ExistingIssue
        existingIssue={true}
        secondaryIssue={false}
        {...commonProps}
      />
    );
  } else if (issuedModalView == 2) {
    alreadyIssuedHeading = "Add Balance";
    alreadyIssuedData = <AddBalance existingIssue={true} {...commonProps} />;
  } else if (issuedModalView == 3) {
    alreadyIssuedHeading = "Offer Security";
    alreadyIssuedData = <AddOffer existingIssue={true} {...commonProps} />;
  } else if (issuedModalView == 4) {
    console.log("show create");
    alreadyIssuedHeading = "Create Product";
    alreadyIssuedData = <CreateProduct existingIssue={true} {...commonProps} />;
  } else if (issuedModalView == 5) {
    alreadyIssuedHeading = "Add Balance";
    alreadyIssuedData = (
      <AddSecondaryBalance existingIssue={true} {...commonProps} />
    );
  }

  if (modalView == 1) {
    heading = "New Primary Issue";
    message = <AddIssue existingIssue={false} {...commonProps} />;
  } else if (modalView == 2) {
    heading = "Add Balance";
    message = <AddBalance existingIssue={false} {...commonProps} />;
  } else if (modalView == 3) {
    heading = "Offer Security";
    message = <AddOffer existingIssue={false} {...commonProps} />;
  } else if (modalView == 4) {
    heading = "Create Product";
    message = <CreateProduct existingIssue={false} {...commonProps} />;
  } else if (modalView == 5) {
    heading = "Add Balance";
    message = <AddSecondaryBalance existingIssue={false} {...commonProps} />;
  }
  // console.log("pools: ", balancerPoolsFmt);
  return (
    <>
      {loading ? <Loader /> : null}
      <section className="d-flex flex-column align-items-start px-0 py-4 headerSection">
        <div className="mb-3 d-flex justify-content-between w-100 align-items-center headerSectionContent">
          <div className="w-100">
            <h3 className="text-left">New Issues</h3>
          </div>
          <div className="w-100 text-right">
            <DropdownButton id="dropdown-basic-button" title="Issue new">
              <Dropdown.Item
                onClick={() => {
                  setIssuedSecurityModal(true);
                }}
              >
                Use existing security token
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setShowModal(true);
                }}
              >
                Create new security token
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </div>
        <PoolsTable
          {...props}
          data={[...balancerPoolsFmt] || []}
          kycStatus={kycStatus}
          kycData={kycData}
        />
      </section>
      {/* <WalletConnect loggedIn={props.loggedIn} /> */}
      <VerticallyModal
        key="issuedSecurity"
        showModal={issuedSecurityModal}
        modalOnHide={handleModalHideIssued}
        modalSize={"lg"}
        modalHeading={
          <h2>
            <b>{alreadyIssuedHeading}</b>
          </h2>
        }
        withFooter={false}
      >
        {alreadyIssuedData}
      </VerticallyModal>

      <VerticallyModal
        key="connectProvider"
        showModal={showModal}
        modalOnHide={handleModalHide}
        modalSize={"lg"}
        modalHeading={
          <h2>
            <b>{heading}</b>
          </h2>
        }
        withFooter={false}
      >
        {message}
      </VerticallyModal>
      {showKYCModal && (
        <KYCModal
          accessToken={accessToken}
          setshowKYCModal={setshowKYCModal}
          showKYCModal={showKYCModal}
        />
      )}
    </>
  );
}

export default function Home(props) {
  return <HomePage {...props} />;
}
