import "../../styles/header.css";
import "../../styles/typo.css";

import React, { useState, useContext, useEffect } from "react";
import { Button, Navbar } from "react-bootstrap";
import logo from "../../assets/images/logo/verified_logo_white.svg";
import { useGlobal } from "../../context/globalContext";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Row } from "react-bootstrap";
import VerticallyModal from "../modal/VerticallyModal";
import HelperModal from "./helperModal";
import UnSupportedModal from "./unSupportedNetwork";
import { networks, ToastOptions, FailureToast } from "../../utils/constants";
import { fetchSumsubAccessToken } from "../../context/globalContext";
import { toast } from "react-toastify";
import {
  useAccount,
  useDisconnect,
  useConnect,
  useClient,
  useConnectorClient,
} from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import {
  getProvider,
  getProviderNetwork,
  getWeb3,
} from "../../utils/helpers/networks";
import KYCModal from "./kycModal";

const { Client, contractAddress } = require("@verified-network/verified-sdk");
const { ethers, providers } = require("ethers");

export const shortAddress = (address = "") => {
  const lst2 = address.slice(-4);
  const first4 = address.slice(0, 5);
  return `${first4}...${lst2}`;
};

const CommonHeader = () => {
  const { open } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const { address, isConnected, chainId, isConnecting } = useAccount();
  const { data: client } = useConnectorClient({ chainId });
  const { chain, transport } = client || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const { login, userRole, kycStatus } = useGlobal();
  const [showHelperModal, setShowHelperModal] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  const [showKYCModal, setshowKYCModal] = useState(false);

  // const { connectors, connect } = useConnect();

  // console.log(
  //   "chain: ",
  //   chainId,
  //   "connected: ",
  //   isConnected,
  //   "isconnecting: ",
  //   isConnecting,
  //   "address: ",
  //   address,
  //   "signer: ",
  //   signer,
  //   "provider: ",
  //   provider,
  //   "trspt: ",
  //   transport
  // );

  const activeNetworkList = Object.fromEntries(
    Object.entries(networks).filter(
      ([key, value]) => contractAddress[key].Security
    )
  );

  useEffect(() => {
    async function switchNetwork() {
      if (
        !Object.keys(activeNetworkList).includes(Number(chainId).toString())
      ) {
        setAlertModal(true);
      } else {
        setAlertModal(false);
      }
    }
    if (chainId) {
      switchNetwork();
    }
  }, [chainId]);

  const handleNetworkSwitch = async (networkName) => {
    console.log("networkName", networkName);
    await changeNetwork({ networkName });
  };

  const changeNetwork = async ({ networkName }) => {
    try {
      if (!window.ethereum) throw new Error("No crypto wallet found");
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            ...networks[networkName],
          },
        ],
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  React.useEffect(() => {
    if (address && chainId) {
      passBaseRequest();
    }
  }, [address, chainId, transport]);

  React.useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", () => {
        passBaseRequest();
      });
      window.ethereum.on("chainChanged", () => {
        passBaseRequest();
      });
      window.ethereum.on("changed", () => {
        passBaseRequest();
      });
      //Todo: handle more events
    }
  }, [address, chainId, transport]);

  const passBaseRequest = async () => {
    if (address) {
      const data = await fetchSumsubAccessToken(address, Number(chainId));
      setAccessToken(data.accessToken);
      login();
    }
  };

  return (
    <header className="bg" style={{ height: "75px" }}>
      <Navbar expand="lg">
        <a href={`/`}>
          <Navbar.Brand>
            <img src={logo} alt="Logo" />{" "}
          </Navbar.Brand>
        </a>
        <div className="d-flex justify-content-end connect-btn align-items-center text-white">
          <div className="mr-2">
            {chainId ? (
              <DropdownButton
                id="dropdown-basic-button"
                title={
                  chainId
                    ? networks[Number(chainId)]?.chainName
                    : "Select Network"
                }
              >
                {Object.keys(activeNetworkList).map((network) => {
                  return (
                    <Dropdown.Item
                      key={network}
                      className={
                        Number(chainId) === network ? "selectedNetwork" : null
                      }
                      onClick={() => {
                        handleNetworkSwitch(network);
                      }}
                    >
                      {networks[network].chainName}
                    </Dropdown.Item>
                  );
                })}
              </DropdownButton>
            ) : null}
          </div>
          {address ? (
            <div className="rounded p-2">
              <b>Account:</b> {shortAddress(address)}
            </div>
          ) : null}
          {address &&
            userRole?.toLowerCase() !== "admin" &&
            kycStatus !== 3 && (
              <Button
                className="btn-index"
                onClick={async () => {
                  if (address && chainId) {
                    const data = await fetchSumsubAccessToken(address, chainId);
                    setshowKYCModal(true);
                    setAccessToken(data.accessToken);
                  }
                }}
                style={{ marginRight: "10px" }}
                variant="outline-light"
              >
                Verify KYC
              </Button>
            )}
          <Button
            className="btn-index"
            onClick={async () => {
              if (!address) {
                open();
              } else {
                disconnect();
              }
            }}
            variant="outline-light"
          >
            {isConnected && address
              ? "Disconnect Wallet"
              : !address && isConnecting
              ? "Connecting..."
              : address && isConnecting
              ? "Disconnecting..."
              : "Connect Wallet"}
          </Button>
          {/* {connectors.map((connector) => (
            <Button
              className="btn-index"
              key={connector.uid}
              onClick={() => connect({ connector })}
              variant="outline-light"
            >
              {connector.name}
            </Button>
          ))} */}
        </div>
      </Navbar>
      {showHelperModal && <HelperModal />}
      {alertModal && (
        <UnSupportedModal
          alertModal={alertModal}
          switchNetwork={handleNetworkSwitch}
          activeNetworkList={activeNetworkList}
          chainName={networks[Number(chainId)]?.chainName}
        />
      )}
      {showKYCModal && (
        <KYCModal
          accessToken={accessToken}
          setshowKYCModal={setshowKYCModal}
          showKYCModal={showKYCModal}
        />
      )}
    </header>
  );
};

export default CommonHeader;
