import { useState, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import Web3 from "web3";
import UiButton from "../button";

import DateTimePicker from "react-datetime-picker";
import Loader from "../loader";
import "react-toastify/dist/ReactToastify.css";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { format } from "date-fns";
import { toast } from "react-toastify";
import ERC20ABI from "../../utils/abi/ERC20.json";
import { useGlobal } from "../../context/globalContext";
import {
  FailureToast,
  SuccessToast,
  ToastOptions,
} from "../../utils/constants";
import { useNetworkOptions } from "../../context/contractDataLoaderService";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../utils/helpers/networks";
import { providers } from "ethers";

function RefundsModal(props) {
  let { snapShots } = props;
  const { address, chainId } = useAccount();
  const { data: client } = useConnectorClient({ chainId });
  const { chain, transport } = client || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const { isUpdate, setIsUpdate } = useGlobal();
  const [oldTime, setOldTime] = useState(null);
  const [newValue, newTime] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [changeContent, setChangeContent] = useState(false);
  const modalContent = useRef(null);
  const { data: networkOptions } = useNetworkOptions();

  // Fetching dates in future
  const currentDate = Math.floor(new Date().getTime() / 1000).toString();
  snapShots = snapShots.filter((date) => date > currentDate);

  const rescheduleSnapshot = async () => {
    newValue.setSeconds(0);
    let newTimeStamp = Math.floor(newValue.getTime() / 1000).toString();
    if (!oldTime) {
      toast.error(FailureToast("", "Select Old time"), ToastOptions);
      return;
    }
    if (!newValue) {
      toast.error(FailureToast("", "Select New time"), ToastOptions);
      return;
    }
    if (newValue && oldTime && newTimeStamp < oldTime) {
      toast.error(
        FailureToast("", "Select New time greater than Old time"),
        ToastOptions
      );
      return;
    }

    const securityTokenContract = new web3.eth.Contract(
      ERC20ABI,
      props.data.security
    );
    setLoading(true);
    try {
      await securityTokenContract.methods
        .rescheduleSnapshot(oldTime, newTimeStamp)
        // .send({from: address});
        .send({ ...(chainId === 137 ? networkOptions : {}), from: address });
      setLoading(false);
      setIsUpdate(!isUpdate);
      toast.success(SuccessToast(), ToastOptions);
      setChangeContent(true);
      modalContent.current.innerHTML = "";
    } catch (err) {
      let error = { err };
      setLoading(false);
      toast.error(FailureToast(), ToastOptions);
    }
  };
  return (
    <>
      {loading ? <Loader /> : null}
      {changeContent && (
        <UiButton
          onClick={() => {
            props.onModalHide();
          }}
          buttonVariant="primary"
          buttonClass="SignUpButton flex-1 ml-0"
          buttonText="Click to close this form&nbsp;&rarr;"
          type="submit"
        />
      )}
      {snapShots.length ? (
        <div className="d-grid gap-2" ref={modalContent}>
          <Row className="ml-1 align-items-center">
            <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
              <Col xs={{ span: 3, offset: 2 }} className="text-left">
                <b>Old Time</b>
              </Col>
              <Col className="pl-0" xs={5}>
                <DropdownButton
                  id="dropdown-basic-button"
                  title={
                    oldTime
                      ? format(
                          new Date(parseInt(oldTime) * 1000),
                          "yyyy-MM-dd hh:mm a"
                        )
                      : "Select Time"
                  }
                >
                  {snapShots?.map((date) => (
                    <Dropdown.Item onClick={(e) => setOldTime(date)}>
                      {format(
                        new Date(parseInt(date) * 1000),
                        "yyyy-MM-dd hh:mm a"
                      )}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Col>
            </Row>
            <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
              <Col xs={{ span: 3, offset: 2 }} className="text-left">
                <b>New Time</b>
              </Col>
              <Col className="pl-0" xs={5}>
                <DateTimePicker onChange={newTime} value={newValue} />
              </Col>
            </Row>
            <Row className="mx-0 my-2 pl-0 width-100 align-items-center">
              <Col className="pl-0">
                <UiButton
                  onClick={() => {
                    rescheduleSnapshot();
                  }}
                  buttonVariant="primary"
                  buttonClass="SignUpButton flex-1 ml-0"
                  buttonText="Reschedule"
                  type="submit"
                />
              </Col>
            </Row>
          </Row>
        </div>
      ) : (
        <p>No snapshots are scheduled</p>
      )}
    </>
  );
}

export function RescheduleSnapshot(props) {
  return <RefundsModal {...props} />;
}
