import { useState, useRef } from "react";
import Loader from "../../../components/loader";
import VerticallyModal from "../../../components/modal/VerticallyModal";
import { Liquidity, contractAddress } from "@verified-network/verified-sdk";
import UiButton from "../../../components/button";
import TextInput from "../../../components/textinput/TextInput";

import { Button, Row, Col } from "react-bootstrap";
import Platform from "./platform";
import Web3 from "web3";
import {
  SuccessToast,
  FailureToast,
  ToastOptions,
  networks,
} from "../../../utils/constants";
import { toast } from "react-toastify";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../../utils/helpers/networks";
import { providers } from "ethers";

function PlatformDetails(props) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [platformName, setPlatformName] = useState("");
  const [platformAddress, setPlatformAddress] = useState("");
  const [changeContent, setChangeContent] = useState(false);
  const [updateTable, setUpdateTable] = useState(false);

  const modalContent = useRef(null);

  let liquidityContract;

  if (provider && provider.getSigner && chainId && address) {
    liquidityContract = new Liquidity(
      provider.getSigner(address),
      contractAddress[chainId].Liquidity
    );
  }
  const handleModalHide = () => {
    setChangeContent(false);
    setUpdateTable(!updateTable);
    setShowModal(false);
  };
  const addPlatform = async () => {
    if (!web3.utils.isAddress(platformAddress)) {
      alert("Enter proper platform address");
    } else if (platformName.length === 0) {
      alert("Enter Platform name");
    }
    setLoading(true);
    try {
      const tx = await liquidityContract.registerPlatform(
        platformAddress,
        platformName
      );
      if (tx.code === "ACTION_REJECTED") {
        toast.error("Transaction rejected by user!", ToastOptions);
      } else {
        const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${tx.response.hash}`;
        toast.success(SuccessToast(transactionLink), ToastOptions);
        setChangeContent(true);
        modalContent.current.innerHTML = "";
      }
      setLoading(false);
    } catch (err) {
      let error = { err };
      console.log(error);
      const transactionLink = `{${networks[chainId].blockExplorerUrls[0]}/tx/${error.err.transactionHash}`;
      setLoading(false);
      toast.error(FailureToast(transactionLink), ToastOptions);
    }
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <section className="d-flex flex-column align-items-start px-0 py-4">
        <div className="mb-3 d-flex justify-content-between w-100 align-items-center">
          <div className="w-50">
            <h3 className="text-left">Platforms</h3>
          </div>
          <div className="w-50 text-right">
            <Button
              onClick={() => {
                setShowModal(true);
              }}
            >
              Add Platform
            </Button>
          </div>
        </div>
        <Platform {...props} updateTable={updateTable} />
      </section>
      <VerticallyModal
        key="connectProvider"
        showModal={showModal}
        modalOnHide={handleModalHide}
        modalSize={"lg"}
        modalHeading={<h3>Platform Details</h3>}
        withFooter={false}
      >
        {changeContent && (
          <UiButton
            onClick={handleModalHide}
            buttonVariant="primary"
            buttonClass="SignUpButton flex-1 ml-0"
            buttonText="Click to close this form&nbsp;&rarr;"
            type="submit"
          />
        )}
        <div className="d-grid gap-2" ref={modalContent}>
          <Row className="ml-1 align-items-center">
            <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
              <Col xs={4}>
                <b>Platform Address</b>
              </Col>
              <Col className="pl-0" xs={6}>
                <TextInput
                  placeholder="Address"
                  onChange={(e) => setPlatformAddress(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
              <Col xs={4}>
                <b>Platform Name</b>
              </Col>
              <Col className="pl-0" xs={6}>
                <TextInput
                  placeholder="Name of platform"
                  onChange={(e) => setPlatformName(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
              <Col className="pl-0" xs={4} />
              <Col className="pl-0" xs={4}>
                <UiButton
                  onClick={() => {
                    addPlatform();
                  }}
                  buttonVariant="primary"
                  buttonClass="SignUpButton flex-1 ml-0"
                  buttonText="Add Platform"
                  type="submit"
                />
                <Col className="pl-0" xs={4} />
              </Col>
            </Row>
          </Row>
        </div>
      </VerticallyModal>
    </>
  );
}

export default function AdminPlatform(props) {
  return <PlatformDetails {...props} />;
}
