import { keyBy } from "lodash";
import { useEffect, useState } from "react";

import { balancerSubgraphClient } from "../adapters";
import { PoolType } from "../pages/poolDetail/balancer/types";
import { fNum2 } from "../pages/poolDetail/balancer/useNumbers";
import ERC20ABI from "../utils/abi/ERC20.json";
import Security from "../utils/abi/Security.json";
import Web3 from "web3";
import { verifiedWalletSubgraphClient } from "../adapters";
import {
  isStableLike,
  orderedPoolTokens,
} from "../pages/poolDetail/balancer/usePool";
import {
  GET_BALANCER_POOL,
  GET_BALANCER_SECONDARY_POOLS,
  GET_BALANCER_PRIMARY_POOLS,
  GET_BALANCER_MARGIN_ISSUE_POOLS,
} from "../utils/queries/balancerQueries";
import {
  GET_VERIFIED_SECURITY,
  GET_VERIFIED_USER,
} from "../utils/queries/walletQueries";
import PoolService from "../services/pool/pool.service";
import { balancerSubgraphService } from "../services/subgraph/balancer-subgraph.service";
import { web3Service } from "../services/web3/web3.service";
import Response from "../utils/response";
import { useGlobal } from "../context/globalContext";
import { ethers, providers } from "ethers";
import { Col, Row } from "react-bootstrap";
import UiButton from "../components/button";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../utils/helpers/networks";
import {
  contractAddress,
  SecuritiesFactory,
} from "@verified-network/verified-sdk";
import { getVerifiedSecurityByAddress } from "./verifiedWalletData";
import { currenciesToFiat } from "../utils/constants";

const parseMarginPools = async (pools, transport) => {
  const marginPools = await Promise.all(
    pools.map(async (pool) => {
      if (transport) {
        const web3 = getWeb3(transport);
        const securityContract = new web3.eth.Contract(ERC20ABI, pool.security);
        const poolId = pool.id;
        const poolType = PoolType.MarginIssue;
        const _security = await securityContract.methods.name().call();
        const security = web3.utils.toAscii(_security.replace(/\0/g, ""));
        const securityType = web3.utils.toAscii(
          ethers.utils.parseBytes32String(pool.securityType)
        );
        const currencyContract = new web3.eth.Contract(ERC20ABI, pool.currency);
        const currency = await currencyContract.methods.name().call();
        const minimumOrderSize = web3.utils.fromWei(pool.minOrderSize, "ether");
        const margin = web3.utils.fromWei(pool.margin, "ether");
        const collateral = web3.utils.fromWei(pool.collateral, "ether");
        const createTime = pool.createTime;
        return {
          poolId,
          poolType,
          security,
          securityType,
          currency,
          minimumOrderSize,
          margin,
          collateral,
          createTime,
        };
      }
    })
  );
  return marginPools;
};

const getBalancerPoolData = async (client, poolAddress) => {
  let data = [];

  try {
    let result = await client.query({
      query: GET_BALANCER_POOL,
      fetchPolicy: "cache-first",
      variables: {
        id: poolAddress,
      },
    });
    data = result;
  } catch (e) {
    console.log("getBalancerPoolData catch", { e });
    console.log(e);
  }

  return data;
};

export function useBalancerData(poolAddress) {
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(true);
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }

  useEffect(() => {
    async function fetchPoolData() {
      if (chainId) {
        let result = await getBalancerPoolData(
          balancerSubgraphClient(chainId),
          poolAddress
        );
        const data = result.data || {};
        const pool = { ...data.pool } || {};
        const pools = [pool];
        const historicalPools = [] //await fetchHistoricalPools(pools);
        const decoratedPools = decorateWithVolumes(historicalPools, pools);
        const formattedPool =
          (await formatPoolData(decoratedPools, transport, account, chainId)) ||
          [];
        setState(formattedPool[0] || {});
        setLoading(false);
      }
    }
    fetchPoolData();
  }, [poolAddress, chainId, transport]);

  return { data: state, loading };
}

const getBalancerPoolsData = async (client, poolType) => {
  let data = [];
  try {
    let result = await client.query({
      query:
        poolType === PoolType.SecondaryIssue
          ? GET_BALANCER_SECONDARY_POOLS()
          : poolType === PoolType.MarginIssue
          ? GET_BALANCER_MARGIN_ISSUE_POOLS()
          : GET_BALANCER_PRIMARY_POOLS(),
      fetchPolicy: "cache-first",
    });
    data = result;
  } catch (e) {
    console.log("PoolData getPoolData catch", e);
    console.log(e);
  }

  return data;
};

const formatPoolData = async (
  data = [],
  transport,
  account,
  chainId,
  setSecondaryManager,
  signer
) => {
  let web3, securityPaused;
  if (transport && chainId) {
    web3 = getWeb3(transport);
  }
  if (web3 && web3.eth) {
    const finalData = await Promise.all(
      data.map(async (pool) => {
        const securityTokenContract = new web3.eth.Contract(
          Security.abi,
          pool.security
        );
        const _security = await securityTokenContract.methods
          .name()
          .call()
          .catch((err) => {
            console.log("errprrr: ", err);
          });
        const marginSecurityName = web3.utils.toAscii(
          web3.utils.toHex(_security.replace(/\0/g, ""))
        );
        const marginSecurityDecimals = Number(
          await securityTokenContract.methods.decimals().call()
        );
        let marginSecurityType;
        pool.securityType
          ? (marginSecurityType = web3.utils.toAscii(
              ethers.utils.parseBytes32String(pool.securityType)
            ))
          : (marginSecurityType = null);
        const currencyToken = pool.currency;
        const currencyContract = new web3.eth.Contract(ERC20ABI, pool.currency);
        const marginCurrencyName = await currencyContract.methods
          .name()
          .call()
          .catch((err) => {
            console.log("error: ", err);
          });
        const marginCurrencySymbol = await currencyContract.methods
          .symbol()
          .call();
        const marginCurrencyDecimals = Number(
          await currencyContract.methods.decimals().call()
        );
        let marginMinimumOrderSize;
        pool.minOrderSize
          ? (marginMinimumOrderSize = web3.utils.fromWei(
              pool.minOrderSize,
              "ether"
            ))
          : (marginMinimumOrderSize = null);
        let margin;
        pool.margin
          ? (margin = web3.utils.fromWei(pool.margin, "ether"))
          : (margin = null);
        let collateral;
        pool.collateral
          ? (collateral = web3.utils.fromWei(pool.collateral, "ether"))
          : (collateral = null);
        const tokens = orderedPoolTokens(
          pool.poolType,
          pool.address,
          pool.tokens
        );
        let issuerName = "",
          investorsNumber = 0,
          verifiedWalletData;
        let composition = "";
        tokens.map((t) => {
          composition = `${composition}${composition ? ", " : ""}${t.symbol}${
            t.weight ? `(${t.weight * 100}%)` : ""
          }`;
        });

        const poolValue = fNum2(pool.totalLiquidity, {
          style: "currency",
          maximumFractionDigits: 0,
        });
        const volume = fNum2(Math.abs(pool.volumeSnapshot), {
          style: "currency",
          maximumFractionDigits: 0,
        });
        const feesSnapshot = fNum2(pool.feesSnapshot, {
          style: "currency",
          maximumFractionDigits: 0,
        });
        const securityToken = pool.security;
        const primarySubscriptionsLatestPrice = fNum2(
          Math.abs(
            pool.primarySubscriptions?.length > 0
              ? pool.primarySubscriptions.at(-1).price
              : "0"
          ),
          {
            style: "currency",
            maximumFractionDigits: 2,
          }
        );
        //TODO: fix securityFactory contract to get restricted countries
        let securityResult = await getVerifiedSecurityByAddress(
          verifiedWalletSubgraphClient(chainId),
          pool.security.toLowerCase()
        );
        const securityData = securityResult?.data?.security;
        const restrictedCountries = securityData?.restrictions
          ? securityData.restrictions.map((country) => {
              return ethers.utils.parseBytes32String(country);
            })
          : [];
        // let securityFactory, restrictedCountries;
        // const factoryAddress = contractAddress[chainId].SecuritiesFactory;
        // if (signer && factoryAddress) {
        //   securityFactory = new SecuritiesFactory(signer, factoryAddress);
        //   restrictedCountries = await securityFactory
        //     .getRestrictedCountries(
        //       "0x38f523b3e8d8fd774f48d5b0667b5758ca0f5d65"
        //     )
        //     .then((res) => {
        //       console.log("return...", res.response);
        //       return res.response.result;
        //     });
        // }
        let minOrderSize;
        if (pool?.minOrderSize) {
          minOrderSize = Response.parseWeiToEther(
            pool.minOrderSize?.toString()
          );
        }

        let productCategory, companyName, marginSecuritySymbol;
        if (pool.security) {
          let result = await verifiedWalletSubgraphClient(chainId).query({
            query: GET_VERIFIED_SECURITY,
            variables: {
              id: pool.security,
            },
          });
          verifiedWalletData = result.data.security;
          if (verifiedWalletData) {
            const investorsList = [
              ...verifiedWalletData.secondaryInvestors.map(
                (investors) => investors.investor || investors.issuer
              ),
            ];
            investorsNumber = [...new Set(investorsList)]; //getting uniq investors
            productCategory = Response.parseBytes32Value(
              verifiedWalletData.productCategory
            );

            let result = await verifiedWalletSubgraphClient(chainId).query({
              query: GET_VERIFIED_USER,
              variables: {
                id: verifiedWalletData.issueManager,
              },
            });
            if (result.data.user)
              issuerName = Response.parseBytes32Value(result.data?.user?.name);
          }

          try {
            securityPaused = await securityTokenContract.methods
              .isPaused()
              .call();
            marginSecuritySymbol = await securityTokenContract.methods
              .symbol()
              .call();
            marginSecuritySymbol = marginSecuritySymbol.replace(/\0/g, "");
            companyName = await securityTokenContract.methods.name().call();
            companyName = companyName.replace(/\0/g, "");
          } catch (e) {
            // console.log("Error", e);
          }
          if (pool.poolType === "SecondaryIssue") {
            if (
              verifiedWalletData?.issueManager.toLowerCase() ===
              account.toLowerCase()
            )
              setSecondaryManager(true);
          }
        }
        investorsNumber = investorsNumber.length;

        const secondaryTradesLatestPrice = fNum2(
          Math.abs(
            pool.secondaryTrades?.length > 0
              ? pool.secondaryTrades.at(-1).price
              : "0"
          ),
          {
            style: "currency",
            maximumFractionDigits: 2,
          }
        );

        return {
          ...pool,
          isStablePool: isStableLike(pool.poolType),
          createTime: Number(pool.createTime),
          tokens,
          composition,
          poolValue,
          volume,
          feesSnapshot,
          primarySubscriptionsLatestPrice,
          secondaryTradesLatestPrice,
          securityToken,
          currencyToken,
          minOrderSize,
          issuerName,
          investorsNumber,
          verifiedWalletData,
          securityPaused,
          productCategory,
          restricted: securityData ? securityData.restricted : false,
          restrictedCountries,
          companyName,
          margin,
          cfiCode: pool.cficode,
          marginCurrencyName,
          marginCurrencySymbol,
          marginCurrencyDecimals,
          marginSecurityDecimals,
          marginMinimumOrderSize,
          marginSecurityName,
          marginSecuritySymbol,
          marginTokensPair: currenciesToFiat[marginCurrencySymbol]
            ? `${marginSecuritySymbol}/${currenciesToFiat[marginCurrencySymbol]}`
            : `${marginSecuritySymbol}/${marginCurrencySymbol}`,
          marginSecurityType,
          collateral,
        };
      })
    );
    return finalData;
  } else {
    return [];
  }
};

const decorateWithVolumes = (historicalPools, pools) => {
  if (!historicalPools || !historicalPools.length) return pools;
  const pastPoolMap = keyBy(historicalPools, "id");
  return pools.map((pool) => {
    const poolService = new PoolService(pool);
    poolService.setFeesSnapshot(pastPoolMap[pool.id]);
    poolService.setVolumeSnapshot(pastPoolMap[pool.id]);
    return poolService.pool;
  });
};

const fetchHistoricalPools = async (pools) => {
  const blockNumber = await web3Service.getTimeTravelBlock("24h");

  const block = { number: blockNumber };
  // console.log("blockNumber...: ", block);
  const isInPoolIds = { id_in: pools.map((pool) => pool.id) };
  // console.log("isInPoolIds...: ", isInPoolIds);
  const pastPoolQuery = await balancerSubgraphService.pools.query({
    where: isInPoolIds,
    block,
  });
  // console.log("query...: ", pastPoolQuery);
  let pastPools = null;
  try {
    const data = await balancerSubgraphService.client.get(pastPoolQuery);
    pastPools = data.pools;
  } catch {
    return pools;
  }

  return pastPools;
};

export function useBalancerPoolsData(poolType, isUpdate = "false") {
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setSecondaryManager } = useGlobal();
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  useEffect(() => {
    async function fetchPoolsData() {
      if (chainId) {
        setLoading(true);
        let result = await getBalancerPoolsData(
          balancerSubgraphClient(chainId),
          poolType
        );
        const data = result.data || {};
        const pools = data.pools || [];
        let formattedData;
        const historicalPools = [] //await fetchHistoricalPools(pools);
        const decoratedPools = decorateWithVolumes(historicalPools, pools);
        formattedData = await formatPoolData(
          decoratedPools,
          transport,
          account,
          chainId,
          setSecondaryManager,
          signer
        );
        formattedData = formattedData?.sort(function (a, b) {
          return b.createTime - a.createTime;
        });
        setState(formattedData);
        setLoading(false);
      }
    }
    fetchPoolsData();
  }, [chainId, isUpdate, transport]);

  return { data: state, loading };
}
