import React, { useRef, useState } from "react";
import Loader from "../../components/loader";
import { useBalancerPoolsData } from "../../context/balancerPoolData";
import PoolsTable from "../poolDetail/PoolsTable";
import WalletConnect from "../../components/common/walletConnect/index";
import { PoolType } from "../poolDetail/balancer/types";
import { Button, Col, Dropdown, DropdownButton, Row } from "react-bootstrap";
import MarginIssueTable from "../../components/table/MarginIssueTable";
import UiTable from "../../components/table/Table";
import TextInput from "../../components/textinput/TextInput";
import VerticallyModal from "../../components/modal/VerticallyModal";
import UiButton from "../../components/button";
import {
  MarginIssueManager,
  contractAddress,
} from "@verified-network/verified-sdk";

import { toast } from "react-toastify";
import {
  OneYearAsSeconds,
  SuccessToast,
  ToastOptions,
  financingInterest,
  networks,
} from "../../utils/constants";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import Web3 from "web3";
import BigNumber from "bignumber.js";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../utils/helpers/networks";
import { providers } from "ethers";

function MarginIssues(props) {
  let { data: marginIssues, loading } = useBalancerPoolsData(
    PoolType.MarginIssue
  );
  marginIssues = marginIssues.sort((a, b) => Number(b.createTime) - Number(a.createTime));
  const [contractCallLoading, setContractCallLoading] = useState(false);
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, marginManagerContract, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    const marginManagerAddress =
      contractAddress[chainId].BalancerMarginIssueManager;
    if (marginManagerAddress) {
      marginManagerContract = new MarginIssueManager(
        provider.getSigner(address),
        marginManagerAddress
      );
    }
    web3 = getWeb3(transport);
  }
  const modalContent = useRef(null);
  const [modalView, setModalView] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [isFinancing, setIsFinancing] = useState(false);
  const [financingTitle, setFinancingTitle] = useState("Select Financing");
  const [financingAmounts, setFinancingAmounts] = useState({
    bid: 0,
    offer: 0,
  });
  const [isDividends, setIsDividends] = useState(false);
  const [dividendsTitle, setDividendsTitle] = useState("Select Dividends");
  const [dividendsAmounts, setDividendsAmounts] = useState({
    bid: 0,
    offer: 0,
  });
  const [isSwaps, setIsSwaps] = useState(false);
  const [swapsTitle, setSwapsTitle] = useState("Select Swaps");
  const [swapsAmounts, setSwapsAmounts] = useState({
    short: 0,
    long: 0,
  });
  const [collateralAmount, setCollateralAmount] = useState(0);
  const [withdrawalAmount, setWithdrawalAmount] = useState(0);
  const [columnSelected, setColumnSelected] = useState({
    security: "",
    currency: "",
    currencyName: "",
    currencyDecimals: 0,
    marginTokensPair: "",
  });

  const commonProps = {
    modalView,
    updateModalView: setModalView,
    showModal,
    updateShowModal: setShowModal,
    columnSelected,
    updateColumnSelected: setColumnSelected,
    chainId,
    marginManagerContract,
  };

  const handleModalHide = () => {
    setModalView(1);
    setShowModal(false);
    setIsFinancing(false);
    setIsDividends(false);
    setIsSwaps(false);
    setFinancingTitle("Select Financing");
    setDividendsTitle("Select Dividends");
    setSwapsTitle("Select Swaps");
    setFinancingAmounts({ bid: 0, offer: 0 });
    setDividendsAmounts({ bid: 0, offer: 0 });
    setSwapsAmounts({ short: 0, long: 0 });
  };

  const headers = [
    { label: "Security", val: "marginSecurityName" },
    { label: "Security Type", val: "marginSecurityType" },
    { label: "Currency", val: "marginCurrencyName" },
    { label: "Start Date", val: "createTime" },
    { label: "Call Action", val: "marginCallAction" },
  ];

  const handleSendCollateral = async () => {
    if (collateralAmount === 0) {
      alert(`${columnSelected.currencyName} amount cannot be zero(0)`);
    } else {
      setContractCallLoading(true);
      await marginManagerContract
        .sendCollateral(
          columnSelected.currency,
          collateralAmount.toString(),
          columnSelected.security
        )
        .then((res) => {
          setContractCallLoading(false);
          if (res.code === "ACTION_REJECTED") {
            toast.error("User Rejected Transaction", ToastOptions);
          } else if (res.code === "UNPREDICTABLE_GAS_LIMIT") {
            toast.error("Send collateral transaction failed", ToastOptions);
          } else {
            setShowModal(false);
            const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${res.response.hash}`;
            toast.success(
              SuccessToast(transactionLink, "Sent collateral succesfully"),
              ToastOptions
            );
          }
        })
        .catch((err) => {
          setContractCallLoading(false);
          toast.error("Send collateral transaction failed", ToastOptions);
        });
    }
  };

  const getRateInSeconds = (amount, rate) => {
    const _rate = rate / 100;
    const amt = Number(
      Number(web3.utils.toWei(amount.toString(), "ether")) *
        (_rate / OneYearAsSeconds)
    ).toFixed(0);
    return amt;
  };

  const getInterestRatesInSeconds = (Amounts) => {
    if (Amounts["bid"]) {
      const bidInSeconds = Amounts.bid / OneYearAsSeconds;
      const offerInSeconds = Amounts.offer / OneYearAsSeconds;
      return {
        bid: (bidInSeconds * 10 ** 18).toFixed(0),
        offer: (offerInSeconds * 10 ** 18).toFixed(0),
      };
    } else if (Amounts["short"]) {
      const shortInSeconds = Amounts.short / OneYearAsSeconds;
      const longInSeconds = Amounts.long / OneYearAsSeconds;
      return {
        short: (shortInSeconds * 10 ** 18).toFixed(0),
        long: (longInSeconds * 10 ** 18).toFixed(0),
      };
    }
    return;
  };

  const handleSettleAccount = async () => {
    let isValidated = true;
    if (
      !isFinancing ||
      financingAmounts.bid === 0 ||
      financingAmounts.offer === 0
    ) {
      alert("Input Financing Interest Percentages");
      isValidated = false;
    }
    if (
      (isDividends && dividendsAmounts.bid === 0) ||
      (isDividends && dividendsAmounts.offer === 0)
    ) {
      alert("Input Dividend  Percentages");
      isValidated = false;
    }
    if (
      (isSwaps && swapsAmounts.short === 0) ||
      (isSwaps && swapsAmounts.long === 0)
    ) {
      alert("Input Swap Percentages");
      isValidated = false;
    }
    if (columnSelected.tokensPair === "") {
      isValidated = false;
    }

    const financingRates = getInterestRatesInSeconds(financingAmounts);
    let dividendRates = { bid: 0, offer: 0 };
    let swapRates = { short: 0, long: 0 };
    if (dividendsAmounts.bid !== 0 && dividendsAmounts.offer !== 0) {
      dividendRates = getInterestRatesInSeconds(dividendsAmounts);
    }
    if (swapsAmounts.short !== 0 && swapsAmounts.long !== 0) {
      swapRates = getInterestRatesInSeconds(swapsAmounts);
    }
    if (isValidated && financingRates) {
      setContractCallLoading(true);
      console.log(
        "expected: ",
        `security, currency, financingBid, financingOffer, dividendBid,
        dividendOffer, swapLong, swapShort`
      );
      console.log(
        "sending: ",
        columnSelected.security,
        columnSelected.currency,
        financingRates.bid.toString(),
        financingRates.offer.toString(),
        dividendRates.bid.toString(),
        dividendRates.offer.toString(),
        swapRates.long.toString(),
        swapRates.short.toString(),
        Date.now().toString()
      );
      await marginManagerContract
        .onSettle(
          columnSelected.security,
          columnSelected.currency,
          financingRates.bid.toString(),
          financingRates.offer.toString(),
          dividendRates.bid.toString(),
          dividendRates.offer.toString(),
          swapRates.long.toString(),
          swapRates.short.toString(),
          Date.now().toString()
        )
        .then((res) => {
          setContractCallLoading(false);
          if (res.code === "ACTION_REJECTED") {
            toast.error("User Rejected Transaction", ToastOptions);
          } else if (res.code === "UNPREDICTABLE_GAS_LIMIT") {
            toast.error("settle account transaction failed", ToastOptions);
          } else {
            setShowModal(false);
            setIsFinancing(false);
            setIsDividends(false);
            setIsSwaps(false);
            setFinancingTitle("Select Financing");
            setDividendsTitle("Select Dividends");
            setSwapsTitle("Select Swaps");
            setFinancingAmounts({ bid: 0, offer: 0 });
            setDividendsAmounts({ bid: 0, offer: 0 });
            setSwapsAmounts({ short: 0, long: 0 });
            const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${res.response.hash}`;
            toast.success(
              SuccessToast(transactionLink, "Account settled succesfully"),
              ToastOptions
            );
          }
        })
        .catch((err) => {
          setContractCallLoading(false);
          console.log("err: ", err);
          toast.error("settle account transaction failed", ToastOptions);
        });
    }
  };

  const handleWithdraw = async () => {
    let isValidated = true;
    if (withdrawalAmount === 0) {
      alert("Input amount to withdraw");
      isValidated = false;
    }
    if (columnSelected.security === "") {
      isValidated = false;
    }
    if (columnSelected.currency === "") {
      isValidated = false;
    }
    if (isValidated) {
      setContractCallLoading(true);
      await marginManagerContract
        .withdraw(
          columnSelected.security,
          columnSelected.currency,
          withdrawalAmount.toString()
        )
        .then((res) => {
          setContractCallLoading(false);
          if (res.code === "ACTION_REJECTED") {
            toast.error("User Rejected Transaction", ToastOptions);
          } else if (res.code === "UNPREDICTABLE_GAS_LIMIT") {
            toast.error("Withdraw profit transaction failed", ToastOptions);
          } else {
            setShowModal(false);
            const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${res.response.hash}`;
            toast.success(
              SuccessToast(transactionLink, "Withdrawn profit succesfully"),
              ToastOptions
            );
          }
        })
        .catch((err) => {
          setContractCallLoading(false);
          toast.error("Withdraw profit transaction failed", ToastOptions);
        });
    }
  };

  let message, heading;
  if (modalView === 1) {
    heading = "Send Collateral";
    message = (
      <>
        {contractCallLoading ? <Loader /> : null}
        <div className="d-grid gap-2" ref={modalContent}>
          <Row className="ml-1 align-items-center">
            <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
              <Col xs={{ span: 4, offset: 2 }} className="text-left">
                <b>{columnSelected.currencyName} Amount</b>
              </Col>
              <Col className="pl-0" xs={3.5}>
                <TextInput
                  placeholder="Value of collateral"
                  fieldType="number"
                  onChange={(e) =>
                    setCollateralAmount(
                      Number(e.target.value) *
                        10 ** columnSelected.currencyDecimals
                    )
                  }
                  required
                />
              </Col>
            </Row>
            <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
              <Col className="pl-0">
                <UiButton
                  onClick={async () => {
                    await handleSendCollateral();
                  }}
                  buttonVariant="primary"
                  buttonClass="SignUpButton flex-1 ml-0"
                  buttonText="Send Collateral"
                  type="submit"
                />
              </Col>
            </Row>
          </Row>
        </div>
      </>
    );
  } else if (modalView === 2) {
    heading = "Transfer Profit";
    message = (
      <>
        {contractCallLoading ? <Loader /> : null}
        <div className="d-grid gap-2" ref={modalContent}>
          <Row className="ml-1 align-items-center">
            <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
              <Col xs={{ span: 4, offset: 2 }} className="text-left">
                <b>{columnSelected.currencyName} Amount</b>
              </Col>
              <Col className="pl-0" xs={3.5}>
                <TextInput
                  placeholder="Amount to withdraw"
                  fieldType="number"
                  onChange={(e) =>
                    setWithdrawalAmount(
                      Number(e.target.value) *
                        10 ** columnSelected.currencyDecimals
                    )
                  }
                  required
                />
              </Col>
            </Row>
            <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
              <Col className="pl-0">
                <UiButton
                  onClick={async () => {
                    await handleWithdraw();
                  }}
                  buttonVariant="primary"
                  buttonClass="SignUpButton flex-1 ml-0"
                  buttonText="Withdraw Profit"
                  type="submit"
                />
              </Col>
            </Row>
          </Row>
        </div>
      </>
    );
  } else if (modalView === 3) {
    heading = "Settle Accounts";
    message = (
      <>
        {contractCallLoading ? <Loader /> : null}
        <div className="d-grid gap-2" ref={modalContent}>
          <Row className="ml-1 align-items-center">
            <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
              <Col xs={{ span: 4, offset: 2 }} className="text-left">
                <b>Financing</b>
              </Col>
              <Col className="pl-0" xs={3.5}>
                <DropdownButton title={financingTitle}>
                  <DropdownItem
                    key={1}
                    onClick={() => {
                      setIsFinancing(true);
                      setFinancingTitle("True");
                    }}
                  >
                    True
                  </DropdownItem>
                  <DropdownItem
                    kwy={2}
                    onClick={() => {
                      setIsFinancing(false);
                      setFinancingTitle("False");
                      setFinancingAmounts({ bid: 0, offer: 0 });
                    }}
                  >
                    False
                  </DropdownItem>
                </DropdownButton>
              </Col>
            </Row>
            {isFinancing && (
              <>
                <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                  <Col xs={{ span: 4, offset: 2 }} className="text-left">
                    <b>Financing Bid %</b>
                  </Col>
                  <Col className="pl-0" xs={3.5}>
                    <TextInput
                      placeholder="Finacing bid in %"
                      fieldType="number"
                      onChange={(e) => {
                        Number(e.target.value) != NaN
                          ? (financingAmounts.bid = Number(e.target.value))
                          : (financingAmounts.bid = 0);
                      }}
                      required
                    />
                  </Col>
                </Row>
                <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                  <Col xs={{ span: 4, offset: 2 }} className="text-left">
                    <b>Financing Offer %</b>
                  </Col>
                  <Col className="pl-0" xs={3.5}>
                    <TextInput
                      placeholder="Finacing offer in %"
                      fieldType="number"
                      onChange={(e) => {
                        Number(e.target.value) != NaN
                          ? (financingAmounts.offer = Number(e.target.value))
                          : (financingAmounts.offer = 0);
                      }}
                      required
                    />
                  </Col>
                </Row>
              </>
            )}
            <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
              <Col xs={{ span: 4, offset: 2 }} className="text-left">
                <b>Dividend</b>
              </Col>
              <Col className="pl-0" xs={3.5}>
                <DropdownButton title={dividendsTitle}>
                  <DropdownItem
                    key={1}
                    onClick={() => {
                      setIsDividends(true);
                      setDividendsTitle("True");
                    }}
                  >
                    True
                  </DropdownItem>
                  <DropdownItem
                    kwy={2}
                    onClick={() => {
                      setIsDividends(false);
                      setDividendsTitle("False");
                      setDividendsAmounts({ bid: 0, offer: 0 });
                    }}
                  >
                    False
                  </DropdownItem>
                </DropdownButton>
              </Col>
            </Row>
            {isDividends && (
              <>
                <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                  <Col xs={{ span: 4, offset: 2 }} className="text-left">
                    <b>Dividend Bid %</b>
                  </Col>
                  <Col className="pl-0" xs={3.5}>
                    <TextInput
                      placeholder="Dividend bid in %"
                      fieldType="number"
                      onChange={(e) => {
                        Number(e.target.value) != NaN
                          ? (dividendsAmounts.bid = Number(e.target.value))
                          : (dividendsAmounts.bid = 0);
                      }}
                      required
                    />
                  </Col>
                </Row>
                <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                  <Col xs={{ span: 4, offset: 2 }} className="text-left">
                    <b>Dividend Offer %</b>
                  </Col>
                  <Col className="pl-0" xs={3.5}>
                    <TextInput
                      placeholder="Dividend offer in %"
                      fieldType="number"
                      onChange={(e) => {
                        Number(e.target.value) != NaN
                          ? (dividendsAmounts.offer = Number(e.target.value))
                          : (dividendsAmounts.offer = 0);
                      }}
                      required
                    />
                  </Col>
                </Row>
              </>
            )}
            <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
              <Col xs={{ span: 4, offset: 2 }} className="text-left">
                <b>Swaps</b>
              </Col>
              <Col className="pl-0" xs={3.5}>
                <DropdownButton title={swapsTitle}>
                  <DropdownItem
                    key={1}
                    onClick={() => {
                      setIsSwaps(true);
                      setSwapsTitle("True");
                    }}
                  >
                    True
                  </DropdownItem>
                  <DropdownItem
                    kwy={2}
                    onClick={() => {
                      setIsSwaps(false);
                      setSwapsTitle("False");
                      setSwapsAmounts({ short: 0, long: 0 });
                    }}
                  >
                    False
                  </DropdownItem>
                </DropdownButton>
              </Col>
            </Row>
            {isSwaps && (
              <>
                <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                  <Col xs={{ span: 4, offset: 2 }} className="text-left">
                    <b>Swap Short %</b>
                  </Col>
                  <Col className="pl-0" xs={3.5}>
                    <TextInput
                      placeholder="Swap short in %"
                      fieldType="number"
                      onChange={(e) => {
                        Number(e.target.value) != NaN
                          ? (swapsAmounts.short = Number(e.target.value))
                          : (swapsAmounts.short = 0);
                      }}
                      required
                    />
                  </Col>
                </Row>
                <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                  <Col xs={{ span: 4, offset: 2 }} className="text-left">
                    <b>Swap Long %</b>
                  </Col>
                  <Col className="pl-0" xs={3.5}>
                    <TextInput
                      placeholder="Swap long in %"
                      fieldType="number"
                      onChange={(e) => {
                        Number(e.target.value) != NaN
                          ? (swapsAmounts.long = Number(e.target.value))
                          : (swapsAmounts.long = 0);
                      }}
                      required
                    />
                  </Col>
                </Row>
              </>
            )}
            <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
              <Col className="pl-0">
                <UiButton
                  onClick={async () => {
                    await handleSettleAccount();
                  }}
                  buttonVariant="primary"
                  buttonClass="SignUpButton flex-1 ml-0"
                  buttonText="Settle Accounts"
                  type="submit"
                />
              </Col>
            </Row>
          </Row>
        </div>
      </>
    );
  }

  // const options = {
  //   onClick: (event, row) => {
  //     onTransaction(row);
  //   },
  // };
  // const onTransaction = (row) => {
  //   console.log(props, row);
  //   props.history.push(
  //     `/margintransaction/${row.id}`
  //   );
  // };

  // onSettle(address security, address currency, uint256 financingPerSec, bool charge, uint256 dividendPerSec, bool payout, uint256 settlementTime)
  return (
    <>
      {loading ? <Loader /> : null}
      <section className="d-flex flex-column align-items-start px-0 py-4">
        <div className="mb-3 d-flex justify-content-between w-100 align-items-center">
          <div className="w-100">
            <h3 className="text-left">Margin Issues</h3>
          </div>
        </div>
        <div className="pools-table width-100">
        <UiTable
          thead={headers}
          tbodyData={marginIssues ?? []}
          // rowEvents={options}
          hover
          bordered={false}
          chainId={chainId}
          contract={marginManagerContract}
          isUpdate
          commonProps={commonProps}
        />
        </div>
        
      </section>
      <VerticallyModal
        key="connectProvider"
        showModal={showModal}
        modalOnHide={handleModalHide}
        modalSize={"lg"}
        modalHeading={
          <h2>
            <b>{heading}</b>
          </h2>
        }
        withFooter={false}
      >
        {message}
      </VerticallyModal>
      <WalletConnect loggedIn={props.loggedIn} />
    </>
  );
}

export default function MarginIssuesManager(props) {
  return <MarginIssues {...props} />;
}
