import React, { useRef, useEffect } from "react";
import anychart from "anychart";

const LineChart = (props) => {
  const chartContainer = useRef(null);
  useEffect(() => {
    const element = chartContainer.current;
    if (element.lastElementChild !== null)
      element.removeChild(element.lastElementChild);

    const data = props.data;
    // create the chart
    const chart = anychart.line();

    // set the data for the chart
    var firstSeries = chart.line(data);
    firstSeries.name(props.firstLabel);
    firstSeries.stroke(props.lineColor);
    firstSeries.legendItem({
      text: props.firstLabel,
      iconType: "circle",
      iconFill: props.lineColor,
    });

    if (props.acquisitionData) {
      var secondSeries = chart.line(props.acquisitionData);
      secondSeries.name(props.secondLabel);
      secondSeries.stroke("#003cff");
      secondSeries.legendItem({
        text: "Acquisition Price",
        iconType: "diamond",
        iconFill: "#003cff",
      });
      chart.legend(true);
    }
    // set the X-axis to display time in minutes
    chart.xAxis().title("Time").labels().format("{%H:%m}");
    // set the Y-axis title
    chart.yAxis().title(props.yAxisTitle);
    // render the chart
    chart.container(chartContainer.current);
    chart.draw();
  }, [props.data, props.data2, props.chartTitle, props.yAxisTitle]);

  return (
    <div
      ref={chartContainer}
      style={{
        height: props.chartHeight,
        width: "100%",
        minWidth: "fit-content",
      }}
    />
  );
};

export default LineChart;
