import { useState, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import UiButton from "../button";
import TextInput from "../textinput/TextInput";
import Loader from "../loader";
import Web3 from "web3";

import ERC20ABI from "../../utils/abi/ERC20.json";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNetworkOptions } from "../../context/contractDataLoaderService";
import {
  PrimaryIssueManager,
  contractAddress,
} from "@verified-network/verified-sdk";
import {
  SuccessToast,
  FailureToast,
  ToastOptions,
  networks,
} from "../../utils/constants";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProvider,
  getProviderNetwork,
  getWeb3,
} from "../../utils/helpers/networks";
const { ethers, providers } = require("ethers");

function AcceptModal(props) {
  const { address, isConnected, chainId, isConnecting } = useAccount();
  const { data: client } = useConnectorClient({ chainId });
  const { chain, transport } = client || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [loading, setLoading] = useState(false);
  const [subscribedAmount, setSubscribedAmount] = useState("");
  const [changeContent, setChangeContent] = useState(false);
  const modalContent = useRef(null);
  const { data: networkOptions } = useNetworkOptions();

  let primaryIssueManagerContract;
  if (address && chainId && provider && provider.getSigner) {
    const BalancerPrimaryIssueManager =
      contractAddress[chainId].BalancerPrimaryIssueManager;
    if (BalancerPrimaryIssueManager) {
      primaryIssueManagerContract = new PrimaryIssueManager(
        provider.getSigner(address),
        BalancerPrimaryIssueManager,
        "balancer"
      );
    }
  }

  const acceptInvestor = async () => {
    if (subscribedAmount.length == 0) {
      toast.error("Enter proper subscribedAmount amount", ToastOptions);
      return;
    }
    if (Number(subscribedAmount) > Number(props.data.qty)) {
      toast.error("Enter amount less than subscribed amount", ToastOptions);
      return;
    }
    const currencyTokenContract = new web3.eth.Contract(
      ERC20ABI,
      props.data.asset
    );
    const tokenDecimals = await currencyTokenContract.methods.decimals().call();
    const subscribedAmountFixed = String(
      subscribedAmount * 10 ** tokenDecimals
    );

    setLoading(true);
    try {
      const tx = await primaryIssueManagerContract.accept(
        props.data.poolId,
        props.data.address,
        subscribedAmountFixed,
        props.data.asset,
        networkOptions
      );
      if (tx.code === "ACTION_REJECTED") {
        toast.error("Transaction rejected by user!", ToastOptions);
      } else {
        const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${tx.response.hash}`;
        toast.success(SuccessToast(transactionLink), ToastOptions);
        setChangeContent(true);
        modalContent.current.innerHTML = "";
      }
      setLoading(false);
    } catch (err) {
      let error = { err };
      console.log(error);
      const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${error.err.transactionHash}`;
      setLoading(false);
      toast.error(FailureToast(transactionLink), ToastOptions);
    }
  };
  return (
    <>
      {loading ? <Loader /> : null}
      {changeContent && (
        <UiButton
          onClick={() => {
            props.onModalHide();
          }}
          buttonVariant="primary"
          buttonClass="SignUpButton flex-1 ml-0"
          buttonText="Click to close this form&nbsp;&rarr;"
          type="submit"
        />
      )}
      <div className="d-grid gap-2" ref={modalContent}>
        <Row className="ml-1 align-items-center">
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Allotment amount</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <TextInput
                placeholder="Amount"
                fieldType="number"
                onChange={(e) => setSubscribedAmount(e.target.value)}
                required
              />
            </Col>
          </Row>
          <Row className="mx-0 my-2 pl-0 width-100 align-items-center">
            <Col className="pl-0">
              <UiButton
                onClick={() => {
                  acceptInvestor();
                }}
                buttonVariant="primary"
                buttonClass="SignUpButton flex-1 ml-0"
                buttonText="Accept"
                type="submit"
              />
            </Col>
          </Row>
        </Row>
      </div>
    </>
  );
}

export function AcceptInvestor(props) {
  return <AcceptModal {...props} />;
}
