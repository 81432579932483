import React from "react";
class UnavailablePage extends React.Component {
  render() {
      return (
        <h1 className="text-center mt-5">
          Sorry, this page isn't available. <br /> <a href="/">RELOAD</a>
        </h1>
      );

  }
}

export default UnavailablePage;
