import { useState, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import Web3 from "web3";
import UiButton from "../../components/button";

import Loader from "../loader";
import TextInput from "../../components/textinput/TextInput";
import ERC20ABI from "../../utils/abi/ERC20.json";
import { toast } from "react-toastify";
import {
  FailureToast,
  SuccessToast,
  ToastOptions,
} from "../../utils/constants";
import { useNetworkOptions } from "../../context/contractDataLoaderService";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../utils/helpers/networks";
import { providers } from "ethers";

function RefundsModal(props) {
  const { address, chainId } = useAccount();
  const { data: client } = useConnectorClient({ chainId });
  const { chain, transport } = client || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [freezeAmount, setFreezeAmount] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [changeContent, setChangeContent] = useState(false);
  const modalContent = useRef(null);
  const { data: networkOptions } = useNetworkOptions();

  const scheduleSnapshot = async () => {
    if (web3) {
      const securityTokenContract = new web3.eth.Contract(
        ERC20ABI,
        props.data.securityAddress
      );
      const amount = web3.utils.toWei(freezeAmount, "ether");
      setLoading(true);
      try {
        await securityTokenContract.methods
          .freeze(props.data.transferor, amount)
          // .send({ from: address });
          .send({ ...(chainId === 137 ? networkOptions : {}), from: address })
          .then(() => {
            setLoading(false);
            toast.success(SuccessToast(), ToastOptions);
            setChangeContent(true);
          })
          .catch(() => {
            setLoading(false);
            toast.error(FailureToast(), ToastOptions);
          });
        modalContent.current.innerHTML = "";
      } catch (err) {
        let error = { err };
        setLoading(false);
        toast.error(FailureToast(), ToastOptions);
      }
    }
  };
  return (
    <>
      {loading ? <Loader /> : null}
      {changeContent && (
        <UiButton
          onClick={() => {
            props.onModalHide();
          }}
          buttonVariant="primary"
          buttonClass="SignUpButton flex-1 ml-0"
          buttonText="Click to close this form&nbsp;&rarr;"
          type="submit"
        />
      )}
      <div className="d-grid gap-2" ref={modalContent}>
        <Row className="ml-1 align-items-center">
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Amount</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <TextInput
                placeholder="Decimals supported"
                fieldType="number"
                required
                value={freezeAmount}
                onChange={(e) => setFreezeAmount(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mx-0 my-2 pl-0 width-100 align-items-center">
            <Col className="pl-0">
              <UiButton
                onClick={() => {
                  scheduleSnapshot();
                }}
                buttonVariant="primary"
                buttonClass="SignUpButton flex-1 ml-0"
                buttonText="Freeze"
                type="submit"
              />
            </Col>
          </Row>
        </Row>
      </div>
    </>
  );
}

export function FreezeInvestor(props) {
  return <RefundsModal {...props} />;
}
