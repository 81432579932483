import React from "react";
import UiTable from "../../components/table/Table";
import MarginProductTable from "../../components/table/MarginProductTable";
import { PoolType } from "../poolDetail/balancer/types";
import { Button, Col, Row } from "react-bootstrap";
import UiButton from "../../components/button";
import { getKycStatus } from "../../utils/helpers";

const PoolsTable = (props) => {
  let headers;
  const onInvest = (row) => {
    const type = row.isKyber ? "kyber" : "balancer";
    props.history.push(`/pool/${type}/${row.id || row.address || row.poolId}`);
  };
  const poolType = props.isMargin
    ? PoolType.MarginIssue
    : props.data.length
    ? props.data[0].poolType
    : PoolType.PrimaryIssue;

  if (poolType === PoolType.PrimaryIssue) {
    headers = props.kycStatus === 3? [
      { label: "Company", val: "companyName" },
      { label: "Security", val: "security" },
      { label: "Category", val: "productCategory" },
      { label: "Amount Offered", val: "securityOffered" },
      { label: "Minimum Price", val: "minimumPrice" },
      { label: "Currency", val: "currency" },
      { label: "Issue Close Date", val: "cutoffTime" },
      { label: "Offering Document", val: "offeringDocs" }
    ]:  [
      { label: "Company", val: "companyName" },
      { label: "Security", val: "security" },
      { label: "Category", val: "productCategory" },
      { label: "Amount Offered", val: "securityOffered" },
      { label: "Minimum Price", val: "minimumPrice" },
      { label: "Currency", val: "currency" },
      { label: "Issue Close Date", val: "cutoffTime" }
    ]
  } else if (poolType === PoolType.SecondaryIssue) {
    headers = [
      { label: "Company", val: "companyName" },
      { label: "Security", val: "security" },
      { label: "Issuer Name", val: "issuerName" },
      { label: "Currency", val: "currency" },
      { label: "Number of investors", val: "investorsNumber" },
      { label: "Minimum Order Size", val: "minOrderSize" },
      { label: "Category", val: "productCategory" },
    ];
  } else if (poolType === PoolType.MarginIssue) {
    headers = [
      // { label: "Company", val: "marginSecurityName" },
      { label: "Symbol", val: "marginSecuritySymbol" },
      { label: "Minimu Order Size", val: "marginMinimumOrderSize" },
      { label: "Currency", val: "marginCurrencySymbol" },
      { label: "Margin", val: "margin" },
      { label: "Collateral", val: "collateral" },
    ];
  }
  const data = [...props.data];

  const options = {
    onClick: (event, row) => {
      onInvest(row);
    },
  };

  return (
    <>
      <div className="pools-table width-100">
          <UiTable
            thead={headers}
            tbodyData={data}
            hover
            bordered={false}
            rowEvents={options}
            kycData={props.kycData}
          />
        </div>
      {/* {poolType === PoolType.MarginIssue && (
        <div className="pools-table width-100">
          <MarginProductTable
            thead={headers}
            tbodyData={data}
            hover
            bordered={false}
            rowEvents={options}
            kycData={props.kycData}
          />
        </div>
      )} */}
    </>
  );
};

export default PoolsTable;
