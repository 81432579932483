import { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import Web3 from "web3";
import UiButton from "../../components/button";
import Loader from "../loader";

import { format } from "date-fns";
import TextInput from "../../components/textinput/TextInput";
import {
  SuccessToast,
  FailureToast,
  ToastOptions,
  auth,
} from "../../utils/constants";
import { create } from "ipfs-http-client";
import ERC20ABI from "../../utils/abi/ERC20.json";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { toast } from "react-toastify";
import { useNetworkOptions } from "../../context/contractDataLoaderService";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../utils/helpers/networks";
import { providers } from "ethers";

function Resolution(props) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const [value, onChange] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(null);
  const [isVotable, setIsVotable] = useState(null);
  const [loading, setLoading] = useState(false);
  const [changeContent, setChangeContent] = useState(false);
  const modalContent = useRef(null);
  const { data: networkOptions } = useNetworkOptions();

  let { snapShots } = props;
  const [ipfsDoc, setIpfsDoc] = useState("");
  const client = create({
    host: "ipfs.infura.io",
    port: 5001,
    protocol: "https",
    headers: {
      authorization: auth,
    },
  });

  const retrieveFile = (e) => {
    const fileData = e.target.files[0];
    const reader = new window.FileReader();
    reader.readAsArrayBuffer(fileData);
    reader.onloadend = () => {
      setIpfsDoc(reader.result);
    };
    e.preventDefault();
  };

  // Fetching dates in future
  const currentDate = Math.floor(new Date().getTime() / 1000).toString();
  snapShots = snapShots.filter((date) => date > currentDate);

  const scheduleSnapshot = async () => {
    let dateTime = Math.floor(value.getTime() / 1000).toString();
    const securityTokenContract = new web3.eth.Contract(
      ERC20ABI,
      props.data.security
    );
    setLoading(true);
    const ipfsCID = (await client.add(ipfsDoc)).path;
    if (ipfsCID.length == 0) {
      alert("File not uploaded, try again");
      setLoading(false);
      return;
    }
    if (!selectedTime) {
      alert("No time selected");
      setLoading(false);
      return;
    }
    try {
      await securityTokenContract.methods
        .createResolution(selectedTime, isVotable, ipfsCID)
        // .send({from: account});
        .send({ ...(chainId === 137 ? networkOptions : {}), from: account });

      setLoading(false);
      toast.success(SuccessToast(), ToastOptions);
      setChangeContent(true);
      modalContent.current.innerHTML = "";
    } catch (err) {
      let error = { err };
      setLoading(false);
      toast.error(FailureToast(), ToastOptions);
    }
  };
  return (
    <>
      {loading ? <Loader /> : null}
      {changeContent && (
        <UiButton
          onClick={() => {
            props.onModalHide();
          }}
          buttonVariant="primary"
          buttonClass="SignUpButton flex-1 ml-0"
          buttonText="Click to close this form&nbsp;&rarr;"
          type="submit"
        />
      )}
      <div className="d-grid gap-2" ref={modalContent}>
        <Row className="ml-1 align-items-center">
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Time</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <DropdownButton
                id="dropdown-basic-button"
                title={
                  selectedTime
                    ? format(
                        new Date(parseInt(selectedTime) * 1000),
                        "yyyy-MM-dd hh:mm a"
                      )
                    : "Select Time"
                }
              >
                {snapShots.length ? (
                  snapShots.map((snapshot) => (
                    <Dropdown.Item onClick={(e) => setSelectedTime(snapshot)}>
                      {format(
                        new Date(parseInt(snapshot) * 1000),
                        "yyyy-MM-dd hh:mm a"
                      )}
                    </Dropdown.Item>
                  ))
                ) : (
                  <div className="pl-2 pr-2">No snapshot scheduled</div>
                )}
              </DropdownButton>
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Votable</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <DropdownButton
                id="dropdown-basic-button"
                title={isVotable ? "Yes" : "No"}
              >
                <Dropdown.Item onClick={(e) => setIsVotable(true)}>
                  Yes
                </Dropdown.Item>
                <Dropdown.Item onClick={(e) => setIsVotable(false)}>
                  No
                </Dropdown.Item>
              </DropdownButton>
            </Col>
          </Row>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Copy of resolution</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <TextInput
                placeholder="ipfs address for uploading documents"
                fieldType="file"
                onChange={(e) => retrieveFile(e)}
                required
              />
            </Col>
          </Row>
          <Row className="mx-0 my-2 pl-0 width-100 align-items-center">
            <Col className="pl-0">
              <UiButton
                onClick={() => {
                  scheduleSnapshot();
                }}
                buttonVariant="primary"
                buttonClass="SignUpButton flex-1 ml-0"
                disabled={snapShots.length ? false : true}
                buttonText="Create resolution"
                type="submit"
              />
            </Col>
          </Row>
        </Row>
      </div>
    </>
  );
}

export function ScheduleResolution(props) {
  return <Resolution {...props} />;
}
