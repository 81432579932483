import UiTable from "../table/Table";
import "react-toastify/dist/ReactToastify.css";

function LiquidityModal(props) {
  const { liquidityOffered } = props.data;
  console.log("liquidityOffered",liquidityOffered);
  const headers = [
    { label: "Offered By", val: "offeredBy" },
    { label: "Currency", val: "currencyName" },
    { label: "Amount Offered", val: "amountOffered" },
    { label: "Desired Price", val: "desiredPrice" },
    { label: "Minimum Price", val: "minimumPrice" },
  ];
  return (
    <>
        <div className="pools-table width-100">
            <UiTable
            thead={headers}
            tbodyData={liquidityOffered ?? []}
            hover
            bordered={false}
            />
        </div>
            
    </>
  );
}

export function OffersList(props) {
  return <LiquidityModal {...props} />;
}